<template>
  <div class="main-content">
    <p class="icon-area">
      <i class="el-icon-circle-check success"></i>
    </p>
    <p>{{ $t('pages.login.registeSuccessText1') }}</p>
    <p>{{ $t('pages.login.registeSuccessText2') }}</p>
    <div class="btn-area">
      <router-link class="btn" to="/auth/login">{{
        $t('pages.login.loginNow')
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.main-content {
  text-align: center;
  .success {
    font-size: 60px;
    color: #4caf50;
    margin-top: 60px;
  }
  p {
    color: $text-color-grey;
    font-size: 16px;
    margin-top: 20px;
  }
  .btn-area {
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
    .btn {
      width: 150px;
      height: 44px;
      line-height: 44px;
      border-radius: 22px;
      background: $base-color;
      color: $text-color-black;
      display: inline-block;
      box-shadow: 0 0 10px 0 rgba($color: #ffbb05, $alpha: 0.7);
    }
  }
}
</style>
