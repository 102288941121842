<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.personal.orgVideos') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-dropdown @command="changeType">
            <el-button class="my-btn" type="primary" size="small">
              {{ type | typeText(locale) }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key) in types"
                :key="key"
                :command="key"
                >{{ value[locale] }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            class="my-btn"
            type="success"
            size="small"
            icon="el-icon-plus"
            @click="
              () => {
                toAdd();
              }
            "
          >
            <span>{{ $t('common.add') }}</span>
          </el-button>
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('common.name2')" prop="title">
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.coverURL" alt="" />
              <span class="name"
                >{{ scope.row.title
                }}<i
                  class="el-icon-edit"
                  v-if="netStatus === 'net'"
                  @click="toEditName(scope.row)"
                ></i
              ></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.personal.duration')"
          width="100"
          prop="duration"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.duration | handleTime }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.size')"
          width="100"
          prop="size"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.size | handleFileSize }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.status')"
          width="100"
          prop="status"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status === 'Normal'" style="color: #67c23a">{{
              scope.row.status | typeText(locale)
            }}</span>
            <span v-else style="color: #f56c6c">{{
              scope.row.status | typeText(locale)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :label="$t('common.createTime')"
          prop="creationTime"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.creationTime | dateFormat('yyyy/MM/dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operation')"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="toAdd(scope.row)"
              v-if="netStatus === 'net'"
              >{{ $t('pages.personal.changeVideo') }}</el-link
            >
            <el-link type="primary" @click="toAdd(scope.row)" v-else>{{
              $t('common.edit')
            }}</el-link>
            <el-link type="danger" @click="toDelete(scope.row)">{{
              $t('common.delete')
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 上传视频 -->
    <el-dialog
      :title="$t('common.upload')"
      width="800px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showVideoUploader"
    >
      <div class="popup-content form">
        <el-form label-position="right" label-width="100px">
          <el-form-item :label="$t('common.title')" prop="video">
            <el-input
              v-model="form.name"
              :disabled="uploading || !!form.videoId"
              :placeholder="$t('pages.personal.plsEnterName')"
            />
          </el-form-item>
          <el-form-item :label="$t('common.upload')" prop="video">
            <upload-auth
              v-if="form.name"
              ref="uploader"
              :title="form.name"
              :currentVideoId="form.videoId"
              @uploading="uploading = true"
              @failed="handleFailed"
              @stopUpload="handleStop"
              @uploaded="handleVideoUploadComplete"
            />
            <div v-else class="notice">
              {{ $t('pages.personal.plsEnterName') }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCancel">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="toUpload" v-loading="uploading">{{
          $t('common.upload')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 局域网上传视频 -->
    <el-dialog
      :title="localForm.videoId ? $t('common.edit') : $t('common.add')"
      width="800px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showLocalVideoUploader"
    >
      <div class="popup-content form">
        <el-form
          ref="form"
          :model="localForm"
          :rules="localRules"
          label-position="right"
          label-width="100px"
        >
          <el-form-item :label="$t('common.title')" prop="title">
            <el-input
              v-model="localForm.title"
              :placeholder="$t('pages.personal.plsEnterName')"
            />
          </el-form-item>
          <el-form-item :label="$t('common.cover')" prop="coverURL">
            <div class="photo-area">
              <div class="preveiw-area" v-if="localForm.coverURL">
                <img :src="localForm.coverURL" />
                <i
                  class="el-icon-error delete"
                  @click="localForm.coverURL = ''"
                ></i>
              </div>
              <div class="upload-area" v-else>
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeImageUpload"
                  :on-success="
                    (res) => {
                      handleImageUploadSuccess(res, 'coverURL');
                    }
                  "
                >
                  <el-button class="my-btn" size="small">
                    <span>{{ $t('common.upload') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">
                  {{ $t('common.format') }}：JPG、PNG、JPEG
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.description')"
            prop="description"
          >
            <el-input
              v-model="localForm.description"
              :placeholder="$t('common.placeHolder_enter')"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.personal.duration')" prop="duration">
            <el-input
              v-model="localForm.duration"
              type="number"
              :placeholder="$t('common.placeHolder_enter')"
            >
              <template slot="append">{{ $t('common.sec') }}</template>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="视频大小" prop="size">
            <el-input
              v-model="localForm.size"
              type="number"
              placeholder="请输入视频大小"
            >
              <template slot="append">MB</template>
            </el-input>
          </el-form-item> -->
          <el-form-item :label="$t('common.uploadV')" prop="localUrl">
            <div class="video-area" v-if="localForm.localUrl">
              <video :src="localForm.localUrl" controls></video>
            </div>
            <el-upload
              class="upload-demo"
              ref="videoUploader"
              :action="`${baseURL}/file/uploadVideo`"
              :headers="{ Authorization: `pBearer ${$store.state.token}` }"
              :on-success="handleLocalVideoUploaded"
              :on-remove="handleLocalVideoRemove"
            >
              <el-button size="small" type="primary">{{
                $t('common.uploadV')
              }}</el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t('pages.personal.uploadRule') }}
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showLocalVideoUploader = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button
          type="primary"
          @click="toSubmitLocal"
          v-loading="uploading"
          >{{ $t('common.submit') }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改视频名称 -->
    <el-dialog
      :title="$t('pages.personal.editName')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showTitleUpdate"
    >
      <div class="popup-content form">
        <el-form label-position="right" label-width="100px">
          <el-form-item :label="$t('common.title')" prop="name">
            <el-input
              v-model="form.name"
              :placeholder="$t('common.placeHolder_enter')"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTitleUpdate = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmit" v-loading="submiting">{{
          $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectVideoOfOrg,
  updateVideoTitleFromOrg,
  deleteVideoFromOrg,
  selectSysEnv,
  insertLocalVideo,
  updateLocalVideo,
} from '@/api/common';
import { baseURL } from '@/config';
import UploadAuth from '@/components/UploadAuth/UploadAuth.vue';
const types = {
  '': {
    cn: '全部',
    en: 'All',
  },
  UploadSucc: {
    cn: '上传完成',
    en: 'Upload Success',
  },
  TranscodeFail: {
    cn: '转码失败',
    en: 'Transcode Fail',
  },
  Normal: {
    cn: '正常',
    en: 'Normal',
  },
};
export default {
  components: { UploadAuth },
  data() {
    return {
      baseURL,
      netStatus: '',
      showLocalVideoUploader: false,
      localForm: {
        videoId: '',
        title: '',
        coverURL: '',
        description: '',
        duration: '',
        size: '',
        localUrl: '',
        createType: 0,
      },
      localRules: {
        title: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
        coverURL: [
          {
            required: true,
            message: this.$t('common.placeHolder_upload'),
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
        duration: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
        localUrl: [
          {
            required: true,
            message: this.$t('common.placeHolder_upload'),
            trigger: 'blur',
          },
        ],
      },
      types,
      type: '',
      showVideoUploader: false,
      uploading: false,
      form: {
        name: '',
        videoId: '',
      },
      // 修改标题
      showTitleUpdate: false,
      submiting: false,
      currentItem: null,

      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 6,
      total: 0,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  filters: {
    typeText(type, locale) {
      return types[type][locale];
    },
  },
  methods: {
    toSubmitLocal() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            if (this.localForm.videoId) {
              // 修改
              this.localForm.orgId = this.$store.state.orgInfo.orgId;
              await updateLocalVideo(this.localForm);
            } else {
              // 新增
              await insertLocalVideo(this.localForm);
            }
            this.$message.success(this.$t('common.success'));
            this.$refs.form.resetFields();
            this.showLocalVideoUploader = false;
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    handleLocalVideoRemove() {
      this.localForm.localUrl = '';
      this.localForm.size = '';
    },
    handleLocalVideoUploaded(res, file) {
      if (res.code === 200) {
        this.localForm.size = file.size;
        this.localForm.localUrl = res.data.url;
      }
    },
    handleImageUploadSuccess(res, field) {
      if (res.code === 200) {
        this.localForm[field] = res.data.url;
      } else {
        this.$message.error(this.$t('common.error'));
      }
    },
    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(this.$t('common.formatWrong'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('common.overSize'));
      }
      return isJPG && isLt2M;
    },
    toDelete(item) {
      this.$confirm(this.$t('common.deleteConfirm'), this.$t('common.prompt'), {
        type: 'warning',
      })
        .then(async () => {
          await deleteVideoFromOrg({ videoIds: item.videoId });
          this.$message.success(this.$t('common.success'));
          this.page = 1;
          this.getData();
        })
        .catch(() => {});
    },
    async toSubmit() {
      try {
        if (this.form.name) {
          if (this.form.name === this.currentItem.title) {
            this.showTitleUpdate = false;
            this.form.name = '';
            this.form.videoId = '';
          } else {
            await updateVideoTitleFromOrg({
              videoId: this.form.videoId,
              title: this.form.name,
              orgId: this.$store.state.orgInfo.orgId,
            });
            this.$message.success(this.$t('common.success'));
            this.showTitleUpdate = false;
            this.form.name = '';
            this.form.videoId = '';
            this.page = 1;
            this.getData();
          }
        } else {
          this.$message.error(this.$t('pages.personal.plsEnterName'));
        }
      } catch (error) {
        console.log(error);
      }
    },
    toEditName(item) {
      this.form.name = item.title;
      this.form.videoId = item.videoId;
      this.currentItem = item;
      this.showTitleUpdate = true;
    },
    toCancel() {
      if (this.uploading) {
        this.$confirm(
          this.$t('common.cancelConfirm'),
          this.$t('common.prompt'),
          {
            type: 'warning',
          }
        )
          .then(() => {
            this.$refs.uploader.toDelete();
          })
          .catch(() => {});
      } else {
        this.showVideoUploader = false;
      }
    },
    async handleVideoUploadComplete() {
      try {
        this.uploading = false;
        this.$refs.uploader.toDelete();
        this.$message.success(this.$t('common.success'));
        this.showVideoUploader = false;
        this.form = {
          name: '',
          videoId: '',
        };
        this.toSearch();
      } catch (error) {
        console.log(error);
        this.uploading = false;
      }
    },
    async handleStop(videoId) {
      await deleteVideoFromOrg({ videoIds: videoId });
      this.showVideoUploader = false;
      this.uploading = false;
      this.page = 1;
      this.getData();
    },
    handleFailed() {
      this.$refs.uploader.file = null;
      this.$refs.uploader.$refs.form.reset();
      this.uploading = false;
    },
    toUpload() {
      if (this.uploading) return;
      if (this.$refs.uploader.file) {
        this.$refs.uploader.toAuthUpload();
      } else {
        this.$message.error(this.$t('pages.personal.plsChooseFile'));
      }
    },
    toAdd(item) {
      if (this.netStatus === 'net') {
        // vod上传
        if (item) {
          this.form.name = item.title;
          this.form.videoId = item.videoId;
        } else {
          this.form.name = '';
          this.form.videoId = '';
        }
        this.showVideoUploader = true;
      } else {
        // 本地上传
        if (item) {
          this.localForm.videoId = item.videoId;
          this.localForm.title = item.title;
          this.localForm.coverURL = item.coverURL;
          this.localForm.description = item.description;
          this.localForm.duration = item.duration;
          this.localForm.size = item.size;
          this.localForm.localUrl = item.localUrl;
          this.localForm.createType = 0;
          if (this.$refs.videoUploader) {
            this.$refs.videoUploader.clearFiles();
          }
        } else {
          if (this.$refs.form) {
            this.$refs.form.resetFields();
          }
          if (this.$refs.videoUploader) {
            this.$refs.videoUploader.clearFiles();
          }
        }
        this.showLocalVideoUploader = true;
      }
    },
    changeType(type) {
      this.type = type;
      this.page = 1;
      this.getData();
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getNetStatus() {
      try {
        const res = await selectSysEnv();
        this.netStatus = res.runWork;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        const res = await selectVideoOfOrg({
          title: this.keywords,
          status: this.type,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getData();
    this.getNetStatus();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .btn-area {
    .el-dropdown {
      margin-right: 10px;
    }
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .el-icon-edit {
      color: $base-color;
      margin-left: 5px;
      cursor: pointer;
      user-select: none;
    }
  }
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.video-area {
  width: 300px;
  video {
    width: 100%;
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
    }
  }
}
</style>
