<template>
  <div class="course-plan-list-wrap">
    <div class="course-plan-list-area">
      <ul
        class="course-plan-list"
        :style="{ transform: `translateX(${this.index * 305}px)` }"
      >
        <li
          v-for="(item, index) in list"
          :key="item.coursePlanId"
          :style="{ backgroundImage: `url(${bgImgList[index % 4]})` }"
          @click="toNext(item)"
        >
          <div class="title" :title="item.coursePlanName">
            {{ item.coursePlanName }}
          </div>
          <div class="line"></div>
          <!-- <div class="bottom-area">
            <price-num
              v-if="item.createType === '1'"
              :val="item.coursePlanPrice"
              color="#FFFFFF"
              :signSize="19"
              :intSize="42"
              :pointSize="42"
              :decSize="42"
              :showDec="false"
            />
            <span v-else class="fake-price"></span>
            <span
              class="btn"
              :style="{ color: colorList[index % 4] }"
              >{{
                $t(item.createType === '1' ? 'common.buy' : 'common.study')
              }}</span
            >
          </div> -->
        </li>
      </ul>
    </div>
    <i
      class="iconfont slide-btn left"
      :class="{ disabled: index >= 0 }"
      @click="toBack"
      >&#xe627;</i
    >
    <i
      class="iconfont slide-btn right"
      :class="{ disabled: index + list.length <= 4 }"
      @click="toForward"
      >&#xe60a;</i
    >
  </div>
</template>

<script>
import PriceNum from '@/components/PriceNum/PriceNum.vue';
export default {
  name: 'CoursePlanList',
  components: { PriceNum },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      bgImgList: [
        `${require('@/assets/images/img-course-plan01.png')}`,
        `${require('@/assets/images/img-course-plan02.png')}`,
        `${require('@/assets/images/img-course-plan03.png')}`,
        `${require('@/assets/images/img-course-plan04.png')}`,
      ],
      colorList: ['#01AFEF', '#19BFBD', '#F7971A', '#7651E3'],
      index: 0,
      flag: true,
    };
  },
  methods: {
    toNext(item) {
      if (item.createType === '1') {
        this.$router.push(`/course-plan/detail/${item.coursePlanId}`);
      } else {
        this.$router.push(`/course-study/${item.coursePlanId}/1/0`);
      }
    },
    toBack() {
      if (this.flag) {
        this.flag = false;
        setTimeout(() => {
          this.flag = true;
        }, 350);
        if (this.index < 0) {
          this.index++;
        }
      }
    },
    toForward() {
      if (this.flag) {
        this.flag = false;
        setTimeout(() => {
          this.flag = true;
        }, 350);
        if (this.list.length + this.index > 4) {
          this.index--;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-plan-list-wrap {
  position: relative;
  .slide-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    color: #d1d1d1;
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;
    &:hover {
      box-shadow: 0 0 100px 0 rgba($color: #000000, $alpha: 0.1);
    }
    &.disabled {
      color: rgba($color: #d1d1d1, $alpha: 0.3);
      &:active {
        color: rgba($color: #d1d1d1, $alpha: 0.3);
      }
    }
    &:active {
      color: $base-color;
    }
    &.left {
      left: -60px;
    }
    &.right {
      right: -60px;
    }
  }
  .course-plan-list-area {
    overflow-x: hidden;
    .course-plan-list {
      white-space: nowrap;
      transition: transform 0.3s;
      text-align: left;
      li {
        width: 280px;
        height: 360px;
        display: inline-block;
        margin-right: 25px;
        padding: 60px 30px 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        // transition: transform .3s;
        // &:hover {
        //   transform: scale(1.02);
        // }
        .title {
          font-size: 28px;
          font-weight: bold;
          color: #ffffff;
          white-space: normal;
          line-height: 34px;
          text-align: left;
          margin-bottom: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .line {
          width: 30px;
          height: 3px;
          background: #ffffff;
        }
        .bottom-area {
          position: absolute;
          width: calc(100% - 60px);
          bottom: 50px;
          left: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 75px;
          .fake-price {
            font-size: 42px;
            color: #ffffff;
          }
          .btn {
            width: 70px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            background: #ffffff;
            text-align: center;
            user-select: none;
            font-size: 16px;
            transition: all 0.3s;
            &:hover {
              box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.3);
            }
          }
        }
      }
    }
  }
}
</style>
