<template>
  <div class="order-pay">
    <div class="steps-area">
      <my-steps :active="active" :list="[$t('pages.other.tijiaodingdan'), $t('pages.other.dingdanzhifu'), $t('pages.other.kaikechenggong')]" />
    </div>
    <div class="bottom-content">
      <div class="order-info" :class="{ expired }">
        <div class="left">
          <div class="first" v-if="!expired">
            <i class="el-icon-circle-check"></i>
            <div class="txt">
              {{$t('pages.other.dingdanyitijiaoqingzai')}}{{ countDownTime }}{{ $t('pages.other.neiwanchengzhifu') }}
            </div>
            <div class="notice">{{$t('pages.other.yuqidingdanjiangbeiquxiao')}}</div>
          </div>
          <div class="first" v-else>
            <i class="el-icon-circle-close"></i>
            <div class="txt"> {{ $t('pages.other.dingdanyiguoqiqingchongxinxiadan') }}</div>
          </div>
          <div class="second">
            <div class="order-num"> {{ $t('pages.other.dingdanbiaohao') }}：{{ orderNo }}</div>
            <div class="name">{{ $t('pages.other.kechengmingcheng') }}：{{ name }}</div>
          </div>
        </div>
        <div class="right">
          {{ $t('pages.other.zhifujine') }}：
          <span class="price">￥{{ payMoney | fixZero }}</span>
        </div>
      </div>
      <div class="pay-type">
        <div class="tabs">
          <div class="title">{{ $t('pages.other.fukuanfangshi') }}：</div>
          <div
            class="tab"
            :class="{ active: type === 0 }"
            @click="toChangeTab(0)"
          >
            {{ $t('pages.other.zhifupingtai') }}
          </div>
          <div
            class="tab"
            :class="[
              { active: type === 1 },
              { disabled: productType === '2' || role === '2' },
            ]"
            @click="toChangeTab(1)"
          >
            {{ $t('pages.other.kechengduhuanma') }}
          </div>
        </div>
        <div class="type-content" v-if="type === 0">
          <div class="types-list">
            <el-radio-group v-model="payType">
              <el-radio :label="3">
                <div class="type-area">
                  <img src="@/assets/images/img-alipay.png" alt="" />
                </div>
              </el-radio>
              <el-radio :label="6">
                <div class="type-area wechat">
                  <img src="@/assets/images/img-wechat-pay.png" alt="" />
                </div>
              </el-radio>
            </el-radio-group>
          </div>
          <div class="btns">
            <div class="intro">
              <div class="title">{{ $t('pages.other.fukuanyudaowenti') }}：</div>
              <p>
                1. {{ $t('pages.other.fukuanwanchengtishi1') }}
              </p>
              <p>2. {{ $t('pages.other.fukuanwanchengtishi2') }}</p>
            </div>
            <!-- <el-button
              type="primary"
              class="my-btn"
              :disabled="expired"
              @click="toNext"
              >{{$t('pages.other.lijizhifu')}}</el-button
            > -->
          </div>
        </div>
        <div class="type-content" v-if="type === 1">
          <div class="form-area">
            <el-form :inline="true" :model="form">
              <el-form-item label="">
                <el-input
                  v-model="form.code"
                  :placeholder="$t('pages.other.shuruduihuanma')"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit"
                  >{{ $t('pages.other.yanzhengduihuanma') }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付二维码弹窗 -->
    <el-dialog
      :title="`${payType === 6 ? $t('titles.wx') :  $t('pages.other.zhifubao')}${$t('pages.other.saomazhifu')}`"
      width="800px"
      :visible.sync="showQrcode"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <div class="qrcode-content">
        <p class="label">{{$t('pages.other.saoyisaofukuan')}}</p>
        <p class="price">{{ payMoney }}</p>
        <div class="qrcode-area">
          <vue-qr
            :text="qrcode"
            :margin="0"
            colorDark="#333333"
            colorLight="#fff"
            :size="200"
          ></vue-qr>
        </div>
        <div class="bottom-intro">
          <i class="iconfont">&#xe6a5;</i>
          <div class="right">
            <p>{{ $t('pages.other.dakaishouji') }}{{ payType === 6 ? $t('titles.wx') :  $t('pages.other.zhifubao') }}</p>
            <p>{{ $t('pages.other.saoyisaofukuan2') }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectOrderInfo,
  wechatPay,
  aliPay,
  selectPayStatus,
  useRedeemCode,
} from '@/api/common';
import vueQr from 'vue-qr';
import MySteps from '@/components/MySteps/MySteps.vue';
let timer = null;
let payTimer = null;
export default {
  components: { MySteps, vueQr },
  data() {
    return {
      flag: true,
      qrcode: '',
      showQrcode: false,
      active: 2,
      type: 0,
      payType: 3,
      duration: 0,
      expired: false,
      orderId: '',
      payMoney: 0,
      orderNo: '',
      productId: '',
      productType: '2',
      name: '',
      form: {
        code: '',
      },
    };
  },
  computed: {
    countDownTime() {
      const d = this.$store.state.locale == 'cn' ? '天' : 'd'
      const h = this.$store.state.locale == 'cn' ? '小时' : 'h'
      const min = this.$store.state.locale == 'cn' ? '分钟' : 'min'
      const s = this.$store.state.locale == 'cn' ? '秒' : 's'
      const day = 24 * 60 * 60;
      const hour = 60 * 60;
      const minute = 60;
      if (this.duration >= day) {
        return `${Math.ceil(this.duration / day)}${d}`;
      } else if (this.duration >= hour) {
        return `${Math.ceil(this.duration / hour)}${h}`;
      } else if (this.duration >= minute) {
        return `${Math.ceil(this.duration / minute)}${min}`;
      } else if (this.duration > 0) {
        return `${this.duration}${s}`;
      } else {
        return '--';
      }
    },
    role() {
      return this.$store.state.userinfo.roles[
        this.$store.state.currentRoleIndex
      ];
    },
  },
  filters: {
    fixZero(val) {
      let str = val.toString();
      const tempArr = str.split('.');
      if (tempArr[1]) {
        if (tempArr[1].length > 1) {
          return val;
        } else {
          return `${tempArr[0]}.${tempArr[1]}`;
        }
      } else {
        return `${tempArr[0]}.00`;
      }
    },
  },
  methods: {
    toChangeTab(index) {
      if (index === 1) {
        if (this.productType !== '2' && this.role !== '2') {
          this.type = index;
        }
      } else {
        this.type = index;
      }
    },
    handleClose() {
      this.$confirm(this.$t('pages.other.quedingyaoguanbima'), this.$t('pages.other.tishi'), {
        type: 'warning',
      })
        .then(async () => {
          clearInterval(payTimer);
          this.showQrcode = false;
        })
        .catch(() => {});
    },
    async checkPayStatus() {
      this.flag = false;
      try {
        const res = await selectPayStatus({ orderNo: this.orderNo });
        this.flag = true;
        console.log(res);
        if (res) {
          clearInterval(payTimer);
          this.$message.success(this.$t('pages.other.zhifuchenggong'));
          this.$router.replace('/pay/order-result');
        }
      } catch (error) {
        console.log(error);
      }
    },
    toCheckPayStatus() {
      if (this.flag) {
        this.checkPayStatus();
      }
    },
    async toNext() {
      try {
        if (this.payType === 6) {
          const res = await wechatPay({ orderNo: this.orderNo });
          this.qrcode = res.code_url;
          this.showQrcode = true;
        } else {
          const res = await aliPay({ orderNo: this.orderNo });
          this.qrcode = res.qr_code;
          this.showQrcode = true;
        }
        payTimer = setInterval(this.toCheckPayStatus, 15000);
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      if (this.form.code) {
        try {
          await useRedeemCode({
            orderId: this.orderId,
            redeemCode: this.form.code,
            productId: this.productId,
            productType: this.productType,
          });
          this.$router.push('/pay/order-result');
        } catch (error) {
          console.log(error);
        }
      }
    },
    countDown() {
      clearInterval(timer);
      timer = setInterval(() => {
        this.duration--;
        if (this.duration <= 0) {
          clearInterval(timer);
          this.expired = true;
        }
      }, 1000);
    },
    async getData() {
      try {
        const res = await selectOrderInfo({ orderId: this.orderId });
        this.duration = Math.floor((res.endTime - new Date().getTime()) / 1000);
        this.payMoney = res.payMoney;
        this.orderNo = res.orderNO;
        this.name = res.productName;
        this.productId = res.productId;
        this.productType = res.productType;
        if (this.duration < 0) {
          this.expired = true;
        } else {
          this.countDown();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.orderId = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {
    clearInterval(timer);
    clearInterval(payTimer);
  },
};
</script>

<style lang="scss" scoped>
.order-pay {
  width: 960px;
  margin: 0 auto;
  .qrcode-content {
    text-align: center;
    padding-bottom: 40px;
    .label {
      font-size: 12px;
    }
    .price {
      color: $base-color;
      font-size: 26px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .qrcode-area {
      display: inline-block;
      padding: 10px;
      box-shadow: 0 0 2px 2px #ddd;
    }
    .bottom-intro {
      width: 130px;
      margin: 20px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .iconfont {
        font-size: 30px;
        color: $base-color;
      }
      .right {
        line-height: 1.5;
      }
    }
  }
  .steps-area {
    padding: 70px 30px 90px;
  }
  .bottom-content {
    margin-bottom: 215px;
    background: #ffffff;
    .order-info {
      background: #e2f8d1;
      border-bottom: 3px solid #b6db96;
      padding: 25px 45px;
      display: flex;
      align-items: center;
      &.expired {
        background: #f8d1d1;
        border-color: #db9696;
        .left {
          .first {
            i {
              color: #cd7070;
            }
            .txt {
              color: #775252;
            }
          }
        }
      }
      .left {
        flex: 1;
        min-width: 0;
        .first {
          display: flex;
          align-items: flex-end;
          line-height: 28px;
          margin-bottom: 20px;
          i {
            font-size: 28px;
            font-weight: bold;
            color: #71cd70;
            margin-right: 10px;
          }
          .txt {
            font-size: 18px;
            color: #5d7752;
          }
          .notice {
            color: $text-color-grey;
            line-height: 22px;
            font-size: 12px;
          }
        }
        .second {
          padding-left: 38px;
          display: flex;
          font-size: 14px;
          .name {
            margin-left: 20px;
            flex: 1;
            min-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .right {
        font-size: 18px;
        .price {
          color: #ff7e08;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
    .pay-type {
      padding-top: 50px;
      .tabs {
        height: 50px;
        font-size: 20px;
        line-height: 49px;
        display: flex;
        border-bottom: 1px solid #c9c9c9;
        .title {
          width: 200px;
          text-align: center;
          color: #666666;
        }
        .tab {
          border: 1px solid #c9c9c9;
          border-bottom: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          width: 250px;
          text-align: center;
          position: relative;
          user-select: none;
          cursor: pointer;
          &.active {
            border-top-color: $base-color;
            box-shadow: 0 -1px 0 0 $base-color;
            &::after {
              content: '';
              width: 100%;
              height: 4px;
              background: #ffffff;
              position: absolute;
              bottom: -2px;
              left: 0;
            }
          }
          & ~ .tab {
            margin-left: 10px;
          }
          &.disabled {
            color: #ddd;
          }
        }
      }
      .types-list {
        padding: 40px 30px 160px;
        .el-radio {
          display: inline-flex;
          align-items: center;
        }
        .type-area {
          width: 165px;
          height: 44px;
          display: inline-flex;
          align-items: center;
          padding-left: 25px;
          border: 1px solid #c9c9c9;
          border-radius: 4px;
          overflow: hidden;
          img {
            height: 20px;
          }
          &.wechat {
            img {
              height: 17px;
            }
          }
        }
      }
      .form-area {
        padding: 40px 30px 290px;
        ::v-deep .el-input {
          width: 300px;
        }
      }
      .btns {
        border-top: 1px solid #c9c9c9;
        padding: 40px 60px;
        display: flex;
        align-items: center;
        .intro {
          flex: 1;
          min-width: 0;
          font-size: 12px;
          color: $text-color-grey;
          .title {
            font-size: 14px;
            font-weight: bold;
          }
        }
        .my-btn {
          color: $text-color-black;
          width: 174px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
