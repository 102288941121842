<template>
  <div>
    <div class="header-area">{{$t('titles.myCollTeacher')}}</div>
    <div class="info-area">
      <ul class="list" v-loading="isLoading" v-if="list.length > 0">
        <li v-for="item in list" :key="item.teacherId">
          <div class="teacher-info">
            <div class="img-area">
              <img :src="item.bustShot" :alt="item.userName">
            </div>
            <div class="info">
              <p class="name">{{ item.userName }}</p>
              <div class="extra">{{ item.teachingAge }}{{$t('pages.other.year')}}{{$t('pages.other.jiaoLin')}}  {{$t('pages.other.numberCourses')}} {{ item.courseSize }}</div>
              <p class="delete" @click.stop.prevent="toToggleColl(item)">{{$t('pages.other.cancelCollection')}}</p>
            </div>
          </div>
          <div class="course-list">
            <div class="course" v-for="course in item.courseDtoList" :key="course.courseId" @click="toDetail(course)">
              <img :src="course.courseCoverUrl">
            </div>
          </div>
          <div class="to-detail">
            <i class="el-icon-more" @click="toTeacherDetail(item)"></i>
          </div>
        </li>
      </ul>
      <the-empty v-else :text="$t('pages.other.noFollowLecture')" />
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCollList, updateCollStatus } from '@/api/common';
import TheEmpty from '../../components/TheEmpty/TheEmpty.vue';

export default {
  components: { TheEmpty },
  data () {
    return {
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 12,
      total: 0
    }
  },
  methods: {
    toTeacherDetail (item) {
      this.$router.push(`/lecturer/detail/${item.teacherId}`)
    },
    toDetail (item) {
      this.$router.push(`/course-zone/detail/${item.courseId}`)
    },
    async toToggleColl (item) {
      this.$confirm(this.$t('pages.other.unfavorite'), this.$t('common.prompt'), {
        type: 'warning'
      })
        .then(async () => {
          try {
            await updateCollStatus({
              productId: item.teacherId,
              productType: 3
            });
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    async getData () {
      this.isLoading = true;
      try {
        const res = await selectMyCollList({
          productType: 3,
          pageNum: this.page,
          pageSize: this.pageSize
        });
        if (res.data) {
          res.data.forEach(item => {
            if (item.courseDtoList && item.courseDtoList.length > 3) {
              item.courseDtoList = item.courseDtoList.slice(0, 2)
            }
          });
          this.list = res.data;
        }
        this.total = res.total;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  },
  created () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #F7F7F7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .list {
    border: 1px solid #E6E6E6;
    li {
      display: flex;
      align-items: center;
      padding: 20px 0 20px 30px;
      &:not(:last-child) {
        border-bottom: 1px solid #E6E6E6;
      }
      .teacher-info {
        width: 330px;
        padding-right: 10px;
        display: flex;
        .img-area {
          width: 75px;
          height: 75px;
          margin-right: 28px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          flex: 1;
          min-width: 0;
          .name {
            line-height: 20px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .extra {
            font-size: 14px;
            color: $text-color-grey;
            margin-bottom: 12px;
          }
          .delete {
            color: $base-color;
            user-select: none;
            cursor: pointer;
          }
        }
      }
      .course-list {
        flex: 1;
        min-width: 0;
        display: flex;
        .course {
          width: 154px;
          height: 100px;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 10px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .to-detail {
        color: #CCCCCC;
        padding: 0 10px;
        height: 100px;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          transform: rotate(90deg);
          font-size: 16px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>