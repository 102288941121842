<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.personal.qna') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <div class="dropdown-area">
            <el-dropdown @command="changeType">
              <el-button class="my-btn" type="primary" size="small">
                {{ type | typeText(locale) }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="key"
                  v-for="(value, key) in types"
                  :key="key"
                  >{{ value[locale] }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column
          :label="$t('pages.personal.question')"
          prop="quizContent"
        />
        <el-table-column
          :label="$t('pages.personal.courseName')"
          width="200"
          prop="courseName"
          align="center"
        />
        <el-table-column
          :label="$t('pages.personal.questioner')"
          width="100"
          prop="userName"
          align="center"
        />
        <el-table-column
          :label="$t('common.status')"
          width="100"
          prop="userName"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isReplay === '1'" style="color: #67c23a">{{
              $t('pages.personal.solved')
            }}</span>
            <span v-if="scope.row.isReplay === '0'" style="color: #f56c6c">{{
              $t('pages.personal.unsolved')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          :label="$t('common.createTime')"
          prop="createTime"
          align="center"
        />
        <el-table-column
          :label="$t('common.operation')"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="toAnswer(scope.row)">{{
              $t('pages.personal.answer')
            }}</el-link>
            <el-link type="danger" @click="toIgnore(scope.row)">{{
              $t('pages.personal.ignore')
            }}</el-link>
            <el-link
              :type="scope.row.isTop === 0 ? 'success' : 'info'"
              @click="toSetTop(scope.row)"
              >{{
                scope.row.isTop === 0
                  ? $t('pages.personal.stick')
                  : $t('pages.personal.unstick')
              }}</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="$t('common.detail')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showDetail"
    >
      <div class="popup-content form" v-if="currentItem">
        <el-form label-position="right" label-width="80px">
          <el-form-item :label="$t('common.title')">
            <el-input
              type="textarea"
              resize="none"
              v-model="currentItem.quizContent"
              :readonly="true"
            />
          </el-form-item>
          <el-form-item :label="$t('common.content')">
            <el-input
              type="textarea"
              :placeholder="$t('common.placeHolder_enter')"
              :autosize="{ minRows: 8, maxRows: 12 }"
              v-model="currentItem.replayContent"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toClose">{{
          $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectQAListOfOrg,
  UpdateQAOfOrg,
  deleteQAOfOrg,
  updateQAStatusOfOrg,
} from '@/api/common';
const types = {
  '': {
    cn: '全部',
    en: 'All',
  },
  '1': {
    cn: '已解答',
    en: 'Solved',
  },
  '0': {
    cn: '未解答',
    en: 'Unsolved',
  },
};
export default {
  data() {
    return {
      types,
      type: '',
      showDetail: false,
      currentItem: null,
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  filters: {
    typeText(type, locale) {
      return types[type][locale];
    },
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.page = 1;
      this.getData();
    },
    async toSetTop(item) {
      try {
        await updateQAStatusOfOrg({ id: item.id });
        item.isTop = item.isTop === 0 ? 1 : 0;
        this.$message.success(this.$t('common.success'));
      } catch (error) {
        console.log(error);
      }
    },
    toIgnore(item) {
      this.$confirm(
        this.$t('pages.personal.ignoreText'),
        this.$t('common.prompt'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          await deleteQAOfOrg({ id: item.id });
          this.$message.success(this.$t('common.success'));
          this.page = 1;
          this.getData();
        })
        .catch(() => {});
    },
    async toClose() {
      // 修改笔记
      if (this.currentItem.replayContent) {
        try {
          await UpdateQAOfOrg({
            id: this.currentItem.id,
            replayContent: this.currentItem.replayContent,
          });
          this.$message.success(this.$t('common.success'));
          this.showDetail = false;
          this.page = 1;
          this.getData();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error(this.$t('common.placeHolder_enter'));
      }
    },
    toAnswer(item) {
      this.currentItem = item;
      this.showDetail = true;
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        const res = await selectQAListOfOrg({
          queryKey: this.keywords,
          isReplay: this.type,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .video-timing {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    user-select: none;
    cursor: pointer;
    .iconfont {
      margin-right: 5px;
    }
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
