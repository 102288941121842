<template>
  <div class="news-detail">
    <!-- banner -->
    <the-banner type="4" />
    <!-- 内容 -->
    <div class="content-area">
      <!-- 面包屑 -->
      <div class="breadcrumb-area">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{$t('titles.index')}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">{{$t('titles.news')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            detail && detail.newTitle
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 新闻详情 -->
      <div class="detail-area" v-if="detail">
        <p class="title">{{ detail.newTitle }}</p>
        <p class="extra">
          <span class="author">{{ detail.orgName }}</span>
          <span class="create-time">{{ detail.createTime }}</span>
        </p>
        <div style="width: 100%;border-bottom: 1px solid #000;margin-bottom: 10px;"></div>
        <!-- <div class="img-area">
          <img :src="detail.newCoverUrl" alt="" />
        </div> -->
        <div class="content" v-html="detail.newContent"></div>
        <!-- 收藏 -->
        <div class="coll-btn-area">
          <vue-star
            animate="animate__animated animate__bounceIn"
            color="#FFBB05"
            :active="isColl"
          >
            <div
              slot="icon"
              v-if="detail"
              class="coll-tag"
              @click="toToggleColl"
            >
              <i class="iconfont" v-if="isColl">&#xe629;</i>
              <i class="iconfont" v-else>&#xe62a;</i>
              {{ isColl ? $t('common.collected') : $t('common.collect') }}
            </div>
          </vue-star>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectNewsDetail, updateCollStatus } from "@/api/common";

import TheBanner from "@/components/TheBanner/TheBanner.vue";
import VueStar from "@/components/VueStar/VueStar";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from "quill";
export default {
  name: "newsDetail",
  components: {
    TheBanner,
    VueStar,
  },
  data() {
    return {
      id: "",
      detail: null,
      isColl: false,
    };
  },
  methods: {
    async toToggleColl() {
      try {
        await updateCollStatus({
          productId: this.id,
          productType: 4,
        });
        this.isColl = !this.isColl;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await selectNewsDetail({
          id: this.id,
        });
        this.isColl = res.isCollected === "1";
        this.detail = res.news;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.news-detail {
  .content-area {
    width: 1200px;
    margin: 0 auto;
    .breadcrumb-area {
      padding: 15px 0;
    }
    .detail-area {
      background: #ffffff;
      padding: 50px 160px;
      margin-bottom: 80px;
      .title {
        font-size: 32px;
        line-height: 52px;
        font-weight: bold;
        text-align: center;
      }
      .extra {
        text-align: center;
        font-size: 14px;
        color: $text-color-grey;
        line-height: 1;
        margin: 17px 0 39px;
        .create-time {
          margin-left: 30px;
        }
      }
      .img-area {
        width: 876px;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
      .content {
        ::v-deep img {
          max-width: 100%;
        }
      }
      .coll-btn-area {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .coll-tag {
          min-width: 114px;
          height: 34px;
          border-radius: 17px;
          color: #ffffff;
          font-size: 14px;
          background: #ff5c5c;
          user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          .iconfont {
            font-size: 14px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}
</style>
<style>
em {
  font-style: italic !important;
}
.editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
}
.quill-img {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-size-small {
  font-size: 10px;
}
.ql-size-large {
  font-size: 18px;
}
.ql-size-huge {
  font-size: 32px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
