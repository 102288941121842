<template>
  <div class="my-orders">
    <!-- 选项卡 -->
    <div class="tab-area">
      <el-tabs v-model="activeName">
        <el-tab-pane name="null">
          <div class="tab" slot="label">
            {{ $t('pages.personal.allOrders') }}
          </div>
        </el-tab-pane>
        <el-tab-pane name="0">
          <div class="tab" slot="label">{{ $t('pages.personal.unpaid') }}</div>
        </el-tab-pane>
        <el-tab-pane name="1">
          <div class="tab" slot="label">{{ $t('pages.personal.paid') }}</div>
        </el-tab-pane>
        <el-tab-pane name="2">
          <div class="tab" slot="label">{{ $t('pages.personal.refund') }}</div>
        </el-tab-pane>
        <el-tab-pane name="-1">
          <div class="tab" slot="label">{{ $t('pages.personal.canceled') }}</div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 内容 -->
    <div class="info-area">
      <div class="tr th">
        <div class="td name">{{ $t('pages.personal.courseName') }}</div>
        <div class="td price">
          {{ $t('pages.personal.price') }}({{ $t('pages.personal.unit') }})
        </div>
        <div class="td discount">
          {{ $t('pages.personal.promotionPrice') }}({{
            $t('pages.personal.unit')
          }})
        </div>
        <div class="td actual-price">
          {{ $t('pages.personal.payPrice') }}({{ $t('pages.personal.unit') }})
        </div>
        <div class="td count">{{ $t('pages.personal.amount') }}</div>
        <div class="td opers"></div>
      </div>
      <div class="ul" v-if="list.length > 0">
        <div class="tr-container" v-for="item in list" :key="item.id">
          <div class="tr-area">
            <div class="title">
              <div class="left">
                <span class="create-time"
                  >{{ $t('pages.personal.orderTime') }}：{{
                    item.createTime
                  }}</span
                >
                <span class="order-no"
                  >{{ $t('pages.personal.orderNumber') }}：{{
                    item.orderNo
                  }}</span
                >
              </div>
              <div class="right">
                <div
                  class="status"
                  :class="{ focus: item.orderStatus === '0' }"
                >
                  {{
                    item.payStatus === '1' && item.orderStatus === '0'
                      ? '4'
                      : item.orderStatus | orderStatusText($store.state.locale)
                  }} {{item.refund ? '（'+$t('pages.personal.refundRejection')+'）' : ''}}
                </div>
              </div>
            </div>
            <div
              class="tr"
              v-for="(course, index) in item.productDtoList"
              :key="index"
            >
              <div class="td name" @click="toCourseDetail(item, course)">
                <div class="img-area">
                  <img :src="course.productImg" alt="" />
                </div>
                <div class="name-text">{{ course.productName }}</div>
              </div>
              <div class="td price">
                <div class="origin-price">
                  ¥{{ course.singlePrice | zeroFix }}
                </div>
              </div>
              <div class="td discount">
                <div class="price">
                  -¥{{ course.singleDiscountPrice | zeroFix }}
                </div>
              </div>
              <div class="td actual-price">
                ¥{{ course.singlePayPrice | zeroFix }}
              </div>
              <div class="td count">{{ course.productNumber }}</div>
              <div class="td opers">
                <div class="btns">
                  <a
                    href="javascript:;"
                    v-if="item.orderStatus === '1' && course.isApprase === '0'"
                    @click="toShowCommentForm(course, item)"
                    >{{ $t('pages.personal.comment') }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-area">
            <div class="left">
              <span
                >{{ $t('pages.personal.orderPrice') }}：￥{{
                  item.orderPrice | zeroFix
                }}</span
              >
              <span
                >{{ $t('pages.personal.promotionPrice') }}：￥{{
                  item.discountPrice | zeroFix
                }}</span
              >
              <span
                >{{ $t('pages.personal.payPrice') }}：￥{{
                  item.payPrice | zeroFix
                }}</span
              >
            </div>
            <div class="right">
              <el-button
                type="primary"
                size="small"
                round
                class="my-btn"
                @click="toShowForm(item)"
                v-if="item.orderStatus === '1' && item.payType !== '2'"
                >{{
                  item.isVoice === '0'
                    ? $t('pages.personal.invoicing')
                    : item.isVoice === '1' || item.isVoice === '3'
                    ? $t('pages.personal.checkProgress')
                    : $t('pages.personal.checkInvoice')
                }}</el-button
              >
              <el-button
                type=""
                size="small"
                round
                class="my-btn"
                @click="toRefund(item)"
                v-if="item.orderStatus === '1' && item.isVoice === '0'"
                >{{ $t('pages.personal.refund') }}</el-button
              >
              <el-button
                type="primary"
                size="small"
                round
                class="my-btn"
                @click="toRefund(item)"
                v-if="item.orderStatus === '2' || item.orderStatus === '3'"
                >{{ $t('pages.personal.checkProcess') }}</el-button
              >
              <el-button
                type="danger"
                size="small"
                round
                class="my-btn"
                @click="toPay(item)"
                v-if="item.orderStatus === '0' && item.payStatus === '0'"
                >{{ $t('pages.personal.pay') }}</el-button
              >
              <el-button
                type=""
                size="small"
                round
                class="my-btn"
                @click="toCancelOrder(item)"
                v-if="item.orderStatus === '0'"
                >{{ $t('pages.personal.cancelOrder') }}</el-button
              >
              <el-button
                type=""
                size="small"
                round
                class="my-btn"
                @click="toCancelRefund(item)"
                v-if="item.orderStatus === '2'"
                >{{ $t('pages.personal.cancelRefund') }}</el-button
              >
              <!-- <el-button
                type=""
                size="small"
                round
                class="my-btn"
                @click="toShowInvoice(item)"
                v-if="item.orderStatus === '1' && item.invoiceUrl"
                >查看发票</el-button
              > -->
            </div>
          </div>
        </div>
      </div>
      <the-empty v-else />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      />
    </div>
    <!-- 开发票弹窗 -->
    <el-dialog
      :title="$t('pages.personal.invoice')"
      width="800px"
      :visible.sync="showFormDialog"
    >
      <div class="popup-content form">
        <div class="title" v-if="currentItem.isVoice !== '0'">
          {{ $t('pages.personal.checkProgress') }}
        </div>
        <el-form
          v-if="currentItem.isVoice !== '0'"
          label-position="right"
          label-width="100px"
          size="mini"
        >
          <el-form-item :label="$t('pages.personal.auditResult')">
            <div
              class="status-text"
              :class="[
                { pass: form.isInvoice === '2' },
                { reject: form.isInvoice === '3' },
              ]"
            >
              {{
                form.isInvoice === '1'
                  ? $t('pages.personal.toAudit')
                  : form.isInvoice === '2'
                  ? $t('pages.personal.pass')
                  : $t('pages.personal.reject')
              }}
            </div>
          </el-form-item>
          <!-- <el-form-item
            :label="$t('pages.personal.invoice')"
            v-if="form.isInvoice === '2'"
          >
            <div class="img-area" @click="toShowInvoice">
              <img :src="currentItem.invoiceUrl" alt="" />
              <i class="icon el-icon-zoom-in"></i>
            </div>
          </el-form-item> -->
          <el-form-item
            :label="$t('pages.personal.rejectReason')"
            v-if="form.isInvoice === '3'"
          >
            {{ form.reamrks }}
          </el-form-item>
        </el-form>
        <div class="title">{{ $t('pages.personal.invoiceInfo') }}</div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="right"
          label-width="120px"
          size="mini"
        >
          <el-form-item :label="$t('pages.personal.orderNumber')">{{
            currentItem.orderNo
          }}</el-form-item>
          <el-form-item :label="$t('pages.personal.invoiceType')">{{
            $t('pages.personal.vat')
          }}</el-form-item>
          <el-form-item :label="$t('pages.personal.invoiceAmount')"
            >{{ currentItem.payPrice }}({{
              $t('pages.personal.unit')
            }})</el-form-item
          >
          <el-form-item :label="$t('pages.personal.titleType')" prop="type">
            <el-radio-group v-model="form.type" :disabled="disabled">
              <el-radio label="0">{{
                $t('pages.personal.titleType1')
              }}</el-radio>
              <el-radio label="1">{{
                $t('pages.personal.titleType2')
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('pages.personal.title')" prop="title">
            <el-input v-model="form.title" :readonly="disabled"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.TFN')"
            prop="taxNo"
            v-if="form.type === '1'"
          >
            <el-input v-model="form.taxNo" :readonly="disabled"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('pages.personal.companyName')"
            prop="companyName"
          >
            <el-input
              v-model="form.companyName"
              :readonly="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.contactName')"
            prop="contactName"
          >
            <el-input
              v-model="form.contactName"
              :readonly="disabled"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('pages.personal.depositBank')"
            prop="bankName"
            v-if="form.type === '1'"
          >
            <el-input v-model="form.bankName" :readonly="disabled"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.bankAccount')"
            prop="bankNum"
            v-if="form.type === '1'"
          >
            <el-input v-model="form.bankNum" :readonly="disabled"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.enterpriceAddr')"
            prop="addr"
            v-if="form.type === '1'"
          >
            <el-input v-model="form.addr" :readonly="disabled"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.enterpriceTel')"
            prop="tel"
            v-if="form.type === '1'"
          >
            <el-input v-model="form.tel" :readonly="disabled"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showFormDialog = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmitInvoiceData">{{
          form.isInvoice === '3'
            ? $t('common.resubmit')
            : currentItem.isVoice === '0'
            ? $t('common.submit')
            : $t('common.close')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 评价弹窗 -->
    <el-dialog
      :title="$t('pages.personal.comment')"
      width="800px"
      :visible.sync="showCommentFormDialog"
    >
      <div class="popup-content form">
        <el-form
          ref="commentForm"
          :model="commentForm"
          label-position="right"
          label-width="100px"
          size="mini"
        >
          <el-form-item :label="$t('pages.personal.rate')">
            <el-rate
              v-model="commentForm.score"
              :colors="['#FF992B', '#FF992B', '#FF992B']"
              :allow-half="true"
              void-color="#DCDCDC"
            ></el-rate>
          </el-form-item>
          <el-form-item :label="$t('pages.personal.content')">
            <el-input
              v-model="commentForm.content"
              type="textarea"
              rows="5"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCommentFormDialog = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmitCommentData">{{
          $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectMyOrderList,
  deleteOrder,
  insertInvoiceData,
  insertCommentData,
  deleteRefundData,
  selectInvoiceData,
} from '@/api/common';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
const orderStatus = {
  '0': {
    cn: '待付款',
    en: 'Unpaid',
  },
  '1': {
    cn: '已付款',
    en: 'Paid',
  },
  '2': {
    cn: '退款中',
    en: 'Refunding',
  },
  '3': {
    cn: '已退款',
    en: 'Refunded',
  },
  '4': {
    cn: '已过期',
    en: 'Overdue',
  },
  '-1': {
    cn: '已取消',
    en: 'Canceled',
  },
};
export default {
  components: { TheEmpty },
  data() {
    return {
      showCommentFormDialog: false,
      currentCommentItem: null,
      commentForm: {
        score: 5,
        content: '',
      },
      showFormDialog: false,
      currentItem: {
        orderNo: '',
        payPrice: 0,
      },
      form: {
        type: '0',
        title: '',
        taxNo: '',
        companyName: '',
        contactName: '',
        bankName: '',
        bankNum: '',
        addr: '',
        tel: '',
        isInvoice: '',
        reamrks: '',
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: ['blur'],
          },
        ],
        taxNo: [
          {
            required: false,
            message: this.$t('common.placeHolder_enter'),
            trigger: ['blur'],
          },
        ],
      },
      disabled: false,
      activeName: 'null',
      total: 0,
      page: 1,
      pageSize: 10,
      list: [],
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
  },
  watch: {
    activeName() {
      this.page = 1;
      this.getData();
    },
    'form.type'(newVal) {
      if (newVal === '1') {
        this.rules.taxNo[0].required = true;
      } else {
        this.rules.taxNo[0].required = false;
      }
    },
  },
  filters: {
    zeroFix(val) {
      // if (val === null) {
      //   val = 0;
      // }
      val = Math.round(parseFloat(val) * 100) / 100;
      let str = val.toString();
      const tempArr = str.split('.');
      if (tempArr[1]) {
        if (tempArr[1].length > 2) {
          tempArr[1] = tempArr[1].slice(0, 2);
          return `${tempArr[0]}.${tempArr[1]}`;
        } else if (tempArr[1].length < 2) {
          return `${tempArr[0]}.${tempArr[1]}0`;
        } else {
          return `${tempArr[0]}.${tempArr[1]}`;
        }
      } else {
        return `${tempArr[0]}.00`;
      }
    },
    orderStatusText(val, locale) {
      return orderStatus[val][locale];
    },
  },
  methods: {
    toCourseDetail(order, course) {
      if (order.productType === '1') {
        this.$router.push(`/course-plan/detail/${course.productId}`);
      } else {
        this.$router.push(`/course-zone/detail/${course.productId}`);
      }
    },
    async toSubmitCommentData() {
      if (this.commentForm.content) {
        try {
          await insertCommentData({
            orderId: this.currentCommentItem.orderId,
            bodyId: this.currentCommentItem.productId,
            bodyType: this.currentCommentItem.productType,
            messageText: this.commentForm.content,
            appraiseAcount: this.commentForm.score,
          });
          this.currentCommentItem.isApprase = '1';
          this.showCommentFormDialog = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error(this.$t('pages.other.qingshurupingjianeirong'));
      }
    },
    toShowCommentForm(item, order) {
      this.currentCommentItem = item;
      this.currentCommentItem.productType = order.productType;
      this.currentCommentItem.orderId = order.orderId;
      this.showCommentFormDialog = true;
    },
    toShowInvoice() {
      this.$imgPreview({
        imgs: [this.currentItem.invoiceUrl],
      });
    },
    toCancelRefund(item) {
      this.$confirm(
        this.$t('pages.personal.cancelRefundText'),
        this.$t('common.prompt'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteRefundData({
              orderNo: item.orderNo,
            });
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toRefund(item) {
      this.$router.push(`/pay/refund/${item.orderId}`);
    },
    toSubmitInvoiceData() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (
            this.currentItem.isVoice === '0' ||
            this.currentItem.isVoice === '3'
          ) {
            try {
              await insertInvoiceData({
                orderNo: this.currentItem.orderNo,
                invoicePrice: this.currentItem.payPrice,
                invoiceType:
                  this.$store.state.locale === 'cn'
                    ? '增值税专票'
                    : 'VAT special invoice',
                riseType: this.form.type,
                riseText: this.form.title,
                dutyNumber: this.form.taxNo,
                depositBank: this.form.bankName,
                bankAcount: this.form.bankNum,
                companyName: this.form.companyName,
                contactName: this.form.contactName,
                companyAdress: this.form.addr,
                companyTel: this.form.tel,
              });
              this.currentItem.isVoice = '1';
              this.$refs['form'].resetFields();
              this.showFormDialog = false;
              this.$message.success(this.$t('common.success'));
            } catch (error) {
              console.log(error);
            }
          } else {
            this.showFormDialog = false;
          }
        }
      });
    },
    async toShowForm(item) {
      this.currentItem = item;
      if (item.isVoice !== '0') {
        try {
          const res = await selectInvoiceData({ orderNo: item.orderNo });
          this.form.type = res.riseType;
          this.form.title = res.riseText;
          this.form.taxNo = res.dutyNumber;
          this.form.bankName = res.depositBank;
          this.form.bankNum = res.bankAcount;
          this.form.addr = res.companyAdress;
          this.form.tel = res.companyTel;
          this.form.isInvoice = res.isInvoice;
          this.form.reamrks = res.reamrks;
          if (this.form.isInvoice === '3') {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
          this.showFormDialog = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.showFormDialog = true;
      }
    },
    toCancelOrder(item) {
      this.$confirm(
        this.$t('pages.personal.cancelOrderText'),
        this.$t('common.prompt'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteOrder({
              orderNo: item.orderNo,
            });
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toPay(item) {
      this.$router.push(`/pay/order-pay/${item.orderId}`);
    },
    async getData() {
      try {
        const res = await selectMyOrderList({
          orderStatus: this.activeName === 'null' ? '' : this.activeName,
          payRole: this.role,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        if (res.data) {
          res.data.forEach((item) => {
            item.productDtoList = JSON.parse(item.productDetail);
          });
        }
        this.list = res.data || [];
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.my-orders {
  padding: 20px 0 30px;
  .tab-area {
    padding: 0 30px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 14px;
      height: 2px;
      width: 100%;
      background: #e4e7ed;
    }
    .el-tabs__item.is-active {
      .tab {
        font-weight: bold;
      }
    }
    .tab {
      color: #333333;
      font-size: 16px;
      font-weight: normal;
      user-select: none;
    }
  }
  .info-area {
    padding: 0 30px;
    .tr {
      display: flex;
      &.th {
        height: 44px;
        background: #fff9eb;
        font-size: 12px;
        margin-bottom: 15px;
      }
      .td {
        display: flex;
        align-items: center;
        justify-content: center;
        &.name {
          flex: 1;
          min-width: 0;
          justify-content: flex-start;
          padding-left: 15px;
        }
        &.price {
          width: 100px;
          justify-content: flex-start;
        }
        &.discount {
          width: 100px;
        }
        &.count {
          width: 95px;
        }
        &.actual-price {
          width: 110px;
        }
        // &.status {
        //   width: 110px;
        // }
        &.opers {
          width: 140px;
        }
      }
    }
    .tr-area {
      border: 1px solid #e8e8e8;
      margin-bottom: 15px;
      .title {
        padding: 0 15px;
        background: #f4f6f9;
        color: #666;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        .left {
          .order-no {
            margin-left: 40px;
          }
        }
        .right {
          .status {
            color: $text-color-grey;
            &.focus {
              color: #ff4c4c;
            }
          }
        }
      }
      .tr {
        align-items: stretch;
        .td {
          font-size: 14px;
          padding-top: 15px;
          padding-bottom: 20px;
          align-items: flex-start;
          &.name {
            display: flex;
            align-items: flex-start;
            padding-right: 10px;
            cursor: pointer;
            &:hover {
              .name-text {
                color: $base-color;
              }
            }
            .name-text {
              flex: 1;
              min-width: 0;
            }
          }
          &.price {
            display: block;
          }
          &.actual-price {
            font-weight: bold;
          }
          &.status {
            border-right: 1px solid #e8e8e8;
          }
          .img-area {
            width: 88px;
            height: 54px;
            margin-right: 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          // .origin-price {
          //   color: $text-color-grey;
          //   text-decoration: line-through;
          // }
          .btns {
            text-align: center;
            a {
              display: block;
              color: $base-color;
            }
          }
        }
      }
    }
    .bottom-area {
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
      align-items: center;
      .left {
        span {
          & ~ span {
            margin-left: 20px;
          }
        }
      }
      .right {
        .my-btn {
          min-width: 80px;
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
  }
  ::v-deep .el-rate__icon {
    font-size: 22px;
  }
  .popup-content {
    .title {
      position: relative;
      font-weight: bold;
      font-size: 16px;
      padding-left: 15px;
      margin-bottom: 10px;
      &::before {
        content: '';
        position: absolute;
        height: 60%;
        width: 3px;
        border-radius: 2px;
        background: $base-color;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .status-text {
      color: $base-color;
      &.pass {
        color: #67c23a;
      }
      &.reject {
        color: #f56c6c;
      }
    }
    .img-area {
      width: 286px;
      height: 160px;
      position: relative;
      cursor: pointer;
      &:hover {
        i.icon {
          display: inline-block;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
      i.icon {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #ffffff;
      }
    }
  }
}
</style>
