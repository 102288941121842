<template>
  <div>
    <div class="header-area">{{$t('titles.survey')}}</div>
    <div class="info-area">
      <div class="t-area" v-if="list.length > 0">
        <dl v-for="(item, index) in list" :key="item.questionId">
          <dt>
            <span class="index">{{ index + 1 }}、</span>
            <span class="stem">{{ $store.state.locale === 'cn' ? item.questionTest : item.questionTestEn}}</span>
          </dt>
          <el-checkbox-group v-model="item.checked">
            <dd v-for="option in item.options" :key="option.questionOptionId">
              <el-checkbox :label="option.questionOptionId">
                {{ $store.state.locale === 'cn' ?  option.optionText : option.optionTextEn}}
              </el-checkbox>
            </dd>
          </el-checkbox-group>
        </dl>
        <div class="oper-area">
          <el-button type="primary" @click="toSubmit">{{$t('common.save')}}</el-button>
        </div>
      </div>
      <!-- 空数据提示 -->
      <the-empty :text="$t('common.noData')" v-else/>
    </div>
  </div>
</template>

<script>
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
import { selectSurveyList, updateSurvey } from '@/api/common';
export default {
  components: { TheEmpty },
  data () {
    return {
      list: []
    }
  },
  methods: {
    async toSubmit () {
      try {
        if (this.list.some(item => { return item.checked.length == 0})) {
          this.$message.warning(this.$t('pages.other.wenjuandiaochabaocuntishi'));
          return false
        }
        const answer = [];
        this.list.forEach(item => {
          answer.push({
            questionId: item.questionId,
            questionOptionId: item.checked.join(',')
          });
        });
        await updateSurvey(answer);
        this.$message.success(this.$t('pages.other.wenjuandiaochabaocun'));
      } catch (error) {
        console.log(error);
      }
    },
    async getData () {
      try {
        const res = await selectSurveyList();
        res.forEach(item => {
          if (item.checked) {
            item.checked = item.checked.split(',').map(item => parseInt(item));
          } else {
            item.checked = [];
          }
        });
        this.list = res;
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #F7F7F7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 50px 50px 80px;
  .t-area {
    dl {
      padding: 10px 0;
      dt {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      dd {
        color: $text-color-grey;
        font-size: 14px;
        padding: 5px 0 5px 26px;
      }
    }
    .oper-area {
      .el-button {
        width: 120px;
      }
    }
  }
}
</style>