<template>
  <div class="lecturer-detail">
    <div class="section">
      <div class="breadcrumb-area">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t('titles.index')
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/lecturer' }">{{
            $t('titles.lecturer')
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            $t('titles.lecturerDetail')
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 讲师信息 -->
      <div class="lecturer-info" v-if="teacherInfo">
        <div class="img-area">
          <img :src="teacherInfo.bustShot" :alt="teacherInfo.userName" />
        </div>
        <div class="info">
          <div class="title">{{ teacherInfo.userName }}</div>
          <p class="edu">
            {{ teacherInfo.officialLabel }} | {{ teacherInfo.school }} |
            {{ $t('pages.other.teachingAge') }}：{{ teacherInfo.teachingAge
            }}{{ $t('pages.other.year') }}
          </p>
          <div class="line">
            <div class="label">{{ $t('pages.other.areasExpertise') }}：</div>
            <div class="value">
              {{
                teacherInfo.classTypeLabels &&
                teacherInfo.classTypeLabels.length > 0
                  ? teacherInfo.classTypeLabels.join('，')
                  : $t('pages.other.noRecords')
              }}
            </div>
          </div>
          <div class="line">
            <div class="label">{{ $t('pages.other.applicableArea') }}：</div>
            <div class="value">
              {{
                teacherInfo.applyAdressLabels &&
                teacherInfo.applyAdressLabels.length > 0
                  ? teacherInfo.applyAdressLabels.join('，')
                  : $t('pages.other.noRecords')
              }}
            </div>
          </div>
          <div class="line">
            <div class="label">
              {{ $t('pages.other.applicableIndustries') }}：
            </div>
            <div class="value">
              {{
                teacherInfo.applyBusinessLabels &&
                teacherInfo.applyBusinessLabels.length > 0
                  ? teacherInfo.applyBusinessLabels.join('，')
                  : $t('pages.other.noRecords')
              }}
            </div>
          </div>
          <div class="line">
            <div class="label">{{ $t('pages.other.haveExpertise') }}：</div>
            <div class="value">
              {{
                teacherInfo.labelList && teacherInfo.labelList.length > 0
                  ? teacherInfo.labelList.join('，')
                  : $t('pages.other.noRecords')
              }}
            </div>
          </div>
        </div>
        <div class="coll-btn-area">
          <vue-star
            animate="animate__animated animate__bounceIn"
            color="#FFBB05"
            :active="isColl"
          >
            <div
              slot="icon"
              v-if="teacherInfo"
              class="coll-tag"
              @click="toToggleColl"
            >
              <i class="iconfont" v-if="isColl">&#xe629;</i>
              <i class="iconfont" v-else>&#xe62a;</i>
              {{
                isColl
                  ? $t('pages.other.followed')
                  : $t('pages.other.followLecturer')
              }}
            </div>
          </vue-star>
        </div>
      </div>
      <!-- 内容 -->
      <div class="bottom-container">
        <!-- 选项卡 -->
        <div class="tab-area">
          <el-tabs class="my-tabs" v-model="activeName">
            <el-tab-pane :label="$t('pages.other.coursesTaught')" name="first">
              <div class="tab-body">
                <ul class="list" v-if="list.length > 0">
                  <li v-for="item in list" :key="item.courseId">
                    <router-link :to="`/course-zone/detail/${item.courseId}`">
                      <div class="img-area">
                        <img
                          :src="item.courseCoverUrl"
                          :alt="item.courseName"
                        />
                      </div>
                      <div class="name-area">
                        <span class="name" :title="item.courseName">{{
                          item.courseName
                        }}</span>
                        <price-num
                          :val="item.coursePrice"
                          color="#FF4C4C"
                          :signSize="12"
                          :intSize="22"
                          :pointSize="22"
                          :decSize="22"
                          :showDec="false"
                        />
                      </div>
                    </router-link>
                  </li>
                </ul>
                <the-empty :text="$t('pages.other.noCourses')" v-else />
                <el-pagination
                  :current-page.sync="page"
                  :page-size="pageSize"
                  :hide-on-single-page="true"
                  background
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                  @current-change="getCourseData"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('pages.other.instructors')" name="second">
              <div
                class="detail-content"
                v-if="teacherInfo"
                v-html="teacherInfo.sufferText"
              >
                <img
                  src="@/assets/images/temp/img-lecturer-detail.png"
                  alt=""
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  selectLecturerDetail,
  selectCourseZoneList,
  updateCollStatus,
} from '@/api/common';

import PriceNum from '@/components/PriceNum/PriceNum.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
import VueStar from '@/components/VueStar/VueStar';

export default {
  name: 'lecturerDetail',
  components: {
    PriceNum,
    TheEmpty,
    VueStar,
  },
  data() {
    return {
      id: '',
      activeName: 'first',
      isColl: false,
      teacherInfo: null,
      page: 1,
      pageSize: 9,
      list: [],
      total: 0,
    };
  },
  methods: {
    async toToggleColl() {
      try {
        await updateCollStatus({
          productId: this.$route.params.id,
          productType: 3,
        });
        this.isColl = !this.isColl;
      } catch (error) {
        console.log(error);
      }
    },
    async getCourseData() {
      try {
        const res = await selectCourseZoneList({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          teacherId: this.id,
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await selectLecturerDetail({
          teacherId: this.id,
          locale: this.$store.state.locale,
        });
        this.teacherInfo = res.teachInfo;
        this.list = res.courseDtoList.data;
        this.total = res.courseDtoList.total;
        this.isColl = res.isCollected === '1';
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.lecturer-detail {
  .section {
    .breadcrumb-area {
      padding: 15px 0;
    }
    width: 1200px;
    margin: 0 auto;
    .lecturer-info {
      padding: 30px 40px;
      background: #ffffff;
      display: flex;
      align-items: center;
      .img-area {
        width: 184px;
        height: 230px;
        margin-right: 60px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex: 1;
        min-width: 0;
        position: relative;
        padding-right: 40px;
        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 24px;
          width: 470px;
          padding-top: 20px;
        }
        .edu {
          margin: 10px 0;
        }
        .line {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
          .value {
            flex: 1;
            min-width: 0;
            color: #3390ff;
          }
        }
      }
      .coll-tag {
        min-width: 180px;
        height: 34px;
        border-radius: 17px;
        color: #ffffff;
        font-size: 14px;
        background: #ff5c5c;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        flex-wrap: nowrap;
        .iconfont {
          font-size: 14px;
          margin-right: 3px;
        }
      }
    }
    .bottom-container {
      margin: 30px 0 50px;
      background: #ffffff;
      padding: 30px 30px 0;
      ::v-deep .el-tabs {
        .el-tabs__active-bar {
          height: 3px;
        }
        .el-tabs__item {
          color: $text-color-grey;
          font-size: 16px;
          &.is-active {
            color: $text-color-black;
            font-weight: bold;
          }
        }
        .el-tabs__content {
          overflow: visible;
        }
      }
      .tab-body {
        padding-bottom: 30px;
      }
      .list {
        margin-left: -40px;
        padding-top: 30px;
        li {
          margin: 0 0 45px 40px;
          width: 338px;
          box-shadow: 0 0 0 1px #e6e6e6;
          display: inline-block;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.02);
          }
          a {
            display: block;
            .img-area {
              height: 190px;
              box-shadow: 0 1px 0 0 #e6e6e6;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              height: 75px;
              padding: 0 20px;
              .name {
                flex: 1;
                min-width: 0;
                margin-right: 10px;
                font-size: 18px;
                color: $text-color-black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .detail-content {
        padding: 0 0 30px 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    padding-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #ffbb05;
      }
    }
  }
}
</style>
