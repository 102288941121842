<template>
  <div class="course-plan">
    <!-- banner -->
    <the-banner type="2" />
    <!-- 筛选 -->
    <the-filter @change="handleFilte" />
    <!-- 列表 -->
    <div class="list-container">
      <ul class="list" v-if="list.length > 0">
        <li v-for="item in list" :key="item.coursePlanId">
          <router-link :to="`/course-plan/detail/${item.coursePlanId}`">
            <div class="img-area">
              <img :src="item.coursePlanCoverUrl" :alt="item.coursePlanName">
            </div>
            <div class="info-area">
              <div class="title" :title="item.coursePlanName">{{ item.coursePlanName }}</div>
              <div class="extra">
                <!-- <price-num :val="item.coursePlanPrice" color="#FF4C4C" :signSize="14" :intSize="26" :pointSize="26" :decSize="26" :showDec="false" /> -->
                <!-- <span class="oper">
                  <i class="iconfont">&#xe610;</i>
                  <span class="text">{{$t('common.buy')}}</span>
                </span> -->
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <the-empty v-else />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { selectCoursePlanList } from '@/api/common';

import PriceNum from '@/components/PriceNum/PriceNum.vue'
import TheBanner from '@/components/TheBanner/TheBanner.vue';
import TheFilter from '@/components/TheFilter/TheFilter.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
export default {
  name: 'coursePlan',
  components: {
    PriceNum, TheBanner, TheFilter, TheEmpty
  },
  data () {
    return {
      category: '',
      area: '',
      industry: '',
      page: 1,
      pageSize: 12,
      total: 0,
      list: [],
    }
  },
  methods: {
    handleFilte (res) {
      console.log(res);
      this.category = res.category;
      this.area = res.area;
      this.industry = res.industry;
      this.page = 1;
      this.list = [];
      this.getData();
    },
    async getData () {
      try {
        const res = await selectCoursePlanList({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          courseTypeId: this.category,
          businessId: this.industry,
          regionId: this.area
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.course-plan {
  .list-container {
    width: 1200px;
    margin: 0 auto;
    min-height: 647px;
    .list {
      padding-top: 25px;
      padding-bottom: 50px;
      margin-left: -18px;
      li {
        width: 286px;
        height: 266px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        display: inline-block;
        margin-bottom: 20px;
        margin-left: 18px;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.05);
        }
        .img-area {
          width: 100%;
          height: 160px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info-area {
          padding: 15px;
          .title {
            line-height: 1;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
            padding: 15px 0 10px;
          }
          .extra {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            line-height: 1;
            .oper {
              cursor: pointer;
              user-select: none;
              width: 80px;
              height: 26px;
              border-radius: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #66A7FF;
              color: #66A7FF;
              i.iconfont {
                font-size: 14px;
                color: #66A7FF;
                margin-right: 7px;
              }
              & ~ .oper {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #FFBB05;
      }
    }
  }
}
</style>
