<template>
  <div id="img-preview-c">
    <el-dialog
      :visible.sync="isShowImageDialog"
      width="1000px"
      lock-scroll
      modal
      close-on-click-modal
      custom-class="dialog"
      @closed="clearImg"
    >
      <el-carousel
        :autoplay="false"
        :initial-index="initIndex"
        height="562px"
        :arrow="imgs.length > 1 ? 'always' : 'never'"
      >
        <el-carousel-item v-for="(img, index) in imgs" :key="index">
          <img
            :src="img"
            style="
              max-width: 100%;
              max-height: 100%;
              display: block;
              margin: 0 auto;
            "
          />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
 
<script>
export default {
  name: "ImgPreview",
  data() {
    return {
      imgs: [],
      initIndex: 0,
      isShowImageDialog: false,
    };
  },
  methods: {
    clearImg() {
      this.imgs = [];
    },
  },
};
</script>
 
<style lang="scss">
#img-preview-c {
  .dialog {
    background: transparent;
    box-shadow: none;
    .el-dialog__header {
      .el-dialog__headerbtn {
        .el-dialog__close {
          color: #ffffff;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-carousel__item {
      display: flex;
      align-items: center;
    }
  }
}
</style>
