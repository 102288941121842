<template>
  <div class="search">
    <!-- tabs -->
    <div class="tabs-area">
      <div class="tabs-wrap">
        <el-tabs v-model="queryType" @tab-click="handleClick">
          <el-tab-pane
            v-for="type in types"
            :key="type.value"
            :label="type.label"
            :name="type.value"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list-container">
      <!-- 课程列表 -->
      <ul class="list course" v-if="list.length > 0 && queryType === '0'">
        <li
          v-for="course in list"
          :key="course.courseId"
          @click="toDetail(course)"
        >
          <div class="img-area">
            <img :src="course.courseCoverUrl" :alt="course.courseName" />
          </div>
          <div class="info-area">
            <div class="title" :title="course.courseName">
              {{ course.courseName }}
            </div>
            <div class="extra">
              <div class="oper-area">
                <span class="oper">
                  <i class="iconfont">&#xe610;</i>
                  <span class="text">{{ $t('common.buy') }}</span>
                </span>
                <span class="oper">
                  <i class="iconfont">&#xe601;</i>
                  <span class="text">{{ $t('common.try') }}</span>
                </span>
              </div>
              <price-num
                :val="course.coursePrice"
                color="#FF4C4C"
                :signSize="14"
                :intSize="26"
                :pointSize="26"
                :decSize="26"
                :showDec="false"
              />
            </div>
          </div>
        </li>
      </ul>
      <!-- 课程计划列表 -->
      <ul class="list course-plan" v-if="list.length > 0 && queryType === '1'">
        <li v-for="item in list" :key="item.coursePlanId">
          <router-link :to="`/course-plan/detail/${item.coursePlanId}`">
            <div class="img-area">
              <img :src="item.coursePlanCoverUrl" :alt="item.coursePlanName" />
            </div>
            <div class="info-area">
              <div class="title" :title="item.coursePlanName">
                {{ item.coursePlanName }}
              </div>
              <div class="extra">
                <price-num
                  :val="item.coursePlanPrice"
                  color="#FF4C4C"
                  :signSize="14"
                  :intSize="26"
                  :pointSize="26"
                  :decSize="26"
                  :showDec="false"
                />
                <span class="oper">
                  <i class="iconfont">&#xe610;</i>
                  <span class="text">{{$t('common.buy')}}</span>
                </span>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- 课程预约列表 -->
      <ul
        class="list course-subscribe"
        v-if="list.length > 0 && queryType === '2'"
      >
        <li v-for="item in list" :key="item.id">
          <router-link :to="`/course-subscribe-detail/${item.courseApointId}`">
            <div class="img-area">
              <img
                :src="item.courseApointCoverUrl"
                :alt="item.courseApointName"
              />
              <!-- <div class="btn subscribe">
                <i class="iconfont">&#xe613;</i>
                <span class="txt">预约</span>
              </div> -->
            </div>
            <div class="middle-area">
              <div class="title-area">
                <span class="text">{{ item.courseApointName }}</span>
                <price-num
                  :val="item.courseApointPrice"
                  color="#FF4C4C"
                  :signSize="14"
                  :intSize="26"
                  :pointSize="26"
                  :decSize="26"
                  :showDec="false"
                />
              </div>
              <div class="start-time">{{ $t('pages.other.kaikeshijian') }}：{{ item.courseStartTime }}</div>
              <div class="extra">
                <span class="teacher-info">
                  <el-avatar :size="40" :src="item.teacherHeadImg" />
                  <span class="name">{{ item.teacherName }}</span>
                </span>
                <span class="num-text">
                  {{ $t('pages.other.yiyou') }}<span class="num">{{ item.apointAcount }}</span
                  >{{ $t('pages.other.renyuyue') }}
                </span>
              </div>
            </div>
            <div class="bottom-area">
              <div class="quote-area">
                <div class="text-wrap">{{ item.courseApointBright }}</div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- 讲师列表 -->
      <ul class="list lecturer" v-if="list.length > 0 && queryType === '3'">
        <li v-for="item in list" :key="item.teacherId">
          <router-link :to="`/lecturer/detail/${item.teacherId}`">
            <div class="up-area">
              <div class="img-area">
                <img :src="item.bustShot" :alt="item.userName" />
              </div>
              <div class="name" :title="item.userName">{{ item.userName }}</div>
            </div>
            <div class="down-area">
              <p class="first">{{ item.officialLabel }} - {{ item.school }}</p>
              <p class="second" :title="item.courseTypeLabals">
                {{ $t('pages.other.areasExpertise') }}：{{ item.courseTypeLabals }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- 新闻列表 -->
      <ul class="list news" v-if="list.length > 0 && queryType === '4'">
        <li v-for="item in list" :key="item.newsId">
          <router-link :to="`/news/detail/${item.newsId}`">
            <div class="img-area">
              <img :src="item.newCoverUrl" alt="" />
            </div>
            <div class="info-area">
              <div class="up-area">
                <div class="title">{{ item.newTitle }}</div>
                <div class="subtitle">
                  <span class="tag">{{ item.ancestorName }}</span>
                </div>
              </div>
              <div class="create-time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </li>
      </ul>
      <the-empty v-if="list.length === 0" />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { elasticSearch } from '@/api/common';

import PriceNum from '@/components/PriceNum/PriceNum.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
export default {
  name: 'search',
  components: {
    PriceNum,
    TheEmpty,
  },
  data() {
    return {
      types: [
        { value: '0', label: this.$t('titles.course') },
        { value: '1', label: this.$t('titles.coursePlan') },
        { value: '2', label: this.$t('titles.courseSubscribe') },
        { value: '3', label: this.$t('titles.lecturers') },
        { value: '4', label: this.$t('titles.news') },
      ],
      queryType: '0',
      page: 1,
      pageSize: 12,
      total: 0,
      list: [],
    };
  },
  computed: {
    keywords() {
      return this.$store.state.keywords;
    },
  },
  watch: {
    keywords() {
      this.getData();
    },
  },
  methods: {
    toDetail(item) {
      console.log(item);
      this.$router.push(`/course-zone/detail/${item.courseId}`);
    },
    handleClick() {
      this.list = [];
      this.page = 1;
      this.getData();
    },
    async getData() {
      console.log(this.keywords);
      try {
        const res = await elasticSearch({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          queryType: this.queryType,
          keyWords: this.keywords,
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.search {
  .tabs-area {
    background: #ffbb05;
    overflow: hidden;
    margin-bottom: 20px;
    .tabs-wrap {
      width: 1200px;
      margin: 0 auto;
      padding: 0 30px;
      ::v-deep .el-tabs {
        .el-tabs__header {
          margin-bottom: 0;
        }
        .el-tabs__content {
          display: none;
        }
        .el-tabs__item {
          font-weight: bold;
          &:hover {
            color: #333;
          }
          &.is-active {
            color: #333;
          }
        }
        .el-tabs__active-bar {
          background-color: #333;
          bottom: 1px;
        }
        .el-tabs__nav-wrap::after {
          background-color: #ffbb05;
        }
      }
    }
  }
  .list-container {
    width: 1200px;
    margin: 0 auto;
    min-height: 647px;
    .list.course {
      padding-top: 25px;
      padding-bottom: 50px;
      margin-left: -18px;
      li {
        width: 286px;
        height: 266px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        display: inline-block;
        margin-bottom: 20px;
        margin-left: 18px;
        transition: transform 0.3s;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
        .img-area {
          width: 100%;
          height: 160px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info-area {
          padding: 15px;
          .title {
            line-height: 1;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
            padding: 15px 0 10px;
          }
          .extra {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            line-height: 1;
            .oper-area {
              color: #999999;
              font-size: 16px;
              .oper {
                cursor: pointer;
                user-select: none;
                i.iconfont {
                  font-size: 14px;
                  color: #66a7ff;
                  margin-right: 7px;
                }
                & ~ .oper {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
    .list.course-plan {
      padding-top: 25px;
      padding-bottom: 50px;
      margin-left: -18px;
      li {
        width: 286px;
        height: 266px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        display: inline-block;
        margin-bottom: 20px;
        margin-left: 18px;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.05);
        }
        .img-area {
          width: 100%;
          height: 160px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info-area {
          padding: 15px;
          .title {
            line-height: 1;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
            padding: 15px 0 10px;
          }
          .extra {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            line-height: 1;
            .oper {
              cursor: pointer;
              user-select: none;
              width: 80px;
              height: 26px;
              border-radius: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #66a7ff;
              color: #66a7ff;
              i.iconfont {
                font-size: 14px;
                color: #66a7ff;
                margin-right: 7px;
              }
              & ~ .oper {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    .list.course-subscribe {
      margin-left: -30px;
      margin-bottom: 40px;
      padding-top: 25px;
      li {
        display: inline-block;
        width: 380px;
        height: 380px;
        margin-left: 30px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        margin-bottom: 20px;
        vertical-align: middle;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.02);
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          .img-area {
            height: 150px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .btn {
              position: absolute;
              right: 10px;
              bottom: 10px;
              background: linear-gradient(0deg, #66a7ff 0%, #66a6fd 100%);
              border-radius: 3px;
              height: 26px;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #ffffff;
              padding: 0 14px;
              .iconfont {
                margin-right: 5px;
                font-size: 12px;
              }
            }
          }
          .middle-area {
            padding: 22px 0 20px;
            width: 350px;
            margin: 0 auto;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.03);
            .title-area {
              display: flex;
              align-items: center;
              .text {
                margin-right: 10px;
                flex: 1;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 20px;
                color: #333;
              }
            }
            .start-time {
              font-size: 12px;
              color: $text-color-grey;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 14px;
            }
            .extra {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .teacher-info {
                display: flex;
                align-items: center;
                .name {
                  font-size: 12px;
                  color: $text-color-grey;
                  margin-left: 15px;
                }
              }
              .num-text {
                font-size: 14px;
                color: $text-color-grey;
                .num {
                  color: #66a7fe;
                }
              }
            }
          }
          .bottom-area {
            height: 72px;
            padding: 5px;
            .quote-area {
              background: url('../../assets/images/bg-quote.png') no-repeat;
              height: 100%;
              font-size: 14px;
              color: $text-color-grey;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 38px;
              .text-wrap {
                text-align: left;
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
    .list.lecturer {
      padding-top: 40px;
      padding-bottom: 70px;
      margin-left: -30px;
      li {
        width: 268px;
        margin-left: 30px;
        margin-bottom: 30px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        display: inline-block;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.05);
        }
        a {
          display: block;
          padding: 26px 30px 22px;
          .up-area {
            border-bottom: 1px solid #eeeeee;
            .img-area {
              width: 142px;
              height: 142px;
              margin: 0 auto;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name {
              font-size: 18px;
              margin: 22px 0 20px;
              text-align: center;
              line-height: 1;
            }
          }
          .down-area {
            font-size: 12px;
            text-align: center;
            line-height: 22px;
            padding-top: 17px;
            .first {
              color: $text-color-black;
              margin-bottom: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .second {
              color: $text-color-grey;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              min-height: 44px;
            }
          }
        }
      }
    }
    .list.news {
      padding: 15px 40px 70px;
      li {
        a {
          display: flex;
          padding: 15px 0;
          .img-area {
            width: 244px;
            height: 165px;
            margin-right: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info-area {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .up-area {
              .title {
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
                margin-bottom: 10px;
                transition: all 0.3s;
              }
              .subtitle {
                .tag {
                  display: inline-block;
                  border: 1px solid #dbdbdb;
                  font-size: 12px;
                  color: $text-color-grey;
                  padding: 0px 9px;
                  border-radius: 5px;
                }
              }
            }
            .create-time {
              font-size: 14px;
              color: $text-color-grey;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #ffbb05;
      }
    }
  }
}
</style>
