export default {
  userLogin: 'User Login',
  account: 'Account',
  password: 'Password',
  CAPTCHA: 'CAPTCHA',
  forgetPwd: 'Forgot login info?',
  wechatLogin: 'Sign in with Wechat',
  accountLogin: 'Sign in via password',
  loginText: 'No account yet, ',
  plh_username: 'Please enter your account',
  plh_username_va:
    'A minimum of 6 characters, consisting of letters, numbers and underscores, and must not begin with a number',
  plh_username_used: 'This user name is registered',
  plh_pwd: 'Please enter your password',
  plh_pwd_2: 'Please enter your password again',
  plh_pwd_3: 'Password length should be at least 6 characters',
  plh_pwd_c: 'The two passwords do not match',
  plh_captcha: 'Please enter CAPTCHA code',
  plh_captcha_wrong: 'CAPTCHA Wrong',
  byPhone: 'Retrieve by phone number',
  byPhoneText: 'send sms text to',
  byPhonePlh: 'no binding phone number',
  byEmail: 'Retrieve by email',
  byEmailText: 'send email to',
  byEmailPlh: 'no binding email',
  email: 'Email',
  email2: 'New email',
  mobile: 'Cellphone number',
  cpwd: 'Confirm password',
  send: 'Send',
  resend: 'Resend',
  plh_phone: 'Please enter correct phone number',
  plh_email: 'Please enter correct email address',
  plh_email_used: 'This email has been registered',
  plh_send: 'CAPTCHA code send success',
  plh_modify_pwd: 'Password changed successfully, please log in!',
  plh_bind_email: 'Email binding has been successful!',
  plh_modify_email: 'Email address has been modified successfully!',
  title_modify: 'Change the password',
  title_reset: 'Retrieve password',
  title_addTel: 'Binding mobile',
  title_addEmail: 'Binding email',
  student: 'Student',
  studentOfInstitution: 'Student Of Institution',
  institution: 'Institution',
  institution2: 'Institution',
  name: 'Name',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  company: 'Company',
  companyAddr: 'Company Address',
  companySize: 'Company Size',
  department: 'Department',
  position: 'Position',
  smsCode: 'SMS Code',
  emailCode: 'Email Code',
  URAText: 'I have read and agreed',
  URA: 'User Registration Agreement',
  USA: 'User Service Agreement',
  PS: 'Privacy Statement',
  ASA: 'Agency Service Agreement',
  and: 'and',
  agreed:
    'RegHub can use my contact information to notify me of any product or service information at any time',
  plh_register_success: 'Register success',
  plh_register_success2:
    'The registration application has been submitted and is waiting for the platform review',
  plh_name: 'Please enter name',
  plh_gender: 'Please select gender',
  plh_institution: 'Please select your institution',
  plh_company: 'Please enter company name',
  plh_company_addr: 'Please enter the company address',
  plh_company_size: 'Please enter the company size',
  plh_dept: 'Please enter department name',
  plh_position: 'Please enter job title',
  plh_phone_used: 'This mobile phone number has been registered',
  plh_phone_or_email: 'Please enter your mobile phone number or email address',
  plh_SMS_code: 'Please enter SMS verification code',
  plh_Email_code: 'Please enter email verification code',
  plh_agreement: 'Please read and agree to the agreement',
  registeSuccessText1: 'Congratulations on registering!',
  registeSuccessText2:
    'Dear user, you have successfully registered ruihe education, please log in',
  loginNow: 'Login Now',
};
