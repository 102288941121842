<template>
  <div>
    <div class="header-area">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
      <span>{{ $t('pages.other.wodewenda') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="dropdown-area">
          <el-dropdown @command="changeType">
            <el-button class="my-btn" type="primary" size="small">
              {{ type | typeText }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="''">{{$t('pages.other.quanbu')}}</el-dropdown-item>
              <el-dropdown-item :command="'1'">{{$t('pages.other.yijieda')}}</el-dropdown-item>
              <el-dropdown-item :command="'0'">{{ $t('pages.other.weijieda') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="name"></div>
      </div>
      <ul class="list" v-if="list.length > 0">
        <li v-for="item in list" :key="item.id">
          <div class="row up">
            <div class="left">{{$t('pages.other.ask')}}</div>
            <div class="info">{{ item.quizContent }}</div>
          </div>
          <div class="row down">
            <div class="left" v-if="item.isReplay === '1'"></div>
            <div class="info">
              <div class="author-area" v-if="item.isReplay === '1'">
                <el-avatar :size="28" :src="item.replayImg" />
                <span class="name"
                  >{{ item.replayName
                  }}<span v-if="item.teachingAge">
                    | {{ item.teachingAge }}{{ $t('pages.other.nianjiaoling') }}</span
                  ></span
                >
              </div>
              <div class="text" v-if="item.isReplay === '1'">
                {{ item.replayContent }}
              </div>
              <div class="waiting" v-else>
                <div class="wrap">
                  <i class="el-icon-time"></i>
                  <span>{{$t('pages.other.dengdaihuida')}}...</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <the-empty v-else />
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectQAList } from '@/api/common';
import TheEmpty from '../../components/TheEmpty/TheEmpty.vue';
let types = {};
export default {
  components: { TheEmpty },
  data() {
    return {
      type: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  filters: {
    typeText(type) {
      return types[type];
    },
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        const res = await selectQAList({
          courseId: this.$route.params.id,
          isReplay: this.type,
          isOwn: 1,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created () {
    types = {
      '': this.$t('pages.other.quanbu'),
      1: this.$t('pages.other.yijieda'),
      0: this.$t('pages.other.weijieda'),
    }
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  i {
    padding: 0 10px;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: $base-color;
    }
  }
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .dropdown-area {
      width: 200px;
    }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: bold;
      text-align: right;
    }
  }
  .list {
    li {
      padding: 35px 0 30px;
      border-bottom: 1px solid #f7f7f7;
      .row {
        position: relative;
        padding-left: 38px;
        &.up {
          .info {
            font-size: 16px;
            color: #333;
          }
        }
        &.down {
          margin-top: 20px;
          .author-area {
            display: flex;
            align-items: center;
            font-size: 16px;
            .name {
              margin-left: 13px;
            }
          }
          .text {
            font-size: 14px;
            line-height: 24px;
            color: $text-color-grey;
            margin-top: 7px;
          }
          .waiting {
            border: 1px solid #f7f7f7;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #aaa;
            i {
              margin-right: 5px;
            }
          }
        }
        .left {
          background: $base-color;
          font-size: 12px;
          color: #ffffff;
          border-radius: 5px;
          border-bottom-right-radius: 0;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 3px;
          user-select: none;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
