<template>
  <div class="banner-area" v-if="bannerList.length > 0">
    <el-carousel
      :interval="5000"
      :height="bannerHeight + 'px'"
      :arrow="bannerList.length > 1 ? 'always' : 'never'"
      indicator-position="none"
    >
      <el-carousel-item v-for="(banner, index) in bannerList" :key="index">
        <!-- 不跳转 -->
        <a href="javascript:;" v-if="banner.redirectType === '0'">
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </a>
        <!-- 跳转外链 -->
        <a
          :href="banner.redirectUrl"
          v-if="banner.redirectType === '1'"
          target="_blank"
        >
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </a>
        <!-- 跳转课程详情 -->
        <router-link
          :to="`/course-zone/detail/${banner.redirectUrl}`"
          v-else-if="banner.redirectType === '2'"
        >
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </router-link>
        <!-- 跳转课程计划 -->
        <router-link
          :to="`/course-plan/detail/${banner.redirectUrl}`"
          v-else-if="banner.redirectType === '3'"
        >
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </router-link>
        <!-- 跳转课程预约 -->
        <router-link
          :to="`/course-subscribe-detail/${banner.redirectUrl}`"
          v-else-if="banner.redirectType === '4'"
        >
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </router-link>
        <!-- 跳转讲师详情 -->
        <router-link :to="`/lecturer/detail/${banner.redirectUrl}`" v-else>
          <img ref="banner" :src="banner.slideImg" alt="" @load="imgLoad" />
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { selectBannerList } from '@/api/common';
export default {
  props: {
    // arrow: {
    //   type: String,
    //   default: 'always'
    // },
    type: {
      // 0首页，1课程专区，2课程计划，3讲师风貌，4新闻，5课程预约
      require: true,
      type: String,
    },
  },
  data() {
    return {
      bannerList: [],
      bannerHeight: '',
    };
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        let ref = this.$refs;
        // console.log(ref)
        this.bannerHeight =
          ref.banner && ref.banner.length ? ref.banner[0].height -100: '';
      });
    },
    async getBanner() {
      try {
        const params = {
          sildeType: this.type,
          locale: this.$store.state.locale,
        };
        if (this.$store.state.orgInfo && this.$store.state.orgInfo.orgId) {
          params.orgId = this.$store.state.orgInfo.orgId;
        }
        const res = await selectBannerList(params);
        this.bannerList = res;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.imgLoad();
    window.addEventListener(
      'resize',
      () => {
        this.imgLoad();
      },
      false
    );
  },
  created() {
    this.getBanner();
  },
};
</script>

<style lang="scss" scoped>
.banner-area {
  a {
    display: block;
    width: 100%;
    img {
      width: 100%;
    }
  }
  ::v-deep .el-carousel__arrow {
    width: 60px;
    height: 60px;
    font-size: 30px;
    color: $text-color-grey;
    border: 2px solid $text-color-grey;
    opacity: 0.5;
    &.el-carousel__arrow--left {
      left: 50%;
      transform: translate(-600px, -50%);
    }
    &.el-carousel__arrow--right {
      right: 50%;
      transform: translate(600px, -50%);
    }
  }
}
</style>
