<template>
  <div>
    <div class="header-area">{{$t('titles.mySubscribe')}}</div>
    <div class="info-area">
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="small"
        style="width: 100%"
      >
        <el-table-column :label="$t('titles.courseZoneName')" width="310">
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.courseApointCoverUrl" alt="" />
              <div class="info">
                <router-link
                  class="name"
                  :to="`/course-subscribe-detail/${scope.row.courseApointId}`"
                  v-if="scope.row.apointStatus === '0'"
                  >{{ scope.row.courseApointName }}</router-link
                >
                <a class="name" href="javascript:;" v-else>{{
                  scope.row.courseApointName
                }}</a>
                <p class="teacher-name">{{$t('titles.lecturerName')}}：{{ scope.row.teacherName }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="apointStatusLabel" :label="$t('common.status')" align="center">
        </el-table-column>
        <el-table-column prop="courseStartTime" :label="$t('common.broadcastTime')" align="center">
        </el-table-column>
        <el-table-column :label="$t('common.operation')" align="center">
          <template slot-scope="scope">
            <div class="btns">
              <el-button
                type="danger"
                size="small"
                @click="toBuy(scope.row)"
                v-if="scope.row.apointStatus === '2'"
                >{{$t('common.buyNow')}}</el-button
              >
              <el-button
                size="small"
                v-if="scope.row.apointStatus === '0'"
                @click="toToggleColl(scope.row)"
                >{{$t('common.cancel')}}</el-button
              >
              <el-button
                size="small"
                v-if="scope.row.apointStatus !== '0'"
                @click="toToggleColl(scope.row)"
                >{{$t('common.delete')}}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMySubscribeList, updateSubscribeStatus } from "@/api/common";
export default {
  data() {
    return {
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    toBuy(item) {
      this.$router.push(`/pay/confirm-order/${item.courseId}/0`);
    },
    async toToggleColl(item) {
      let msg = this.$t('pages.personal.yycancel');
      if (item.apointStatus === "2") {
        msg += this.$t('pages.personal.yy');
      }
      this.$confirm(msg, this.$t('common.prompt'), {
        type: "warning",
      })
        .then(async () => {
          try {
            await updateSubscribeStatus({
              apointId: item.courseApointId,
            });
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMySubscribeList({
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.isLoading = false;
        this.list = res.data ? res.data : [];
        this.total = res.total;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .name-area {
    display: flex;
    padding: 10px 0;
    img {
      width: 100px;
      height: 60px;
      margin-right: 11px;
    }
    .info {
      flex: 1;
      min-width: 0;
      .name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
      .teacher-name {
        color: $text-color-grey;
      }
    }
  }
  // .delete {
  //   color: $text-color-grey;
  // }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>