<template>
  <div>
    <div class="header-area">{{ $t('titles.teacherInfo') }}</div>
    <div class="info-area">
      <div class="form-area">
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          :hide-required-asterisk="true"
          label-position="right"
          label-width="80px"
        >
          <el-form-item :label="$t('pages.other.PersonalPhotos')" prop="photo" class="photo-item">
            <div class="photo-area">
              <div class="preveiw-area">
                <img
                  :src="formData.photo"
                  v-if="formData.photo"
                  @click="toPreviewImage"
                />
                <img
                  src="@/assets/images/icon-photo.png"
                  class="placeholder-img"
                  v-else
                />
              </div>
              <div class="upload-area">
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                >
                  <el-button class="my-btn" size="medium">
                    <span>{{ $t('pages.other.uploadphotos') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">
                  {{ $t('pages.other.uploadPhotoTile') }}
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('pages.other.jiaoLin')" prop="teachingAga">
            <el-input
              v-model="formData.teachingAga"
              oninput="value=value.replace(/[^0-9]/g,'')"
            >
              <template slot="append">{{ $t('pages.other.year') }}</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.other.xueLi')" prop="eduId">
            <el-select
              v-model="formData.eduId"
              filterable
              :placeholder="$t('common.placeHolder_select')"
              :loading="eduIsLoading"
            >
              <el-option
                v-for="item in eduOptions"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.school')" prop="school">
            <el-input v-model="formData.school"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.other.areasExpertise')" prop="skill">
            <el-checkbox-group v-model="formData.skill">
              <el-checkbox
                v-for="item in categoryOptions"
                :key="item.courseTypeId"
                :label="item.courseTypeId">
                {{ item.courseTypeName }}
                </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('pages.other.Applicablearea')" prop="area">
            <el-select
              v-model="formData.area"
              filterable
              :placeholder="$t('common.placeHolder_select')"
              multiple
              :loading="areaIsLoading"
            >
              <el-option
                v-for="item in areaList"
                :key="item.regionId"
                :label="item.regionName"
                :value="item.regionId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.Applicableindustries')" prop="applyBusiness">
            <el-select
              v-model="formData.applyBusiness"
              filterable
              :placeholder="$t('common.placeHolder_select')"
              multiple
              :loading="industryIsLoading"
            >
              <el-option
                v-for="item in industryList"
                :key="item.businessId"
                :label="item.businessName"
                :value="item.businessId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.myexpertise')" prop="tags">
            <div class="tag-list" v-if="formData.tags.length > 0">
              <div
                class="tag"
                v-for="(tag, index) in formData.tags"
                :key="tag.value"
              >
                <span>{{ tag.label }}</span>
                <i class="el-icon-close delete" @click="toDeleteTag(index)"></i>
              </div>
            </div>
            <el-autocomplete
              class="inline-input"
              v-model="newTag"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              :placeholder="$t('pages.other.PleaseLabel')"
              value-key="label"
              @select="handleSelect"
            >
              <el-button slot="append" icon="el-icon-plus" @click="toAddTag"
                >{{$t('pages.other.add')}}</el-button
              >
            </el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('pages.other.myexperience')" prop="detail">
            <editor v-model="formData.detail" />
          </el-form-item>
          <el-form-item class="row">
            <el-button type="primary" @click="submitForm">{{ $t('common.save') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/config';
import {
  selectEduOptionList,
  selectAreaList,
  selectIndustryList,
  selectCategoryList,
  selectTagList,
  AddUserTag,
  updateTeacherInfo,
} from '@/api/common';
import Editor from '@/components/Editor';
export default {
  components: { Editor },
  data() {
    return {
      baseURL,
      eduIsLoading: false,
      eduOptions: [],
      categoryOptions: [],
      newTag: '',
      areaIsLoading: false,
      areaList: [],
      industryIsLoading: false,
      industryList: [],
      tagList: [],
      formData: {
        id: '',
        userId: '',
        photo: '',
        teachingAga: '',
        edu: '',
        eduId: '',
        school: '',
        skill: [],
        area: [],
        applyBusiness: [],
        tags: [],
        detail: '',
      },
      rules: {
        photo: [{ required: true, message: this.$t('pages.other.Pleaseuploadhalfbodyphoto'), trigger: 'blur' }],
        teachingAga: [
          { required: true, message: this.$t('pages.other.Pleaseenterteachingage'), trigger: 'blur' },
        ],
        eduId: [{ required: true, message: this.$t('pages.other.Pleaseselectdegree'), trigger: 'blur' }],
        school: [{ required: true, message: this.$t('pages.other.PleaseEnterCollege'), trigger: 'blur' }],
        skill: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.PleaseTickAreasExpertise'));
              }
            },
            message: this.$t('pages.other.PleaseTickAreasExpertise'),
            trigger: 'blur',
          },
        ],
        area: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingxuanzeshiyongdiqu'));
              }
            },
            message: this.$t('pages.other.qingxuanzeshiyongdiqu'),
            trigger: 'blur',
          },
        ],
        applyBusiness: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingxuanzeshiyonghangye'));
              }
            },
            message: this.$t('pages.other.qingxuanzeshiyonghangye'),
            trigger: 'blur',
          },
        ],
        tags: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingtianjiabiaoqian'));
              }
            },
            message: this.$t('pages.other.qingtianjiabiaoqian'),
            trigger: 'blur',
          },
        ],
        detail: [{ required: true, message: this.$t('pages.other.qingshurujingyan'), trigger: 'blur' }],
      },
    };
  },
  computed: {
    teacherInfo() {
      return this.$store.state.teacherInfo;
    },
  },
  methods: {
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await updateTeacherInfo({
              id: this.formData.id,
              userId: this.formData.userId,
              bustShot: this.formData.photo,
              teachingAge: this.formData.teachingAga,
              officialValue: this.formData.eduId,
              school: this.formData.school,
              classTypeId: this.formData.skill
                ? this.formData.skill.join(',')
                : '',
              applyAdress: this.formData.area
                ? this.formData.area.join(',')
                : '',
              applyBusiness: this.formData.applyBusiness
                ? this.formData.applyBusiness.join(',')
                : '',
              labalId: this.formData.tags
                ? this.formData.tags.map((item) => item.labelId).join(',')
                : '',
              sufferText: this.formData.detail,
            });
            this.$message.success(this.$t('pages.other.gengxinchenggong') );
            this.$store.commit('setTeacherInfo', res);
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    toPreviewImage() {
      this.$imgPreview({
        imgs: [this.formData.photo],
      });
    },
    handleAvatarSuccess(res) {
      console.log(res);
      if (res.code === 200) {
        this.formData.photo = res.data.url;
      } else {
        this.$message.error(this.$t('pages.other.shangchuanshibai') );
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(this.$t('pages.other.shangchuantouxianggeshi') );
      }
      if (!isLt2M) {
        this.$message.error(this.$t('pages.other.shangchuantouxiangdaxiao'));
      }
      return isJPG && isLt2M;
    },
    handleSelect(e) {
      console.log(e);
    },
    async querySearch(queryString, cb) {
      if (queryString) {
        const res = await selectTagList({ label: queryString });
        if (res.length > 0) {
          cb(res);
        } else {
          cb([]);
        }
      }
    },
    createFilter(queryString) {
      console.log(queryString);
      return (tag) => {
        return tag.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    toDeleteTag(index) {
      console.log(index);
      this.formData.tags.splice(index, 1);
    },
    async toAddTag() {
      if (this.newTag.trim()) {
        const checkArr = this.formData.tags.filter(
          (item) => item.label === this.newTag.trim()
        );
        if (checkArr.length === 0) {
          try {
            const res = await AddUserTag({ labalContent: this.newTag.trim() });
            console.log(res);
            this.formData.tags.push({
              labalType: res.labalType,
              label: res.labalContent,
              labelId: res.labalId,
            });
            this.newTag = '';
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message.error(this.$t('pages.other.biaoqianchongfu'));
        }
      } else {
        this.$message.error(this.$t('pages.other.qingshurubiaoqianneirong'));
      }
    },
    setArea() {},
    // handleAreaData (arr) {
    //   arr.forEach(item => {
    //     if (item.children) {
    //       if (item.children.length > 0) {
    //         this.handleAreaData(item.children);
    //       } else {
    //         delete item.children;
    //       }
    //     }
    //   });
    // },
    async toGetCategoryList() {
      try {
        const res = await selectCategoryList({
          locale: this.$store.state.locale,
        });
        this.categoryOptions = res;
      } catch (error) {
        console.log(error);
      }
    },
    async toGetAreaList() {
      if (this.areaList.length === 0) {
        try {
          this.areaIsLoading = true;
          let res = await selectAreaList({ locale: this.$store.state.locale });
          this.areaIsLoading = false;
          this.areaList = res;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async toGetIndustryList() {
      if (this.industryList.length === 0) {
        try {
          this.industryIsLoading = true;
          let res = await selectIndustryList({
            locale: this.$store.state.locale,
          });
          this.industryIsLoading = false;
          this.industryList = res;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async toGetEduList() {
      if (this.eduOptions.length === 0) {
        try {
          this.eduIsLoading = true;
          const res = await selectEduOptionList();
          this.eduIsLoading = false;
          this.eduOptions = res;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    if (this.teacherInfo) {
      this.formData.id = this.teacherInfo.id;
      this.formData.userId = this.teacherInfo.userId;
      this.formData.photo = this.teacherInfo.bustShot;
      this.formData.teachingAga = this.teacherInfo.teachingAge || '';
      this.formData.eduId = this.teacherInfo.officialValue;
      this.formData.school = this.teacherInfo.school;
      this.formData.skill = this.teacherInfo.classTypeArray || [];
      this.formData.area = this.teacherInfo.applyAdressArray || [];
      this.formData.applyBusiness = this.teacherInfo.applyBusiness
        ? this.teacherInfo.applyBusiness
            .split(',')
            .map((item) => parseInt(item))
        : [];
      this.formData.tags = this.teacherInfo.labels || [];
      this.formData.detail = this.teacherInfo.sufferText || '';
    }
  },
  created() {
    this.toGetCategoryList();
    this.toGetEduList();
    this.toGetIndustryList();
    this.toGetAreaList();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 35px 40px 80px;
  .form-area {
    width: 730px;
    .photo-area {
      display: flex;
      align-items: center;
      .preveiw-area {
        width: 110px;
        height: 154px;
        border: 1px solid #e5e5e5;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
        .placeholder-img {
          width: 40px;
          height: 40px;
        }
      }
      .upload-area {
        margin-left: 25px;
        .desc {
          color: $text-color-grey;
          font-size: 12px;
        }
      }
    }
    .el-form-item {
      margin-bottom: 18px;
      ::v-deep {}
      ::v-deep {}
      ::v-deep {}
      ::v-deep .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
      &.photo-item {
        align-items: center;
        display: flex;
        ::v-deep {}
        ::v-deep {}
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
      }
      .el-cascader {
        width: 100%;
      }
    }
    .row {
      .el-button {
        width: 120px;
      }
    }
    .tag-list {
      .tag {
        padding: 8px 20px 8px 16px;
        background: #e6ffee;
        color: #3fca6c;
        font-size: 14px;
        line-height: 1;
        display: inline-block;
        position: relative;
        margin-bottom: 17px;
        & ~ .tag {
          margin-left: 25px;
        }
        &::after {
          content: '';
          border: 15px solid;
          border-color: transparent transparent transparent #e6ffee;
          position: absolute;
          right: -30px;
          top: 0px;
        }
        .delete {
          position: absolute;
          right: 0;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: #3fca6c;
            border-radius: 50%;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
