<template>
  <div class="notice-area">
    <div class="notice-box">
    <div class="title">{{$t('pages.other.LatestNews')}}：</div>
    <el-carousel
      height="24px"
      direction="vertical"
      :autoplay="true"
      indicator-position="none"
    >
      <el-carousel-item v-for="(ul, index) in handledList" :key="index">
        <ul>
          <li v-for="li in ul" :key="li.id">
            <router-link :to="`/news/detail/${li.id}`">
              <span class="text" :title="li.newTitle">{{ li.newTitle }}</span>
              <span class="create-time">{{ li.createTime.split(" ")[0] }}</span>
            </router-link>
          </li>
        </ul>
      </el-carousel-item>
    </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    handledList() {
      const handleRes = [];
      let tempArr = [];
      let flag = 0;
      this.list.forEach((item) => {
        tempArr.push(item);
        flag++;
        if (flag === 2) {
          handleRes.push(JSON.parse(JSON.stringify(tempArr)));
          tempArr = [];
          flag = 0;
        }
      });
      if (this.list.length % 2) {
        handleRes.push([this.list[this.list.length - 1]]);
      }
      return handleRes;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-area {
  width: 100%;
  margin: 0 auto 0;
  padding: 30px 40px 21px;
  position: relative;
  z-index: 99;
  background: #ffffff;
  .notice-box{
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    color: $text-color-black;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 9px;
  }
  ul {
    display: flex;
    height: 100%;
    li {
      flex: 1;
      min-width: 0;
      height: 100%;
      &:first-child {
        padding-right: 30px;
      }
      a {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 13px;
        color: #666666;
        height: 100%;
        line-height: 1;
        font-size: 14px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 4px;
          height: 4px;
          background: $base-color;
        }
        .text {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .create-time {
          width: 80px;
          margin-left: 60px;
        }
      }
    }
  }
}
</style>
