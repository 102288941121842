<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title-area">
      <span class="text">{{ $t('pages.other.wxSaoBinding')}}</span>
    </div>
    <div class="qrcode-area">
      <wxlogin
        v-if="appid"
        :appid="appid"
        :state="uuid"
        scope="snsapi_login"
        theme="black"
        :redirect_uri="redictUrl"
        self_redirect="true"
      />
    </div>
    <p>{{ $t('pages.other.bangdingweixinxiacishiyongweixindenglu') }}</p>
    <p v-if="$route.params.flag">
      <router-link to="/auth/register-success">{{ $t('pages.other.tiaoguo') }} ></router-link>
    </p>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import { selectWechatQrcode, selectScanQrcodeResult } from '@/api/common';
let timer = null;
export default {
  components: { wxlogin },
  data() {
    return {
      appid: '',
      uuid: '',
      redictUrl: '',
      flag: true,
    };
  },
  methods: {
    async checkLoginStatus() {
      this.flag = false;
      try {
        const res = await selectScanQrcodeResult({ uuid: this.uuid });
        this.flag = true;
        console.log(res);
        if (res) {
          res.userShowDto.roles = res.roles;
          this.$store.commit('setUserinfo', res.userShowDto);
          this.$message.success(this.$t('pages.other.bangdingchenggong'));
          clearInterval(timer);
          this.$router.push('/personal/my-info');
        } else if (res === '') {
          clearInterval(timer);
        }
      } catch (error) {
        console.log(error);
      }
    },
    toCheckLoginStatus() {
      if (this.flag) {
        this.checkLoginStatus();
      }
    },
    async toGetWechatQrcode() {
      try {
        const res = await selectWechatQrcode();
        this.appid = res.Appid;
        this.redictUrl = res.redictUrl;
        this.uuid = res.uuid;
        timer = setInterval(this.toCheckLoginStatus, 1000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.toGetWechatQrcode();
  },
  beforeDestroy() {
    clearInterval(timer);
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .title-area {
    width: 454px;
    margin: 0 auto 30px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    .text {
      display: inline-block;
      padding: 5px 30px;
      background: #ffffff;
      position: relative;
      line-height: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .qrcode-area {
    text-align: center;
    // margin-bottom: 50px;
    // height: 172px;
    img {
      width: 172px;
      height: 172px;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: $text-color-grey;
    &:last-child {
      margin-top: 22px;
      a {
        color: $text-color-grey;
        &:hover {
          color: $base-color;
        }
      }
    }
  }
}
</style>
