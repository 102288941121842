import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import router from '../router';

const ls = new SecureLS({ isCompression: false });

import { selectMessageUnreadCount } from '@/api/common';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    toBottom: false,
    keywords: '',
    needNoticeToOrg: true,
    hasNewMsg: false,
    isFirst: false,
    token: '',
    userinfo: {
      username: '',
      name: '',
      avatar: '',
      tel: '',
      email: '',
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      roles: [],
      gender: '',
      company: '',
      dept: '',
      job: '',
      nickName: '',
      orgId: '',
    },
    teacherInfo: null,
    orgInfo: null,
    currentRoleIndex: 1,
    locale: 'cn',
  },
  getters: {
    isLogin(state) {
      return !!state.token;
    },
  },
  mutations: {
    setToBottom(state, params) {
      state.toBottom = params;
    },
    setKeywords(state, params) {
      state.keywords = params;
    },
    setNeedNoticeToOrg(state) {
      state.needNoticeToOrg = false;
    },
    setHasNewMsg(state, params) {
      state.hasNewMsg = params;
    },
    setOrgInfo(state, params) {
      state.orgInfo = params;
    },
    setTeacherInfo(state, params) {
      state.teacherInfo = params;
    },
    setIsFirst(state, params) {
      state.isFirst = params;
    },
    setToken(state, params) {
      state.token = params;
    },
    setUserinfo(state, params) {
      state.userinfo.userId = params.userId;
      state.userinfo.username = params.loginName;
      state.userinfo.name = params.realName;
      state.userinfo.avatar = params.avater;
      state.userinfo.tel = params.phone;
      state.userinfo.roles = params.roles;
      state.userinfo.email = params.mail;
      state.userinfo.gender = params.sex;
      state.userinfo.company = params.companyName;
      state.userinfo.dept = params.orgName;
      state.userinfo.job = params.position;
      state.userinfo.nickName = params.nickName;
      state.userinfo.orgId = params.orgId;
      state.userinfo.weights = params.weights;
    },
    setUserinfoBase(state, params) {
      state.userinfo.name = params.name;
      state.userinfo.gender = params.gender;
      state.userinfo.company = params.company;
      state.userinfo.dept = params.dept;
      state.userinfo.job = params.job;
    },
    unbindWechat(state) {
      state.userinfo.avatar = null;
      state.userinfo.nickName = null;
    },
    setUserinfoTel(state, params) {
      state.userinfo.tel = params;
    },
    setUserinfoEmail(state, params) {
      state.userinfo.email = params;
    },
    setCurrentRoleIndex(state, params) {
      state.currentRoleIndex = params;
    },
    logout(state) {
      state.token = '';
      state.userinfo.userId = '';
      state.userinfo.username = '';
      state.userinfo.name = '';
      state.userinfo.avatar = '';
      state.userinfo.tel = '';
      state.userinfo.email = '';
      state.userinfo.roles = [];
      state.userinfo.gender = '';
      state.userinfo.company = '';
      state.userinfo.dept = '';
      state.userinfo.job = '';
      state.userinfo.nickName = '';
      state.userinfo.orgId = '';
      state.userinfo.weights = '';
      router.push('/auth/login');
    },
    setLocale(state, params) {
      state.locale = params;
    },
  },
  actions: {
    async getUnreadCount({ commit }) {
      try {
        const res = await selectMessageUnreadCount();
        commit('setHasNewMsg', res > 0);
      } catch (error) {
        console.log('error');
      }
    },
  },
  modules: {},
  plugins: [
    persistedState({
      key: 'ruihe',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
