<template>
  <div class="course-plan">
    <div class="section">
      <div class="breadcrumb-area">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{$t('titles.index')}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course-zone' }"
            >{{$t('titles.courseZone')}}</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{$t('titles.courseZoneDetail')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 课程信息 -->
      <div class="course-info">
        <div class="img-area">
          <img v-if="detail" :src="detail.courseCoverUrl" alt="" />
          <div class="tag" v-if="isBuy">{{$t('common.own')}}</div>
        </div>
        <div class="info">
          <div class="up-area">
            <div class="title" v-if="detail">{{ detail.courseName }}</div>
            <div class="line">
              <div class="label">{{$t('titles.curriculumHighlights')}}：</div>
              <div class="value" v-if="detail">{{ detail.courseBright }}</div>
            </div>
            <div class="line">
              <div class="label">{{$t('titles.teachingObject')}}：</div>
              <div class="value" v-if="detail">{{ detail.applyPeople }}</div>
            </div>
          </div>
          <div class="down-area">
            <!-- <price-num
              v-if="detail"
              :val="detail.coursePrice"
              color="#FF4C4C"
              :signSize="20"
              :intSize="30"
              :pointSize="30"
              :decSize="30"
              :showDec="false"
            /> -->
            <div class="btns" v-if="isBuy">
              <el-button
                v-if="detail"
                type="primary"
                class="my-btn"
                @click="toStudy"
                >{{$t('common.learnNow')}}</el-button
              >
              <el-button
                v-if="detail"
                type="primary"
                class="my-btn"
                plain
                @click="toDownload"
                >{{$t('titles.prepareData')}}</el-button
              >
              <el-button
                v-if="detail && role === '2'"
                type=""
                class="my-btn"
                @click="toNext"
                >{{$t('titles.buyAgain')}}</el-button
              >
            </div>

            <div class="btns" v-else>
              <!-- <el-button
                v-if="detail"
                type="primary"
                class="my-btn"
                @click="toNext"
                >{{$t('common.buyNow')}}</el-button
              > -->
              <el-button
                v-if="detail"
                type="primary"
                class="my-btn"
                plain
                @click="toStudy"
                >{{$t('common.try')}}</el-button
              >
            </div>

          </div>
          <div class="coll-btn-area">
            <vue-star
              animate="animate__animated animate__bounceIn"
              color="#FFBB05"
              :active="isColl"
            >
              <div
                slot="icon"
                v-if="detail"
                class="coll-tag"
                @click="toToggleColl"
              >
                <i class="iconfont" v-if="isColl">&#xe629;</i>
                <i class="iconfont" v-else>&#xe62a;</i>
                {{ isColl ? $t('common.collected') : $t('titles.myCollCourse') }}
              </div>
            </vue-star>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="bottom-container">
        <div class="left">
          <!-- 选项卡 -->
          <div class="tab-area">
            <el-tabs class="my-tabs" v-model="activeName">
              <el-tab-pane :label="$t('titles.courseZoneDetail')" name="first">
                <div
                  class="detail-content"
                  v-if="detail"
                  v-html="detail.courseDetail"
                ></div>
              </el-tab-pane>
              <el-tab-pane :label="$t('titles.courseZoneEvaluate')" name="second">
                <ul class="comment-list" v-if="commentList.length > 0">
                  <li v-for="item in commentList" :key="item.id">
                    <div class="img-area">
                      <img
                        :src="
                          item.appraiseUserHeadImg
                            ? item.appraiseUserHeadImg
                            : defaultAvatar
                        "
                        alt=""
                      />
                    </div>
                    <div class="name-area">
                      <div class="name">{{ item.appraiseUserName }}</div>
                      <div class="create-time">{{ item.appraiseTime }}</div>
                    </div>
                    <div class="comment-content">{{ item.messageText }}</div>
                  </li>
                </ul>
                <the-empty :text="$t('common.noData')" v-else />
                <el-pagination
                  :current-page.sync="page"
                  :page-size="pageSize"
                  :hide-on-single-page="true"
                  background
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                  @current-change="getCommentData"
                >
                </el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- right -->
        <div class="right">
          <!-- 讲师信息 -->
          <div class="teacher-info">
            <div class="title-area">
              <div class="title">{{$t('titles.lecturerInfo')}}</div>
            </div>
            <router-link
              v-if="teacherInfo"
              class="img-area"
              :to="`/lecturer/detail/${teacherInfo.userId}`"
            >
              <img :src="teacherInfo.bustShot" :alt="teacherInfo.userName" />
            </router-link>
            <p class="name" v-if="teacherInfo" :title="teacherInfo.userName">
              {{ teacherInfo.userName }}
            </p>
            <div class="info-text" v-if="teacherInfo">
              <p class="first">
                {{ teacherInfo.officialLabel }} - {{ teacherInfo.school }}
              </p>
              <p
                class="second"
                :title="
                  teacherInfo.courseTypeLabals
                    ? teacherInfo.courseTypeLabals
                    : ''
                "
              >
                {{$t('titles.adept')}}：{{
                  teacherInfo.courseTypeLabals
                    ? teacherInfo.courseTypeLabals
                    : '--'
                }}
              </p>
            </div>
          </div>
          <!-- 更多热门计划 -->
          <div class="more-plan">
            <div class="title-area">
              <div class="title">{{$t('titles.courseHot')}}</div>
              <router-link to="/course-zone" class="more">
                {{$t('common.more')}}
                <i class="iconfont">&#xe60a;</i>
              </router-link>
            </div>
            <ul class="list">
              <li
                v-for="item in recommendList"
                :key="item.courseId"
                @click="toDetail(item)"
              >
                <div class="img-area">
                  <img :src="item.courseCoverUrl" :alt="item.courseName" />
                </div>
                <div class="name-area">
                  <div class="name" :title="item.courseName">
                    {{ item.courseName }}
                  </div>
                  <!-- <price-num
                    :val="item.coursePrice"
                    color="#FF4C4C"
                    :signSize="12"
                    :intSize="20"
                    :pointSize="20"
                    :decSize="20"
                    :showDec="false"
                  /> -->
                </div>
              </li>
            </ul>
            <div class="to-all">
              <router-link to="/course-zone">{{$t('common.toSeeAll')}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  selectCourseZoneDetail,
  updateCollStatus,
  selectCommentList,
  // selectCourseTryData,
} from '@/api/common';

import PriceNum from '@/components/PriceNum/PriceNum.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
import VueStar from '@/components/VueStar/VueStar';
export default {
  components: {
    PriceNum,
    TheEmpty,
    VueStar,
  },
  data() {
    return {
      activeName: 'first',
      id: '',
      isColl: false,
      isBuy: false,
      detail: null,
      teacherInfo: null,
      recommendList: [],
      commentList: [],
      page: 1,
      pageSize: 12,
      total: 0,
      defaultAvatar: require('@/assets/images/default-avatar.png'),
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
  },
  methods: {
    toDownload() {
      let a = document.createElement('a');
      a.href = this.courseWare;
      a.download = 'courseWare';
      a.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    toStudy() {
      this.$router.push(`/course-study/${this.id}/0/0`);
    },
    toNext() {
      this.$router.push(`/pay/confirm-order/${this.id}/0`);
    },
    toDetail(item) {
      this.id = item.courseId;
      this.page = 1;
      this.getData();
    },
    async toToggleColl() {
      try {
        await updateCollStatus({
          productId: this.id,
          productType: 0,
        });
        this.isColl = !this.isColl;
      } catch (error) {
        console.log(error);
      }
    },
    async getCommentData() {
      try {
        const res = await selectCommentList({
          bodyId: this.id,
          bodyType: 0,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.commentList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await selectCourseZoneDetail({
          courseId: this.id,
        });
        this.isColl = res.isCollected === '1';
        this.isBuy = res.isBuy === '1';
        this.detail = res.course;
        this.courseWare = res.courseWare;
        this.teacherInfo = res.teacherDto;
        this.recommendList = res.recommendCourse ? res.recommendCourse : [];
        this.commentList = res.appariseMsgs ? res.appariseMsgs.data : [];
        this.total = res.appariseMsgs ? res.appariseMsgs.total : 0;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.course-plan {
  .section {
    .breadcrumb-area {
      padding: 15px 0;
    }
    width: 1200px;
    margin: 0 auto;
    .course-info {
      padding: 30px 30px 40px;
      background: #ffffff;
      display: flex;
      .img-area {
        width: 450px;
        height: 250px;
        margin-right: 70px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .tag {
          background: #ff5c5c;
          width: 80px;
          height: 30px;
          line-height: 30px;
          border-bottom-right-radius: 8px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .info {
        flex: 1;
        min-width: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-size: 24px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 24px;
          width: 470px;
        }
        .line {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          line-height: 20px;
          color: $text-color-grey;
          margin-bottom: 10px;
          .value {
            flex: 1;
            min-width: 0;
          }
        }
        .btns {
          margin-top: 10px;
          .my-btn {
            border-radius: 0;
            width: 160px;
          }
        }
        .coll-btn-area {
          position: absolute;
          top: -30px;
          right: 0px;
          .coll-tag {
            width: 150px;
            height: 34px;
            border-radius: 17px;
            color: #ffffff;
            font-size: 14px;
            background: #ff5c5c;
            user-select: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconfont {
              font-size: 14px;
              margin-right: 3px;
            }
          }
        }
      }
    }
    .bottom-container {
      margin: 30px auto 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        width: 900px;
        background: #ffffff;
        padding: 30px 30px 0;
        margin-right: 20px;
        ::v-deep .el-tabs {
          .el-tabs__active-bar {
            height: 3px;
          }
          .el-tabs__item {
            color: $text-color-grey;
            font-size: 16px;
            &.is-active {
              color: $text-color-black;
              font-weight: bold;
            }
          }
        }
        .detail-content {
          padding-bottom: 30px;
          img {
            max-width: 100%;
          }
        }
        .comment-list {
          padding: 20px 0 30px;
          li {
            position: relative;
            padding: 25px 0 25px 76px;
            .img-area {
              width: 32px;
              height: 32px;
              position: absolute;
              top: 25px;
              left: 39px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 32px;
              .name {
                color: #8d8d8d;
                margin-bottom: 5px;
                width: 380px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .create-time {
                color: $text-color-grey;
              }
            }
          }
        }
        .el-pagination {
          text-align: center;
          margin-bottom: 60px;
        }
      }
      .right {
        flex: 1;
        min-width: 0;
        .teacher-info {
          padding: 15px;
          background: #ffffff;
          user-select: none;
          .img-area {
            display: block;
            margin: 20px auto;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            transition: color 0.3s;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .info-text {
            padding: 0 15px;
            text-align: center;
            font-size: 14px;
            .first {
              color: #333333;
              margin-bottom: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .second {
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              min-height: 50px;
            }
          }
        }
        .more-plan {
          margin-top: 20px;
          padding: 15px;
          background: #ffffff;
        }
        .title-area {
          color: #333333;
          padding-left: 10px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          &::before {
            content: '';
            width: 4px;
            height: 16px;
            background: $base-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          .more {
            font-size: 12px;
            font-weight: normal;
            display: inline-flex;
            align-items: center;
            transition: color 0.3s;
            .iconfont {
              font-size: 12px;
            }
          }
        }
        .list {
          li {
            padding: 15px 0 10px;
            cursor: pointer;
            &:not(:last-child) {
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
            }
            .img-area {
              width: 100%;
              height: 140px;
              margin-bottom: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              transition: color 0.3s;
              .name {
                flex: 1;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .to-all {
          text-align: center;
          padding: 20px 0;
          a {
            display: inline-block;
            width: 128px;
            height: 31px;
            line-height: 29px;
            border: 1px solid #ccc;
            border-radius: 3px;
            text-align: center;
            color: $text-color-grey;
            transition: all 0.3s;
            &:hover {
              color: $base-color;
              border-color: $base-color;
            }
          }
        }
      }
    }
  }
}
</style>
