<template>
  <span class="price-num" :style="{fontWeight: fontWeight}">
    <span class="sign" :style="{color: signColor? signColor: color, fontSize: `${signSizeRem}px` }">￥</span>
    <span class="int" :style="{color: intColor? intColor: color, fontSize: `${intSizeRem}px`}">{{ getInt }}</span>
    <span class="point" v-if="showDec || getDec !== '00'" :style="{color: pointColor? pointColor: color, fontSize: `${pointSizeRem}px`}">.</span>
    <span class="dec" v-if="showDec || getDec !== '00'" :style="{color: decColor? decColor: color, fontSize: `${decSizeRem}px`}">{{ getDec | fixZero }}</span>
  </span>
</template>

<script>
export default {
  name: 'PriceNum',
  props: {
    val: {
      type: [Number, String],
      default: 0
    },
    fontWeight: {
      type: Number,
      default: 600
    },
    color: {
      type: String,
      default: '#FF5C4C'
    },
    signColor: {
      type: String
    },
    intColor: {
      type: String
    },
    pointColor: {
      type: String
    },
    decColor: {
      type: String
    },
    signSize: {
      type: Number,
      default: 14
    },
    intSize: {
      type: Number,
      default: 20
    },
    pointSize: {
      type: Number,
      default: 14
    },
    decSize: {
      type: Number,
      default: 14
    },
    showDec: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    fixZero (val) {
      return val.toString()[1] ? val: `${val}0`;
    }
  },
  computed: {
    signSizeRem () {
      return this.signSize;
    },
    intSizeRem () {
      return this.intSize;
    },
    pointSizeRem () {
      return this.pointSize;
    },
    decSizeRem () {
      return this.decSize;
    },
    getInt () {
      return this.val.toString().includes('.') ? this.val.toString().split('.')[0]: this.val;
    },
    getDec () {
      return this.val.toString().includes('.') ? this.val.toString().split('.')[1]: '00';
    }
  }
}
</script>

<style lang="less" scoped>

</style>