var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"el-fade-in-linear"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"floor-nav",class:{ toggleSide: !_vm.show }},[_c('div',{staticClass:"handled-area",on:{"click":_vm.toggleShow}},[_c('i',{staticClass:"iconfont",class:{ left: !_vm.show }},[_vm._v("")])]),_vm._l((_vm.floorNavList.filter(
        function (item) { return item.target !== 'mp' || (item.target === 'mp' && _vm.qrCodeUrl); }
      )),function(item,index){return _c('li',{key:index,class:[
        item.target ? ((item.target.substring(1)) + "-nav") : '',
        {
          active:
            item.rangeStart &&
            item.rangeStart <= _vm.scrollTop &&
            item.rangeEnd > _vm.scrollTop,
        },
        { hide: !_vm.show },
        { top: !item.target } ],on:{"click":function($event){return _vm.scrollTo(item.target)},"mouseover":function($event){return _vm.showQrcode(item.target)},"mouseleave":_vm.hideQrcode}},[_c('div',{staticClass:"inner-area"},[_c('img',{attrs:{"src":item.img,"alt":item.title}}),_c('p',[_vm._v(_vm._s(item.title))])])])}),(_vm.isShowQrcode)?_c('div',{staticClass:"qrcode-area",class:{ en: _vm.locale === 'en' }},[_c('div',{staticClass:"img-area"},[_c('img',{attrs:{"src":_vm.qrCodeUrl}})]),_c('div',{staticClass:"intro"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_c('div',{staticClass:"right"},[_c('p',[_vm._v(_vm._s(_vm.$t('pages.index.scanWithWechat')))]),_c('p',[_vm._v(_vm._s(_vm.$t('pages.index.visitMiniProgram')))])])])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }