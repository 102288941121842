import app from '@/main';
// 根据路由中的 meta 信息设置 title
export const setTitle = (title) => {
  window.document.title = app.$t(title);
};

export function flatten(arr, key) {
  if (key) {
    return arr.reduce((result, item) => {
      if (item[key]) {
        result.push(item);
      }
      return result.concat(
        Array.isArray(item[key]) ? flatten(item[key], key) : item
      );
    }, []);
  } else {
    return arr.reduce((result, item) => {
      return result.concat(Array.isArray(item) ? flatten(item) : item);
    }, []);
  }
}
