<template>
  <div class="refund-1">
    <div class="steps-area">
      <my-steps
        :active="active"
        :list="listData[$store.state.locale]"
        type="area"
      />
    </div>
    <div class="bottom-content">
      <!-- 表单 -->
      <el-form
        v-if="active === 1"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="right"
        label-width="140px"
      >
        <!-- <el-form-item label="退款原因" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('pages.personal.refundAmount')" prop="money">
          <el-input v-model="form.money" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.personal.refundReason')" prop="reason">
          <el-input v-model="form.reason" type="textarea" rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="my-btn" @click="submit">{{
            $t('common.submit')
          }}</el-button>
          <el-button type="primary" plain class="my-btn" @click="goBack">{{
            $t('common.cancel')
          }}</el-button>
        </el-form-item>
      </el-form>
      <!-- 平台处理中 -->
      <div v-else-if="active === 2" class="result-area">
        <div class="img-area">
          <img src="@/assets/images/icon-busyness.png" alt="" />
        </div>
        <p class="desc">{{ $t('pages.personal.refundText1') }}</p>
      </div>
      <!-- 退款成功 -->
      <div v-else class="result-area">
        <div class="img-area">
          <img src="@/assets/images/icon-success.png" alt="" />
        </div>
        <p class="desc">{{ $t('pages.personal.refundText2') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { selectRefundData, insertRefundData } from '@/api/common';
import MySteps from '../../components/MySteps/MySteps.vue';
export default {
  components: { MySteps },
  data() {
    return {
      active: 0,
      listData: {
        cn: [this.$t('pages.other.shenqingtuikuan'), this.$t('pages.other.pingtaichuli'), this.$t('pages.other.tuikuanchenggong')],
        en: ['Apply', 'Processing', 'Success'],
      },
      form: {
        orderNo: '',
        reason: '',
        money: '',
        // detail: ''
      },
      rules: {
        reason: [
          {
            required: true,
            message: this.$t('pages.personal.plsEnterReason'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            await insertRefundData({
              orderNo: this.form.orderNo,
              refundReason: this.form.reason,
              // remarks: this.form.detail
            });
          } catch (error) {
            console.log(error);
          }
          this.active = 2;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    async getData() {
      try {
        const res = await selectRefundData({ orderId: this.orderId });
        this.form.money = res.payMoney;
        this.form.orderNo = res.orderNo;
        this.active = parseInt(res.orderStatus);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.orderId = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.refund-1 {
  width: 990px;
  margin: 0 auto;
  .steps-area {
    padding: 50px 25px 30px 0;
  }
  .bottom-content {
    margin-bottom: 215px;
    min-height: 520px;
    background: #ffffff;
    padding: 55px 200px 50px 80px;
    position: relative;
    .result-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      padding-top: 108px;
      .img-area {
        width: 66px;
        height: 66px;
        margin: 0 auto 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
