<template>
  <div>
    <div class="header-area">{{$t('titles.myDemand')}}</div>
    <div class="info-area">
      <div class="oper-area">
        <el-button
          class="my-btn"
          type="primary"
          size="small"
          icon="el-icon-plus"
          :isLoading="isLoading"
          @click="showCourseList"
          >{{$t('common.add')}}</el-button
        >
      </div>
      <el-table
        :data="demandCourseList"
        border
        size="small"
        style="width: 100%"
      >
        <el-table-column :label="$t('titles.courseZoneName')" width="310">
          <template slot-scope="scope">
            <router-link
              class="name-area"
              :to="`/course-zone/detail/${scope.row.demandId}`"
            >
              <img :src="scope.row.demandCoverUrl" alt="" />
              <span class="name">{{ scope.row.demandName }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="demandTeacher" :label="$t('titles.lecturerName')"> </el-table-column>
        <el-table-column prop="commitTime" :label="$t('common.submitTime')"> </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 课程选择弹窗 -->
    <el-dialog :title="$t('common.submit') +' '+ $t('titles.myDemand')" width="1100px" :visible.sync="showTableDialog">
      <div class="popup-content table">
        <div class="filter-area">
          <el-select
            v-model="type"
            v-loading="typeLoading"
            size="small"
            clearable
            :placeholder="$t('common.genre')"
            @focus="toGetCategoryList"
            @change="toGetData"
          >
            <el-option
              v-for="item in options"
              :key="item.courseTypeId"
              :label="item.courseTypeName"
              :value="item.courseTypeId"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="$t('common.placeHolder_enter')+$t('common.keywords')"
            v-model="keywords"
            class="input-with-select"
            size="small"
            clearable
            @keyup.enter.native="getData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getData"
            ></el-button>
          </el-input>
        </div>
        <el-table
          :data="courseList"
          style="width: 100%"
          row-key="courseId"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="55"
          >
          </el-table-column>
          <el-table-column :label="$t('titles.courseZoneName')" width="310">
            <template slot-scope="scope">
              <router-link
                class="name-area"
                :to="`/course-zone/detail/${scope.row.courseId}`"
              >
                <img :src="scope.row.courseCoverUrl" alt="" />
                <span class="name">{{ scope.row.courseName }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="courseTypeLabels" :label="$t('common.courseCategory')">
            <template slot-scope="scope">
              {{
                scope.row.courseTypeLabels &&
                  scope.row.courseTypeLabels.map((item) => item.label).join(',')
              }}
            </template>
          </el-table-column>
          <el-table-column prop="teacherName" :label="$t('titles.lecturerName')"> </el-table-column>
          <el-table-column :label="$t('common.price')">
            <template slot-scope="scope">
              <span style="color: #ff4c4c">￥{{ scope.row.coursePrice }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-area">
          <el-pagination
            :current-page.sync="coursePage"
            :page-size="coursePageSize"
            background
            layout="prev, pager, next"
            :total="courseTotal"
            @current-change="getCourseData"
          >
          </el-pagination>
        </div>
        <div class="count-info">
          <div class="count-area">
            <div class="count">{{$t('common.select')}} {{ selection.length }} {{$t('titles.course')}}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTableDialog = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="toSubmit">{{$t('common.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectDemandList,
  selectCourseZoneList,
  selectCategoryList,
  insertDemandData,
} from '@/api/common';
export default {
  data() {
    return {
      isLoading: false,
      showTableDialog: false,
      type: '',
      options: [],
      typeLoading: false,
      keywords: '',
      selection: [],
      courseList: [],
      coursePage: 1,
      coursePageSize: 5,
      courseTotal: 0,
      demandCourseList: [],
      page: 1,
      pageSize: 5,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    async toSubmit() {
      try {
        await insertDemandData({
          demandIds: this.selection.map((item) => item.courseId).join(','),
        });
        this.showTableDialog = false;
        this.page = 1;
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    toGetData() {
      this.page = 1;
      this.getCourseData();
    },
    async showCourseList() {
      this.getCourseData(() => {
        this.showTableDialog = true;
      });
    },
    async getCourseData(callback) {
      try {
        try {
          this.isLoading = true;
          const res = await selectCourseZoneList({
            locale: this.$store.state.locale,
            courseName: this.keywords,
            pageNum: this.coursePage,
            pageSize: this.coursePageSize,
            courseTypeId: this.type,
          });
          this.courseList = res.data;
          this.courseTotal = res.total;
          this.isLoading = false;
          callback && typeof callback === 'function' && callback();
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async toGetCategoryList() {
      if (this.options.length === 0) {
        try {
          this.typeLoading = true;
          const res = await selectCategoryList({
            locale: this.$store.state.locale,
          });
          res.unshift({
            courseTypeId: '',
            courseTypeName: '全部',
          });
          this.options = res;
          this.typeLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectDemandList({
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.demandCourseList = res.data;
        this.total = res.total;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 78px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      text-align: left;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.popup-content {
  &.table {
    padding: 0 70px;
    .filter-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 33px;
      .input-with-select {
        width: 250px;
      }
    }
    .name-area {
      display: flex;
      padding: 10px 0;
      align-items: center;
      img {
        width: 82px;
        height: 49px;
        margin-right: 13px;
      }
      .name {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ::v-deep .el-table {
      border: 1px solid #ebeef5;
      border-bottom: none;
      th {
        background: #f7f7f7;
        text-align: left;
        &.el-table-column--selection {
          .cell {
            padding-left: 14px;
          }
        }
      }
    }
    .pagination-area {
      border: 1px solid #ebeef5;
      border-top: none;
      padding: 18px 0;
      .el-pagination {
        text-align: center;
      }
    }
    .count-info {
      line-height: 1;
      padding-top: 36px;
      .count-area {
        padding-left: 20px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .count {
          font-size: 16px;
          color: $text-color-grey;
        }
        .price {
          font-size: 18px;
          margin-left: 5px;
        }
      }
      .price-area {
        text-align: right;
        font-size: 16px;
        color: $text-color-grey;
        .label {
          color: $text-color-black;
        }
        .price {
          font-size: 20px;
          font-weight: bold;
        }
        .red {
          color: #ff4c4c;
        }
      }
    }
  }
}
.dialog-footer {
  padding: 0 70px;
}
</style>
