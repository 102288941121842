<template>
  <div class="home">
    <!-- banner 图 -->
    <the-banner type="0" />
    <!-- 最新通知 -->
    <the-notice :list="newsList" v-if="newsList.length > 0" />
    <!-- 课程专区 -->
    <div class="section course-area">
      <div class="section-container">
        <div class="title">{{ $t('pages.index.courseZone') }}</div>
        <div class="subtitle">
          {{ locale === 'cn' ? courseDescribe : courseDescribeEn }}
        </div>
        <div class="recommend-cat">
          <div @click="courseRecommendIndex = catIndex" v-for="(cat, catIndex) in courseRecommendList" :key="catIndex" :class="[catIndex == courseRecommendIndex ? 'active' : '', 'cat-item']">{{cat.courseTypeName}}</div>
        </div>
        <div class="course-box">
          <ul
              class="course-list"
              v-if="courseRecommendList[courseRecommendIndex] && courseRecommendList[courseRecommendIndex].courseDto.length > 0"
            >
              <li
                v-for="course in courseRecommendList[courseRecommendIndex].courseDto.slice(0, 8)"
                :key="course.courseId"
                @click="toCourseDetail(course)"
              >
                <div class="img-area">
                  <img :src="course.courseCoverUrl" :alt="course.courseName" />
                </div>
                <div class="info-area">
                  <div class="title" :title="course.courseName">
                    {{ course.courseName }}
                  </div>
                  <div class="extra">
                    <div class="oper-area">


                      <!-- <span
                        v-if="course.createType === '0'"
                        class="oper"
                        @click="toCourseStudy(course)"
                      >
                        <i class="iconfont">&#xe648;</i>
                        <span class="text">{{ $t('common.study') }}</span>
                      </span>
                      <span
                        v-if="course.createType === '1'"
                        class="oper"
                      >
                        <i class="iconfont">&#xe610;</i>
                        <span class="text">{{ $t('common.buy') }}</span>
                      </span>
                      <span
                        v-if="course.createType === '1'"
                        class="oper"
                      >
                        <i class="iconfont">&#xe601;</i>
                        <span class="text">{{ $t('common.try') }}</span>
                      </span> -->


                    </div>
                    <!-- <price-num
                      v-if="course.createType === '1'"
                      :val="course.coursePrice"
                      color="#FF4C4C"
                      :signSize="14"
                      :intSize="26"
                      :pointSize="26"
                      :decSize="26"
                      :showDec="false"
                    /> -->
                  </div>
                </div>
              </li>
            </ul>
            <the-empty v-else />
        </div>
        <!-- <el-tabs :stretch="true">
          <el-tab-pane
            :label="category.courseTypeName"
            v-for="category in courseRecommendList"
            :key="category.courseTypeId"
          >
            <ul
              class="course-list"
              v-if="category.courseDto && category.courseDto.length > 0"
            >
              <li
                v-for="course in category.courseDto.slice(0, 8)"
                :key="course.courseId"
              >
                <div class="img-area">
                  <img :src="course.courseCoverUrl" :alt="course.courseName" />
                </div>
                <div class="info-area">
                  <div class="title" :title="course.courseName">
                    {{ course.courseName }}
                  </div>
                  <div class="extra">
                    <div class="oper-area">
                      <span
                        v-if="course.createType === '0'"
                        class="oper"
                        @click="toCourseStudy(course)"
                      >
                        <i class="iconfont">&#xe648;</i>
                        <span class="text">{{ $t('common.study') }}</span>
                      </span>
                      <span
                        v-if="course.createType === '1'"
                        class="oper"
                        @click="toCourseDetail(course)"
                      >
                        <i class="iconfont">&#xe610;</i>
                        <span class="text">{{ $t('common.buy') }}</span>
                      </span>
                      <span
                        v-if="course.createType === '1'"
                        class="oper"
                        @click="toCourseDetail(course)"
                      >
                        <i class="iconfont">&#xe601;</i>
                        <span class="text">{{ $t('common.try') }}</span>
                      </span>
                    </div>
                    <price-num
                      v-if="course.createType === '1'"
                      :val="course.coursePrice"
                      color="#FF4C4C"
                      :signSize="14"
                      :intSize="26"
                      :pointSize="26"
                      :decSize="26"
                      :showDec="false"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <the-empty v-else />
          </el-tab-pane>
        </el-tabs> -->


        <!-- <div class="btns">
          <a class="btn" :href="downloadUrl" download>{{
            $t('pages.index.download')
          }}</a>
          <router-link class="btn" to="/course-zone">{{
            $t('pages.index.more')
          }}</router-link>
        </div> -->

        
      </div>
    </div>
    <!-- 课程计划 -->
    <div class="section course-plan">
      <div class="section-container">
        <div class="title">{{ $t('pages.index.coursePlan') }}</div>
        <div class="subtitle">
          {{ locale === 'cn' ? coursePlanDescribe : coursePlanDescribeEn }}
        </div>
        <!-- 课程计划列表滚动组件 -->
        <course-plan-list :list="coursePlanList" />
        <div class="btns">
          <router-link class="btn" to="/course-customization">{{
            $t('pages.index.customization')
          }}</router-link>
        </div>
      </div>
    </div>
    <!-- 课程预约 -->
    <div class="section course-subscribe">
      <div class="section-container">
        <div class="title">{{ $t('pages.index.courseSubscribe') }}</div>
        <div class="subtitle">
          {{ locale === 'cn' ? courseApointDecribe : courseApointDecribeEn }}
        </div>
        <ul class="course-subscribe-list">
          <li v-for="item in courseSubscribeList" :key="item.courseApointId">
            <router-link
              :to="`/course-subscribe-detail/${item.courseApointId}`"
            >
              <div class="img-area">
                <img
                  :src="item.courseApointCoverUrl"
                  :alt="item.courseApointName"
                />
                <!-- <div class="btn subscribe">
                  <i class="iconfont">&#xe613;</i>
                  <span class="txt">预约</span>
                </div> -->
              </div>
              <div class="middle-area">
                <div class="title-area">
                  <span class="text" :title="item.courseApointName">{{
                    item.courseApointName
                  }}</span>
                  <!-- <price-num
                    :val="item.courseApointPrice"
                    color="#FF4C4C"
                    :signSize="14"
                    :intSize="26"
                    :pointSize="26"
                    :decSize="26"
                    :showDec="false"
                  /> -->
                </div>
                <div class="start-time">
                  {{ $t('pages.index.startTime') }}：{{ item.courseStartTime }}
                </div>
                <div class="extra">
                  <div class="teacher-info">
                    <el-avatar :size="40" :src="item.teacherHeadImg" />
                    <div class="name">{{ item.teacherName }}</div>
                  </div>
                  <div class="num-text">
                    {{ $t('pages.other.yiyou') }}
                    <span class="num">{{ item.apointAcount }}</span>
                    {{ $t('pages.other.renyuyue') }}
                  </div>
                  <!-- <span class="num-text" v-if="locale === 'cn'">
                    <span class="num">{{ item.apointAcount }}</span> people have
                    been booked
                  </span> -->
                </div>
              </div>
              <div class="bottom-area">
                <div class="quote-area">
                  <div class="text-wrap" :title="item.courseApointBright">
                    {{ item.courseApointBright }}
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="btns">
          <router-link class="btn" to="/course-subscribe">{{
            $t('pages.index.more')
          }}</router-link>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="section contact-us">
      <div class="section-container">
        <div class="title">{{ $t('pages.index.contacts') }}</div>
        <div class="content-area">
          <div class="form-area">
            <div class="line">
              <input
                type="text"
                class="my-input short"
                :placeholder="$t('pages.index.phName')"
                v-model="form.userName"
              />
              <input
                type="text"
                class="my-input short"
                :placeholder="$t('pages.index.phContacts')"
                v-model="form.userPhone"
              />
            </div>
            <input
              type="text"
              class="my-input short"
              :placeholder="$t('pages.index.phEmail')"
              v-model="form.userEmail"
            />
            <textarea
              class="my-textarea"
              :placeholder="$t('pages.index.phContent')"
              v-model="form.feedbackText"
            ></textarea>
            <div class="btn-area">
              <el-button
                type="primary"
                class="my-btn"
                v-loading="isSubmiting"
                @click="toSubmit"
                >{{ $t('common.submit') }}</el-button
              >
            </div>
          </div>
          <div class="contacts-area">
            <div class="item">
              <img src="@/assets/images/icon-location-white.png" alt="" />
              <div class="top-text">{{ $t('pages.index.BeijingOffice') }}</div>
              <div class="bottom-text">{{ $t('footer.location') }}</div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon-location-white.png" alt="" />
              <div class="top-text">{{ $t('pages.index.HongKongOffice') }}</div>
              <div class="bottom-text">
                {{ $t('footer.location2') }}
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon-email-white.png" alt="" />
              <div class="bottom-text">academy@reghubchina.com</div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon-website-white.png" alt="" />
              <div class="bottom-text">www.reghubchina.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 楼层导航 -->
    <floor-nav :qrcode="qrCode" @service="toShowService" />
    <!-- 客服弹窗 -->
    <the-service :show="showService" @close="showService = false" />
  </div>
</template>

<script>
import { selectIndexData, insertFeedbackData } from '@/api/common';

import TheBanner from '@/components/TheBanner/TheBanner.vue';
import TheNotice from '@/components/TheNotice/TheNotice.vue';
import PriceNum from '@/components/PriceNum/PriceNum.vue';
import CoursePlanList from '@/components/CoursePlanList/CoursePlanList.vue';
import FloorNav from '@/components/FloorNav/FloorNav.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
import TheService from '@/components/TheService/TheService.vue';
export default {
  components: {
    TheBanner,
    TheNotice,
    PriceNum,
    CoursePlanList,
    FloorNav,
    TheEmpty,
    TheService,
  },
  data() {
    return {
      showService: false,
      courseDescribe: '',
      courseDescribeEn: '',
      courseRecommendList: [],
      coursePlanDescribe: '',
      coursePlanDescribeEn: '',
      coursePlanList: [],
      courseApointDecribe: '',
      courseApointDecribeEn: '',
      courseSubscribeList: [],
      downloadUrl: 'javascript:;',
      newsList: [],
      // 意见反馈表单
      form: {
        userName: '',
        userPhone: '',
        userEmail: '',
        feedbackText: '',
      },
      isSubmiting: false,
      qrCode: null,
      courseRecommendIndex: 0
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  created() {
    this.getData();
  },
  mounted () {
    console.log(this.qrCode);
  },
  methods: {
    async toSubmit() {
      if (this.form.userName && this.form.userName.length > 30) {
        this.$message.error(this.$t('pages.other.xingmingbunengchaoguo30gezi'));
        return;
      }
      if (
        this.form.userPhone &&
        !/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.userPhone)
      ) {
        this.$message.error(
          this.$t('pages.other.qingtianxiezhengquedeshoujihao')
        );
        return;
      }
      if (
        this.form.userEmail &&
        !/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          this.form.userEmail
        )
      ) {
        this.$message.error(
          this.$t('pages.other.qingtianxiezhengquedeyouxiangdizhi')
        );
        return;
      }
      if (this.form.feedbackText.trim()) {
        try {
          await insertFeedbackData(this.form);
          this.$message.success(this.$t('pages.other.ganxienindefankui'));
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error(this.$t('pages.other.qingtianxiefankuiyijian'));
      }
    },
    toShowService() {
      this.showService = !this.showService;
    },
    toCourseStudy(item) {
      this.$router.push(`/course-study/${item.courseId}/0/0`);
    },
    toCourseDetail(item) {
      console.log(item);
      this.$router.push(`/course-zone/detail/${item.courseId}`);
    },
    getData() {
      // alert(0)
      selectIndexData({ locale: this.$store.state.locale }).then(res => {
        // alert(1)
        this.courseDescribe = res.pcConfig.courseDescribe;
        this.courseDescribeEn = res.pcConfig.courseDescribeEn;
        this.coursePlanDescribe = res.pcConfig.coursePlanDescribe;
        this.coursePlanDescribeEn = res.pcConfig.coursePlanDescribeEn;
        this.courseApointDecribe = res.pcConfig.courseApointDecribe;
        this.courseApointDecribeEn = res.pcConfig.courseApointDecribeEn;
        this.courseRecommendList = res.courseRecommendList;
        this.coursePlanList = res.coursePlanDtoList;
        this.courseSubscribeList = res.CourseApointList;
        this.downloadUrl = res.pcConfig.courseMenuUrl;
        this.newsList = res.recommendNews || [];
        this.qrCode = res.pcConfig.qrCode;
        // console.log(this.courseRecommendList)
        setTimeout(() => {
          if (this.$store.state.toBottom) {
            this.$store.commit('setToBottom', false);
            document.querySelector('.contact-us-nav').click();
            if (this.$store.state.userinfo && this.$store.state.userinfo.name) {
              this.form.userName = this.$store.state.userinfo.name
              this.form.userPhone = this.$store.state.userinfo.tel
              this.form.userEmail = this.$store.state.userinfo.email
            }
          }
        }, 800);
      }).catch((error) => {
        console.log(error);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.course-box {
  overflow: hidden;
}
.home {
  .recommend-cat {
    display: flex;
    flex-wrap: wrap;
    .cat-item {
      width: 20%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fffaeb;
      margin-top: 20px;
      &:nth-child(-n + 5) {
        margin-top: 0;
      }
      font-size: 18px;
      color: #999999;
      cursor: pointer;
      &.active {
        color: #333333;
        font-weight: bold;
        background-color: #ffbb05;
      }
    }
  }
  .section {
    background: #ffffff;
    &.course-plan {
      background-color: #f7f7f7;
      background-image: url('../assets/images/bg-course-plan.png');
      background-repeat: no-repeat;
      .btns {
        margin-top: 90px;
        .btn {
          color: $base-color !important;
          border-color: $base-color !important;
        }
      }
    }
    &.contact-us {
      background-color: #000000;
      background-image: url('../assets/images/bg-contact.png');
      background-repeat: no-repeat;
      .title {
        color: #ffffff;
        padding-bottom: 78px;
      }
      .section-container {
        padding-top: 77px;
        height: 630px;
        color: #ffffff;
      }
    }
    .section-container {
      width: 1200px;
      margin: 0 auto;
      padding-top: 35px;
      padding-bottom: 63px;
      text-align: center;
      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
      }
      .subtitle {
        font-size: 16px;
        color: #666666;
        margin-top: 54px;
        padding: 0 110px;
        margin-bottom: 105px;
      }
      ::v-deep .el-tabs {
        .el-tabs__nav {
          background: #fffaeb;
          .el-tabs__active-bar {
            height: 100%;
          }
          .el-tabs__item {
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            color: $text-color-grey;
            position: relative;
            z-index: 2;
            transition: all 0.3s;
            user-select: none;
            &.is-active {
              color: $text-color-black;
              font-weight: bold;
            }
          }
        }
        .el-tabs__content {
          overflow: visible;
        }
      }
      .course-list {
        padding-top: 25px;
        padding-bottom: 50px;
        margin-left: -18px;
        min-height: 647px;
        text-align: left;
        li {
          width: 286px;
          height: 266px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.15);
          display: inline-block;
          margin-bottom: 26px;
          margin-left: 18px;
          transition: transform 0.3s;
          cursor: pointer;
          &:hover {
            transform: scale(1.05);
          }
          .img-area {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info-area {
            padding: 15px;
            .title {
              line-height: 1;
              font-size: 18px;
              font-weight: bold;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #333;
              padding: 15px 0 10px;
            }
            .extra {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              line-height: 1;
              .oper-area {
                color: #999999;
                font-size: 16px;
                .oper {
                  cursor: pointer;
                  user-select: none;
                  i.iconfont {
                    font-size: 14px;
                    color: #66a7ff;
                    margin-right: 7px;
                  }
                  & ~ .oper {
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
      .course-subscribe-list {
        margin-left: -30px;
        margin-bottom: 40px;
        text-align: left;
        li {
          display: inline-block;
          width: 380px;
          height: 380px;
          margin-left: 30px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
          margin-bottom: 20px;
          vertical-align: middle;
          transition: transform 0.3s;
          &:hover {
            transform: scale(1.02);
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
            .img-area {
              height: 150px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .btn {
                position: absolute;
                right: 10px;
                bottom: 10px;
                background: linear-gradient(0deg, #66a7ff 0%, #66a6fd 100%);
                border-radius: 3px;
                height: 26px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #ffffff;
                padding: 0 14px;
                .iconfont {
                  margin-right: 5px;
                  font-size: 12px;
                }
              }
            }
            .middle-area {
              padding: 22px 0 20px;
              width: 350px;
              margin: 0 auto;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.03);
              .title-area {
                display: flex;
                align-items: center;
                .text {
                  margin-right: 10px;
                  flex: 1;
                  min-width: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 20px;
                  color: #333;
                }
              }
              .start-time {
                font-size: 12px;
                color: $text-color-grey;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 14px;
              }
              .extra {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .teacher-info {
                  flex: 1;
                  min-width: 0;
                  display: flex;
                  align-items: center;
                  .name {
                    flex: 1;
                    min-width: 0;
                    font-size: 12px;
                    color: $text-color-grey;
                    margin-left: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .num-text {
                  font-size: 14px;
                  color: $text-color-grey;
                  margin-left: 10px;
                  .num {
                    color: #66a7fe;
                  }
                }
              }
            }
            .bottom-area {
              height: 72px;
              padding: 5px;
              .quote-area {
                background: url('../assets/images/bg-quote.png') no-repeat;
                height: 100%;
                font-size: 14px;
                color: $text-color-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 38px;
                .text-wrap {
                  text-align: left;
                  line-height: 1.3;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
      .btns {
        text-align: center;
        .btn {
          display: inline-block;
          width: 198px;
          height: 44px;
          line-height: 44px;
          border: 1px solid #d2d2d2;
          border-radius: 22px;
          color: #999999;
          font-size: 16px;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s;
          &:hover {
            color: $base-color;
            border-color: $base-color;
          }
          & ~ .btn {
            margin-left: 20px;
          }
        }
      }
      .content-area {
        display: flex;
        text-align: left;
        .form-area {
          width: 584px;
          .my-input {
            outline: none;
            border: 1px solid #666666;
            background: transparent;
            padding: 0 13px;
            height: 50px;
            margin-bottom: 15px;
            width: 100%;
            color: #ffffff;
          }
          .my-textarea {
            outline: none;
            border: 1px solid #666666;
            background: transparent;
            padding: 13px;
            width: 100%;
            height: 156px;
            resize: none;
            margin-bottom: 15px;
            color: #ffffff;
          }
          .line {
            display: flex;
            .my-input {
              flex: 1;
              & ~ .my-input {
                margin-left: 20px;
              }
            }
          }
          .my-btn {
            width: 120px;
            height: 50px;
            font-size: 18px;
            color: #333333;
            border-radius: 0;
          }
        }
        .contacts-area {
          margin-left: 137px;
          .item {
            padding-left: 38px;
            position: relative;
            margin-bottom: 40px;
            transition: color 0.3s;
            &:hover {
              color: $base-color;
              .bottom-text {
                color: $base-color;
              }
            }
            img {
              width: 18px;
              height: 18px;
              position: absolute;
              top: 6px;
              left: 0;
            }
            .top-text {
              font-size: 18px;
              font-weight: bold;
            }
            .bottom-text {
              font-size: 14px;
              color: rgba($color: #ffffff, $alpha: 0.3);
              transition: color 0.3s;
            }
          }
        }
      }
    }
  }
}
</style>
