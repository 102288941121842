<template>
  <div class="main-section">
    <div class="main-container">
      <!-- 登录 -->
      <div class="login-area">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.main-section {
  background-image: url('../assets/images/bg-login.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding-bottom: 30px;
  .main-container {
    padding-top: 20px;
    margin: 0 auto;
    .login-area {
      width: 1000px;
      min-height: 646px;
      background: #ffffff;
      margin: 0 auto;
      padding-top: 60px;
      padding-bottom: 48px;
      position: relative;
    }
  }
}
</style>