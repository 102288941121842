<template>
  <div class="empty">
    <img src="@/assets/images/img-empty.png" />
    <div class="desc">
      <span>{{ handledText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  computed: {
    handledText() {
      return this.text || this.$t('common.noData');
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scope>
.empty {
  padding-top: 100px;
  img {
    width: 250px;
    margin-bottom: 20px;
    display: block;
    margin: 0 auto;
  }
  .desc {
    color: #999999;
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
  }
}
</style>
