<template>
  <div class="course-customization">
    <!-- banner -->
    <div class="banner-area"></div>
    <!-- 内容 -->
    <div class="section">
      <div class="section-container">
        <div class="intro-area">
          <div class="intro-text">{{ intro }}</div>
          <div class="btns">
            <el-button
              type="primary"
              class="my-btn"
              round
              @click="showFormDialog = true"
              >{{ $t('pages.other.woyaodingzhi') }}</el-button
            >
            <el-button
              type="primary"
              class="my-btn"
              plain
              round
              :loading="isLoading"
              @click="showCourseList"
              >{{ $t('pages.other.kechengdabao') }}</el-button
            >
          </div>
        </div>
        <div class="detail-area" v-if="detail" v-html="detail">
          <!-- <div class="title">培训计划</div>
          <div class="content">
            <img src="@/assets/images/temp/img-customization01.png" alt="">
          </div>
          <div class="title">课程形式设计</div>
          <div class="content">
            <img src="@/assets/images/temp/img-customization02.png" alt="">
          </div> -->
        </div>
      </div>
    </div>
    <!-- 定制课程弹窗 -->
    <el-dialog
      :title="$t('pages.other.dingzhikecheng')"
      width="1100px"
      :visible.sync="showFormDialog"
    >
      <div class="popup-content form">
        <p class="title">{{ $t('pages.other.tijiaopeixun') }}：</p>
        <div class="subtitle">
          <p>{{ $t('pages.other.peixunketicanjia') }}</p>
          <p>
            {{ $t('pages.other.peixunxuqiulianxi') }}
          </p>
        </div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="right"
          label-width="120px"
        >
          <el-form-item :label="$t('pages.other.company')" prop="company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.other.chenhu')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.other.lianxifangshi')" prop="tel">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.jutipeixunxuqiu')"
            prop="detail"
          >
            <el-input v-model="form.detail" type="textarea" rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showFormDialog = false">{{
          $t('pages.other.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmit">{{
          $t('pages.other.tijiaoxuqiu')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 打包课程弹窗 -->
    <el-dialog
      :title="$t('pages.other.dabaokecheng')"
      width="1100px"
      :visible.sync="showTableDialog"
    >
      <div class="popup-content table">
        <div class="filter-area">
          <el-select
            v-model="type"
            v-loading="typeLoading"
            size="small"
            clearable
            :placeholder="$t('pages.other.shaixuankechengleixing')"
            @focus="toGetCategoryList"
            @change="toGetData"
          >
            <el-option
              v-for="item in options"
              :key="item.courseTypeId"
              :label="item.courseTypeName"
              :value="item.courseTypeId"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="$t('pages.other.keywords')"
            v-model="keywords"
            class="input-with-select"
            size="small"
            clearable
            @keyup.enter.native="getData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getData"
            ></el-button>
          </el-input>
        </div>
        <el-table
          :data="courseList"
          style="width: 100%"
          row-key="courseId"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="55"
          >
          </el-table-column>
          <el-table-column
            :label="$t('pages.other.kechengmingcheng')"
            width="310"
          >
            <template slot-scope="scope">
              <div class="name-area">
                <img :src="scope.row.courseCoverUrl" alt="" />
                <span class="name">{{ scope.row.courseName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseTypeLabels"
            :label="$t('pages.other.kechengleixing')"
          >
          </el-table-column>
          <el-table-column
            prop="teacherName"
            :label="$t('pages.other.jiangshi')"
          >
          </el-table-column>
          <el-table-column :label="$t('pages.other.jiage')">
            <template slot-scope="scope">
              <span style="color: #ff4c4c">￥{{ scope.row.coursePrice }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-area">
          <el-pagination
            :current-page.sync="page"
            :page-size="pageSize"
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="getData"
          >
          </el-pagination>
        </div>
        <div class="count-info">
          <div class="count-area">
            <div class="count">
              {{ $t('pages.other.yixuan') }}{{ selection.length
              }}{{ $t('pages.other.gekecheng') }}
            </div>
            <div class="total-price">
              {{ $t('pages.other.heji') }}：
              <span class="price">¥{{ totalPrice | fixZero }}</span>
            </div>
          </div>
          <div class="price-area">
            <span class="label">{{ $t('pages.other.yingfukuan') }}：</span>
            <span class="price red" v-if="orgRate === 1">
              ¥{{ (Math.round(totalPrice * discount * 100) / 100) | fixZero }}
            </span>
            <span class="price red" v-else>
              ¥{{
                (Math.round(totalPrice * discount * orgRate * 100) / 100)
                  | fixZero
              }}
            </span>
            <span class="discount">
              ({{ $t('pages.other.pingtaiyouhui')
              }}<span class="red"
                >-{{
                  (Math.round(totalPrice * (1 - discount) * 100) / 100)
                    | fixZero
                }}</span
              >
              <span v-if="orgRate !== 1"
                >, {{ $t('pages.other.jigouyouhui')
                }}<span class="red"
                  >-{{
                    (Math.round(totalPrice * (1 - discount) * orgRate * 100) /
                      100)
                      | fixZero
                  }}</span
                ></span
              >
              )
            </span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTableDialog = false">{{
          $t('pages.other.cancel')
        }}</el-button>
        <el-button type="primary" @click="toNext">
          {{ $t('pages.other.lijigoumai') }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectIndexData,
  selectCustomCourseList,
  selectCategoryList,
  insertCustomizationData,
} from '@/api/common';
export default {
  data() {
    return {
      showFormDialog: false,
      form: {
        company: '',
        name: '',
        tel: '',
        detail: '',
      },
      rules: {
        company: [
          {
            required: true,
            message: this.$t('pages.other.qingshurugongsimingcheng'),
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('pages.other.qingshurunindechenhu'),
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: this.$t('pages.other.qingshuruzhengquedelianxifangshi'),
            trigger: 'blur',
          },
        ],
        detail: [
          {
            required: true,
            message: this.$t('pages.other.qingshurujutixuqiu'),
            trigger: 'blur',
          },
        ],
      },
      showTableDialog: false,
      courseList: [],
      total: 0,
      page: 1,
      pageSize: 5,
      isLoading: false,
      type: '',
      options: [],
      typeLoading: false,
      keywords: '',
      selection: [],
      intro: '',
      detail: '',
      discount: 1,
      orgRate: 1,
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.selection.forEach((item) => {
        total += item.coursePrice;
      });
      return Math.round(total * 100) / 100;
    },
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  filters: {
    fixZero(val) {
      const arr = val.toString().split('.');
      if (arr[1]) {
        arr[1] = arr[1][1] ? arr[1] : `${arr[1]}0`;
        return arr.join('.');
      } else {
        arr.push('00');
        return arr.join('.');
      }
    },
  },
  methods: {
    toNext() {
      if (this.selection.length > 2) {
        this.$router.push(
          `/pay/confirm-order/${this.selection
            .map((item) => item.courseId)
            .join(',')}/2`
        );
        this.showTableDialog = false;
      } else {
        this.$message.error(this.$t('pages.other.qingxuanzekecheng'));
      }
    },
    async toSubmit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            await insertCustomizationData({
              applyCompany: this.form.company,
              applyUserName: this.form.name,
              applyUserPhone: this.form.tel,
              customizationText: this.form.detail,
            });
            this.$message.success(
              this.$t('pages.other.dingzhixuqiutijiaochenggong')
            );
            this.showFormDialog = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.selection = val;
    },
    toGetData() {
      this.page = 1;
      this.getData();
    },
    async showCourseList() {
      this.getData(() => {
        this.showTableDialog = true;
      });
    },
    async getData(callback) {
      try {
        this.isLoading = true;
        const res = await selectCustomCourseList({
          locale: this.$store.state.locale,
          courseName: this.keywords,
          pageNum: this.page,
          pageSize: this.pageSize,
          courseTypeId: this.type,
          payRole: this.userinfo.roles[this.$store.state.currentRoleIndex],
        });
        this.courseList = res.data;
        this.total = res.total;
        this.isLoading = false;
        this.orgRate = res.orgRate ? parseFloat(res.orgRate) : 1;
        callback && typeof callback === 'function' && callback();
      } catch (error) {
        console.log(error);
      }
    },
    async toGetCategoryList() {
      if (this.options.length === 0) {
        try {
          this.typeLoading = true;
          const res = await selectCategoryList({
            locale: this.$store.state.locale,
          });
          res.unshift({
            courseTypeId: '',
            courseTypeName: '全部',
          });
          this.options = res;
          this.typeLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getInitData() {
      try {
        const res = await selectIndexData({ locale: this.$store.state.locale });
        if (this.$store.state.locale === 'en') {
          this.intro = res.pcConfig.courseCustomizationDecribeEn;
          this.detail = res.pcConfig.courseCustomizationShowEn;
        } else {
          this.intro = res.pcConfig.courseCustomizationDecribe;
          this.detail = res.pcConfig.courseCustomizationShow;
        }
        this.discount = res.pcConfig.courseCustomizationDiscounts;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getInitData();
  },
};
</script>

<style lang="scss" scoped>
.course-customization {
  .banner-area {
    height: 350px;
    background-image: url('../../assets/images/bg-course-customization.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }
  .section {
    .section-container {
      width: 1200px;
      margin: 50px auto 85px;
      .intro-area {
        height: 305px;
        background-image: url('../../assets/images/img-course-customization.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        padding: 60px 512px 0 44px;
        .intro-text {
          font-size: 16px;
          text-align: justify;
          margin-bottom: 33px;
          height: 112px;
          overflow-y: auto;
          padding-right: 5px;
          &::-webkit-scrollbar {/*滚动条整体样式*/
              width: 3px;     /*高宽分别对应横竖滚动条的尺寸*/
              background-color: #F5F5F5;
          }
          &::-webkit-scrollbar-thumb {/*滚动条整体样式*/
              width: 3px;     /*高宽分别对应横竖滚动条的尺寸*/
              background-color: #e5e5e5;
              border-radius: 5px;
          }
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 4;
          // -webkit-box-orient: vertical;
        }
      }
      .detail-area {
        margin-top: 30px;
        background: #ffffff;
        padding-top: 40px;
        padding-bottom: 40px;
        .title {
          background: $base-color;
          display: inline-block;
          width: 146px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          margin-left: -10px;
        }
        .content {
          margin: 15px 0;
          ::v-deep img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .popup-content {
    &.form {
      padding: 40px 200px 40px;
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 20px;
      }
      .subtitle {
        font-size: 16px;
        color: $text-color-grey;
        line-height: 32px;
        margin-bottom: 50px;
      }
    }
    &.table {
      padding: 0 70px;
      .filter-area {
        display: flex;
        justify-content: space-between;
        margin-bottom: 33px;
        .input-with-select {
          width: 250px;
        }
      }
      .name-area {
        display: flex;
        padding: 10px 0;
        align-items: center;
        img {
          width: 82px;
          height: 49px;
          margin-right: 13px;
        }
        .name {
          flex: 1;
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      ::v-deep .el-table {
        border: 1px solid #ebeef5;
        border-bottom: none;
        th {
          background: #f7f7f7;
          text-align: left;
          &.el-table-column--selection {
            .cell {
              padding-left: 14px;
            }
          }
        }
      }
      .pagination-area {
        border: 1px solid #ebeef5;
        border-top: none;
        padding: 18px 0;
        .el-pagination {
          text-align: center;
        }
      }
      .count-info {
        line-height: 1;
        padding-top: 36px;
        .count-area {
          padding-left: 20px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .count {
            font-size: 16px;
            color: $text-color-grey;
          }
          .price {
            font-size: 18px;
            margin-left: 5px;
          }
        }
        .price-area {
          text-align: right;
          font-size: 16px;
          color: $text-color-grey;
          .label {
            color: $text-color-black;
          }
          .price {
            font-size: 20px;
            font-weight: bold;
          }
          .red {
            color: #ff4c4c;
          }
        }
      }
    }
  }
  .dialog-footer {
    padding: 0 70px;
  }
}
</style>
