<template>
  <div class="exam">
    <!-- 头部 -->
    <div class="header">
      <div class="container">
        <div class="logo-area">
          <img src="../../assets/images/logo.png" alt="" />
          <span class="title">{{ $t('pages.other.zaixiankaoshi') }}</span>
        </div>
        <router-link class="userinfo" to="/personal">
          <el-avatar :size="54" :src="userinfo.avatar || defaultAvatar" />
          <span class="username">{{ userinfo.name }}</span>
        </router-link>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="left">
        <div class="title-area">
          <div class="title">{{ courseName }}</div>
          <div class="timing-area">
            <i class="iconfont">&#xe614;</i>
            <div class="timing">{{ countDownTime }}</div>
          </div>
        </div>
        <div class="t-list" v-if="list.length > 0">
          <div class="t-area">
            <div class="title">
              {{ list[currentIndex].index + 1 }}、{{
                list[currentIndex].type | typeText
              }}
            </div>
            <div class="question">{{ list[currentIndex].question }}</div>
            <el-radio-group
              size="medium"
              v-model="list[currentIndex].result"
              v-if="list[currentIndex].type !== '2'"
            >
              <div
                class="option"
                v-for="option in list[currentIndex].options"
                :key="option.optionId"
              >
                <el-radio :label="option.optionId"
                  ><span
                    class="the-label"
                    v-if="list[currentIndex].type !== '3'"
                    >{{ option.optionId }}、</span
                  >
                  {{ option.optionText }}</el-radio
                >
              </div>
            </el-radio-group>
            <el-checkbox-group
              size="medium"
              v-model="list[currentIndex].result"
              v-else
            >
              <div
                class="option"
                v-for="option in list[currentIndex].options"
                :key="option.optionId"
              >
                <el-checkbox :label="option.optionId"
                  >{{ option.optionId }}、 {{ option.optionText }}</el-checkbox
                >
              </div>
            </el-checkbox-group>
          </div>
          <div class="oper-area">
            <el-button
              type="primary"
              :disabled="currentIndex === 0"
              @click="toPrve"
              >{{ $t('pages.other.shangyiti') }}</el-button
            >
            <el-button type="success" @click="toSubmit">{{
              $t('pages.other.jiaojuan')
            }}</el-button>
            <el-button
              type="primary"
              :disabled="currentIndex === list.length - 1"
              @click="toNext"
              >{{ $t('pages.other.xiayiti') }}</el-button
            >
          </div>
        </div>
      </div>
      <!-- 答题卡 -->
      <div class="right">
        <div class="title-area">
          {{ $t('pages.other.qianbutimu') }}({{ currentIndex + 1 }}/{{
            list.length
          }})
        </div>
        <div class="a-card-area">
          <ul class="a-card">
            <li
              v-for="item in list"
              :key="item.id"
              :class="{
                done:
                  (Array.isArray(item.result) && item.result.length > 0) ||
                  (!Array.isArray(item.result) && item.result !== ''),
              }"
              @click="changeCurrentIndex(item)"
            >
              {{ item.index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 结果弹窗 -->
    <el-dialog
      :visible.sync="resultDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="740"
      center
    >
      <span slot="title"></span>
      <div class="pop-content">
        <div class="img-area">
          <img src="@/assets/images/img-exam-result.png" alt="" />
        </div>
        <div class="title">{{ $t('pages.other.tijiaochenggong') }}</div>
        <div class="title sub">
          {{ $t('pages.other.bencidatigong') }}{{ list.length
          }}{{ $t('pages.other.ti') }}，<span class="text-green">
            {{ $t('pages.other.dangqiandadui') }}{{ correct
            }}{{ $t('pages.other.ti') }}</span
          >，<span class="text-orange"
            >{{ $t('pages.other.dacuo') }}{{ incorrect
            }}{{ $t('pages.other.ti') }}，</span
          >{{ $t('pages.other.kaoshirenzheng')
          }}{{
            correct >= standard
              ? $t('pages.other.chenggong')
              : $t('pages.other.shibai')
          }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="toPersonal">{{
          $t('pages.other.gerenzhongxin')
        }}</el-button>
        <el-button type="primary" @click="toCertificate">{{
          $t('pages.other.chakanzhengshu')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectPaperDetailOfOrg, insertExamData } from '@/api/common';
let timer = null;
let types = {};
export default {
  data() {
    return {
      defaultAvatar: require('@/assets/images/default-avatar.png'),
      resultDialog: false,
      correct: 0,
      incorrect: 0,
      standard: 0,
      duration: 0,
      expired: false,
      courseName: '',
      id: '',
      paperId: '',
      type: 0,
      currentIndex: 0,
      list: [],
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    countDownTime() {
      if (this.duration > 0) {
        const hour = Math.floor(this.duration / 3600);
        const min = Math.floor((this.duration - hour * 3600) / 60);
        const sec = this.duration - hour * 3600 - min * 60;
        return `${hour > 9 ? `${hour}:` : hour ? `0${hour}:` : ''}${
          min > 9 ? min : `0${min}`
        }:${sec > 9 ? sec : `0${sec}`}`;
      } else {
        return this.$t('pages.other.kaoshijiesu');
      }
    },
  },
  filters: {
    typeText(type) {
      return types[type];
    },
  },
  methods: {
    toPersonal() {
      this.$router.push('/personal');
    },
    toCertificate() {
      this.$router.push('/personal/my-certificate');
    },
    toSubmit() {
      if (
        this.list.filter(
          (item) =>
            (Array.isArray(item.result) && item.result.length > 0) ||
            (!Array.isArray(item.result) && item.result !== '')
        ).length < this.list.length
      ) {
        this.$confirm(
          this.$t('pages.other.datiweiwanchengqueding'),
          this.$t('pages.other.tishi'),
          {
            type: 'warning',
          }
        )
          .then(() => {
            this.submit();
          })
          .catch(() => {});
      } else {
        this.submit();
      }
    },
    async submit() {
      const answers = this.list.map((item) => {
        let flag = false;
        if (item.type === '2') {
          let answer = item.answer.split(',');
          if (answer.length === item.result.length) {
            let count = 0;
            answer.forEach((a) => {
              if (item.result.includes(a)) {
                count++;
              }
            });
            flag = count === answer.length;
          }
        } else {
          flag = item.result === item.answer;
        }
        if (flag) {
          this.correct++;
        } else {
          this.incorrect++;
        }
        const answer =
          item.type === '2' ? item.result.join(',') : item.result.toString();
        return {
          id: item.id,
          answer,
          type: item.type,
          flag,
        };
      });
      try {
        const res = await insertExamData({
          paperType: this.type,
          paperId: this.paperId,
          courseId: this.id,
          rightAcount: this.correct,
          errorAcount: this.incorrect,
          errorQuestionBankIds: answers
            .filter((item) => !item.flag)
            .map((item) => item.id)
            .join(','),
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      console.log({
        id: this.id,
        answers,
        correct: this.correct,
        incorrect: this.incorrect,
      });
      this.resultDialog = true;
    },
    changeCurrentIndex(item) {
      this.currentIndex = item.index;
    },
    toPrve() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    toNext() {
      if (this.currentIndex < this.list.length - 1) {
        this.currentIndex++;
      }
    },
    countDown() {
      clearInterval(timer);
      timer = setInterval(() => {
        this.duration--;
        if (this.duration <= 0) {
          clearInterval(timer);
          // 自动交卷
          this.submit();
        }
      }, 1000);
    },
    async getData() {
      try {
        const res = await selectPaperDetailOfOrg({
          authorizeId: this.id,
          queryType: this.type,
        });
        this.paperId = res.paperId;
        this.courseName = res.paperName;
        this.standard = res.rightAcount;
        this.duration = res.duration * 60;
        res.questionBankList.forEach((item, index) => {
          this.list.push({
            id: item.questionBankId,
            type: item.contentType,
            index,
            question: item.content,
            options: JSON.parse(item.allOptions),
            answer: item.correctOptionIds,
            result: item.contentType === '2' ? [] : '',
          });
        });
        this.countDown();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    types = {
      1: this.$t('pages.other.danxuanti'),
      2: this.$t('pages.other.duoxuanti'),
      3: this.$t('pages.other.panduanti'),
    };
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.exam {
  .header {
    height: 140px;
    background: #ffffff;
    .container {
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .logo-area {
        display: flex;
        align-items: center;
        img {
          width: 140px;
          margin-right: 37px;
        }
        .title {
          color: $text-color-grey;
          font-size: 28px;
          font-weight: bold;
        }
      }
      .userinfo {
        display: flex;
        align-items: center;
        .username {
          font-size: 18px;
          margin-left: 16px;
        }
      }
    }
  }
  .content {
    width: 1200px;
    margin: 30px auto 30px;
    position: relative;
    padding-right: 340px;
    .left {
      .title-area {
        background: #ffffff;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 30px 0 55px;
        .title {
          flex: 1;
          font-size: 24px;
          line-height: 30px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .timing-area {
          margin-left: 20px;
          display: flex;
          align-items: center;
          .iconfont {
            font-size: 24px;
            color: #bbb;
          }
          .timing {
            font-size: 28px;
            font-weight: bold;
            color: #ff5c5c;
            margin-left: 15px;
          }
        }
      }
      .t-list {
        background: #ffffff;
        padding: 50px 55px 120px;
        margin-top: 10px;
        .t-area {
          font-size: 16px;
          line-height: 26px;
          .title {
            height: 50px;
            line-height: 50px;
            font-weight: bold;
          }
          .question {
            padding: 22px 27px;
            margin-bottom: 44px;
            background: #f8f9fb;
          }
          ::v-deep .el-radio-group {
            display: block;
            .el-radio {
              display: flex;
              padding: 16px 30px 16px 46px;
              .el-radio__input {
                padding-top: 6px;
              }
              .el-radio__label {
                white-space: pre-wrap;
                line-height: 26px;
                padding-left: 28px;
                font-size: 16px;
              }
            }
          }
          ::v-deep .el-checkbox-group {
            .el-checkbox {
              display: flex;
              padding: 16px 30px 16px 46px;
              .el-checkbox__input {
                padding-top: 6px;
              }
              .el-checkbox__label {
                white-space: pre-wrap;
                line-height: 26px;
                padding-left: 28px;
                font-size: 16px;
              }
            }
          }
          .option {
            margin-bottom: 10px;
            background: #f8f9fb;
          }
        }
        .oper-area {
          display: flex;
          justify-content: space-between;
          margin-top: 100px;
        }
      }
    }
    .right {
      position: absolute;
      top: 0;
      right: 0;
      width: 330px;
      .title-area {
        background: #ffffff;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 36px;
        font-size: 20px;
        font-weight: bold;
      }
      .a-card-area {
        margin-top: 10px;
        background: #ffffff;
        padding: 38px 40px 56px 36px;
        .a-card {
          font-size: 0;
          margin-left: -6px;
          li {
            font-size: 16px;
            color: #b3b3b3;
            width: 46px;
            height: 46px;
            border-radius: 5px;
            margin-left: 6px;
            margin-bottom: 10px;
            border: 1px solid #ebebeb;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            &.done {
              border-color: #3fca6c;
              background: #3fca6c;
              color: #ffffff;
            }
            &:hover {
              border-color: #70eb97;
            }
            &:active {
              transform: translate(1px, 1px);
            }
          }
        }
      }
    }
  }
  .pop-content {
    text-align: center;
    .img-area {
      width: 199px;
      margin: 0 auto 20px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      &.sub {
        font-size: 18px;
        font-weight: normal;
        color: #666666;
        margin-top: 10px;
      }
      .text-orange {
        color: #ff5000;
      }
      .text-green {
        color: #34af35;
      }
    }
  }
}
</style>
