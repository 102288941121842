<template>
  <div class="main-section">
    <div class="main-container">
      <div class="side-area">
        <div class="userinfo-area">
          <el-avatar
            :size="80"
            :src="userinfo.avatar ? userinfo.avatar : defaultAvatar"
          />
          <div class="username">{{ userinfo.name }}</div>
        </div>
        <div class="menu-area">
          <el-menu
            :default-active="$route.path"
            :unique-opened="true"
            :router="true"
          >
            <el-menu-item index="/personal/my-home">
              <div class="menu-item">
                <img src="@/assets/images/icon-my-home.png" alt="" />
                <span>{{ $t('pages.personal.index') }}</span>
              </div>
            </el-menu-item>
            <el-submenu index="1" v-if="role !== '2' && role !== '3'">
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-info.png" alt="" />
                  <span>{{ $t('pages.personal.personal') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/my-info">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.profiles') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-orders">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orders') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/teacher-info" v-if="role === '4'">
                <div class="sub-menu-item">{{ $t('pages.personal.exp') }}</div>
              </el-menu-item>
              <el-menu-item index="/personal/my-message">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.messages') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-survey">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.survey') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="2"
              v-if="role !== '2' && role !== '3' && role !== '4'"
            >
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-study.png" alt="" />
                  <span>{{ $t('pages.personal.learning') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/my-course/">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.courses') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-course-plan">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.coursePlans') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-try">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myTry') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-subscribe">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.appointments') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-wrong-set">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myWrongs') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-demand" v-if="role === '1'">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myDemands') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-certificate">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myCertificates') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="3"
              v-if="role !== '2' && role !== '3' && role !== '4'"
            >
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-collection.png" alt="" />
                  <span>{{ $t('pages.personal.myFavorite') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/my-coll-course">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.favCourses') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-coll-course-plan">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.favCoursePlans') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-coll-teacher">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.favTeacher') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-coll-news">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.favNews') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="4"
              v-if="role !== '2' && role !== '3' && role !== '4'"
            >
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-comment.png" alt="" />
                  <span>{{ $t('pages.personal.myRating') }}</span>
                </div>
              </template>
              <!-- <el-menu-item index="/personal/my-comment-news">
                <div class="sub-menu-item">评价的新闻</div>
              </el-menu-item> -->
              <el-menu-item index="/personal/my-comment-course">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myRatingCourses') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-comment-course-plan">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.myRatingCoursePlans') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="5" v-if="role === '2'">
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-org-info.png" alt="" />
                  <span>{{ $t('pages.personal.orgManage') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/org-info" v-if="role === '2'">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgInfo') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/org-manage" v-if="role === '2'">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgMember') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-orders" v-if="role === '2'">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgOrders') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-message">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.messages') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/my-news-list" v-if="role === '2'">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgNews') }}
                </div>
              </el-menu-item>
              <el-menu-item
                index="/personal/my-banner-list"
                v-if="role === '2'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgBanners') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="6"
              v-if="role === '2' || role === '3' || role === '4'"
            >
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-study.png" alt="" />
                  <span>{{ $t('pages.personal.courseManage') }}</span>
                </div>
              </template>
              <el-menu-item
                index="/personal/org-video-list"
                v-if="role === '2'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgVideos') }}
                </div>
              </el-menu-item>
              <el-menu-item
                index="/personal/org-course-list"
                v-if="role === '2' || role === '3'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgCourses') }}
                </div>
              </el-menu-item>
              <el-menu-item
                index="/personal/org-course-plan-list"
                v-if="role === '2' || role === '3'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.orgCoursePlans') }}
                </div>
              </el-menu-item>
              <el-menu-item
                index="/personal/my-qa/"
                v-if="role === '2' || role === '3' || role === '4'"
              >
                <div class="sub-menu-item">{{ $t('pages.personal.qna') }}</div>
              </el-menu-item>
              <el-menu-item
                index="/personal/org-statistics"
                v-if="role === '2'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.statistics') }}
                </div>
              </el-menu-item>
              <el-menu-item
                index="/personal/my-demand-manage"
                v-if="role === '2'"
              >
                <div class="sub-menu-item">
                  {{ $t('pages.personal.demandManage') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="7" v-if="role === '2'">
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-test.png" alt="" />
                  <span>{{ $t('pages.personal.testManage') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/org-question-list">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.testManage') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/org-wrong-set">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.wrongManage') }}
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="8" v-if="role === '2'">
              <template slot="title">
                <div class="menu-item">
                  <img src="@/assets/images/icon-my-plan.png" alt="" />
                  <span>{{ $t('pages.personal.trainManage') }}</span>
                </div>
              </template>
              <el-menu-item index="/personal/org-plan-list">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.trainPlans') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/org-target-list">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.trainTarget') }}
                </div>
              </el-menu-item>
              <el-menu-item index="/personal/org-type-list">
                <div class="sub-menu-item">
                  {{ $t('pages.personal.trainType') }}
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="content-area">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultAvatar: require('@/assets/images/default-avatar.png'),
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  padding: 20px 0 30px;
  .main-container {
    width: 1200px;
    min-height: 886px;
    margin: 0 auto;
    position: relative;
    display: flex;
    .side-area {
      width: 240px;
      min-height: 886px;
      background: #ffffff;
      .userinfo-area {
        text-align: center;
        padding: 20px 0 0;
        height: 162px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.03);
        .username {
          padding: 0 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $text-color-grey;
        }
      }
      .menu-area {
        padding-top: 20px;
        .el-menu {
          border: none;
          ::v-deep .el-submenu__title {
            height: 40px;
            line-height: 40px;
          }
          ::v-deep .el-menu-item {
            height: 40px;
            line-height: 40px;
            &.is-active {
              background: #fffbf2;
              .sub-menu-item {
                color: $base-color;
              }
            }
          }
        }
        .menu-item {
          font-size: 16px;
          padding-left: 47px;
          font-weight: bold;
          img {
            width: 20px;
            height: 20px;
            margin-right: 11px;
          }
        }
        .el-submenu {
          .sub-menu-item {
            padding-left: 61px;
            font-size: 14px;
            color: $text-color-grey;
          }
        }
      }
    }
    .content-area {
      flex: 1;
      min-width: 0;
      background: #ffffff;
      margin-left: 20px;
    }
  }
}
</style>
