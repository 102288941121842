export default {
  userLogin: '用户登录',
  account: '用户名',
  password: '密码',
  CAPTCHA: '验证码',
  forgetPwd: '忘记密码',
  wechatLogin: '微信快捷登录',
  accountLogin: '账号登录',
  loginText: '还没有账号，立即',
  plh_username: '请输入用户名',
  plh_username_va: '最少6位，由字母数字下划线组成，且不能以数字开头',
  plh_username_used: '此用户名已注册',
  plh_pwd: '请输入密码',
  plh_pwd_2: '请再次输入密码',
  plh_pwd_3: '密码长度最少6位',
  plh_pwd_c: '两次输入密码不一致',
  plh_captcha: '请输入验证码',
  plh_captcha_wrong: '图形验证码错误',
  byPhone: '通过绑定手机号码找回',
  byPhoneText: '发送找回密码短信至',
  byPhonePlh: '未绑定手机号',
  byEmail: '通过绑定的邮箱找回',
  byEmailText: '发送找回密码邮件至',
  byEmailPlh: '未绑定邮箱',
  email: '邮箱地址',
  email2: '新邮箱',
  mobile: '手机号',
  cpwd: '确认密码',
  send: '获取验证码',
  resend: '重新发送',
  plh_phone: '请输入正确的手机号码',
  plh_email: '请输入正确的电子邮箱地址',
  plh_email_used: '此邮箱已注册',
  plh_send: '验证码发送成功',
  plh_modify_pwd: '密码修改成功，请登录！',
  plh_bind_email: '电子邮箱绑定成功！',
  plh_modify_email: '电子邮箱修改成功！',
  title_modify: '修改密码',
  title_reset: '找回密码',
  title_addTel: '绑定手机',
  title_addEmail: '绑定邮箱',
  student: '学员注册',
  studentOfInstitution: '机构学员注册',
  institution: '机构注册',
  institution2: '所属机构',
  name: '姓名',
  gender: '性别',
  male: '男',
  female: '女',
  company: '公司',
  companyAddr: '公司地址',
  companySize: '公司规模',
  department: '部门',
  position: '职务',
  smsCode: '短信验证码',
  emailCode: '邮件验证码',
  URAText: '我已阅读并同意',
  URA: '用户注册协议',
  USA: '用户服务协议',
  PS: '隐私申明',
  ASA: '机构服务协议',
  and: '和',
  agreed: '锐合可以使用我的联系信息随时通知我有关产品服务的信息',
  plh_register_success: '注册成功',
  plh_register_success2: '注册申请已提交，等待平台审核',
  plh_name: '请输入姓名',
  plh_gender: '请选择性别',
  plh_institution: '请选择所属机构',
  plh_company: '请输入公司名称',
  plh_company_addr: '请输入公司地址',
  plh_company_size: '请输入公司规模',
  plh_dept: '请输入部门名称',
  plh_position: '请输入职务',
  plh_phone_used: '此手机号已注册',
  plh_phone_or_email: '请输入手机号码或者电子邮箱',
  plh_SMS_code: '请输入短信验证码',
  plh_Email_code: '请输入邮箱验证码',
  plh_agreement: '请阅读并同意相关协议',
  registeSuccessText1: '恭喜你完成注册！',
  registeSuccessText2: '尊敬的用户，您已成功注册锐合教育，请登录',
  loginNow: '立即登录',
};
