<template>
  <div class="footer">
    <div class="up-container">
      <div class="section about">
        <div class="title">
          <span class="text">
            <a href="http://www.reghubchina.com" class="company_link">{{ $t('footer.about_us') }}</a>
          </span>
        </div>
        <p class="content">{{ $t('footer.about_us_content') }}</p>
      </div>
      <div class="section contacts">
        <div class="title">
          <span class="text">{{ $t('footer.contacts') }}</span>
        </div>
        <ul class="contacts-list">
          <li>
            <img src="@/assets/images/icon-tel.png" alt="">
            <span class="text">{{ $t('footer.tel') }}</span>
          </li>
          <li>
            <img src="@/assets/images/icon-email.png" alt="">
            <span class="text">academy@reghubchina.com</span>
          </li>
          <li>
            <img src="@/assets/images/icon-website.png" alt="">
            <span class="text">www.reghubchina.com</span>
          </li>
          <li>
            <img src="@/assets/images/icon-location.png" alt="">
            <span class="text">{{ $t('footer.location') }}</span>
          </li>
        </ul>
      </div>
      <div class="section follow">
        <div class="title">
          <span class="text">{{ $t('footer.follow_us') }}</span>
        </div>
        <ul class="img-list">
          <li>
            <div class="img-area">
              <img src="@/assets/images/erw-wechat.png" alt="">
            </div>
            <p class="text">{{ $t('footer.follow_us_wechat') }}</p>
          </li>
          <li>
            <div class="img-area">
              <img src="@/assets/images/erw-linkedin.png" alt="">
            </div>
            <p class="text">{{ $t('footer.follow_us_linkedin') }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="down-container">
      <div class="right-info">Copyright © 2020 锐合版权所有 京ICP备19031371号</div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
a.company_link {
  color: #fff;
  &:hover {
    color: #ffbb05;
  }
}
.footer {
  .up-container {
    margin: 0 auto;
    width: 1200px;
    height: 275px;
    display: flex;
    .section {
      font-size: 14px;
      color: #808080;
      text-align: justify;
      &.about {
        width: 336px;
      }
      &.contacts {
        margin-left: 164px;
        flex: 1;
      }
      &.follow {
        margin-left: 40px;
      }
      .title {
        font-size: 18px;
        color: #E6E6E6;
        margin-bottom: 45px;
        .text {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: $base-color;
            bottom: -8px;
            left: 0;
          }
        }
      }
      .contacts-list {
        li {
          img {
            width: 14px;
            height: 14px;
            margin-right: 13px;
          }
        }
      }
      .img-list {
        display: flex;
        justify-content: space-between;
        li {
          width: 110px;
          &:not(:first-child) {
            margin-left: 40px;
          }
          .img-area {
            width: 110px;
            height: 100px;
            margin-bottom: 10px;
            text-align: center;
            img {
              display: inline-block;
              width: 100px;
              height: 100%;
            }
          }
          p.text {
            text-align: center;
            line-height: 1.4;
          }
        }
      }
    }
  }
  .down-container {
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4D4D4D;
    font-size: 14px;
    border-top: 1px solid rgba(129, 129, 129, 0.25);
  }
}
</style>