var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"section-area"},[(_vm.datas && _vm.role !== '4')?_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.personal.stuCourse')))]),_c('div',{staticClass:"data-list full"},[_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.finishedCourse')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course'
                  : 'org-course-list',
                _vm.role === '0' || _vm.role === '1' ? '5' : ''
              )}}},[_vm._v(" "+_vm._s(_vm.datas.doneCourseCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.unfinishedCourse')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course'
                  : 'org-course-list',
                _vm.role === '0' || _vm.role === '1' ? '2' : ''
              )}}},[_vm._v(" "+_vm._s(_vm.datas.doingCourseCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.toTest')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course'
                  : 'org-course-list',
                _vm.role === '0' || _vm.role === '1' ? '3' : ''
              )}}},[_vm._v(" "+_vm._s(_vm.datas.waitExamCourseCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.makeUpTest')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course'
                  : 'org-course-list',
                _vm.role === '0' || _vm.role === '1' ? '4' : ''
              )}}},[_vm._v(" "+_vm._s(_vm.datas.againExamCourseCount)+" ")])])])]):_vm._e(),(_vm.datas && _vm.role !== '4')?_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.personal.stuCoursePlan')))]),_c('div',{staticClass:"data-list full"},[_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.finishedCourse')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course-plan'
                  : 'org-course-plan-list'
              )}}},[_vm._v(" "+_vm._s(_vm.datas.doneCoursePlanCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.unfinishedCourse')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course-plan'
                  : 'org-course-plan-list'
              )}}},[_vm._v(" "+_vm._s(_vm.datas.doingCoursePlanCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.toTest')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course-plan'
                  : 'org-course-plan-list'
              )}}},[_vm._v(" "+_vm._s(_vm.datas.waitExamCoursePlanCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.makeUpTest')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage(
                _vm.role === '0' || _vm.role === '1'
                  ? 'my-course-plan'
                  : 'org-course-plan-list'
              )}}},[_vm._v(" "+_vm._s(_vm.datas.againExamCoursePlanCount)+" ")])])])]):_vm._e(),(_vm.datas && _vm.role !== '0' && _vm.role !== '1')?_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.personal.courseQna')))]),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.undo')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage('my-qa', '0')}}},[_vm._v(" "+_vm._s(_vm.datas.notReplayCount)+" ")])]),_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.done')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage('my-qa', '1')}}},[_vm._v(" "+_vm._s(_vm.datas.hasReplayCount)+" ")])]),_vm._m(1),_vm._m(2)])]):_vm._e(),(_vm.datas && (_vm.role === '2' || _vm.role === '3'))?_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.personal.stuStatistics')))]),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pages.personal.countOfStu')))]),_c('div',{staticClass:"value",on:{"click":function($event){return _vm.toNextPage('org-manage')}}},[_vm._v(" "+_vm._s(_vm.datas.orgUserCount)+" ")])]),_vm._m(3),_vm._m(4),_vm._m(5)])]):_vm._e(),(_vm.role === '0' || _vm.role === '1')?_c('div',{staticClass:"btn-area"},[_c('el-button',{staticClass:"my-btn",attrs:{"type":"primary"},on:{"click":_vm.toNext}},[_vm._v(_vm._s(_vm.role === '0' ? _vm.$t('titles.courseHot') : _vm.$t('pages.index.feedback')))])],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-area"},[_c('img',{attrs:{"src":require("@/assets/images/img-banner-home.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"}),_c('div',{staticClass:"value"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"}),_c('div',{staticClass:"value"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"}),_c('div',{staticClass:"value"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"}),_c('div',{staticClass:"value"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-area"},[_c('div',{staticClass:"label"}),_c('div',{staticClass:"value"})])}]

export { render, staticRenderFns }