<template>
  <div class="main-content">
    <ul class="tabs" :class="{ en: locale === 'en' }">
      <li class="tab">
        <router-link to="/auth/register-member">{{
          $t('pages.login.student')
        }}</router-link>
      </li>
      <li class="tab">
        <router-link to="/auth/register-org-member">{{
          $t('pages.login.studentOfInstitution')
        }}</router-link>
      </li>
      <li class="tab active">
        <router-link to="/auth/register-org">{{
          $t('pages.login.institution')
        }}</router-link>
      </li>
    </ul>
    <!-- 表单 -->
    <div class="form-area">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="right"
        :label-width="locale === 'cn' ? '100px' : '150px'"
      >
        <el-form-item :label="$t('pages.login.account')" prop="username">
          <el-input v-model="formData.username"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.name')" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('pages.login.gender')"
          prop="gender"
          class="gender-item"
        >
          <el-radio-group v-model="formData.gender">
            <el-radio label="0">{{ $t('pages.login.male') }}</el-radio>
            <el-radio label="1">{{ $t('pages.login.female') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('pages.login.company')" prop="company">
          <el-input v-model="formData.company"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.companyAddr')" prop="companyAddr">
          <el-input v-model="formData.companyAddr"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.companySize')" prop="scale">
          <el-input v-model="formData.scale"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.department')" prop="dept">
          <el-input v-model="formData.dept"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.position')" prop="job">
          <el-input v-model="formData.job"></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.login.CAPTCHA')"
          prop="imgVcode"
        >
          <el-input v-model="formData.imgVcode"></el-input>
          <div class="v-img-area" @click="getImgVcode">
            <img :src="`data:image/png;base64,${imgSrc}`" alt="" />
          </div>
        </el-form-item>
        <el-form-item :label="$t('pages.login.mobile')" prop="tel">
          <el-input v-model="formData.tel"></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.login.smsCode')"
          prop="smsVcode"
        >
          <el-input v-model="formData.smsVcode"></el-input>
          <div class="v-btn-area">
            <el-button
              plain
              :type="smsDisabled ? 'info' : 'primary'"
              @click="getSmsVCode"
              >{{ smsTxt }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label="$t('pages.login.email')" prop="email">
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.login.emailCode')"
          prop="emailVcode"
        >
          <el-input v-model="formData.emailVcode"></el-input>
          <div class="v-btn-area">
            <el-button
              plain
              :type="emailDisabled ? 'info' : 'primary'"
              @click="getEmailVCode"
              >{{ emailTxt }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label="$t('pages.login.password')" prop="pass">
          <el-input v-model="formData.pass" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.cpwd')" prop="checkPass">
          <el-input v-model="formData.checkPass" type="password"></el-input>
        </el-form-item>
        <el-form-item prop="isAgree" class="checkbox-item">
          <el-checkbox v-model="formData.isAgree"
            >{{ $t('pages.login.URAText')
            }}<span
              class="toShowAgreement"
              :class="{ 'book-name': locale === 'en' }"
              @click.stop.prevent="toShowPopup('ROG_FUWU')"
            >
              {{ $t('pages.login.ASA') }}</span
            ></el-checkbox
          >
        </el-form-item>
        <el-form-item class="row">
          <el-button type="primary" v-loading="isLoading" @click="submitForm">{{
            $t('common.register')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 机构服务协议 -->
    <el-dialog
      :title="protocolCodeList[currProtocolCode][locale]"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="popup-content" v-html="protocolCodeContent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="dialogVisible = false">{{
          $t('common.confirm')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectImgVcode,
  checkImgVcode,
  selectVcode,
  checkUsername,
  selectServiceAgreement,
  insertOrg,
} from '@/api/common';
let smsTimer = null;
let emailTimer = null;
const protocolCodeList = {
  USE_FUWU: {
    cn: '用户服务协议',
    en: 'User Service Agreement',
  },
  USER_ZHUCE: {
    cn: '用户注册协议',
    en: 'User Registration Agreement',
  },
  USER_YINSI: {
    cn: '隐私声明',
    en: 'Privacy Statement',
  },
  ROG_FUWU: {
    cn: '机构服务协议',
    en: 'Agency Service Agreement',
  },
};
export default {
  data() {
    return {
      isLoading: false,
      currProtocolCode: 'ROG_FUWU',
      protocolCodeList,
      protocolCodeContent: '',
      dialogVisible: false,
      smsDisabled: false,
      emailDisabled: false,
      smsTxt: this.$t('pages.login.send'),
      emailTxt: this.$t('pages.login.send'),
      formData: {
        username: '',
        name: '',
        gender: '',
        company: '',
        companyAddr: '',
        scale: '',
        dept: '',
        job: '',
        imgVcode: '',
        tel: '',
        smsVcode: '',
        email: '',
        emailVcode: '',
        pass: '',
        checkPass: '',
        isAgree: false,
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('pages.login.plh_username'),
            trigger: 'blur',
          },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]{5,15}$/,
            message: this.$t('pages.login.plh_username_va'),
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              checkUsername({
                loginName: this.formData.username,
              })
                .then(() => {
                  callback();
                })
                .catch(() => {
                  callback(new Error(this.$t('pages.login.plh_username_used')));
                });
            },
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('pages.login.plh_name'),
            trigger: 'blur',
          },
        ],
        gender: [
          {
            required: true,
            message: this.$t('pages.login.plh_gender'),
            trigger: 'blur',
          },
        ],
        company: [
          {
            required: true,
            message: this.$t('pages.login.plh_company'),
            trigger: 'blur',
          },
        ],
        companyAddr: [
          {
            required: true,
            message: this.$t('pages.login.plh_company_addr'),
            trigger: 'blur',
          },
        ],
        scale: [
          {
            required: true,
            message: this.$t('pages.login.plh_company_size'),
            trigger: 'blur',
          },
        ],
        dept: [
          {
            required: true,
            message: this.$t('pages.login.plh_dept'),
            trigger: 'blur',
          },
        ],
        job: [
          {
            required: true,
            message: this.$t('pages.login.plh_position'),
            trigger: 'blur',
          },
        ],
        imgVcode: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.imgVcode) {
                callback();
              } else {
                callback(new Error(this.$t('pages.login.plh_captcha')));
              }
            },
            trigger: 'blur',
          },
        ],
        tel: [
          {
            validator: (rule, value, callback) => {
              if (
                /^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel) ||
                /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  this.formData.email
                )
              ) {
                if (this.formData.tel) {
                  checkUsername({
                    phone: this.formData.tel,
                  })
                    .then(() => {
                      this.phoneStatus = true;
                      callback();
                    })
                    .catch(() => {
                      this.phoneStatus = false;
                      callback(
                        new Error(this.$t('pages.login.plh_phone_used'))
                      );
                    });
                } else {
                  callback();
                }
              } else {
                callback(new Error(this.$t('pages.login.plh_phone_or_email')));
              }
            },
            trigger: 'blur',
          },
        ],
        smsVcode: [
          {
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel)) {
                if (value) {
                  callback();
                } else {
                  if (this.formData.email && this.formData.emailVcode) {
                    callback();
                  } else {
                    callback(new Error(this.$t('pages.login.plh_SMS_code')));
                  }
                }
              } else {
                callback();
              }
            },
            message: this.$t('pages.login.plh_SMS_code'),
            trigger: 'blur',
          },
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (
                /^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel) ||
                /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  value
                )
              ) {
                if (this.formData.email) {
                  checkUsername({
                    mail: this.formData.email,
                  })
                    .then(() => {
                      this.emailStatus = true;
                      callback();
                    })
                    .catch(() => {
                      this.emailStatus = false;
                      callback(
                        new Error(this.$t('pages.login.plh_Email_code'))
                      );
                    });
                } else {
                  callback();
                }
              } else {
                callback(new Error(this.$t('pages.login.plh_phone_or_email')));
              }
            },
            trigger: 'blur',
          },
        ],
        emailVcode: [
          {
            validator: (rule, value, callback) => {
              if (
                /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  this.formData.email
                )
              ) {
                if (value) {
                  callback();
                } else {
                  if (this.formData.tel && this.formData.smsVcode) {
                    callback();
                  } else {
                    callback(new Error(this.$t('pages.login.plh_Email_code')));
                  }
                }
              } else {
                callback();
              }
            },
            message: this.$t('pages.login.plh_Email_code'),
            trigger: 'blur',
          },
        ],
        pass: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('pages.login.plh_pwd')));
              } else if (value.length < 6) {
                callback(new Error(this.$t('pages.login.plh_pwd_3')));
              } else {
                console.log(this);
                if (this.formData.checkPass !== '') {
                  this.$refs['form'].validateField('checkPass');
                }
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        checkPass: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('pages.login.plh_pwd_2')));
              } else if (value !== this.formData.pass) {
                callback(new Error(this.$t('pages.login.plh_pwd_c')));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        isAgree: [
          {
            validator(rule, value, callback) {
              if (value) {
                callback();
              } else {
                callback(new Error());
              }
            },
            message: this.$t('pages.login.plh_agreement'),
            trigger: 'blur',
          },
        ],
      },
      phoneStatus: false,
      emailStatus: false,
      imgCodeUuid: '',
      imgSrc: '',
      loadingImgVcode: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    submitForm() {
      if (this.isLoading) return;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;
            await insertOrg({
              loginName: this.formData.username,
              realName: this.formData.name,
              sex: this.formData.gender,
              companyName: this.formData.company,
              companyAddress: this.formData.companyAddr,
              companySize: this.formData.scale,
              orgName: this.formData.dept,
              position: this.formData.job,
              phone: this.formData.tel,
              phoneCaptcha: this.formData.smsVcode,
              mail: this.formData.email,
              mailCaptcha: this.formData.emailVcode,
              password: this.formData.pass,
            });
            this.isLoading = false;
            this.$message.success(this.$t('pages.login.plh_register_success2'));
            this.$router.push('/auth/binding/1');
          } catch (error) {
            console.log(error);
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    async toShowPopup(protocolCode) {
      this.currProtocolCode = protocolCode;
      try {
        const res = await selectServiceAgreement({
          protocolCode,
          locale: this.$store.state.locale,
        });
        console.log(res);
        this.protocolCodeContent = res.length > 0 ? res[0].protocolText : '';
        this.dialogVisible = true;
      } catch (error) {
        console.log(error);
      }
    },
    showSmsTime() {
      this.smsDisabled = true;
      let time = 60;
      this.smsTxt = `${this.$t('pages.login.resend')}${time}`;
      smsTimer = setInterval(() => {
        if (time <= 0) {
          clearInterval(smsTimer);
          this.smsTxt = this.$t('pages.login.send');
          this.smsDisabled = false;
          return;
        }
        this.smsTxt = `${this.$t('pages.login.resend')}${--time}`;
      }, 1000);
    },
    async getSmsVCode() {
      if (!this.smsDisabled) {
        if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel)) {
          if (this.phoneStatus) {
            if (!this.formData.imgVcode) {
              this.$refs['form'].validateField('imgVcode');
              return;
            }
            try {
              await checkImgVcode({
                code: this.formData.imgVcode,
                uuid: this.imgCodeUuid,
              });
              try {
                await selectVcode({
                  mobile: this.formData.tel,
                  type: 2,
                });
                this.showSmsTime();
                this.$message({
                  message: this.$t('pages.login.plh_send'),
                  type: 'success',
                });
              } catch (error) {
                this.$message.error(error);
              }
            } catch (error) {
              console.log(error);
              this.getImgVcode();
              this.$message.error(this.$t('pages.login.plh_captcha_wrong'));
            }
          }
        } else {
          this.$refs['form'].validateField('tel');
        }
      }
    },
    showEmailTime() {
      console.log(123);
      this.emailDisabled = true;
      let time = 60;
      this.emailTxt = `${this.$t('pages.login.resend')}${time}`;
      emailTimer = setInterval(() => {
        if (time <= 0) {
          clearInterval(emailTimer);
          this.emailTxt = this.$t('pages.login.send');
          this.emailDisabled = false;
          return;
        }
        this.emailTxt = `${this.$t('pages.login.resend')}${--time}`;
      }, 1000);
    },
    async getEmailVCode() {
      if (!this.emailDisabled) {
        if (
          /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
            this.formData.email
          )
        ) {
          if (this.emailStatus) {
            if (!this.formData.imgVcode) {
              this.$refs['form'].validateField('imgVcode');
              return;
            }
            try {
              await checkImgVcode({
                code: this.formData.imgVcode,
                uuid: this.imgCodeUuid,
              });
              try {
                await selectVcode({
                  mail: this.formData.email,
                  type: 1,
                });
                this.showEmailTime();
                this.$message.success(this.$t('pages.login.plh_send'));
              } catch (error) {
                this.$message.error(error);
              }
            } catch (error) {
              console.log(error);
              this.getImgVcode();
              this.$message.error(this.$t('pages.login.plh_captcha_wrong'));
            }
          }
        } else {
          this.$refs['form'].validateField('email');
        }
      }
    },
    async getImgVcode() {
      if (!this.loadingImgVcode) {
        try {
          this.loadingImgVcode = true;
          const res = await selectImgVcode();
          this.loadingImgVcode = false;
          this.imgCodeUuid = res.uuid;
          this.imgSrc = res.img;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    this.getImgVcode();
  },
  beforeDestroy() {
    clearInterval(emailTimer);
    clearInterval(smsTimer);
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .tabs {
    width: 492px;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    &.en {
      .tab:first-child::before {
        right: -10px;
      }
      .tab:last-child::before {
        left: -10px;
      }
    }
    .tab {
      line-height: 1;
      padding: 12px;
      position: relative;
      a {
        color: $text-color-grey;
        &:hover {
          color: $base-color;
        }
      }
      &.active {
        font-weight: bold;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: $base-color;
          left: 0;
          bottom: 0;
        }
        a {
          color: $text-color-black;
          &:hover {
            color: $text-color-black;
          }
        }
      }
      &:first-child {
        &::before {
          content: '';
          width: 1px;
          height: 12px;
          background: $base-color-info;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -38px;
        }
      }
      &:last-child {
        &::before {
          content: '';
          width: 1px;
          height: 12px;
          background: $base-color-info;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -38px;
        }
      }
    }
  }
  .form-area {
    width: 500px;
    margin: 0 auto;
    .el-form-item {
      margin-bottom: 18px;
      &.vcode-item {
        ::v-deep .el-form-item__content {
          display: flex;
          align-items: center;
          .el-input {
            width: 187px !important;
            margin-right: 12px;
            input {
              width: 100%;
            }
          }
          .v-img-area {
            text-align: right;
            cursor: pointer;
            img {
              width: 87px;
              height: 40px;
            }
          }
          .v-btn-area {
            text-align: right;
            cursor: pointer;
            .el-button {
              min-width: 87px;
              height: 40px;
            }
          }
        }
      }
      // &.gender-item {
      //   ::v-deep .el-form-item__content {
      //     text-align: center;
      //   }
      // }
      &.row {
        margin-top: 20px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          .el-button {
            width: 100%;
            color: $text-color-black;
            font-size: 18px;
          }
        }
      }
    }
    .toShowAgreement {
      color: $base-color;
      &.book-name {
        font-style: italic;
      }
    }
  }
  .popup-content {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
