<template>
  <div>
    <div class="header-area">{{$t('pages.other.collectedLesson')}}</div>
    <div class="info-area">
      <ul class="list" v-loading="isLoading" v-if="list.length > 0">
        <li v-for="item in list" :key="item.courseId" @click="toDetail(item)">
          <div class="img-area">
            <img :src="item.courseCoverUrl" :alt="item.courseName">
          </div>
          <p class="title">{{ item.courseName }}</p>
          <div class="extra">
            <div class="teacher-info">
              <el-avatar :size="26" :src="item.teacherHeadImg"></el-avatar>
              <div class="name">{{ item.teacherName }}</div>
            </div>
            <div class="status" @click.prevent.stop="toToggleColl(item)">{{$t('common.collected')}}</div>
          </div>
        </li>
      </ul>
      <the-empty v-else :text="$t('pages.other.noCollectionLesson')" />
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCollList, updateCollStatus } from '@/api/common';
import TheEmpty from '../../components/TheEmpty/TheEmpty.vue';

export default {
  components: { TheEmpty },
  data () {
    return {
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 12,
      total: 0
    }
  },
  methods: {
    toDetail (item) {
      this.$router.push(`/course-zone/detail/${item.courseId}`)
    },
    async toToggleColl (item) {
      this.$confirm(this.$t('pages.other.unfavorite'), this.$t('common.prompt'), {
        type: 'warning'
      })
        .then(async () => {
          try {
            await updateCollStatus({
              productId: item.courseId,
              productType: 0
            });
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    async getData () {
      this.isLoading = true;
      try {
        const res = await selectMyCollList({
          productType: 0,
          pageNum: this.page,
          pageSize: this.pageSize
        });
        this.total = res.total;
        this.list = res.data ? res.data : [];
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  },
  created () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #F7F7F7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .list {
    margin-left: -9px;
    li {
      width: 212px;
      display: inline-block;
      padding: 10px;
      border: 1px solid #E6E6E6;
      margin-bottom: 20px;
      margin-left: 9px;
      cursor: pointer;
      .img-area {
        width: 192px;
        height: 116px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }
      .extra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .teacher-info {
          flex: 1;
          min-width: 0;
          display: flex;
          .name {
            margin-left: 8px;
            font-size: 14px;
            color: $text-color-grey;
          }
        }
      }
      .status {
        color: $base-color;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>