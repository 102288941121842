<template>
  <div>
    <!-- banner -->
    <div class="banner-area">
      <img src="@/assets/images/img-banner-home.png" alt="" />
    </div>
    <div class="section-area">
      <!-- 数据 -->
      <!-- 学员课程学习情况 除讲师以外都可见 -->
      <div class="section" v-if="datas && role !== '4'">
        <div class="title">{{ $t('pages.personal.stuCourse') }}</div>
        <div class="data-list full">
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.finishedCourse') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course'
                    : 'org-course-list',
                  role === '0' || role === '1' ? '5' : ''
                )
              "
            >
              {{ datas.doneCourseCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.unfinishedCourse') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course'
                    : 'org-course-list',
                  role === '0' || role === '1' ? '2' : ''
                )
              "
            >
              {{ datas.doingCourseCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.toTest') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course'
                    : 'org-course-list',
                  role === '0' || role === '1' ? '3' : ''
                )
              "
            >
              {{ datas.waitExamCourseCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.makeUpTest') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course'
                    : 'org-course-list',
                  role === '0' || role === '1' ? '4' : ''
                )
              "
            >
              {{ datas.againExamCourseCount }}
            </div>
          </div>
        </div>
      </div>
      <!-- 学员课程计划学习情况 除讲师以外都可见 -->
      <div class="section" v-if="datas && role !== '4'">
        <div class="title">{{ $t('pages.personal.stuCoursePlan') }}</div>
        <div class="data-list full">
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.finishedCourse') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course-plan'
                    : 'org-course-plan-list'
                )
              "
            >
              {{ datas.doneCoursePlanCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.unfinishedCourse') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course-plan'
                    : 'org-course-plan-list'
                )
              "
            >
              {{ datas.doingCoursePlanCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.toTest') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course-plan'
                    : 'org-course-plan-list'
                )
              "
            >
              {{ datas.waitExamCoursePlanCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.makeUpTest') }}</div>
            <div
              class="value"
              @click="
                toNextPage(
                  role === '0' || role === '1'
                    ? 'my-course-plan'
                    : 'org-course-plan-list'
                )
              "
            >
              {{ datas.againExamCoursePlanCount }}
            </div>
          </div>
        </div>
      </div>
      <!-- 课程问答 班主任、小组长和讲师可见 -->
      <div class="section" v-if="datas && role !== '0' && role !== '1'">
        <div class="title">{{ $t('pages.personal.courseQna') }}</div>
        <div class="data-list">
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.undo') }}</div>
            <div class="value" @click="toNextPage('my-qa', '0')">
              {{ datas.notReplayCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.done') }}</div>
            <div class="value" @click="toNextPage('my-qa', '1')">
              {{ datas.hasReplayCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label"></div>
            <div class="value"></div>
          </div>
          <div class="data-area">
            <div class="label"></div>
            <div class="value"></div>
          </div>
        </div>
      </div>
      <!-- 学员统计 班主任和小组长可见 -->
      <div class="section" v-if="datas && (role === '2' || role === '3')">
        <div class="title">{{ $t('pages.personal.stuStatistics') }}</div>
        <div class="data-list">
          <div class="data-area">
            <div class="label">{{ $t('pages.personal.countOfStu') }}</div>
            <div class="value" @click="toNextPage('org-manage')">
              {{ datas.orgUserCount }}
            </div>
          </div>
          <div class="data-area">
            <div class="label"></div>
            <div class="value"></div>
          </div>
          <div class="data-area">
            <div class="label"></div>
            <div class="value"></div>
          </div>
          <div class="data-area">
            <div class="label"></div>
            <div class="value"></div>
          </div>
        </div>
      </div>
      <!-- 去首页 -->
      <div class="btn-area" v-if="role === '0' || role === '1'">
        <el-button class="my-btn" type="primary" @click="toNext">{{
          role === '0' ? $t('titles.courseHot') : $t('pages.index.feedback')
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { selectPersonalHomeData } from '@/api/common';
export default {
  data() {
    return {
      datas: null,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
  },
  watch: {
    role() {
      this.getData();
    },
  },
  methods: {
    toNextPage(path, params) {
      this.$router.push(`${path}${params ? `/?type=${params}` : ''}`);
    },
    toNext() {
      if (this.role === '0') {
        this.$router.push('/course-zone');
      } else {
        this.$store.commit('setToBottom', true);
        this.$router.push('/');
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectPersonalHomeData({ role: this.role });
        this.datas = res;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.banner-area {
  background: #f5f5f5;
  padding-bottom: 10px;
  img {
    width: 100%;
  }
}
.section-area {
  background: #f5f5f5;
  min-height: 556px;
}
.section {
  background: #ffffff;
  &:not(:last-child) {
    border-bottom: 12px solid #f5f5f5;
  }
  color: #333;
  .title {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    padding: 0 25px;
    border-bottom: 1px solid #f7f7f7;
    font-weight: bold;
  }
  .data-list {
    padding: 30px 25px;
    display: flex;
    justify-content: space-between;
    .data-area {
      display: inline-block;
      font-size: 14px;
      text-align: center;
      .value {
        color: #f4c220;
        font-weight: bold;
        margin-top: 26px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
.btn-area {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
  .my-btn {
    width: 200px;
    height: 50px;
    border-radius: 0;
    color: #333;
    font-size: 16px;
  }
}
</style>
