<template>
  <div>
    <div class="header-area">{{ $t('titles.coursePlan') }}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <!-- <el-button class="my-btn" type="primary" size="mini">
            <i class="iconfont">&#xe612;</i>
            <span>导出</span>
          </el-button> -->
          <!-- <el-dropdown @command="changeType">
            <el-button class="my-btn" type="" size="small">
              {{ type | typeText }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="0">全部</el-dropdown-item>
              <el-dropdown-item :command="1">未开始</el-dropdown-item>
              <el-dropdown-item :command="2">学习中</el-dropdown-item>
              <el-dropdown-item :command="3">待考试</el-dropdown-item>
              <el-dropdown-item :command="4">待补考</el-dropdown-item>
              <el-dropdown-item :command="5">已完成</el-dropdown-item>
              <el-dropdown-item :command="6">未完成</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="
              $t('common.placeHolder_enter') + $t('common.keywords')
            "
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
        row-key="rowKey"
        :default-expand-all="true"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :cell-class-name="addClassForCell"
      >
        <el-table-column :label="$t('titles.courseZoneName')" width="310">
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.cover" alt="" />
              <span class="name">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('titles.StatusStudy')" width="150">
          <template slot-scope="scope">
            <div class="progress-area" v-if="!scope.row.children">
              <p>
                {{ $t('common.completed') }}
                <span
                  class="status"
                  :class="[
                    scope.row.studySchedule === '0%'
                      ? 'start'
                      : scope.row.studySchedule === '100%'
                      ? 'finished'
                      : 'in-progress',
                  ]"
                  >{{ scope.row.studySchedule }}</span
                >
              </p>
              <p class="sub-content">
                {{
                  scope.row.studyStatus === '2'
                    ? `${$t('common.study')} ${handleTime(scope.row.studyTime)}`
                    : handleStudyStatus(scope.row.studyStatus)
                }}
              </p>
            </div>
            <div class="progress-area" v-else>
              <p class="sub-content">
                {{
                  scope.row.studyStatus === '2'
                    ? `${$t('common.learning')}`
                    : handleStudyStatus(scope.row.studyStatus)
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          :label="$t('common.lastTime')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.studyLastTime || '--' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              v-if="!scope.row.children"
              @click="toStudy(scope.row)"
              >{{ $t('common.study') }}</el-link
            >
            <el-link
              type="success"
              v-if="handleStatus(scope.row)"
              @click="toExam(scope.row)"
              >{{ $t('titles.exam') }}</el-link
            >
            <el-link
              type="danger"
              v-if="handleStatus(scope.row, true)"
              @click="toExam(scope.row)"
              >{{ $t('common.makeUpExamination') }}</el-link
            >
            <el-link
              type="primary"
              v-if="!scope.row.children"
              @click="toMyNote(scope.row)"
              >{{ $t('titles.LookOverNoteList') }}</el-link
            >
            <el-link
              type="primary"
              v-if="!scope.row.children"
              @click="toMyQuestion(scope.row)"
              >{{ $t('common.questions') }}</el-link
            >
            <el-link
              type="info"
              v-if="!scope.row.children && scope.row.courseWareUrl"
              @click="toDownload(scope.row)"
              >{{ $t('common.download') }}</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCourseOrCoursePlan } from '@/api/common';
import { baseURL } from '@/config';
let types = {};
export default {
  data() {
    return {
      type: 0,
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  filters: {
    typeText(type) {
      return types[type];
    },
  },
  created() {
    types = {
      0: this.$t('common.all'),
      1: this.$t('common.haveNotStarted'),
      2: this.$t('common.learning'),
      3: this.$t('common.waitingExamination'),
      4: this.$t('common.makeUpExamination'),
      5: this.$t('common.completed'),
      6: this.$t('common.unfinished'),
    };
  },
  methods: {
    handleStatus(item, isMakeup) {
      if (!item.children) {
        if (isMakeup) {
          // 补考
          return item.studyStatus === '4';
        } else {
          return item.studyStatus === '3';
        }
      } else {
        let flag = true;
        item.children.forEach((course) => {
          if (course.studyStatus !== '5') {
            flag = false;
          }
        });
        if (!isMakeup) {
          return flag && item.hasExamTimes === 0;
        } else {
          return (
            flag &&
            item.hasExamTimes > 0 &&
            item.leftTimes > 0 &&
            item.studyStatus !== '5'
          );
        }
      }
    },
    handleTime(val) {
      if (val) {
        let hour = Math.floor(val / 3600);
        let minu = Math.floor((val - 3600 * hour) / 60);
        let sec = Math.floor(val - hour * 3600 - minu * 60);
        if (minu.toString().length === 1) {
          minu = `0${minu}`;
        }
        if (sec.toString().length === 1) {
          sec = `0${sec}`;
        }
        if (hour) {
          if (hour.toString().length === 1) {
            hour = `0${hour}`;
          }
          return `${hour}:${minu}:${sec}`;
        } else {
          return `${minu}:${sec}`;
        }
      } else {
        return '00:00';
      }
    },
    handleStudyStatus(status) {
      return types[status];
    },
    toDownload(item) {
      const url = `${baseURL}/ruihe/file/downloadZip?zipName=${item.name}&filePath=${item.courseWareUrl}&fileName=${item.courseWareName}`
      window.open(url, '_blank')
    },
    addClassForCell({ columnIndex }) {
      if (columnIndex === 0) {
        return 'name-cell';
      }
    },
    toMyNote(item) {
      this.$router.push(`/personal/my-note-list/${item.id}`);
    },
    toMyQuestion(item) {
      this.$router.push(`/personal/my-qa-list/${item.id}`);
    },
    toExam(item) {
      if (item.children) {
        this.$router.push(`/exam/${item.id}/1`);
      } else {
        this.$router.push(`/exam/${item.id}/0`);
      }
    },
    toStudy(item) {
      this.$router.push(
        `/course-study/${item.coursePlanId}/1/${
          item.index !== '' ? item.index : 0
        }`
      );
    },
    changeType(type) {
      this.type = type;
      this.page = 1;
      this.getData();
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMyCourseOrCoursePlan({
          queryType: 1,
          studyStatus: this.type,
          pageNum: this.page,
          pageSize: this.pageSize,
          authorizeName: this.keywords,
        });
        this.isLoading = false;
        this.total = res.total;
        const arr = [];
        res.data.forEach((item) => {
          const obj = {
            rowKey: item.authorizeId,
            id: item.authorizeId,
            name: item.authorizeName,
            cover: item.authorizeCoverUrl,
            studyStatus: item.studyStatus,
            studySchedule: '',
            studyLastTime: '',
            studyTime: '',
            index: '',
            type: '1',
            leftTimes: item.leftTimes,
            hasExamTimes: item.hasExamTimes,

          };
          if (item.studyInfoDtos) {
            obj.children = [];
            item.studyInfoDtos.forEach((course, index) => {
              obj.children.push({
                courseWareName: course.courseWareName,
                rowKey: `${obj.rowKey}_${course.authorizeId}`,
                coursePlanId: item.authorizeId,
                id: course.authorizeId,
                name: course.authorizeName,
                cover: course.authorizeCoverUrl,
                studyStatus: course.studyStatus,
                studySchedule: course.studySchedule,
                studyLastTime: course.studyLastTime,
                studyTime: course.studyTime,
                courseWareUrl: course.courseWareUrl,
                leftTimes: course.leftTimes,
                hasExamTimes: course.hasExamTimes,
                index,
                type: '0',
              });
            });
          }
          arr.push(obj);
        });
        this.list = arr;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .btn-area {
      display: flex;
      align-items: center;
      .my-btn {
        margin-right: 10px;
      }
    }
  }
  .sub-content {
    color: $text-color-grey;
  }
  .status {
    &.finished {
      color: $base-color;
    }
    &.in-progress {
      color: #ff8106;
    }
    &.start {
      color: #3dcb6b;
    }
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
    }
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
    .name-cell {
      .cell {
        display: flex;
        align-items: center;
      }
    }
  }
  .el-link {
    margin-right: 10px;
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
