<template>
  <div class="container">
    <div class="upload">
      <div class="choose-area">
        <label class="upload-btn" for="fileUpload">{{$t('pages.other.selectFile')}}</label>
        <form ref="form">
          <input
            class="upload-input"
            type="file"
            id="fileUpload"
            @change="fileChange($event)"
          />
        </form>
        <!-- <span
          class="upload-btn upload"
          :class="{ disabled: uploadDisabled }"
          v-if="file"
          @click="toAuthUpload"
          >开始上传</span
        > -->
      </div>
      <div class="notice">
        {{$t('pages.other.uploadFileTiShi')}}
      </div>
      <div class="file-area" v-if="file">
        <div class="file-info">
          <i class="el-icon-document file"></i>
          <span class="file-name"> {{ file.name }}</span>
          <i class="el-icon-close delete" @click="toDelete"></i>
          <span class="status" v-if="authProgress < 100">
            <span class="status-text">{{ statusText }}</span>
            <span class="progress-text" v-if="authProgress > 0"
              >{{ authProgress }}%</span
            >
          </span>
          <i
            class="el-icon-circle-check success"
            v-if="authProgress === 100"
          ></i>
        </div>
        <el-progress
          v-if="authProgress > 0 && authProgress < 100"
          :text-inside="true"
          :show-text="false"
          :stroke-width="2"
          stroke-linecap="square"
          :percentage="authProgress"
        ></el-progress>
      </div>
      <!-- <div class="upload-type">
        使用 UploadAuth 上传:
        <button @click="authUpload" :disabled="uploadDisabled">开始上传</button>
        <button @click="pauseUpload" :disabled="pauseDisabled">暂停</button>
        <button :disabled="resumeDisabled" @click="resumeUpload">
          恢复上传
        </button>
        <span class="progress"
          >上传进度: <i id="auth-progress">{{ authProgress }}</i> %</span
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  selectVodInitData,
  selectVideoUploadParam,
  updateVideoUploadParam,
} from '@/api/common';
export default {
  props: {
    title: {
      type: String,
    },
    currentVideoId: {
      type: String,
    },
  },
  data() {
    return {
      //阿里账号ID，必须
      userId: '',
      // 上传超时时长
      timeout: 60000,
      //并行上传分片个数，默认5
      parallel: 5,
      // 分片大小
      partSize: 1048576,
      //网络原因失败时，重新上传次数，默认为3
      retryCount: 3,
      //网络原因失败时，重新上传间隔时间，默认为2秒
      retryDuration: 2,
      region: '',
      file: null,
      authProgress: 0,
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploading: false,
      uploader: null,
      statusText: '',
      videoId: this.currentVideoId ? this.currentVideoId : '',
    };
  },
  methods: {
    fileChange(e) {
      this.file = e.target.files[0];
      if (!this.file) {
        this.$message.error(this.$t('pages.other.noSelectFile'));
        return;
      }
      var userData = '{"Vod":{}}';
      if (this.uploader && this.uploading) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = '';
      }
      this.uploader = this.createUploader(this.file.name);
      this.uploader.addFile(this.file, null, null, null, userData);
      this.uploadDisabled = false;
      this.pauseDisabled = true;
      this.resumeDisabled = true;
    },
    toDelete() {
      if (this.uploader && this.uploading) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = '';
      }
      this.file = null;
      this.$refs.form.reset();
    },
    handleFileSize(size) {
      if (size >= 1048576) {
        return `${Math.round((size / 1048576) * 100) / 100}MB`;
      } else if (size >= 1024) {
        return `${Math.round((size / 1024) * 100) / 100}KB`;
      } else {
        return `${size}B`;
      }
    },
    toAuthUpload() {
      if (this.uploadDisabled) return;
      this.$confirm(
        `${this.$t('pages.other.checkUploadInfomation')}：${
          this.file.name
        }，${this.$t('pages.other.fileSize')}：${this.handleFileSize(this.file.size)}`
      )
        .then(() => {
          this.authUpload();
        })
        .catch(() => {});
    },
    authUpload() {
      this.$emit('uploading');
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
        this.pauseDisabled = false;
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.resumeDisabled = false;
        this.pauseDisabled = true;
      }
    },
    // 恢复上传
    resumeUpload() {
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.resumeDisabled = true;
        this.pauseDisabled = false;
      }
    },
    createUploader(name) {
      // eslint-disable-next-line no-undef
      let uploader = new AliyunUpload.Vod({
        timeout: this.timeout,
        partSize: this.partSize,
        parallel: this.parallel,
        retryCount: this.retryCount,
        retryDuration: this.retryDuration,
        region: this.region,
        userId: this.userId,
        // 添加文件成功
        addFileSuccess: (uploadInfo) => {
          this.uploadDisabled = false;
          this.resumeDisabled = false;
          this.statusText = this.$t('pages.other.fileSuccess');
          console.log('addFileSuccess: ' + uploadInfo.file.name);
        },
        // 开始上传
        onUploadstarted: async (uploadInfo) => {
          // 使用 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          try {
            // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
            // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
            let authData = await selectVideoUploadParam({
              videoId: this.videoId,
              title: this.title,
              fileName: name,
            });
            this.videoId = authData.videoId;
            uploader.setUploadAuthAndAddress(
              uploadInfo,
              authData.uploadAuth,
              authData.uploadAddress,
              authData.videoId
            );
            this.statusText = this.$t('pages.other.startUpload');
            this.uploading = true;
          } catch (error) {
            console.log(error);
            this.$message.error(this.$t('pages.other.uploadCerdenty'));
            this.$emit('failed');
          }
        },
        // 文件上传成功
        onUploadSucceed: (uploadInfo) => {
          this.statusText = `${uploadInfo.file.name}${this.$t('pages.other.UploadedSuccessfully')}!`;
          this.uploading = false;
        },
        // 文件上传失败
        onUploadFailed: (uploadInfo, code, message) => {
          console.log(
            'onUploadFailed: file:' +
              uploadInfo.file.name +
              ',code:' +
              code +
              ', message:' +
              message
          );
          this.statusText = this.$t('pages.other.Fileuploadfailed');
          this.uploading = false;
        },
        // 取消文件上传
        onUploadCanceled: (uploadInfo, code, message) => {
          console.log(
            'Canceled file: ' +
              uploadInfo.file.name +
              ', code: ' +
              code +
              ', message:' +
              message
          );
          this.statusText = this.$t('pages.other.Fileuploadpaused');
          this.uploading = false;
          this.$emit('stopUpload', this.videoId);
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: (uploadInfo, totalSize, progress) => {
          let progressPercent = Math.ceil(progress * 100);
          this.authProgress = progressPercent;
          this.statusText = '文件上传中...';
        },
        // 上传凭证超时
        onUploadTokenExpired: async (uploadInfo) => {
          // 上传大文件超时,
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法
          const res = await updateVideoUploadParam({
            videoId: uploadInfo.videoId,
          });
          uploader.resumeUploadWithAuth(res.UploadAuth);
          console.log(
            'upload expired and resume upload with uploadauth ' + res.uploadAuth
          );
          this.statusText = this.$t('pages.other.Filetimedout');
        },
        // 全部文件上传结束
        onUploadEnd: () => {
          this.statusText =  this.$t('pages.other.fileuploadcomplete');
          this.$emit('uploaded', this.videoId);
        },
      });
      return uploader;
    },
    async init() {
      try {
        const res = await selectVodInitData();
        this.userId = res.userId;
        this.region = res.region;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.choose-area {
  display: flex;
  align-items: center;
}
.notice {
  font-size: 12px;
}
.upload-btn {
  display: inline-block;
  width: 98px;
  height: 36px;
  line-height: 34px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid #dcdfe6;
  color: #606266;
  &.upload {
    border-color: $base-color;
    background: $base-color;
    color: #ffffff;
  }
  & ~ .upload-btn {
    margin-left: 10px;
  }
  &.disabled {
    background: #ffdd82;
    border-color: #ffdd82;
  }
}
.upload-input {
  display: none;
}
.file-area {
  font-size: 14px;
  color: #606266;
  cursor: pointer;

  .file-info {
    display: flex;
    align-items: center;
    line-height: 26px;
    margin-top: 10px;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    padding-left: 4px;
    padding-right: 5px;
    border-radius: 4px;
    &:hover {
      background-color: #f5f7fa;
      color: $base-color;
      i.delete {
        display: inline;
      }
      .status {
        display: none;
      }
      i.success {
        display: none;
      }
    }
    i.file {
      margin-right: 7px;
      color: #909399;
    }
    .file-name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .status {
      margin-left: 10px;
      font-size: 12px;
      color: #909399;
    }
    i.delete {
      display: none;
      user-select: none;
      cursor: pointer;
      color: #606266;
    }
    i.success {
      user-select: none;
      color: #67c23a;
    }
  }
}
</style>
