<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  updated() {
    if (this.$store.state.token) {
      this.$store.dispatch("getUnreadCount");
    }
  },
};
</script>

<style lang="scss">
@import "assets/styles/main.scss";
</style>
