import Vue from 'vue'
import store from '@/store'
import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n'
import en from './langs/en'
import cn from './langs/cn'

Vue.use(VueI18n)

const messages = { en, cn };

const i18n = new VueI18n({
  locale: store.state.locale, // 设置默认语言
  messages
});

//为了实现element插件的多语言切换
locale.i18n((key, value) => i18n.t(key, value));

export default i18n