<template>
  <div class="course-study">
    <img :src="timeSrc" id="timing" style="display: none" />
    <div class="title-area">
      <div class="btn back" @click="$router.go(-1)">{{ $t('pages.other.fanhui') }}</div>
      <div class="btn" @click="toExam()" v-if="examId">{{$t('pages.other.qukaoshi')}}</div>
      <div class="title-text">{{ name }}</div>
    </div>
    <div class="content-area">
      <div
        ref="courseArea"
        class="course-area"
        :style="{ width: `${courseAreaWidth}px` }"
      >
        <div class="player-area">
          <vue-aliplayer-v2
            ref="player"
            :source="source"
            :options="options"
            @ready="readyHandle"
            @ended="handleEnded"
            @startSeek="handleSeekStart"
            @completeSeek="handleSeekComplete"
            @timeupdate="handleTimeChange"
            @play="handlePlay"
          />
        </div>
      </div>
      <div
        ref="courseToolsArea"
        class="course-tools-area"
        :style="{ right: `${right}px` }"
      >
        <div class="handle" @click="toggleSiderShow">
          <i class="el-icon-s-unfold" v-if="showSider"></i>
          <i class="el-icon-s-fold" v-else></i>
        </div>
        <div class="tab-area">
          <div
            class="active-bar"
            :style="{ transform: `translateX(${133 * activeName}px)` }"
          ></div>
          <div
            class="tab"
            :class="{ actived: activeName === 0 }"
            @click="toChangeTab(0)"
          >
            <span slot="label"><i class="el-icon-tickets"></i> {{$t('pages.other.mulu')}}</span>
          </div>
          <div
            class="tab"
            :class="{ actived: activeName === 1 }"
            @click="toChangeTab(1)"
          >
            <span slot="label"
              ><i class="el-icon-chat-line-square"></i> {{ $t('pages.other.wenda') }}</span
            >
          </div>
          <div
            class="tab"
            :class="{ actived: activeName === 2 }"
            @click="toChangeTab(2)"
          >
            <span slot="label"><i class="el-icon-notebook-1"></i> {{ $t('pages.other.biji') }}</span>
          </div>
        </div>
        <div class="tab-body">
          <div class="index-area" v-if="activeName === 0">
            <ul class="index-list" v-if="list.length > 0">
              <li
                :class="{ actived: currentIndex === index }"
                v-for="(item, index) in list"
                :key="item.courseId"
                @click="toSwitch(index)"
              >
                <i class="iconfont">&#xe61b;</i>
                <div class="name">{{ item.courseName }}</div>
                <div class="mins">{{ item.duration | handleTime }}</div>
              </li>
            </ul>
          </div>
          <div class="comment-area" v-else-if="activeName === 1">
            <!-- 输入区 -->
            <div class="input-area" v-if="isTry === '0'">
              <div class="input-wrap">
                <el-input
                  type="textarea"
                  :rows="4"
                  resize="none"
                  :placeholder="$t('pages.other.qingshurunidewenti')"
                  v-model="questionContent"
                >
                </el-input>
              </div>
              <div class="submit-area">
                <span></span>
                <el-button type="primary" size="small" @click="submitQuestion"
                  >{{$t('pages.other.tiwen')}}</el-button
                >
              </div>
            </div>
            <!-- 提问列表 -->
            <ul class="question-list">
              <li v-for="item in questionList" :key="item.id">
                <div class="top-area">
                  <div class="userinfo">
                    <el-avatar size="small" :src="item.userImg" />
                    <div class="name">{{ item.userName }}</div>
                  </div>
                  <div class="create-time">
                    {{ item.createTime | dateFormat('yyyy-MM-dd hh:mm:ss') }}
                  </div>
                </div>
                <div class="text-content">{{ item.quizContent }}</div>
                <div class="reply-content" v-if="item.replayContent">
                  {{ item.replayContent }}
                </div>
              </li>
            </ul>
            <div class="pagination-area">
              <el-pagination
                :current-page.sync="qPage"
                :page-size="qPageSize"
                :hide-on-single-page="true"
                layout="total, prev, pager, next"
                :total="qTotal"
                @current-change="getQuestionList"
              >
              </el-pagination>
            </div>
          </div>
          <!-- 笔记 -->
          <div class="note-area" v-else>
            <!-- 输入区 -->
            <div class="input-area" v-if="isTry === '0'">
              <div class="input-wrap">
                <el-input
                  type="textarea"
                  :rows="4"
                  resize="none"
                  :placeholder="$t('pages.other.zaicijilubiji')"
                  v-model="noteContent"
                  @focus="setCurrentTime"
                >
                </el-input>
              </div>
              <div class="submit-area">
                <el-checkbox v-model="checked" @change="handleChange"
                  >{{ $t('pages.other.jilushijian')}}</el-checkbox
                >
                <span class="current-time-area">
                  <span class="wrap" v-if="currentTime !== ''">
                    <i class="iconfont">&#xe648;</i>
                    <span>{{ currentTime | handleTime }}</span>
                  </span>
                </span>
                <el-button type="primary" size="small" @click="submitNote">{{
                  noteId ? $t('pages.other.xiugai') : $t('pages.other.baocun')
                }}</el-button>
              </div>
            </div>
            <!-- 列表 -->
            <ul class="note-list">
              <li v-for="item in noteList" :key="item.id">
                <div class="text-content">{{ item.noteContent }}</div>
                <div class="extra">
                  <div class="create-time">
                    {{ item.createTime | dateFormat('yyyy-MM-dd hh:mm:ss') }}
                  </div>
                  <div
                    class="time-point"
                    v-if="item.nodeTime !== '' && item.nodeTime !== null"
                    @click="toSeek(item)"
                  >
                    <i class="iconfont">&#xe648;</i>
                    <span>{{ item.nodeTime | handleTime }}</span>
                  </div>
                  <div class="opers">
                    <span class="oper" @click="toEditNote(item)">
                      <i class="el-icon-edit-outline"></i>
                      <span>{{ $t('common.edit') }}</span>
                    </span>
                    <el-popover
                      placement="top"
                      width="160"
                      v-model="item.visible"
                    >
                      <p>{{$t('pages.other.quedingyaoshanchuzheyitiaobijima')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button
                          size="mini"
                          type="text"
                          @click="item.visible = false"
                          >{{ $t('pages.other.cancel') }}</el-button
                        >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="toDeleteNote(item)"
                          >{{$t('pages.other.queding')}}</el-button
                        >
                      </div>
                      <span class="oper" slot="reference">
                        <i class="el-icon-delete"></i>
                        <span>{{ $t('common.delete') }}</span>
                      </span>
                    </el-popover>
                  </div>
                </div>
              </li>
            </ul>
            <div class="pagination-area">
              <el-pagination
                :current-page.sync="nPage"
                :page-size="nPageSize"
                :hide-on-single-page="true"
                layout="total, prev, pager, next"
                :total="nTotal"
                @current-change="getNoteList"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/config';
import VueAliplayerV2 from 'vue-aliplayer-v2';
import BulletScreenComponent from '@/components/BulletScreen';
import WaterMarkerComponent from '@/components/WaterMarker';
import {
  selectCourseOrCoursePlanDetail,
  selectVideoPlayParam,
  insertStudyRecordData,
  selectMyNoteList,
  insertMyNoteData,
  updateMyNoteData,
  deleteMyNoteData,
  selectQAList,
  insertQAData,
} from '@/api/common';
export default {
  name: 'courseStudy',
  components: { VueAliplayerV2: VueAliplayerV2.Player },
  data() {
    return {
      id: '',
      player: null,
      // 进度条拖动控制
      start: true,
      startProgress: 0,
      endProgress: 0,

      timeSrc: '',
      avatar: require('@/assets/images/default-avatar.png'),
      type: '0',
      currentIndex: 0,
      name: '',
      examId: '',
      list: [],
      options: {
        language: '',
        height: '100%',
        width: '100%',
        videoWidth: '100%',
        autoplay: false,
        cover: '',
        vid: '',
        playauth: '',
        encryptType: 1,
        format: 'mp4',
        components: [
          {
            name: 'BulletScreenComponent',
            type: BulletScreenComponent,
            args: [
              this.$t('pages.other.huanyinshiyongruihejiaoyupintai'),
              { fontSize: '16px', color: '#ffbb05' },
              'random',
            ],
          },
          {
            name: 'WaterMarkerComponent',
            type: WaterMarkerComponent,
            args: [
              '',
              { fontSize: '16px', color: 'rgba(255,255,255,0.35)' },
              'random',
            ],
          },
        ],
      },
      source: '',
      activeName: 0,
      showSider: true,
      right: 0,
      courseAreaWidth: 0,
      questionContent: '',
      questionList: [],
      qPage: 1,
      qPageSize: 20,
      qTotal: 0,
      noteId: '',
      noteContent: '',
      noteList: [],
      nPage: 1,
      nPageSize: 20,
      nTotal: 0,
      checked: true,
      currentTime: '',
      isAntiCheating: true,
      isTry: '1',
      limit: 300,
      isStop: false,
      myCourseData: {}
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    toSwitch(index) {
      if (index !== this.currentIndex) {
        this.currentIndex = index;
        this.getCourseData();
      }
    },
    toSeek(item) {
      if (item.nodeTime) {
        this.player.seek(item.nodeTime);
      }
    },
    async toDeleteNote(item) {
      try {
        await deleteMyNoteData({ id: item.id });
        this.$message.success(this.$t('pages.other.shanchuchenggong'));
        this.getNoteList();
      } catch (error) {
        console.log(error);
      }
    },
    toEditNote(item) {
      console.log(item.nodeTime);
      this.noteId = item.id;
      this.noteContent = item.noteContent;
      this.currentTime = item.nodeTime;
      this.checked = this.currentTime !== '' && this.currentTime !== null;
    },
    handleChange(value) {
      if (!value) {
        this.currentTime = '';
      }
    },
    handlePlay() {
      if (this.isTry === '1') {
        const currentTime = this.player.getCurrentTime();
        if (currentTime >= this.limit) {
          this.player.pause();
        }
      }
    },
    handleTimeChange() {
      if (this.isTry === '1') {
        const currentTime = this.player.getCurrentTime();
        if (currentTime >= this.limit) {
          this.player.pause();
          if (!this.isStop) {
            this.isStop = true;
            this.$confirm(this.$t('pages.other.shitingyijiesuqugoumai'), this.$t('pages.other.tishi'), {
              confirmButtonText: this.$t('pages.other.qugoumai'),
              cancelButtonText: this.$t('pages.other.fanhui'),
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false,
            })
              .then(() => {
                this.$router.push(
                  `/pay/confirm-order/${
                    this.list[this.currentIndex].courseId
                  }/0`
                );
              })
              .catch(() => {
                this.$router.go(-1);
              });
          }
        }
      }
    },
    setCurrentTime() {
      if (this.checked) {
        const player = this.$refs.player;
        if (player) {
          this.currentTime = player.getCurrentTime();
        }
      }
    },
    handleSeekComplete() {
      this.start = true;
      this.endProgress = this.player.getCurrentTime();
      if (this.endProgress < this.startProgress) {
        this.player.seek(this.endProgress);
      } else {
        if (this.isAntiCheating || this.isTry === '1') {
          this.player.seek(this.startProgress);
        } else {
          this.player.seek(this.endProgress);
        }
      }
      this.player.play();
    },
    handleSeekStart() {
      if (this.start) {
        this.start = false;
        this.startProgress = this.player.getCurrentTime();
      }
      this.player.pause();
    },
    async setTimeAsync() {
      try {
        const time = this.$refs.player.getCurrentTime();
        if (time !== undefined && time > 0) {
          const coursePlanId = this.type === '1' ? this.id : '';
          await insertStudyRecordData({
            userId: this.$store.state.userinfo.userId,
            courseId: this.list[this.currentIndex].courseId,
            coursePlanId,
            studyTime: time,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleEnded() {
      this.setTimeAsync();
    },
    toExam(id, type) {
      this.$router.push(`/exam/${id}/${type}`);
    },
    async submitQuestion() {
      if (this.questionContent) {
        try {
          await insertQAData({
            courseId: this.list[this.currentIndex].courseId,
            quizContent: this.questionContent,
          });
          this.$message.success(this.$t('pages.other.caozuochenggong'));
          this.qPage = 1;
          this.getQuestionList();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async submitNote() {
      if (this.noteContent) {
        try {
          const params = {
            courseId: this.list[this.currentIndex].courseId,
            noteContent: this.noteContent,
            nodeTime: this.checked ? this.currentTime : '',
          };
          if (this.noteId) {
            params.id = this.noteId;
            await updateMyNoteData(params);
          } else {
            await insertMyNoteData(params);
          }
          this.noteId = '';
          this.noteContent = '';
          this.currentTime = '';
          this.getNoteList();
        } catch (error) {
          console.log(error);
        }
      }
    },
    toChangeTab(index) {
      this.activeName = index;
    },
    toggleSiderShow() {
      const beginTime = Date.now();
      const beginWidth = this.courseAreaWidth;
      let frameFunc;
      if (this.showSider) {
        frameFunc = () => {
          let progress = (Date.now() - beginTime) / 300;
          if (progress < 1) {
            this.right = -420 * progress;
            this.courseAreaWidth = beginWidth - this.right;
          } else {
            this.right = -420;
            this.courseAreaWidth = beginWidth + 420;
            this.showSider = false;
          }
          requestAnimationFrame(frameFunc);
        };
      } else {
        frameFunc = () => {
          let progress = (Date.now() - beginTime) / 300;
          if (progress < 1) {
            this.right = -420 * (1 - progress);
            this.courseAreaWidth = beginWidth - this.right;
          } else {
            this.right = 0;
            this.courseAreaWidth = beginWidth - 420;
            this.showSider = true;
          }
          requestAnimationFrame(frameFunc);
        };
      }
      requestAnimationFrame(frameFunc);
    },
    async getQuestionList() {
      try {
        const res = await selectQAList({
          courseId: this.list[this.currentIndex].courseId,
          isReplay: '',
          isOwn: 0,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.qTotal = res.total;
        this.questionList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getNoteList() {
      try {
        const res = await selectMyNoteList({
          courseId: this.list[this.currentIndex].courseId,
          pageNum: this.nPage,
          pageSize: this.nPageSize,
        });
        this.nTotal = res.total;
        this.noteList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    readyHandle () {
      if (this.isTry === '0' &&
          this.myCourseData.studyTime > 0 &&
          this.myCourseData.studyTime < this.myCourseData.duration) {
            this.$refs.player.seek(this.myCourseData.studyTime)
          }
      // console.log('视频初始化', this.$refs.player.seek(res.studyTime))
    },
    async getCourseData() {
      try {
        const res = await selectVideoPlayParam({
          courseId: this.list[this.currentIndex].courseId,
        });
        this.isTry = res.isTry;
        this.isAntiCheating = res.isCopy === '1';
        if (
          this.isTry === '0' &&
          res.studyTime > 0 &&
          res.studyTime < res.duration
        ) {
         
          
          // this.$nextTick(() => {
            
          //    setTimeout(() => {
             
          //     this.$refs.player.seek(res.studyTime);
          // }, 5000)
          // });
        }
        this.options.components[0].args[0] = res.marqueeMsg
          ? res.marqueeMsg
          : this.$t('pages.other.tuihejiaoyu') ;
        this.options.components[1].args[0] = this.$store.state.userinfo.username;
        if (res.localUrl) {
          // 本地
          this.source = res.localUrl;
        } else {
          // vod
          this.options.vid = res.playAuthResponseBody.videoMeta.videoId;
          this.options.playauth = res.playAuthResponseBody.playAuth;
        }
        this.myCourseData = res
      } catch (error) {
        console.log(error);
      }
    },
    async getData(id) {
      try {
        const res = await selectCourseOrCoursePlanDetail({
          studyId: id,
          studyType: this.type,
        });
        this.name = res.studyName;
        this.list = res.coursePlays;
        if (this.currentIndex === 0) {
          this.options.cover = res.studyCoverUrl;
        } else {
          this.options.cover = this.list[this.currentIndex].courseCoverUrl;
        }
        this.getCourseData();
        this.getNoteList();
        this.getQuestionList();
      } catch (error) {
        console.log(error);
      }
    },
    setTime() {
      const time = this.$refs.player.getCurrentTime();
      // 同步xhr请求，已被浏览器阻止 https://www.chromestatus.com/feature/4664843055398912
      // const url = `${baseURL}/ruihe/videoVod/editUserStudy?courseId=${
      //   this.list[this.currentIndex].courseId
      // }&studyTime=${time}`;
      // const xhr = new XMLHttpRequest();
      // xhr.open("POST", url, true);
      // xhr.setRequestHeader(
      //   "Authorization",
      //   `pBearer ${this.$store.state.token}`
      // );
      // xhr.send(null);

      // Beacon API
      if ('sendBeacon' in navigator) {
        const fm = new FormData();
        fm.append('userId', this.$store.state.userinfo.userId);
        fm.append('courseId', this.list[this.currentIndex].courseId);
        if (this.type === '1') {
          fm.append('coursePlanId', this.id);
        }
        fm.append('studyTime', time);
        navigator.sendBeacon(`${baseURL}/ruihe/videoVod/pcEditUserStudy`, fm);
      }

      // 本地存储
      // localStorage.setItem(`${this.options.vid}_timing333`, time);
    },
  },
  mounted() {
    const toolsAreaWidth = this.$refs['courseToolsArea'].getBoundingClientRect()
      .width;
    this.courseAreaWidth = window.innerWidth - toolsAreaWidth - 40;
    window.addEventListener('beforeunload', this.setTime);
    this.player = this.$refs.player;
  },
  beforeDestroy() {
    this.setTimeAsync();
    window.removeEventListener('beforeunload', this.setTime);
  },
  created() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.currentIndex = parseInt(this.$route.params.index);
    this.options.language = this.locale === 'cn' ? 'zh-cn' : 'en-us';
    this.getData(this.id);
  },
};
</script>

<style lang="scss" scoped>
.course-study {
  height: 100vh;
  background: #191919;
  .title-area {
    height: 60px;
    background: #1e1e1e;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .btn {
      width: 80px;
      height: 36px;
      line-height: 33px;
      text-align: center;
      font-size: 16px;
      border: 1px solid #ffffff;
      border-radius: 18px;
      margin-right: 10px;
      color: #ffffff;
      cursor: pointer;
      user-select: none;
      &.back {
        background: #ffffff;
        color: #333333;
      }
    }
    .title-text {
      flex: 1;
      min-width: 0;
      color: #ffffff;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content-area {
    height: calc(100vh - 60px);
    position: relative;
    background: #191919;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 20px;
    padding-left: 20px;
    .course-area {
      height: 100%;
      padding-top: 24px;
      width: calc(100% - 440px);
      .player-area {
        height: 100% !important;
        background: #0d0d0d;
        flex: auto;
        padding-top: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
      }
    }
    .course-tools-area {
      width: 420px;
      height: calc(100% - 20px);
      position: absolute;
      top: 0;
      right: 0px;
      padding-top: 24px;
      padding-right: 20px;
      .handle {
        position: absolute;
        width: 20px;
        height: 120px;
        line-height: 120px;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        color: #5e5e5e;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background: #2d2d2d;
        border-right: 1px solid #242424;
        cursor: pointer;
        i {
          font-size: 20px;
        }
      }
      .tab-area {
        display: flex;
        position: relative;
        background: #2d2d2d;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #242424;
          z-index: 1;
        }
        .active-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 133px;
          background-color: $base-color;
          z-index: 2;
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          list-style: none;
        }
        .tab {
          height: 50px;
          line-height: 50px;
          flex: 1;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
          i {
            font-size: 16px;
          }
          &.actived {
            font-weight: bold;
            i {
              font-weight: bold;
            }
          }
        }
        // ::v-deep .el-tabs {
        //   height: 44px;
        //   .el-tabs__header {
        //     background: #2D2D2D;
        //     margin: 0;
        //     .el-tabs__nav-wrap {
        //       &::after {
        //         background-color: #242424;
        //       }
        //       .el-tabs__item {
        //         font-size: 16px;
        //         height: 50px;
        //         line-height: 50px;
        //         transition: all .3s;
        //         color: #ffffff;
        //         &.is-active {
        //           color: #ffffff;
        //           font-weight: bold;
        //           i {
        //             font-size: 16px;
        //             font-weight: bold;
        //           }
        //         }
        //         &:hover {
        //           color: #ffffff;
        //           font-weight: bold;
        //           i {
        //             font-size: 16px;
        //             font-weight: bold;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .tab-body {
        height: calc(100% - 44px);
        background: #2d2d2d;
        overflow-y: auto;
        color: #c5c5c5;
        .note-area,
        .comment-area {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .input-area {
          .input-wrap {
            padding: 10px;
            ::v-deep {
              .el-textarea__inner {
                background-color: #404040;
                border-color: #4e4e4e;
                color: #f5f5f5;
                &:hover {
                  border-color: #848484;
                }
                &:focus {
                  border-color: $base-color;
                }
              }
            }
          }
          .submit-area {
            padding: 0 10px;
            display: flex;
            align-items: center;
            .current-time-area {
              flex: 1;
              min-width: 0;
              padding-left: 30px;
              .wrap {
                display: flex;
                align-items: center;
                .iconfont {
                  font-size: 14px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .note-list {
          flex: 1;
          min-height: 0;
          overflow-y: auto;
          padding: 10px;
          li {
            padding: 10px 0;
            border-bottom: 1px dotted #4e4e4e;
            &:hover {
              .extra {
                .opers {
                  opacity: 1;
                }
              }
            }
            .text-content {
              margin-bottom: 10px;
            }
            .extra {
              display: flex;
              justify-content: space-between;
              .create-time {
                color: #5e5e5e;
              }
              .time-point {
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;
                .iconfont {
                  font-size: 12px;
                  margin-right: 5px;
                }
              }
              .opers {
                opacity: 0;
                cursor: pointer;
                user-select: none;
                .oper {
                  margin-left: 10px;
                  transition: color 0.3s;
                  &:hover {
                    color: $base-color;
                  }
                  i {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
        .pagination-area {
          padding: 10px 0;
          ::v-deep .el-pagination {
            text-align: center;
            color: #a2a2a2;
            .el-pager {
              li {
                background: #2d2d2d;
              }
            }
            button {
              background-color: #676767;
              color: #303133;
              &:disabled {
                color: #c0c4cc;
                background-color: #676767;
              }
            }
          }
        }
        .question-list {
          flex: 1;
          min-height: 0;
          overflow-y: auto;
          padding: 10px;
          li {
            padding: 10px 0;
            border-bottom: 1px dotted #4e4e4e;
            .top-area {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
              .userinfo {
                margin-right: 20px;
                flex: 1;
                min-width: 0;
                display: flex;
                align-items: center;
                .name {
                  margin-left: 5px;
                  flex: 1;
                  min-width: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .create-time {
                color: #5e5e5e;
              }
            }
            .text-content {
              font-size: 14px;
            }
            .reply-content {
              border: 1px dashed #4e4e4e;
              margin-top: 10px;
              padding: 5px 10px;
            }
          }
        }
        .index-list {
          padding: 10px;
          li {
            display: flex;
            font-size: 16px;
            line-height: 26px;
            padding: 5px 0;
            cursor: pointer;
            &.actived {
              color: $base-color;
            }
            i {
              margin-right: 5px;
            }
            .name {
              flex: 1;
              min-width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .mins {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
