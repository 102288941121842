<template>
  <div>
    <div class="header-area">{{ $t('pages.personal.demandManage') }}</div>
    <div class="info-area">
      <el-table :data="list" border style="width: 100%">
        <el-table-column :label="$t('pages.personal.courseName')" width="310">
          <template slot-scope="scope">
            <router-link
              class="name-area"
              :to="`/course-zone/detail/${scope.row.demandId}`"
            >
              <img :src="scope.row.demandCoverUrl" alt="" />
              <span class="name">{{ scope.row.demandName }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="demandPrice"
          :label="$t('pages.personal.price')"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          :label="$t('common.createTime')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="demandNumber"
          :label="$t('pages.personal.peopleCounting')"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('common.operation')" align="center">
          <template slot-scope="scope">
            <router-link
              class="to-buy"
              :to="`/course-zone/detail/${scope.row.demandId}`"
              v-if="scope.row.isDemand === '0'"
              >{{ $t('common.buy') }}</router-link
            >
            <span v-else>{{ $t('pages.personal.purchased') }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectDemandListForManage } from '@/api/common';
export default {
  data() {
    return {
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectDemandListForManage({
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.list = res.data;
        this.total = res.total;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .to-buy {
    color: #ff3333;
    user-select: none;
    cursor: pointer;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      // text-align: left;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
