<template>
  <div>
    <div class="header-area">{{$t('titles.myCollNews')}}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('pages.other.keywords')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="small"
        style="width: 100%"
      >
        <el-table-column prop="newTitle" :label="$t('pages.other.Newsheadline')" width="310">
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('pages.other.publishTime')" align="center">
        </el-table-column>
        <el-table-column prop="ancestorName" :label="$t('pages.other.publishResource')" align="center">
        </el-table-column>
        <el-table-column :label="$t('common.operation')" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="toDetail(scope.row)">详情</el-link>
            <el-link type="info" @click="toToggleColl(scope.row)"
              >{{$t('pages.other.cancelCollection')}}</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCollList, updateCollStatus } from "@/api/common";
export default {
  data() {
    return {
      isLoading: false,
      keywords: "",
      page: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push(`/news/detail/${item.id}`);
    },
    async toToggleColl(item) {
      this.$confirm(this.$t('pages.other.unfavorite'), this.$t('common.prompt'), {
        type: "warning",
      })
        .then(async () => {
          try {
            await updateCollStatus({
              productId: item.id,
              productType: 4,
            });
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMyCollList({
          keyWords: this.keywords,
          productType: 4,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data ? res.data : [];
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>