<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <the-header />
    </el-header>
    <!-- 主体 -->
    <el-main>
      <!-- <transition name="slide"> -->
        <keep-alive :include="keepAliveComps">
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      <!-- </transition> -->
    </el-main>
    <!-- 底部 -->
    <el-footer>
      <the-footer />
    </el-footer>
  </el-container>
</template>

<script>
import { Container, Header, Main, Footer } from 'element-ui';
import TheHeader from './components/TheHeader';
import TheFooter from './components/TheFooter';
export default {
  name: "Layout",
  components: {
    ElContainer: Container,
    ElHeader: Header,
    ElMain: Main,
    ElFooter: Footer,
    TheHeader,
    TheFooter,
  },
  watch: {
    $route (to, from) {
      if (to.meta.keepAlive) {
        !this.keepAliveComps.includes(to.name) && this.keepAliveComps.push(to.name);
      }
      if (from.meta.keepAlive && (!to.meta.deepth || to.meta.deepth <= from.meta.deepth)) {
        let index = this.keepAliveComps.indexOf(from.name);
        index !== -1 && this.keepAliveComps.splice(index, 1);
      }

    }
  },
  data: () => {
    return {
      keepAliveComps: []
    };
  },
};
</script>
<style lang="scss" scoped>
.el-header {
  padding: 0;
  height: 90px !important;
}
.el-footer {
  padding: 0;
  height: auto !important;
  background-color: #000000;
  padding-top: 45px;
}
</style>
