<template>
  <div>
    <div class="header-area">{{ $t('pages.other.shitiliebiao') }}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-button
            class="my-btn"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="toAdd"
          >
            <span>{{ $t('pages.other.xinzengshiti') }}</span>
          </el-button>
          <el-button
            class="my-btn"
            type="info"
            icon="el-icon-delete"
            size="small"
            @click="toDeleteAll"
            >{{ $t('pages.other.piliangshanchu') }}</el-button
          >
          <el-button
            class="my-btn"
            type="danger"
            size="small"
            @click="toExport"
          >
            <i class="iconfont">&#xe612;</i>
            <span>{{ $t('common.exports') }}</span>
          </el-button>
          <el-upload
            class="upload-area"
            ref="fileUploader"
            :action="`${baseURL}/ruihe/webOrg/importQuestionBankData`"
            :headers="{ Authorization: `pBearer ${$store.state.token}` }"
            :limit="1"
            :show-file-list="false"
            :on-success="handleUpload"
          >
            <el-button class="my-btn" type="success" size="small">
              <i class="iconfont">&#xe635;</i>
              <span>{{ $t('common.imports') }}</span>
            </el-button>
          </el-upload>
          <a href="javascript:;" @click="toDownload">{{$t('pages.other.xiazaidaorumuban')}}</a>
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder=" $t('pages.other.keywords')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
        row-key="questionBankId"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('common.topic')" prop="content">
          <template slot-scope="scope">
            <div class="content-area">
              {{ scope.row.content }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.genre')"
          width="150"
          prop="courseTypeValues"
        ></el-table-column>
        <el-table-column
          :label="$t('common.questionTypes')"
          width="80"
          prop="contentTypeValue"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('common.answers')"
          width="80"
          prop="correctOptionIds"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.contentTypeValue === '判断题'
                ? scope.row.correctOptionIds === 'A'
                  ? '对'
                  : '错'
                : scope.row.correctOptionIds
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.other.yinyongcishu')"
          width="80"
          prop="useAcount"
          align="center"
        ></el-table-column>
        <el-table-column :label="$t('pages.other.caozuo')" width="160px" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="toShowDetail(scope.row, true)"
              >{{$t('common.detail')}}</el-link
            >
            <el-link type="warning" @click="toShowDetail(scope.row, false)"
              >{{ $t('common.edit') }}</el-link
            >
            <el-link type="danger" @click="toDelete(scope.row)">{{$t('common.delete')}}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="$t('pages.other.xinzengshiti')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showDetail"
      :before-close="beforeClose"
    >
      <div class="popup-content form">
        <el-form
          label-position="right"
          label-width="150px"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <el-form-item :label="$t('pages.other.shititigan')" prop="question">
            <el-input
              v-model="form.question"
              type="textarea"
              autosize
              :readonly="disabled"
              :placeholder="$t('pages.other.qingtianxie')"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.shitifenlei')" prop="category">
            <el-select
              v-model="form.category"
              :placeholder="$t('pages.other.qingxuanze')"
              multiple
              :disabled="disabled"
              :style="{ width: '100%' }"
              :loading="categoryIsLoading"
            >
              <el-option
                v-for="category in categoryOptions"
                :key="category.courseTypeId"
                :label="category.courseTypeName"
                :value="category.courseTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.shititixing')" prop="type">
            <el-radio-group v-model="form.type" :disabled="disabled">
              <el-radio
                v-for="type in types"
                :key="type.value"
                :label="type.value"
                >{{ type.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('pages.other.shitibiaoqian')" prop="tags">
            <div class="tag-list" v-if="form.tags.length > 0">
              <div
                class="tag"
                v-for="(tag, index) in form.tags"
                :key="tag.labelId"
              >
                <span>{{ tag.label }}</span>
                <i
                  class="el-icon-close delete"
                  v-if="!disabled"
                  @click="toDeleteTag(index)"
                ></i>
              </div>
            </div>
            <el-autocomplete
              class="inline-input"
              v-model="newTag"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              :placeholder="$t('pages.other.qingshurubiaoqianneirong')"
              value-key="label"
              v-if="!disabled"
            >
              <el-button slot="append" icon="el-icon-plus" @click="toAddTag"
                >{{$t('pages.other.add')}}</el-button
              >
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.shitixuanxiang')"
            prop="options"
            v-if="form.type !== '3'"
          >
            <ul class="options-area">
              <li v-for="(option, index) in form.options" :key="index">
                <span class="label"
                  >{{ String.fromCharCode(index + indexStart) }}、</span
                >
                <el-input
                  v-model="form.options[index].value"
                  type="textarea"
                  :readonly="disabled"
                  :placeholder="$t('pages.other.qingtianxie')"
                />
                <i
                  class="el-icon-remove-outline remove"
                  @click="toRemoveOption(index)"
                  v-if="!disabled && index > 1"
                ></i>
                <i
                  class="el-icon-circle-plus-outline add"
                  @click="toAddOption"
                  v-if="!disabled && index === form.options.length - 1"
                ></i>
              </li>
            </ul>
          </el-form-item>
          <el-form-item :label="$t('pages.other.shitidaan')" prop="answer">
            <el-radio-group
              v-model="form.answer"
              :disabled="disabled"
              v-if="form.type === '1'"
            >
              <el-radio
                v-for="(item, index) in form.options"
                :key="index"
                :label="String.fromCharCode(indexStart + index)"
                >{{ String.fromCharCode(indexStart + index) }}</el-radio
              >
            </el-radio-group>
            <el-checkbox-group
              v-model="form.answer"
              :disabled="disabled"
              v-else-if="form.type === '2'"
            >
              <el-checkbox
                v-for="(item, index) in form.options"
                :key="index"
                :label="String.fromCharCode(indexStart + index)"
                >{{ String.fromCharCode(indexStart + index) }}</el-checkbox
              >
            </el-checkbox-group>
            <el-radio-group v-model="form.answer" :disabled="disabled" v-else>
              <el-radio
                v-for="(item, index) in form.options"
                :key="index"
                :label="String.fromCharCode(indexStart + index)"
                >{{ index === 0 ? '对' : '错' }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('pages.other.shitijiexi')" prop="analysis">
            <el-input
              v-model="form.analysis"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              :readonly="disabled"
              :placeholder="$t('pages.other.qingtianxie')"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{$t('pages.other.cancel')}}</el-button>
        <el-button type="primary" @click="toClose">{{
          disabled ? $t('pages.other.close') : $t('pages.other.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/config';
import {
  selectQuestionListOfOrg,
  selectCategoryList,
  selectTagList,
  AddUserTag,
  insertQuestionFromOrg,
  selectQuestionDetailOfOrg,
  deleteQuestionFromOrg,
  updateQuestionFromOrg,
} from '@/api/common';
export default {
  data() {
    return {
      baseURL,
      disabled: false,
      categoryIsLoading: true,
      categoryOptions: [],
      types: [
        { value: '1', label: this.$t('pages.other.danxuanti') },
        { value: '2', label: this.$t('pages.other.duoxuanti')  },
        { value: '3', label: this.$t('pages.other.panduanti') },
      ],
      tagList: [],
      newTag: '',
      answerList: [],
      form: {
        id: '',
        question: '',
        category: [],
        type: '1',
        tags: [],
        options: [
          {
            value: '',
          },
          {
            value: '',
          },
        ],
        answer: '',
        analysis: '',
      },
      rules: {
        question: [{ required: true, message: this.$t('pages.other.qingshurutigan'), trigger: 'blur' }],
        category: [
          { required: true, message: this.$t('pages.other.qingxuanze')+' '+ this.$t('pages.other.shitifenlei'), trigger: 'blur' },
        ],
        tags: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingtianjiabiaoqian'));
              }
            },
            message: this.$t('pages.other.qingtianjiabiaoqian'),
            trigger: 'blur',
          },
        ],
        options: [
          {
            validator: (rule, value, callback) => {
              let flag = true;
              value.forEach((option) => {
                if (!option.value) {
                  flag = false;
                }
              });
              if (flag) {
                callback();
              } else {
                callback(new Error(this.$t('pages.other.xuanxiangbunengweikong')));
              }
            },
            message: this.$t('pages.other.xuanxiangbunengweikong'),
            trigger: 'blur',
          },
        ],
        answer: [{ required: true, message: this.$t('pages.other.qinglurudaan'), trigger: 'blur' }],
      },
      indexStart: 65,
      showDetail: false,
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      selection: [],
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  watch: {
    'form.type'(newVal, oldVal) {
      this.form.answer = '';
      if (newVal === '2') {
        this.form.answer = [];
      }
      if (newVal === '3') {
        this.form.options = [{ value: '对' }, { value: '错' }];
      }
      if (oldVal === '3') {
        this.form.options = [{ value: '' }, { value: '' }];
      }
    },
  },
  methods: {
    async toExport() {
      const arr = []
      this.selection.forEach(item => {
        arr.push(item.questionBankId)
      })
      axios
        .get(
          `${baseURL}/ruihe/webOrg/exportQuestionData?content=${this.keywords}&ids=${arr.join(',')}`,
          {
            responseType: 'blob',
            headers: {
              'content-disposition': `attachment;filename=xls`,
              'content-type': 'application/x-download;charset=utf-8',
              Authorization: `pBearer ${this.$store.state.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'questionList';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    async toDownload() {
      axios
        .get(`${baseURL}/ruihe/webOrg/exportQuestionBankExcel`, {
          responseType: 'blob',
          headers: {
            'content-disposition': `attachment;filename=xls`,
            'content-type': 'application/x-download;charset=utf-8',
            Authorization: `pBearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'importTemplate';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    toDeleteAll() {
      if (this.selection.length > 0) {
        let flag = false;
        this.selection.forEach((item) => {
          if (item.status) {
            flag = true;
          }
        });
        let noticeStr = this.$t('pages.other.quedingyaoshanchushitima') ;
        if (flag) {
          noticeStr += this.$t('pages.other.youshitibeishijuanyinyong') ;
        }
        this.$confirm(noticeStr, this.$t('pages.other.caozuotixing'))
          .then(async () => {
            try {
              await deleteQuestionFromOrg({
                questionBankIds: this.selection
                  .map((item) => item.questionBankId)
                  .join(','),
              });
              this.selection = [];
              this.page = 1;
              this.getData();
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {});
      } else {
        this.$message.error(this.$t('pages.other.weixuanzerenheshiti'));
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    toDelete(item) {
      console.log(item.questionBankId);
      this.$confirm(this. $t('pages.other.quedingyaoshanchuma'), this.$t('pages.other.tishi'), {
        type: 'warning',
      })
        .then(async () => {
          try {
            await deleteQuestionFromOrg({
              questionBankIds: item.questionBankId,
            });
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    async toAddTag() {
      if (this.newTag.trim()) {
        const checkArr = this.form.tags.filter(
          (item) => item.label === this.newTag.trim()
        );
        if (checkArr.length === 0) {
          try {
            const res = await AddUserTag({ labalContent: this.newTag.trim() });
            this.form.tags.push({
              labalType: res.labalType,
              label: res.labalContent,
              labelId: res.labalId,
            });
            this.newTag = '';
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message.error(this.$t('pages.other.biaoqianchongfu'));
        }
      } else {
        this.$message.error(this.$t('pages.other.qingshurubiaoqianneirong'));
      }
    },
    async querySearch(queryString, cb) {
      if (queryString) {
        const res = await selectTagList({ label: queryString });
        if (res.length > 0) {
          cb(res);
        } else {
          cb([]);
        }
      }
    },
    toDeleteTag(index) {
      if (this.disabled) return;
      this.form.tags.splice(index, 1);
    },
    handleUpload(res) {
      if (res.code === 202 && res.msg) {
        this.$message({
          type: 'success',
          message: res.msg,
          dangerouslyUseHTMLString: true,
        });
      } else if (res.code === 400 && res.msg) {
        this.$message({
          type: 'error',
          message: res.msg,
          dangerouslyUseHTMLString: true,
        });
      }
      this.$refs.fileUploader.clearFiles();
    },
    async toShowDetail(item, flag) {
      try {
        const res = await selectQuestionDetailOfOrg({
          questionBankId: item.questionBankId,
        });
        this.form.id = item.questionBankId;
        this.form.question = res.content;
        this.form.category = res.courseTypeIds
          ? res.courseTypeIds.split(',').map((item) => parseInt(item))
          : [];
        this.form.type = res.contentType;
        this.form.tags = res.labelSets;
        this.form.analysis = res.optionExplain;
        this.form.options = res.questionOptionVos.map((item) => {
          return { value: item.optionText };
        });
        this.disabled = flag;
        this.showDetail = true;
        this.$nextTick(() => {
          this.form.answer =
            res.contentType === '2'
              ? res.correctOptionIds.split(',')
              : res.correctOptionIds;
        });
      } catch (error) {
        console.log(error);
      }
    },
    beforeClose(done) {
      this.$refs['form'].resetFields();
      done();
    },
    async toClose() {
      if (this.disabled) {
        this.$refs['form'].resetFields();
        this.showDetail = false;
      } else {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              const options = [];
              this.form.options.forEach((option, index) => {
                options.push({
                  optionId: String.fromCharCode(this.indexStart + index),
                  optionText: option.value,
                });
              });
              const params = {
                locale: this.$store.state.locale,
                content: this.form.question,
                courseTypeIds: this.form.category.join(','),
                correctOptionIds:
                  this.form.type === '2'
                    ? this.form.answer.sort().join(',')
                    : this.form.answer,
                optionExplain: this.form.analysis,
                contentType: this.form.type,
                questionOptionVos: options,
                labalIds: this.form.tags
                  ? this.form.tags.map((item) => item.labelId).join(',')
                  : '',
              };
              if (this.form.id) {
                params.questionBankId = this.form.id;
                await updateQuestionFromOrg(params);
              } else {
                await insertQuestionFromOrg(params);
              }
              this.$message.success(
                `${this.$t('pages.other.shiti')}${this.form.id ? this.$t('pages.other.xiugai') : this.$t('pages.other.add')}${this.$t('pages.other.chenggong')}！`
              );
              this.$refs['form'].resetFields();
              this.showDetail = false;
              this.toSearch();
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    },
    resetAnswer() {
      if (this.form.type === '2') {
        this.form.answer = [];
      } else {
        this.form.answer = '';
      }
    },
    toRemoveOption(index) {
      this.form.options.splice(index, 1);
      this.resetAnswer();
    },
    toAddOption() {
      this.form.options.push({
        value: '',
      });
      this.resetAnswer();
    },
    toAdd() {
      this.form = {
        question: '',
        category: [],
        type: '1',
        tags: [],
        options: [
          {
            value: '',
          },
          {
            value: '',
          },
        ],
        answer: '',
        analysis: '',
      };
      this.disabled = false;
      this.showDetail = true;
    },
    async toGetCategoryList() {
      try {
        const res = await selectCategoryList({
          locale: this.$store.state.locale,
        });
        this.categoryOptions = res;
        this.categoryIsLoading = false;
      } catch (error) {
        console.log(error);
        this.categoryIsLoading = false;
      }
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectQuestionListOfOrg({
          locale: this.$store.state.locale,
          content: this.keywords,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.toGetCategoryList();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .btn-area {
      display: flex;
      align-items: flex-end;
      .my-btn {
        margin-right: 10px;
        i {
          line-height: 1;
          font-size: 12px;
          margin-right: 5px;
        }
      }
      a {
        margin-left: 10px;
        color: $base-color;
        text-decoration: underline;
      }
    }
  }
  .content-area {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      // &.el-table-column--selection {
      //   .cell {
      //     padding-left: 14px;
      //   }
      // }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.tag-list {
  .tag {
    padding: 8px 20px 8px 16px;
    background: #e6ffee;
    color: #3fca6c;
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    position: relative;
    margin-bottom: 17px;
    & ~ .tag {
      margin-left: 25px;
    }
    &::after {
      content: '';
      border: 15px solid;
      border-color: transparent transparent transparent #e6ffee;
      position: absolute;
      right: -30px;
      top: 0px;
    }
    .delete {
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #3fca6c;
        border-radius: 50%;
        color: #ffffff;
      }
    }
  }
}
.options-area {
  li {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 15px;
    }
    .el-textarea {
      margin-left: 10px;
      width: 562px;
    }
    i {
      font-size: 22px;
      margin-left: 10px;
      cursor: pointer;
      user-select: none;
      // &.remove {
      //   color: #ff3333;
      // }
      &.add {
        color: $base-color;
      }
    }
  }
}
</style>
