<template>
  <div class="org-manage">
    <!-- 选项卡 -->
    <div class="tab-area">
      <el-tabs v-model="activeName">
        <el-tab-pane name="first">
          <div class="tab" slot="label">
            {{ $t('pages.personal.orgMember') }}
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <div class="tab" slot="label">
            {{ $t('pages.personal.orgStructure') }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 内容 -->
    <!-- 机构人员管理 -->
    <div class="info-area first" v-show="activeName === 'first'">
      <div class="oper-area">
        <div class="btn-area">
          <!-- <el-button class="my-btn" type="danger" size="medium" @click="dialogFormVisible = true">
            <i class="iconfont">&#xe636;</i>
            <span>添加学员</span>
          </el-button> -->
          <el-button
            class="my-btn"
            type="primary"
            size="mini"
            @click="toExport"
          >
            <i class="iconfont">&#xe612;</i>
            <span>{{ $t('common.exports') }}</span>
          </el-button>
          <el-upload
            class="upload-area"
            ref="fileUploader"
            :action="`${baseURL}/ruihe/webUser/importData`"
            :headers="{ Authorization: `pBearer ${$store.state.token}` }"
            :limit="1"
            :on-success="handleUpload"
          >
            <el-button class="my-btn" type="success" size="mini">
              <i class="iconfont">&#xe635;</i>
              <span>{{ $t('common.imports') }}</span>
            </el-button>
          </el-upload>
          <a href="javascript:;" @click="toDownload">{{
            $t('common.downloadTheImportTemplate')
          }}</a>
        </div>
        <div class="search-area">
          <el-dropdown @command="changeType">
            <el-button class="my-btn" type="primary" size="small">
              {{ currentStatus | statusText($store.state.locale) }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key) in status"
                :key="key"
                :command="key"
                >{{ value[$store.state.locale] }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-input
            :placeholder="
              `${$t('common.enter')} ${$t('common.name')}/${$t(
                'common.account'
              )}/${$t('common.tel')}/${$t('common.email')}`
            "
            v-model="keywords"
            class="input-with-select"
            clearable
            size="small"
            @keyup.enter.native="getMemberInfoByOrgId"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getMemberInfoByOrgId"
            ></el-button>
          </el-input>
        </div>
      </div>
      <div class="org-detail-area">
        <div class="left">
          <el-tree
            v-if="treeData.length > 0"
            :data="treeData"
            node-key="value"
            :highlight-current="true"
            :props="defaultProps"
            :default-expanded-keys="[treeData[0].value]"
            @node-click="handleNodeClick"
          >
            <div
              slot-scope="scope"
              class="custom-tree-node"
              :class="{ active: scope.data.checked }"
            >
              <i class="iconfont" v-if="scope.data.orgType === '0'">&#xe6e5;</i>
              <i class="iconfont" v-else>&#xe6bd;</i>
              {{ scope.node.label }}
            </div>
          </el-tree>
        </div>
        <div class="right">
          <el-table :data="list" :loading="tLoading" border style="width: 100%">
            <el-table-column
              prop="realName"
              :label="$t('common.name')"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="position"
              :label="$t('pages.personal.position')"
              width="127"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              :label="$t('common.tel')"
              width="125"
              align="center"
            />
            <el-table-column
              prop="role"
              :label="$t('pages.personal.role')"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.role | roleText($store.state.locale) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="loginAuth"
              :label="$t('common.status')"
              width="84"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.loginAuth !== '3'">
                  {{ scope.row.loginAuth | statusText($store.state.locale) }}
                </span>
                <span
                  v-else
                  class="toNotice"
                  :title="$t('pages.personal.sendingSMSNotification')"
                  @click="toNotice(scope.row)"
                  >{{
                    scope.row.loginAuth | statusText($store.state.locale)
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')" align="center">
              <template slot-scope="scope">
                <span
                  class="oper-btn detail"
                  @click="toShowDetail(scope.row)"
                  >{{ $t('common.edit') }}</span
                >
                <span class="oper-btn kickout" @click="toKickout(scope.row)">{{
                  $t('common.remove')
                }}</span>
                <span class="oper-btn delete" @click="toDelete(scope.row)">{{
                  $t('common.delete')
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="Math.ceil(total / pageSize) <= 1"
            :page-size="pageSize"
            :total="total"
            @current-change="getMemberInfoByOrgId"
          />
        </div>
      </div>
    </div>
    <!-- 机构架构图 -->
    <div class="info-area second" v-show="activeName === 'second'">
      <div class="org-detail-area">
        <div class="left">
          <el-tree
            v-if="treeData.length > 0"
            :data="treeData"
            node-key="value"
            :highlight-current="true"
            :props="defaultProps"
            :default-expanded-keys="[treeData[0].value]"
            @node-click="handleNodeClick"
          >
            <div
              slot-scope="scope"
              class="custom-tree-node"
              :class="{ active: scope.data.checked }"
            >
              <i class="iconfont" v-if="scope.data.orgType === '0'">&#xe6e5;</i>
              <i class="iconfont" v-else>&#xe6bd;</i>
              {{ scope.node.label }}
            </div>
          </el-tree>
        </div>
        <div class="right" v-if="treeData.length > 0">
          <div
            class="edit-area"
            v-if="
              currentNode.orgType !== '0' || currentNode.value === orgInfo.orgId
            "
          >
            <div class="name-area">
              <i class="iconfont">&#xe6e5;</i>
              <span class="text">{{ currentNode.label }}</span>
              <!-- <el-button type="primary" size="small" icon="el-icon-edit" @click="toEditOrgName" v-if="currentNode.orgType !== '0'">编辑</el-button> -->
            </div>
            <div class="name-area">
              <i class="iconfont">&#xe6bd;</i>
              <span class="text">{{ $t('pages.personal.departments') }}</span>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="toAddDept"
                >{{ $t('common.add') }}</el-button
              >
            </div>
            <el-table
              :data="deptList"
              :loading="tLoading"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="label"
                :label="$t('common.dept')"
                align="center"
              >
              </el-table-column>
              <el-table-column :label="$t('common.operation')" align="center">
                <template slot-scope="scope">
                  <span
                    class="oper-btn detail"
                    @click="toEditDept(scope.row)"
                    >{{ $t('common.modify') }}</span
                  >
                  <span
                    class="oper-btn delete"
                    @click="toDeleteDept(scope.row)"
                    >{{ $t('common.delete') }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="error-notice" v-else>
            <div>
              <img src="@/assets/images/img-error.png" alt="" />
              <p class="text">{{ $t('pages.personal.canotEdit') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 学员详情弹窗 -->
    <el-dialog
      :title="$t('pages.personal.stuDetail')"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        label-position="right"
        label-width="100px"
      >
        <el-form-item :label="$t('common.name')" prop="name">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.account')" prop="loginName">
          <el-input v-model="form.loginName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.tel')" prop="tel">
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.email')" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.personal.role')" prop="role">
          <!-- <el-input v-model="form.role" ></el-input> -->
          <el-select v-model="form.role" disabled :placeholder="$t('commom.placeHolder_select')">
            <el-option
              v-for="item in myroles"
              :key="item.value"
              :label="$store.state.locale == 'cn' ? item.cn : item.en"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item :label="$t('pages.personal.position')" prop="job">
          <el-input v-model="form.job" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.status')" prop="status">
          <el-radio-group disabled v-model="form.status">
            <el-radio
              :label="key"
              v-for="(val, key) in filtedStatus"
              :key="key"
              >{{ val[$store.state.locale] }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="eidtHandle">{{
          $t('common.confirm')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 部门信息弹窗 -->
    <el-dialog
      :title="$t('pages.personal.deptDetail')"
      width="600px"
      :visible.sync="deptDialogFormVisible"
    >
      <el-form
        :model="deptForm"
        :hide-required-asterisk="true"
        label-position="right"
        label-width="120px"
      >
        <el-form-item :label="$t('common.name')" prop="deptName">
          <el-input v-model="deptForm.deptName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.groupLeader')">
          <div class="leader-card" v-if="deptForm.groupLeaderId">
            <el-avatar
              :size="60"
              :src="
                deptForm.groupLeaderAvatar
                  ? deptForm.groupLeaderAvatar
                  : defaultAvatar
              "
            />
            <div class="leader-info">
              <p class="name">{{ deptForm.groupLeaderName }}</p>
              <p class="tel">
                {{ `${$t('common.tel')}/${$t('common.email')}` }}：{{
                  deptForm.groupLeaderTel
                    ? deptForm.groupLeaderTel
                    : deptForm.groupLeaderEmail
                }}
              </p>
            </div>
            <div class="opers">
              <span class="delete" @click="toDeleteGroupLeader">{{
                $t('common.delete')
              }}</span>
            </div>
          </div>
          <div class="leader-card add" v-else>
            <el-button
              icon="el-icon-plus"
              circle
              @click="toShowMemberList"
            ></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deptDialogFormVisible = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="editDept">{{
          $t('common.confirm')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 选择组长弹窗 -->
    <el-dialog
      :title="$t('common.choose')"
      width="800px"
      :visible.sync="showPopup"
    >
      <el-table
        :data="deptMemberList"
        height="500"
        size="mini"
        style="width: 100%"
      >
        <el-table-column
          prop="userName"
          :label="$t('common.name')"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="userPhone" :label="$t('common.tel')" width="180">
        </el-table-column>
        <el-table-column prop="userMail" :label="$t('common.email')">
        </el-table-column>
        <el-table-column
          :label="$t('common.operation')"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="handleChoose(scope.row)">{{
              $t('common.choose')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/config';
import {
  selectOrgStructureInfo,
  selectMemberInfoByOrgId,
  selectOrgMemberWithoutLeader,
  addDeptInfo,
  kickoutMember,
  selectOrgLeader,
  deleteMember,
  updateDeptInfo,
  deleteDeptInfo,
  insertNoticeToStudent,
  updateUserinfoBase
} from '@/api/common';
const roleList = {
  '0': {
    cn: '散户学员',
    en: 'Student',
  },
  '1': {
    cn: '机构学员',
    en: 'Org Stu',
  },
  '2': {
    cn: '班主任',
    en: 'Manager',
  },
  '3': {
    cn: '小组长',
    en: 'Group Leader',
  },
  '4': {
    cn: '讲师',
    en: 'Teacher',
  },
};
const status = {
  '': {
    cn: '全部',
    en: 'All',
  },
  '0': {
    cn: '正常',
    en: 'Normal',
  },
  '1': {
    cn: '封禁',
    en: 'Banned',
  },
  '2': {
    cn: '过期',
    en: 'Overdue',
  },
  '3': {
    cn: '未报道',
    en: 'Inactive',
  },
};
export default {
  data() {
    return {
      myroles: [
        {
          cn: '散户学员',
          en: 'Student',
          value: '0'
        },
        {
          cn: '机构学员',
          en: 'Org Stu',
          value: '1'
        },
        {
          cn: '班主任',
          en: 'Manager',
          value: '2'
        },
        {
          cn: '小组长',
          en: 'Group Leader',
          value: '3'
        },
        {
          cn: '讲师',
          en: 'Teacher',
          value: '4'
        }
      ],
      status,
      defaultAvatar: require('@/assets/images/default-avatar.png'),
      baseURL,
      activeName: 'first',
      keywords: '',
      treeData: [],
      currentNode: null,
      deptList: [],
      currentDept: null,
      deptMemberList: [],
      showPopup: false,
      defaultProps: {
        children: 'children',
        label: 'label',
        value: 'value',
      },
      currentStatus: '',
      list: [],
      page: 1,
      pageSize: 15,
      total: 0,
      tLoading: false,
      dialogFormVisible: false,
      form: {
        userId: '',
        name: '',
        loginName: '',
        tel: '',
        email: '',
        role: '',
        job: '',
        status: '',
      },
      rules: {
        name: [
          { required: true, message: this.$t('common.placeHolder_enter'), trigger: 'blur' }
        ],
        tel: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value)) {
                callback()
              } else {
                callback(new Error(this.$t('pages.other.qingshuruzhengquedeshoujihao')));
              }
            },
          },
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      deptDialogFormVisible: false,
      deptForm: {
        deptName: '',
        groupLeaderAvatar: '',
        groupLeaderId: '',
        groupLeaderName: '',
        groupLeaderTel: '',
        groupLeaderEmail: '',
      },
    };
  },
  watch: {
    activeName(value) {
      this.getTreeData(value === 'first' ? '1' : '2');
    },
    currentNode() {
      if (this.currentNode) {
        this.deptList = this.currentNode.children
          ? this.currentNode.children.filter((item) => item.orgType === '1')
          : [];
      }
    },
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    orgInfo() {
      return this.$store.state.orgInfo;
    },
    filtedStatus() {
      const copyStatus = JSON.parse(JSON.stringify(this.status));
      delete copyStatus[''];
      return copyStatus;
    },
  },
  filters: {
    roleText(roleId, locale) {
      return roleList[roleId][locale];
    },
    statusText(key, locale) {
      return status[key][locale];
    },
  },
  methods: {
    eidtHandle () {
      console.log(this.form, 666666)
       this.$confirm(this.$t('common.modifyConfirm'), this.$t('common.prompt'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(async () => {
          const res = await updateUserinfoBase ({
            realName: this.form.name,
            mail: this.form.email,
            phone: this.form.tel,
            userId: this.form.userId,
            position: this.form.job })
          console.log(res)
          this.page = 1
          this.getMemberInfoByOrgId()
          this.dialogFormVisible = false
        }).catch(() => {
          this.dialogFormVisible = false
        });
    },
    toNotice(item) {
      this.$confirm(
        this.$t('pages.personal.sendSmsText'),
        this.$t('common.prompt')
      )
        .then(async () => {
          try {
            await insertNoticeToStudent({ userId: item.userId });
            this.$message.success(this.$t('common.success'));
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    changeType(status) {
      this.currentStatus = status;
      this.page = 1;
      this.getMemberInfoByOrgId();
    },
    toAddDept() {
      this.$prompt(
        this.$t('pages.personal.plsEnterDeptName'),
        this.$t('common.prompt'),
        {
          inputValidator(value) {
            return !!value && value.trim().length > 0;
          },
          inputErrorMessage: this.$t('pages.personal.plsEnterDeptName'),
        }
      )
        .then(async ({ value }) => {
          await addDeptInfo({
            parentId: this.currentNode.value,
            orgName: value,
          });
          this.$message.success(this.$t('common.success'));
          this.getTreeData('2');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // toEditOrgName() {
    //   this.$prompt('请输入新名称', this.$t('common.prompt'), {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     inputValidator(value) {
    //       return !!value && value.trim().length > 0;
    //     },
    //     inputErrorMessage: '请输入机构名称',
    //   })
    //     .then(({ value }) => {
    //       this.$message({
    //         type: 'success',
    //         message: '你输入机构名称是: ' + value,
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '取消输入',
    //       });
    //     });
    // },
    handleNodeClick(node) {
      this.currentNode = node;
      this.getMemberInfoByOrgId();
    },
    toDeleteGroupLeader() {
      this.deptForm.groupLeaderAvatar = '';
      this.deptForm.groupLeaderId = '';
      this.deptForm.groupLeaderName = '';
      this.deptForm.groupLeaderTel = '';
      this.deptForm.groupLeaderEmail = '';
    },
    editDept() {
      if (this.deptForm.deptName) {
        this.$confirm(
          this.$t('common.modifyConfirm'),
          this.$t('common.prompt'),
          {
            type: 'warning',
          }
        )
          .then(async () => {
            try {
              await updateDeptInfo({
                orgId: this.currentDept.value,
                orgName: this.deptForm.deptName,
                orgLeadersStr: this.deptForm.groupLeaderId,
              });
              this.deptDialogFormVisible = false;
              this.$message.success(this.$t('common.success'));
              this.getTreeData('2');
              this.deptForm = {
                deptName: '',
                groupLeaderAvatar: '',
                groupLeaderId: '',
                groupLeaderName: '',
                groupLeaderTel: '',
                groupLeaderEmail: '',
              };
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {});
      } else {
        this.$message.error(this.$t('pages.personal.plsEnterDeptName'));
      }
    },
    handleChoose(item) {
      this.deptForm.groupLeaderAvatar = item.headImg || '';
      this.deptForm.groupLeaderId = item.userId;
      this.deptForm.groupLeaderName = item.userName;
      this.deptForm.groupLeaderTel = item.userPhone || '';
      this.deptForm.groupLeaderEmail = item.userMail || '';
      this.showPopup = false;
    },
    async toShowMemberList() {
      try {
        const res = await selectOrgMemberWithoutLeader({
          orgId: this.currentDept.value,
        });
        this.deptMemberList = res;
        this.showPopup = true;
      } catch (error) {
        console.log(error);
      }
    },
    async toEditDept(item) {
      console.log(item);
      this.currentDept = item;
      this.deptForm.deptName = item.label;
      try {
        const res = await selectOrgLeader({ orgId: item.value });
        if (res.length > 0) {
          this.deptForm.groupLeaderAvatar = res[0].headImg;
          this.deptForm.groupLeaderId = res[0].userId;
          this.deptForm.groupLeaderName = res[0].userName;
          this.deptForm.groupLeaderTel = res[0].userPhone;
        }
      } catch (error) {
        console.log(error);
      }
      this.deptDialogFormVisible = true;
    },
    toDeleteDept(item) {
      this.$confirm(`${this.$t('common.deleteConfirm')}[${item.label}]`)
        .then(async () => {
          try {
            await deleteDeptInfo({ orgId: item.value });
            this.$message.success(this.$t('common.success'));
            this.getTreeData('2');
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toDelete(item) {
      this.$confirm(
        `${this.$t('common.deleteConfirm')}[${item.realName}${
          item.loginName ? '(' +item.loginName+')' : ''
        }]`
      )
        .then(async () => {
          try {
            await deleteMember({ userId: item.userId });
            this.$message.success(this.$t('common.success'));
            this.getTreeData('1');
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toKickout(item) {
      this.$confirm(
        `${this.$t('pages.personal.removeConfirmText1')}[${item.realName}（${
          item.loginName
        }）]${this.$t('pages.personal.removeConfirmText2')}`
      )
        .then(async () => {
          try {
            await kickoutMember({ userId: item.userId });
            this.$message.success(this.$t('common.success'));
            this.getTreeData('1');
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toShowDetail(item) {
      console.log(item);
      this.form.userId = item.userId;
      this.form.name = item.realName;
      this.form.loginName = item.loginName;
      this.form.tel = item.phone ? item.phone : '-';
      this.form.email = item.mail ? item.mail : '-';
      // this.form.role = roleList[item.role][this.$store.state.locale];
      this.form.role = item.role;
      this.form.job = item.position;
      this.form.status = item.loginAuth;
      this.dialogFormVisible = true;
    },
    handleUpload(res) {
      this.$alert(res.msg, { dangerouslyUseHTMLString: true });
      this.getTreeData('1');
      this.$refs.fileUploader.clearFiles();
    },
    async toExport() {
      axios
        .get(
          `${baseURL}/ruihe/webUser/exportUserData?orgId=${this.currentNode.value}&realName=${this.keywords}`,
          {
            responseType: 'blob',
            headers: {
              'content-disposition': `attachment;filename=xls`,
              'content-type': 'application/x-download;charset=utf-8',
              Authorization: `pBearer ${this.$store.state.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'memberList';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    async toDownload() {
      axios
        .get(`${baseURL}/ruihe/webUser/importTemplate`, {
          responseType: 'blob',
          headers: {
            'content-disposition': `attachment;filename=xls`,
            'content-type': 'application/x-download;charset=utf-8',
            Authorization: `pBearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'importTemplate';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    async getMemberInfoByOrgId() {
      this.tLoading = true;
      try {
        const res = await selectMemberInfoByOrgId({
          keyWord: this.keywords,
          loginAuth: this.currentStatus,
          orgId: this.currentNode.value,
          page: this.page,
          pageSize: this.pageSize,
        });
        console.log(res);
        this.list = res.rows;
        this.total = res.total;
        this.tLoading = false;
      } catch (error) {
        console.log(error);
        this.tLoading = false;
      }
    },
    async getTreeData(type) {
      try {
        const res = await selectOrgStructureInfo({ seeType: type || '' });
        this.treeData = res;
        this.currentNode = this.treeData[0];
        this.getMemberInfoByOrgId();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    console.log(this.$store.state.locale, 666666666)
    this.getTreeData('1');
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table th {
  background: #f1f4f7;
  text-align: center;
}
::v-deep .el-tree-node {
  .el-tree-node__children {
    .el-tree-node__content {
      font-weight: normal;
    }
  }
  .el-tree-node__content {
    font-weight: bold;
    height: 35px;
  }
}
.custom-tree-node {
  font-size: 16px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
    width: 14px;
    height: 13px;
  }
  .iconfont {
    margin-right: 8px;
    color: $text-color-grey;
    font-weight: normal;
  }
}
.org-manage {
  padding: 20px 0 30px;
  .tab-area {
    padding: 0 30px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 14px;
      height: 2px;
      width: 100%;
      background: #e4e7ed;
    }
    .el-tabs__item.is-active {
      .tab {
        font-weight: bold;
      }
    }
    .tab {
      color: #333333;
      font-size: 16px;
      font-weight: normal;
      user-select: none;
    }
  }
  .info-area {
    padding: 0 30px;
    .toNotice {
      user-select: none;
      cursor: pointer;
      text-decoration: underline;
      color: $base-color;
    }
    .oper-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-area {
        display: flex;
        align-items: flex-end;
        a {
          margin-left: 10px;
          color: $base-color;
          text-decoration: underline;
        }
        .upload-area {
          margin-left: 10px;
          ::v-deep .el-upload-list {
            display: none;
          }
        }
      }
      .search-area {
        display: flex;
        .el-dropdown {
          margin-right: 10px;
        }
      }
    }
    .my-btn {
      i.iconfont {
        margin-right: 5px;
      }
      span {
        vertical-align: middle;
      }
      input {
        display: none;
      }
    }
    &.second {
      .org-detail-area {
        .right {
          margin-left: 0;
          min-height: 430px;
          border: 1px solid #e5e5e5;
          border-left: none;
          padding: 20px 30px;
          .name-area {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 14px;
            }
            .iconfont {
              color: $text-color-grey;
            }
            .text {
              font-size: 16px;
              font-weight: bold;
              margin: 0 30px 0 10px;
            }
            & ~ .name-area {
              margin-top: 30px;
              margin-bottom: 30px;
            }
          }
          .error-notice {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 250px;
            }
            .text {
              font-size: 16px;
              color: #b6bdcc;
              text-align: center;
            }
          }
        }
      }
    }
    .org-detail-area {
      display: flex;
      margin-top: 20px;
      .left {
        width: 167px;
        border: 1px solid #e5e5e5;
        padding-top: 20px;
        // padding-left: 13px;
        overflow: auto;
      }
      .right {
        flex: 1;
        min-width: 0;
        margin-left: 10px;
        min-height: 430px;
        .oper-btn {
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 10px;
          }
          &.detail {
            color: #ffbb05;
          }
          &.kickout {
            color: #ff7c33;
          }
          &.delete {
            color: #ff4c4c;
          }
        }
      }
    }
  }
  .leader-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.add {
      .el-button {
        width: 60px;
        height: 60px;
      }
    }
    .leader-info {
      margin-left: 17px;
      .name {
        font-size: 14px;
        line-height: 34px;
      }
      .tel {
        font-size: 12px;
        color: #666666;
        line-height: 1;
      }
    }
    .opers {
      margin-left: 60px;
      font-size: 12px;
      cursor: pointer;
      .edit {
        color: #1990ff;
        margin-right: 10px;
      }
      .delete {
        color: #ff4c4c;
      }
    }
  }
}
</style>
