<template>
  <div class="my-refund-steps">
    <div class="step" v-for="(item, index) in list" :key="index" :class="[{active: active >= (index + 1),}, type]">
      <div class="index-area">{{ index + 1 }}</div>
      <div class="desc-area">{{ item }}</div>
    </div>
    <!-- <div class="step" :class="[{active: active >= 2,}, type]">
      <div class="index-area">2</div>
      <div class="desc-area">订单支付</div>
    </div>
    <div class="step" :class="[{active: active >= 3,}, type]">
      <div class="index-area">3</div>
      <div class="desc-area">开课成功</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      // 类型 line area
      default: 'line'
    },
    active: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.my-refund-steps {
  display: flex;
  user-select: none;
  .step.area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 0;
    background: #EEEEEE;
    color: $text-color-grey;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    height: 50px;
    border: 2px solid #ffffff;
    border-right: none;
    &.active {
      background: #FFE8AC;
      &::after {
        border-color: transparent transparent transparent #FFE8AC;
      }
      .index-area {
        color: #FFE8AC;
      }
    }
    &::before {
      content: '';
      border: 25px solid;
      border-color: transparent transparent transparent #ffffff;
      position: absolute;
      right: -50px;
      top: -2px;
      z-index: 1;
    }
    &::after {
      content: '';
      border: 22px solid;
      border-color: transparent transparent transparent #EEEEEE;
      position: absolute;
      right: -44px;
      top: 1px;
      z-index: 2;
    }
    .index-area {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      color: #EEEEEE;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background: $text-color-grey;
      margin-right: 10px;
    }
  }
  .step.line {
    flex: 1;
    height: 4px;
    border-radius: 2px;
    background: #E6E6E6;
    position: relative;
    &.active {
      background: $base-color;
      .index-area {
        background: $base-color;
        color: $text-color-black;
      }
    }
    .index-area {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      top: -13px;
      background: #E6E6E6;
      color: $text-color-grey;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    .desc-area {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
