<template>
  <div>
    <div class="header-area">{{$t('titles.myCertificate')}}</div>
    <div class="info-area">
      <ul class="list" v-if="list.length > 0">
        <li v-for="(item, index) in list" :key="index">
          <div class="img-area" @click="toPreviewImage(item)">
            <img :src="item" alt="" />
          </div>
          <div class="btn-area">
            <el-button type="" @click="toDownload(item)">{{$t('common.downloadOriginal')}}</el-button>
          </div>
          <!-- <i class="iconfont new">&#xe6a8;</i> -->
        </li>
      </ul>
      <the-empty :text="$t('pages.personal.certificatesEmpty')" v-else />
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCertificateList } from "@/api/common";
import TheEmpty from "../../components/TheEmpty/TheEmpty.vue";
export default {
  components: { TheEmpty },
  data() {
    return {
      list: [],
      total: 0,
      page: 1,
      pageSize: 9,
    };
  },
  methods: {
    toDownload(item) {
      let a = document.createElement("a");
      a.href = item;
      a.download = "certificate";
      //a.click();
      //下面这个写法兼容火狐
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    toPreviewImage(index) {
      this.$imgPreview({
        imgs: this.list,
        index,
      });
    },
    async getData() {
      try {
        const res = await selectMyCertificateList({
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .list {
    font-size: 0;
    margin-left: -35px;
    li {
      display: inline-block;
      margin-left: 35px;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      .img-area {
        width: 270px;
        height: 188px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .btn-area {
        margin-top: 30px;
      }
      .iconfont {
        font-size: 30px;
        position: absolute;
        top: 0;
        right: 0;
        color: #ff4c4c;
        line-height: 1;
        user-select: none;
      }
    }
  }
}
</style>