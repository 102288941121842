import axios from './index';
// =========================== 通用接口 ===========================
// 查询系统是局域网部署还是互联网部署
export const selectSysEnv = (data) => {
  return axios.request({
    url: '/ruihe/common/getRunWork',
    data,
    method: 'get',
  });
};
// 文件上传
export const uploadFile = (data) => {
  return axios.request({
    url: '/file/pcUpload',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
  });
};
export const donwnloadZip = (data) => {
  return axios.request({
    url: '/ruihe/file/downloadZip',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'get',
  });
};
// 获取图形验证码
export const selectImgVcode = (data) => {
  return axios.request({
    url: '/code',
    data,
    method: 'get',
  });
};
// 校验图形验证码
export const checkImgVcode = (data) => {
  return axios.request({
    url: '/ruihe/common/checkCode',
    data,
    method: 'post',
  });
};
// 查询学历字典列表
export const selectEduOptionList = (data) => {
  return axios.request({
    url: '/ruihe/common/queryEducation',
    data,
    method: 'get',
  });
};
// 查询地区字典列表
export const selectAreaList = (data) => {
  return axios.request({
    url: '/ruihe/common/queryRegionCity',
    data,
    method: 'get',
  });
};
// 查询行业字典列表
export const selectIndustryList = (data) => {
  return axios.request({
    url: '/ruihe/common/queryBusiness',
    data,
    method: 'get',
  });
};
// 查询课程分类字典列表
export const selectCategoryList = (data) => {
  return axios.request({
    url: '/ruihe/common/queryCourseTypeDict',
    data,
    method: 'get',
  });
};
// =========================== 授权相关接口 ===========================
// 获取绑定用微信二维码
export const selectWechatQrcode = (data) => {
  return axios.request({
    url: '/ruihe/wx/getWechatImg',
    data,
    method: 'get',
  });
};
// 获取登陆用微信二维码
export const selectWechatQrcodeForLogin = (data) => {
  return axios.request({
    url: '/ruihe/wx/scanWechatImg',
    data,
    method: 'get',
  });
};
// 查询扫码结果
export const selectScanQrcodeResult = (data) => {
  return axios.request({
    url: '/ruihe/wx/chackIfWeixinLogin',
    data,
    method: 'post',
    showLoading: false,
  });
};
// 获取短信/邮箱验证码
export const selectVcode = (data) => {
  return axios.request({
    url: '/ruihe/common/sendCode',
    data,
    method: 'post',
  });
};
// 获取注册协议
export const selectServiceAgreement = (data) => {
  return axios.request({
    url: '/ruihe/webProtocol/queryProtocols',
    data,
    method: 'post',
  });
};
// 用户名查重
export const checkUsername = (data) => {
  return axios.request({
    url: '/ruihe/webUser/checkAccount',
    data,
    method: 'post',
  });
};
// 学员注册
export const insertMember = (data) => {
  return axios.request({
    url: '/ruihe/webUser/register',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构注册
export const insertOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgRegister',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 查询机构列表
export const selectOrgList = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/selectReg',
    data,
    method: 'post',
  });
};
// 用户登录
export const login = (data) => {
  return axios.request({
    url: '/auth/pLogin',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 退出登录
export const logout = (data) => {
  return axios.request({
    url: '/auth/pLogout',
    data,
    method: 'delete',
  });
};
// 修改密码
export const updatePassword = (data) => {
  return axios.request({
    url: '/ruihe/webUser/updatePassword',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// =========================== 个人中心接口 ===========================
// 查询调查问卷列表
export const selectPersonalHomeData = (data) => {
  return axios.request({
    url: '/ruihe/webUserIndex/initUserIndex',
    data,
    method: 'post',
  });
};
// 查询调查问卷列表
export const selectSurveyList = (data) => {
  return axios.request({
    url: '/ruihe/webQuestion/queryQuestions',
    data,
    method: 'post',
  });
};
// 提交调查问卷答案
export const updateSurvey = (data) => {
  return axios.request({
    url: '/ruihe/webQuestion/answerQuestion',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 修改用户基本信息
export const updateUserinfoBase = (data) => {
  return axios.request({
    url: '/ruihe/webUser/editUser',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 解绑微信
export const unbindWechat = (data) => {
  return axios.request({
    url: '/ruihe/webUser/delUserWxInfo',
    data,
    method: 'post',
  });
};
// 修改用户手机号或电子邮箱
export const updateUserinfoTelOrEmail = (data) => {
  return axios.request({
    url: '/ruihe/webUser/updatePhoneEmail',
    data,
    method: 'post',
    transMethod: 'json',
  });
};

// 添加讲师标签
export const AddUserTag = (data) => {
  return axios.request({
    url: '/ruihe/webTeacher/addLabal',
    data,
    method: 'post',
  });
};
// 查询系统标签列表
export const selectTagList = (data) => {
  return axios.request({
    url: '/ruihe/Labal/queryLabalDtos',
    data,
    method: 'post',
  });
};
// 修改讲师信息
export const updateTeacherInfo = (data) => {
  return axios.request({
    url: '/ruihe/webTeacher/updateTeacherSpecialty',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 查询机构内容非联系人成员列表
export const selectOrgMemberWithoutLeader = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryNoLeaders',
    data,
    method: 'post',
  });
};
// 查询机构或部门负责人信息
export const selectOrgLeader = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryLeaderById',
    data,
    method: 'post',
  });
};
// 班主任通知学员报道
export const insertNoticeToStudent = (data) => {
  return axios.request({
    url: '/ruihe/webUser/askRegister',
    data,
    method: 'post',
  });
};
// 修改机构信息
export const updateOrgInfo = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/updateOrgInfo',
    data,
    method: 'post',
  });
};
// 获取机构架构信息
export const selectOrgStructureInfo = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/selectOwnOrgTree',
    data,
    method: 'post',
  });
};
// 获取机构成员信息
export const selectMemberInfoByOrgId = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryUsersByDept',
    data,
    method: 'post',
  });
};
// 将成员移除机构
export const kickoutMember = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/moveUserById',
    data,
    method: 'post',
  });
};
// 删除成员
export const deleteMember = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/deletUserById',
    data,
    method: 'post',
  });
};
// 修改机构信息
export const updateDeptInfo = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/updateOrgDeptInfo',
    data,
    method: 'post',
  });
};
// 新增部门
export const addDeptInfo = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/addNewOrgDept',
    data,
    method: 'post',
  });
};
// 新增部门
export const deleteDeptInfo = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/deleteOrgDept',
    data,
    method: 'post',
  });
};
// 获取banner
export const selectBannerList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/slides',
    data,
    method: 'post',
  });
};
// 我的收藏
// productType: 收藏的类型（0课程，1课程计划,2课程预约，3讲师）
export const selectMyCollList = (data) => {
  return axios.request({
    url: '/ruihe/silimar/showCollects',
    data,
    method: 'post',
  });
};
// 我的评论
// bodyType: 评价类型（0课程，1课程计划）
export const selectMyCommentList = (data) => {
  return axios.request({
    url: '/ruihe/silimar/showAppraiseMsg',
    data,
    method: 'post',
  });
};
// 我的预约
export const selectMySubscribeList = (data) => {
  return axios.request({
    url: '/ruihe/silimar/showApoints',
    data,
    method: 'post',
  });
};
// 我的订单
export const selectMyOrderList = (data) => {
  return axios.request({
    url: '/ruihe/order/orderList',
    data,
    method: 'post',
  });
};
// 申请发票
export const insertInvoiceData = (data) => {
  return axios.request({
    url: '/ruihe/order/commitInvoice',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 查询发票相关信息
export const selectInvoiceData = (data) => {
  return axios.request({
    url: '/ruihe/order/invoiceDetail',
    data,
    method: 'post',
  });
};
// 申请退款
export const insertRefundData = (data) => {
  return axios.request({
    url: '/ruihe/order/commitRefund',
    data,
    method: 'post',
  });
};
// 获取退款信息
export const selectRefundData = (data) => {
  return axios.request({
    url: '/ruihe/order/queryRefund',
    data,
    method: 'post',
  });
};
// 取消退款
export const deleteRefundData = (data) => {
  return axios.request({
    url: '/ruihe/order/cancelRefundOrder',
    data,
    method: 'post',
  });
};
// 取消订单
export const deleteOrder = (data) => {
  return axios.request({
    url: '/ruihe/order/cancelOrder',
    data,
    method: 'post',
  });
};
// 发表评价
// bodyId: 产品id（课程id,课程计划id,或者课程打包订单里面的课程id）
// bodyType: 产品类别，订单列表中的productType(0课程，1课程计划，2课程打包)
export const insertCommentData = (data) => {
  return axios.request({
    url: '/ruihe/order/appraiseMsg',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构学习统计
export const selectStatisticsOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/Study/showOrgCourseCor',
    data,
    method: 'post',
  });
};
// 课程需求列表
export const selectDemandList = (data) => {
  return axios.request({
    url: '/ruihe/silimar/showOwnDemands',
    data,
    method: 'post',
  });
};
// 新增课程需求
export const insertDemandData = (data) => {
  return axios.request({
    url: '/ruihe/silimar/commitRemand',
    data,
    method: 'post',
  });
};
// 获取视频上传参数
export const selectVideoUploadParam = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/createUploadVideo',
    data,
    method: 'post',
  });
};
// 刷新视频上传参数
export const updateVideoUploadParam = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/refreshUploadVideo',
    data,
    method: 'post',
  });
};
// 去学习
export const selectCourseOrCoursePlanDetail = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/toStudy',
    data,
    method: 'post',
  });
};
// 获取视频播放参数
export const selectVideoPlayParam = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/getPlayAuth',
    data,
    method: 'post',
  });
};
// 我的笔记 列表
export const selectMyNoteList = (data) => {
  return axios.request({
    url: '/ruihe/Study/showMyNotes',
    data,
    method: 'post',
  });
};
// 我的笔记 新增
export const insertMyNoteData = (data) => {
  return axios.request({
    url: '/ruihe/Study/addMyNote',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 我的笔记 修改
export const updateMyNoteData = (data) => {
  return axios.request({
    url: '/ruihe/Study/editMyNote',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 我的笔记 删除
export const deleteMyNoteData = (data) => {
  return axios.request({
    url: '/ruihe/Study/delMyNote',
    data,
    method: 'post',
  });
};
// 我的问答 列表
export const selectQAList = (data) => {
  return axios.request({
    url: '/ruihe/Study/showCourseQuizs',
    data,
    method: 'post',
  });
};
// 我的问答 新增
export const insertQAData = (data) => {
  return axios.request({
    url: '/ruihe/Study/addMyQuiz',
    data,
    method: 'post',
    transMethod: 'json',
  });
};

// 我的问答 待回答问题列表
export const selectQAListOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/Study/queryNeedAnswers',
    data,
    method: 'post',
  });
};
// 我的问答 回答问题
export const UpdateQAOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/Study/answerQuiz',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 我的问答 忽略问题
export const deleteQAOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/Study/ignoreQuiz',
    data,
    method: 'post',
  });
};
// 我的问答 置顶/取消置顶
export const updateQAStatusOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/Study/topCancelQuiz',
    data,
    method: 'post',
  });
};
// 课程需求管理
export const selectDemandListForManage = (data) => {
  return axios.request({
    url: '/ruihe/silimar/showOrgDemandsCount',
    data,
    method: 'post',
  });
};
// 机构轮播图管理-新增
export const insertBannerFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/Silde',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构轮播图管理-列表
export const selectOrgBannerList = (data) => {
  return axios.request({
    url: '/ruihe/Silde/mySildes',
    data,
    method: 'post',
  });
};
// 机构轮播图管理-修改
export const updateBannerFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/Silde',
    data,
    method: 'put',
    transMethod: 'json',
  });
};
// 机构轮播图管理-修改可见状态
export const updateBannerStatusFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/Silde/updateShow',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构轮播图管理-删除
export const deleteBannerFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/Silde/delete',
    data,
    method: 'post',
  });
};
// 机构视频库 列表
export const selectVideoOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/showOrgVideos',
    data,
    method: 'post',
  });
};
// 机构视频库 删除
export const deleteVideoFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/clearVideo',
    data,
    method: 'post',
  });
};
// 机构视频库 删除
export const updateVideoTitleFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/updateVodVideo',
    data,
    method: 'post',
  });
};
// 机构视频库 局域网添加视频
export const insertLocalVideo = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/createLocalVideo',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构视频库 局域网编辑视频
export const updateLocalVideo = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/editLocalVideo',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程/课程计划/新闻 取消推荐
// deleteId 被删除的对象id
// recommendType 推荐类型 0: 课程, 1: 课程计划, 2: 课程预约, 3: 新闻
export const deleteRecommendItem = (data) => {
  return axios.request({
    url: '/ruihe/Recommend/orgDelete',
    data,
    method: 'post',
  });
};
// 机构课程 新增
export const insertCourseFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/commitOrgCourse',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 推荐
export const updateCourseRecommendStatus = (data) => {
  return axios.request({
    url: '/ruihe/Course/recommendCourse',
    data,
    method: 'post',
  });
};
// 机构课程 查询
export const selectCourseOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryOrgCourses',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 详情
export const selectCourseDetailOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCourseDetail',
    data,
    method: 'post',
  });
};
// 机构课程 修改防作弊状态
export const updateCourseAntiCheatingStatus = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/antiCheatingCourse',
    data,
    method: 'post',
  });
};
// 机构课程 VOD上传初始化信息
export const selectVodInitData = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/getVodInfo',
    data,
    method: 'post',
  });
};
// 机构课程 关联视频
// export const updateCourseVideo = (data) => {
//   return axios.request({
//     url: '/ruihe/videoVod/bindCourseVideo',
//     data,
//     method: 'post',
//   });
// };
// 机构课程 编辑
export const updateCourseInfoOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCourseEdit',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 删除
export const deleteCourseOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCourseRemove',
    data,
    method: 'post',
  });
};
// 机构课程 随机组卷
export const insertPaperRandomFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/randomBankCreateExam',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 组卷
export const insertPaperFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/BankCreateExam',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 试卷详情
export const selectPaperDetailOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/silimar/queryPaperBanks',
    data,
    method: 'post',
  });
};
// 机构课程 交卷
export const insertExamData = (data) => {
  return axios.request({
    url: '/ruihe/silimar/commitPaper',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 学习记录
export const selectStudyRecordOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/silimar/courseStudyDetail',
    data,
    method: 'post',
  });
};
// 机构课程 记录视频播放进度
export const insertStudyRecordData = (data) => {
  return axios.request({
    url: '/ruihe/videoVod/pcEditUserStudy',
    data,
    method: 'post',
  });
};
// 机构课程计划 新增
export const insertCoursePlanFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/commitOrgCoursePlan',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程计划 推荐
export const updateCoursePlanRecommendStatus = (data) => {
  return axios.request({
    url: '/ruihe/CoursePlan/recommendCourse',
    data,
    method: 'post',
  });
};
// 机构课程计划 查询可用课程列表
export const selectCourseForPlan = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryOrgPlanCourseLie',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程计划 绑定课程
export const bindCourseForCoursePlan = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/BindCourses',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程计划 列表
export const selectCoursePlanOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/queryOrgCoursePlans',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程计划 详情
export const selectCoursePlanDetailOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCoursePlanDetail',
    data,
    method: 'post',
  });
};
// 机构课程计划 修改
export const updateCoursePlanOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCoursePlanEdit',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 机构课程 删除
export const deleteCoursePlanOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgCoursePlanRemove',
    data,
    method: 'post',
  });
};
// 试题管理 新增
export const insertQuestionFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/commitOrgQuestionBank',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 试题管理 列表
export const selectQuestionListOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgQuestionBanksList',
    data,
    method: 'post',
  });
};
// 试题管理 详情
export const selectQuestionDetailOfOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgQuestionBanksDetail',
    data,
    method: 'post',
  });
};
// 试题管理 删除
export const deleteQuestionFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/orgBankRemove',
    data,
    method: 'post',
  });
};
// 试题管理 修改
export const updateQuestionFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/webOrg/editOrgQuestionBank',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 错题管理 列表
export const selectWrongSetFromOrg = (data) => {
  return axios.request({
    url: '/ruihe/silimar/orgErrorList',
    data,
    method: 'post',
  });
};
// =========================== 一、二级页面接口 ===========================
// 获取首页信息
export const selectIndexData = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/initIndex',
    data,
    method: 'post',
  });
};
// 获取首页信息
export const selectServiceList = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/queryServiceInfos',
    data,
    method: 'post',
  });
};
// 首页意见反馈
export const insertFeedbackData = (data) => {
  return axios.request({
    url: '/ruihe/common/feedback',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 获取课程专区列表
export const selectCourseZoneList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/queryCourse',
    data,
    method: 'post',
  });
};
// 获取定制课程列表
export const selectCustomCourseList = (data) => {
  return axios.request({
    url: '/ruihe/order/queryCourse',
    data,
    method: 'post',
  });
};
// 获取课程专区详情
export const selectCourseZoneDetail = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/courseDetail',
    data,
    method: 'post',
  });
};
// 课程试听
export const selectCourseTryData = (data) => {
  return axios.request({
    url: '/ruihe/Video/tryVideo',
    data,
    method: 'post',
  });
};
// 课程试听
export const selectCourseTryList = (data) => {
  return axios.request({
    url: '/ruihe/Video/myTryVideos',
    data,
    method: 'post',
  });
};
// 获取课程计划列表
export const selectCoursePlanList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/queryCoursePlan',
    data,
    method: 'post',
  });
};
// 获取课程计划详情
export const selectCoursePlanDetail = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/coursePlanDetail',
    data,
    method: 'post',
  });
};
// 获取课程预约列表
export const selectCourseSubscribeList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/queryCourseApoint',
    data,
    method: 'post',
  });
};
// 获取课程预约详情
export const selectCourseSubscribeDetail = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/courseApointDetail',
    data,
    method: 'post',
  });
};
// 预约/取消预约
export const updateSubscribeStatus = (data) => {
  return axios.request({
    url: '/ruihe/silimar/apointed',
    data,
    method: 'post',
  });
};
// 获取讲师风貌列表
export const selectLecturerList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/queryTeachers',
    data,
    method: 'post',
  });
};
// 获取讲师风貌详情
export const selectLecturerDetail = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/teacherDetail',
    data,
    method: 'post',
  });
};
// 消息 列表
export const selectMessageList = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/showMyMsgs',
    data,
    method: 'post',
  });
};
// 消息 新增
export const insertMessageData = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/commitMsg',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 消息 未读数量
export const selectMessageUnreadCount = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/checkNotRead',
    data,
    method: 'get',
  });
};
// 新闻模块 列表
export const selectNewsList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/queryNews',
    data,
    method: 'post',
  });
};
// 新闻模块 详情
export const selectNewsDetail = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/newsDetail',
    data,
    method: 'post',
  });
};
// 新闻 列表
export const selectMyNewsList = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/showNews',
    data,
    method: 'post',
  });
};
// 新闻 新增
export const insertNewsData = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/commitNews',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 新闻 编辑
export const updateNewsData = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/editNews',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 新闻 切换是否推荐
export const updateNewsStatus = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/recommendNews',
    data,
    method: 'post',
  });
};
// 新闻 删除
export const deleteNewsData = (data) => {
  return axios.request({
    url: '/ruihe/newMsgs/deleteNews',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 收藏/取消收藏
// productId: 	产品id
// productType: 产品类型（0课程，1课程计划，2课程预约，3讲师）
export const updateCollStatus = (data) => {
  return axios.request({
    url: '/ruihe/silimar/colledted',
    data,
    method: 'post',
  });
};
// 获取评论
// bodyId: 被评论的主体id(课程id, 课程计划id, 订单id)
// bodyType: 主体类别（0课程，1课程计划, 2订单）
export const selectCommentList = (data) => {
  return axios.request({
    url: '/ruihe/webConfig/showAppraise',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 提交定制需求
export const insertCustomizationData = (data) => {
  return axios.request({
    url: '/ruihe/silimar/commitCustomization',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 搜索热词
export const selectHotWords = (data) => {
  return axios.request({
    url: '/ruihe/elasticSearch/queryHotWords',
    data,
    method: 'post',
  });
};
// 全局搜索
export const elasticSearch = (data) => {
  return axios.request({
    url: '/ruihe/elasticSearch/queryHits',
    data,
    method: 'post',
  });
};
// 同步搜索库
export const syncSearch = (data) => {
  return axios.request({
    url: '/ruihe/elasticSearch/bulkDocuments',
    data,
    method: 'post',
  });
};
// 我的课程、课程计划
export const selectMyCourseOrCoursePlan = (data) => {
  return axios.request({
    url: '/ruihe/silimar/myCoursesOrPlans',
    data,
    method: 'post',
  });
};
// 我的错题集
export const selectMyWrongSet = (data) => {
  return axios.request({
    url: '/ruihe/silimar/myErrorList',
    data,
    method: 'post',
  });
};
// 我的证书
export const selectMyCertificateList = (data) => {
  return axios.request({
    url: '/ruihe/silimar/myCertificates',
    data,
    method: 'post',
  });
};
// =========================== 培训计划相关接口 ===========================
// 受众群体列表
export const selectOrgTrainTargetList = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/audiencelist',
    data,
    method: 'get',
  });
};
// 添加受众群体
export const insertOrgTrainTarget = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/addAudiences',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 修改受众群体
export const updateOrgTrainTarget = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/editAudiences',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 删除受众群体
export const deleteOrgTrainTarget = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/delAudiences',
    data,
    method: 'post',
  });
};
// 培训类型列表
export const selectOrgTrainTypeList = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/trainTypelist',
    data,
    method: 'get',
  });
};
// 添加培训类型
export const insertOrgTrainType = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/addTrainType',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 修改培训类型
export const updateOrgTrainType = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/editTrainType',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 删除培训类型
export const deleteOrgTrainType = (data) => {
  return axios.request({
    url: '/ruihe/webAudTrain/delTrainTypes',
    data,
    method: 'post',
  });
};
// 培训计划列表
export const selectOrgTrainPlanList = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/trainPlanList',
    data,
    method: 'get',
  });
};
// 添加培训计划
export const insertOrgTrainPlan = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/addTrainPlan',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 修改培训计划
export const updateOrgTrainPlan = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/editTrainPlan',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 删除培训计划
export const deleteOrgTrainPlan = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/delTrainPlans',
    data,
    method: 'post',
  });
};
// 培训列表
export const selectOrgTrainList = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/trainList',
    data,
    method: 'get',
  });
};
// 添加培训
export const insertOrgTrain = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/addTrain',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 修改培训
export const updateOrgTrain = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/editTrain',
    data,
    method: 'post',
    transMethod: 'json',
  });
};
// 删除培训
export const deleteOrgTrain = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/delTrains',
    data,
    method: 'post',
  });
};
// 查询平台和机构课程列表
export const selectAllCourseList = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/queryTrainCourses',
    data,
    method: 'get',
  });
};
// 查询课程计算数据
export const selectCourseCalcData = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/countResource',
    data,
    method: 'get',
  });
};
// 查询培训进度
export const selectTrainProgressData = (data) => {
  return axios.request({
    url: '/ruihe/webTrain/getTraindSchedule',
    data,
    method: 'get',
  });
};
// =========================== 购买相关接口 ===========================
// 获取预订单数据
// orderType: 	商品类别（0课程，1课程计划，2课程打包）
// productIds: 产品id逗号分割（课程打包存在多个）
// payRole: 购买角色（0散户，1机构学员，2班主任，3小组长，4讲师）
export const selectPreOrderData = (data) => {
  return axios.request({
    url: '/ruihe/order/preparOrder',
    data,
    method: 'post',
  });
};
// 提交订单
export const insertOrder = (data) => {
  return axios.request({
    url: '/ruihe/order/commitOrder',
    data,
    method: 'post',
  });
};
// 提交订单
export const selectOrderInfo = (data) => {
  return axios.request({
    url: '/ruihe/order/queryOrderInfo',
    data,
    method: 'post',
  });
};
// 微信支付
export const wechatPay = (data) => {
  return axios.request({
    url: '/ruihe/wx/nativePay',
    data,
    method: 'post',
  });
};
// 支付宝支付
export const aliPay = (data) => {
  return axios.request({
    url: '/ruihe/ali/nativePay',
    data,
    method: 'post',
  });
};
// 轮询支付状态
export const selectPayStatus = (data) => {
  return axios.request({
    url: '/ruihe/order/chackIfPayDone',
    data,
    method: 'post',
  });
};
// 兑换码兑换
export const useRedeemCode = (data) => {
  return axios.request({
    url: '/ruihe/silimar/redeemCode',
    data,
    method: 'post',
  });
};
