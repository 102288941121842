<template>
  <div>
    <div class="header-area">评价的新闻</div>
    <div class="info-area">
      <ul class="list" v-loading="isLoading">
        <li v-for="i in 4" :key="i">
          <div class="left">
            <el-avatar
              :size="40"
              :src="require('@/assets/images/temp/img-lecturer.png')"
            />
          </div>
          <div class="right">
            <div class="content-area">
              <p class="content">
                此处为评价内容，此处为评价内容。此处为评价内容。此处为评价内容，此处为评价内容。此处为评价内容。此处为评价内容，此处为评价内容。此处为评价内容。此处为评价内容，此处为评价内容。此处为评价内容。
              </p>
              <div class="create-time">2021-02-10 16:30</div>
            </div>
            <div class="news-area" @click="toDetail(i)">
              <div class="img-area">
                <img src="@/assets/images/temp/img-course-plan01.png" alt="" />
              </div>
              <div class="news-info">
                <div class="title">
                  每周资讯-全球金融监管动态（2021.01.18-2021.01.24）RegHub
                  锐合新创
                </div>
                <div class="desc">
                  加强对非银行支付机构的监督管理，规范非银行支付机构行为，防范支付风险，保障当事人合法权益.
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="pagination-area">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    toDetail(i) {
      // this.$router.push(`/course-zone/detail/${i}`)
      console.log(i);
    },
    async getData(callback) {
      this.isLoading = true;
      setTimeout(() => {
        this.list = [];
        this.isLoading = false;
        callback && callback();
      }, 500);
    },
  },
  mounted() {
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .list {
    li {
      padding-top: 24px;
      padding-bottom: 12px;
      display: flex;
      .left {
        margin-right: 20px;
        display: flex;
      }
      .right {
        padding-top: 9px;
        flex: 1;
        min-width: 0;
        .content-area {
          display: flex;

          .content {
            flex: 1;
            min-width: 0;
            margin-right: 20px;
            font-size: 16px;
            margin-bottom: 15px;
          }
          .create-time {
            font-size: 14px;
            color: $text-color-grey;
          }
        }
        .news-area {
          background: #f7f7f7;
          padding: 14px 20px;
          display: flex;
          margin-left: 30px;
          cursor: pointer;
          .img-area {
            width: 78px;
            height: 50px;
            margin-right: 23px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .news-info {
            flex: 1;
            min-width: 0;
            .title {
              font-size: 14px;
            }
            .desc {
              color: $text-color-grey;
            }
          }
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>