import enLocale from 'element-ui/lib/locale/lang/en';
import index from './pages/index/en';
import login from './pages/login/en';
import personal from './pages/personal/en';
import other from './pages/other/en';
const en = {
  common: {
    placeHolder_Please_question_stem: 'Enter the question stem',
    placeHolder_select: 'Please select',
    placeHolder_enter: 'Please enter',
    placeHolder_upload: 'Please upload',
    login: 'Sign in',
    register: 'Register',
    keywords: ' Keywords',
    study: 'Study',
    learnNow: 'Learn Now',
    buyNow: 'Buy Now',
    collected: 'collected',
    collect: 'collect',
    continue: 'Continue ',
    buy: 'Buy',
    own: 'own',
    day: 'Day',
    try: 'Try',
    trying: 'Trying',
    tryCount: 'try Count',
    noData: 'No Data',
    submit: 'Submit',
    resubmit: 'Resubmit',
    confirm: 'OK',
    cancel: 'Cancel',
    close: 'Close',
    courseCategory: 'Course Category',
    applicableArea: 'Applicable Area',
    applicableIndustry: 'Applicable Industry',
    all: 'All',
    haveNotStarted: 'Have Not Started',
    learning: 'Learning',
    waitingExamination: 'Waiting Examination',
    makeUpExamination: 'Make-up',
    completed: 'completed',
    unfinished: 'unfinished',
    toSeeAll: 'To See All',
    toSee: 'to See',
    questions: 'Questions',
    next: 'Next',
    upload: 'Upload',
    uploadV: 'Upload',
    video: 'video',
    sec: 'Sec',
    min: 'Minutes',
    time: 'Time',
    format: 'Format: ',
    formatWrong: 'Wrong Format',
    size: 'Size',
    overSize: 'Over Szie',
    enter: 'Enter',
    save: 'Save',
    delete: 'Delete',
    post: 'Post',
    exports: 'Exports',
    imports: 'Imports',
    downloadTheImportTemplate: 'Download the import template',
    download: 'download',
    downloadOriginal: 'download Original',
    weight: 'Weight',
    tel: 'Tel',
    account: 'Account',
    companyName: 'Company Name',
    name: 'Name',
    name2: 'Name',
    email: 'Email',
    bindEmail: 'Bind Email',
    operation: 'Operations',
    choose: 'Choose',
    status: 'Status',
    detail: 'Detail',
    remove: 'Remove',
    edit: 'Edit',
    add: 'Add',
    prompt: 'Prompt',
    dept: 'Department',
    modify: 'Modify',
    groupLeader: 'Group Leader',
    success: 'Operation is successful',
    error: 'Operation is failure',
    modifyConfirm: 'Confirm the modification?',
    deleteConfirm: 'Are you sure you want to delete it?',
    cancelConfirm: 'Are you sure you want to cancel?',
    title: 'Title',
    topic: 'Topic',
    cover: 'Cover',
    category: 'Category',
    genre: 'Genre',
    questionTypes: 'QuestionTypes',
    tag: 'Tag',
    createTime: 'Create Time',
    submitTime: 'Submit Time',
    lastTime: 'Last',
    broadcastTime: 'Broadcast Time',
    content: 'Content',
    sort: 'Sort',
    send: 'Send',
    more: 'More',
    correct: 'Correct',
    incorrect: 'Incorrect',
    errorRate: 'errorRate',
    sex: 'sex',
    man: 'man',
    woman: 'woman',
    answers: 'Answers',
    price: 'price',
    select: 'selected',
  },
  titles: {
    index: 'Home',
    login: 'Sign in',
    reset: 'Reset Password',
    register: 'Register',
    binding: 'Bind Wechat',
    resetWx: 'Reset Wechat',
    removeWx: 'Unbind Wechat',
    wx: 'Wechat',
    bindTel: 'bindTel',
    survey: 'Demand Survey',
    course: 'Courses',
    courseHot: 'Course Hot',
    courseZone: 'Courses',
    courseZoneName: 'Name CourseZone',
    courseZoneDetail: 'Course Detail',
    coursePlan: 'Packages',
    coursePlanDetail: 'Packages Detail',
    courseCustomization: 'Course Customization',
    curriculumHighlights: 'Curriculum Highlights',
    teachingObject: 'Teaching Object',
    prepareData: 'Prepare Data',
    lecturerInfo: 'Lecturers Info',
    lecturerName: 'Lecturers Name',
    lecturers: 'Lecturers',
    adept: 'Adept',
    buyAgain: 'Buy Again',
    myHome: 'Home',
    myCourse: 'My Course',
    CourseList: 'List Course',
    AlreadyBought: ' already bought',
    myCoursePlan: 'My Packages',
    myNewsList: 'News',
    myBannerList: 'Banners',
    orgVideoList: 'Video Library',
    orgCourseList: "Institutions' Courses",
    orgCoursePlanList: "Institutions' Packages",
    myQa: 'Q&A',
    orgStatistics: 'Statistical Learning',
    StatusStudy: 'Status Study',
    orgQuestionList: 'Questions',
    orgWrongSet: 'Wrong Set',
    myTry: 'My Try',
    myQuestionList: 'My Q&A',
    myNoteList: 'My Note',
    LookOverNoteList: 'LookOverNote',
    myCertificate: 'My Certificates',
    myWrongSet: 'My WrongSet',
    courseSubscribe: 'Course Subscribes',
    courseSubscribeDetail: 'Course Subscribe Detail',
    courseStudy: 'Course Study',
    exam: 'Exam',
    lecturer: 'Lecturers',
    lecturerDetail: 'Lecturers Detail',
    confirmOrder: 'Order Confirm',
    orderPay: 'Pay',
    orderResult: 'Pay Result',
    refund: 'Refund',
    news: 'News',
    newsDetail: 'News Detail',
    myInfo: 'My Info',
    myOrders: 'My Order',
    myMessage: 'Messages',
    myDemand: 'My Demand',
    myDemandManage: 'Demand Manage',
    mySubscribe: 'My Subscribes',
    myCollNews: 'Favorite News',
    myCollTeacher: 'Favorite Lecturers',
    myCollCourse: 'Favorite Course',
    courseZoneEvaluate: 'EvaluateCourse',
    myCollCoursePlan: 'FavoritePlan',
    myCommentNews: 'Comment For News',
    myCommentCourse: 'Comment For Course',
    myCommentCoursePlan: 'Comment For Packages',
    mySurvey: 'Survey',
    resetTel: 'Reset Phone Number',
    resetEmail: 'Reset Email',
    bindEmail: 'Bind Email',
    orgManage: "Institutions' Members",
    teacherInfo: "Lecturer's Info",
    orgInfo: "Institutions' Info",
    orgTypeList: 'The training type',
    orgTargetList: 'Target Audience ',
    orgPlanList: 'Training Plan',
    orgTrainList: 'Training',
    analysis: 'Analysis',
  },
  pages: {
    index,
    login,
    personal,
    other,
  },
  header: {
    index: 'Home',
    course_zone: 'Courses',
    course_plan: 'Packages',
    lecturer: 'Lecturers',
    news: 'News',
    placeHolder_input: 'Input course / train name',
  },
  footer: {
    about_us: 'ABOUT US',
    about_us_content:
      'RegHub New Technology (RegHub) is a firm that integrates compliance and risk advisory services with regulatory technology solutions. ',
    contacts: 'CONTACT US',
    tel: '010-59576028(Monday to Friday 9:30-19:30)',
    location:
      'Unit 20919, Floor 9, Tower B, Galaxy SOHO,Dong Cheng District, Beijing, P.R. China',
    location2:
      'A17, 8th floor, Wannian industrial building, 116-118 Qiaoming street, Kwun Tong, Kowloon, Hong Kong',
    follow_us: 'FOLLOW US',
    follow_us_wechat: 'Follow our Official Account on Wechat',
    follow_us_linkedin: 'Follow our Company Page on Linkedin',
  },
  ...enLocale,
};

export default en;
