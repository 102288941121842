<template>
  <div class="course-zone">
    <!-- banner -->
    <the-banner type="5" />
    <!-- 筛选 -->
   <the-filter @change="handleFilte" />
    <!-- 列表 -->
    <div class="list-container">
      <ul class="list" v-if="list.length > 0">
        <li v-for="item in list" :key="item.id">
          <router-link :to="`/course-subscribe-detail/${item.courseApointId}`">
            <div class="img-area">
              <img :src="item.courseApointCoverUrl" :alt="item.courseApointName">
              <!-- <div class="btn subscribe">
                <i class="iconfont">&#xe613;</i>
                <span class="txt">预约</span>
              </div> -->
            </div>
            <div class="middle-area">
              <div class="title-area">
                <span class="text">{{ item.courseApointName }}</span>
                <!-- <price-num :val="item.courseApointPrice" color="#FF4C4C" :signSize="14" :intSize="26" :pointSize="26" :decSize="26" :showDec="false" /> -->
              </div>
              <div class="start-time">{{ $t('pages.other.kaikeshijian') }}：{{ item.courseStartTime }} </div>
              <div class="extra">
                <span class="teacher-info">
                  <el-avatar :size="40" :src="item.teacherHeadImg" />
                  <span class="name">{{ item.teacherName }}</span>
                </span>
                <span class="num-text">
                  {{ $t('pages.other.yiyou') }}<span class="num">{{ item.apointAcount }}</span>{{ $t('pages.other.renyuyue') }}
                </span>
              </div>
            </div>
            <div class="bottom-area">
              <div class="quote-area">
                <div class="text-wrap">{{ item.courseApointBright }}</div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <the-empty v-else />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { selectCourseSubscribeList } from '@/api/common';
import PriceNum from '@/components/PriceNum/PriceNum.vue';
import TheBanner from '@/components/TheBanner/TheBanner.vue';
import TheFilter from '@/components/TheFilter/TheFilter.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
export default {
  name: 'courseSubscribe',
  components: {
    PriceNum, TheBanner, TheFilter, TheEmpty
  },
  data () {
    return {
      category: '',
      area: '',
      industry: '',
      page: 1,
      pageSize: 12,
      total: 0,
      list: []
    }
  },
  methods: {
    handleFilte (res) {
      console.log(res);
      this.category = res.category;
      this.area = res.area;
      this.industry = res.industry;
      this.page = 1;
      this.list = [];
      this.getData();
    },
    async getData () {
      try {
        const res = await selectCourseSubscribeList({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          courseTypeId: this.category,
          businessId: this.industry,
          regionId: this.area
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.course-zone {
  .list-container {
    width: 1200px;
    margin: 0 auto;
    min-height: 647px;
    .list {
      margin-left: -30px;
      margin-bottom: 40px;
      padding-top: 25px;
      li {
        display: inline-block;
        width: 380px;
        height: 380px;
        margin-left: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        margin-bottom: 20px;
        vertical-align: middle;
        transition: transform .3s;
        &:hover {
          transform: scale(1.02);
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          .img-area {
            height: 150px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .btn {
              position: absolute;
              right: 10px;
              bottom: 10px;
              background: linear-gradient(0deg, #66A7FF 0%, #66A6FD 100%);
              border-radius: 3px;
              height: 26px;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #ffffff;
              padding: 0 14px;
              .iconfont {
                margin-right: 5px;
                font-size: 12px;
              }
            }
          }
          .middle-area {
            padding: 22px 0 20px;
            width: 350px;
            margin: 0 auto;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.03);
            .title-area {
              display: flex;
              align-items: center;
              .text {
                margin-right: 10px;
                flex: 1;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 20px;
                color: #333;
              }
            }
            .start-time {
              font-size: 12px;
              color: $text-color-grey;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 14px;
            }
            .extra {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .teacher-info {
                display: flex;
                align-items: center;
                .name {
                  font-size: 12px;
                  color: $text-color-grey;
                  margin-left: 15px;
                }
              }
              .num-text {
                font-size: 14px;
                color: $text-color-grey;
                .num {
                  color: #66A7FE;
                }
              }
            }
          }
          .bottom-area {
            height: 72px;
            padding: 5px;
            .quote-area {
              background: url('../../assets/images/bg-quote.png') no-repeat;
              height: 100%;
              font-size: 14px;
              color: $text-color-grey;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 38px;
              .text-wrap {
                text-align: left;
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #FFBB05;
      }
    }
  }
}
</style>
