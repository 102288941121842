export default {
  myCenter: '个人中心',
  messages: '消息中心',
  switch: '切换',
  modifyPwd: '修改密码',
  logout: '退出登录',
  // sider menu
  index: '首页',
  personal: '个人档案',
  profiles: '个人信息',
  orders: '我的订单',
  exp: '经验与专长',
  survey: '需求调研',
  learning: '学习档案',
  courses: '我的课程',
  coursePlans: '我的课程计划',
  myTry: '我的试听',
  appointments: '预约课程',
  myWrongs: '我的错题集',
  myDemands: '课程需求',
  myCertificates: '我的证书',
  myFavorite: '我的收藏',
  favCourses: '收藏课程',
  favCoursePlans: '收藏课程计划',
  favTeacher: '收藏讲师',
  favNews: '收藏新闻',
  myRating: '我的评价',
  myRatingCourses: '评价的课程',
  myRatingCoursePlans: '评价的课程计划',
  orgManage: '机构管理',
  orgInfo: '机构信息',
  orgMember: '机构人员',
  orgOrders: '机构订单',
  orgNews: '新闻管理',
  orgBanners: '轮播图管理',
  courseManage: '课程管理',
  orgVideos: '视频库',
  orgCourses: '机构课程',
  orgCoursePlans: '机构课程计划',
  qna: '课程问答',
  statistics: '学习统计',
  demandManage: '需求管理',
  testManage: '试题管理',
  wrongManage: '错题管理',
  trainManage: '培训管理',
  trainPlans: '培训计划',
  trainTarget: '目标受众',
  trainType: '培训类型',
  // 个人中心首页
  stuCourse: '学员课程',
  finishedCourse: '已完成',
  unfinishedCourse: '学习中',
  toTest: '待考试',
  makeUpTest: '待补考',
  stuCoursePlan: '学员课程计划',
  courseQna: '课程问答',
  undo: '未处理',
  done: '已处理',
  stuStatistics: '学员统计',
  countOfStu: '学员总数',
  // 调研问题
  surveyTitle1: '尊敬的用户，欢迎您使用锐课风险合规学习培训平台。 ',
  surveyTitle2:
    '在您启航前，请回答以下问题，使我们更加了解您并为您推送您专有课程、讲师和新闻。',
  prev: '上一题',
  fillInTheLater: '稍后填写',
  next: '下一题',
  finished: '完成答题',
  askSkip: '确定跳过问卷调查吗？',
  // 机构信息
  guide: '指引',
  guideText: '请在“机构人员”页面导入机构学员，导入后机构学员才能注册到本机构',
  orgName: '机构名称',
  orgAddr: '机构地址',
  orgSize: '机构规模',
  orgLicense: '营业执照',
  orgManager: '负责人',
  // 机构成员
  orgStructure: '机构架构图',
  role: '角色',
  position: '岗位',
  stuDetail: '学员详情',
  sendingSMSNotification: '发送短信通知',
  departments: '下级部门',
  canotEdit: '不能编辑子机构',
  deptDetail: '部门信息',
  sendSmsText: '要发送短信提醒学员报道吗？ ',
  plsEnterDeptName: '请输入部门名称',
  removeConfirmText1: '确定要将',
  removeConfirmText2: '移出本机构吗？',
  // 我的订单
  refundRejection: '退款拒绝',
  canceled: '已取消',
  allOrders: '全部订单',
  unpaid: '待支付',
  paid: '已支付',
  refund: '退款',
  courseName: '课程名称',
  unit: '元',
  price: '单价',
  promotionPrice: '优惠金额',
  payPrice: '实付款',
  amount: '数量',
  orderTime: '下单时间',
  orderNumber: '订单编号',
  orderPrice: '订单金额',
  comment: '发表评价',
  invoice: '发票',
  invoicing: '开发票',
  checkProgress: '开票进度',
  checkInvoice: '查看发票',
  pay: '立即支付',
  checkProcess: '查看退款进度',
  cancelOrder: '取消订单',
  cancelRefund: '取消退款',
  auditResult: '审核结果',
  toAudit: '待审核',
  pass: '审核通过',
  reject: '审核驳回',
  rejectReason: '驳回原因',
  invoiceInfo: '开票信息',
  invoiceType: '发票类型',
  vat: '增值税专票',
  invoiceAmount: '开票金额',
  titleType: '抬头类型',
  titleType1: '个人或事业单位',
  titleType2: '企业',
  title: '发票抬头',
  TFN: '税号',
  companyName: '单位名称',
  contactName: '联系人',
  depositBank: '开户银行',
  bankAccount: '银行账号',
  enterpriceAddr: '企业地址',
  enterpriceTel: '企业电话',
  rate: '课程评分',
  content: '课程评价',
  cancelRefundText: '确定要取消退款吗？',
  cancelOrderText: '确定要取消此订单吗？',
  refundAmount: '退款金额',
  refundReason: '退款原因',
  plsEnterReason: '请输入退款原因',
  refundText1: '平台处理中，请耐心等待',
  refundText2: '退款成功，支付金额已原路退回',
  // 消息中心
  sendMsg: '发布消息',
  onlyOrgMsg: '只看机构消息',
  // 机构新闻
  syncSearchLib: '同步搜索库',
  whetherHomePageDisplay: '是否首页展示',
  newsDetail: '新闻公告详情',
  // 轮播图列表
  noteText: '注: 最多可启用5张轮播图',
  banner: '轮播图',
  url: '跳转地址',
  isActived: '是否启用',
  placeHolder1: '请填写跳转地址（http(s)://***.***.***），如不需跳转则不填写',
  placeHolder2: '数字越大，排序越靠前',
  promptText: '确定要取消展示吗？',
  // 需求管理
  peopleCounting: '请求人数',
  purchased: '已购买',
  // 课程统计
  credits: '课程学分',
  duration: '时长',
  completeness: '课程完成度',
  passRate: '考试一次性通过率',
  // 课程问答
  solved: '已解答',
  unsolved: '未解答',
  question: '问题',
  questioner: '提问人',
  answer: '回答',
  ignore: '忽略',
  stick: '置顶',
  unstick: '取消置顶',
  ignoreText: '确定要忽略此提问吗？忽略后将不在列表中显示',
  // 视频库
  changeVideo: '更换视频',
  plsEnterName: '请先填写视频名称',
  description: '视频描述',
  uploadRule: '只能上传mp4文件，且大小不超过2048MB',
  editName: '修改名称',
  plsChooseFile: '请选择文件',
  // 机构课程
  orgCourse: '自有课程',
  platformCourse: '已购课程',
  antiCheating: '防作弊',
  testTimes: '考试次数',
  record: '学习记录',
  viewTest: '查看试卷',
  recommend: '推荐',
  unrecommend: '取消推荐',
  createTestPaper: '创建试卷',
  selectMethod: '选择组卷方式',
  randomly: '随机组卷',
  manually: '手动组卷',
  supplementary: '课程补购',
  certificate: '课程证书',
  courseware: '课件',
  chooseFile: '选择文件',
  chooseFileText: '支持的文件格式：pdf/word/ppt，文件大小不超过100M',
  courseVideo: '课程视频',
  chooseVideo: '选择视频',
  choose: '选择',
  testDuration: '考试时长',
  markNumText: '答题正确数量大于等于此数量，则视为考试通过',
  singleChoiceQuestion: '单选题数量',
  multipleChoiceQuestions: '多选题数量',
  trueOrFalseQuestions: '判断题数量',
  passingCount: '达标数量',
  questionList: '试题列表',
  plh_category: '请选择分类',
  plh_tag: '请选择标签',
  plh_type: '请选择题型',
  questionType: '题型',
  questionStem: '题干',
  totalCount: '试题总数',
  selectedCount: '已选试题',
  scq: '单选题',
  mcq: '多选题',
  tfq: '判断题',
  number: '个数',
  theAnswer: '答案',
  studyRecord: '学习记录',
  studyDuration: '学习时长',
  progress: '学习进度',
  numberOfTests: '已考试次数',
  numberOfRemaining: '剩余考试次数',
  recentLearningTime: '最近学习时间',
  showInHomePage: '首页推荐',
  randomlyRuleText: '达标数量不能大于试题总数',
  duplicateTag: '标签重复',
  createTestRuleText: '此课程已创建试卷，再次创建将覆盖之前的试卷！',
  // 机构课程计划
  orgCoursePlan: '自有课程计划',
  platformCoursePlan: '已购课程计划',
  relatedCourses: '关联课程',
  viewCourse: '查看课程',
  // 预约
  yy: '此预约已开课，你可以在课程专区找到它!',
  yycancel: '确定要取消预约吗？',
  // 证书
  certificatesEmpty: '暂无证书记录，快去学习吧!',
};
