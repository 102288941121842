<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.personal.orgNews') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-button
            class="my-btn"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="toAdd"
          >
            <span>{{ $t('common.add') }}</span>
          </el-button>
          <el-button
            class="my-btn"
            type="success"
            size="small"
            icon="el-icon-refresh"
            @click="toSync"
          >
            <span>{{ $t('pages.personal.syncSearchLib') }}</span>
          </el-button>
          <!-- <el-dropdown @command="changeType">
            <el-button class="my-btn" type="" size="small">
              {{ type | typeText }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="0">全部</el-dropdown-item>
              <el-dropdown-item :command="1">新闻</el-dropdown-item>
              <el-dropdown-item :command="2">公告</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('common.title')">
          <template slot-scope="scope">
            <div class="content">
              {{ scope.row.newTitle }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.personal.whetherHomePageDisplay')"
          width="250"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isRecommend"
              active-value="0"
              inactive-value="1"
              @change="
                (val) => {
                  toChangeStatus(val, scope.row);
                }
              "
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          :label="$t('common.createTime')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operation')"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="toShowDetail(scope.row, true)">{{
              $t('common.detail')
            }}</el-link>
            <el-link type="warning" @click="toShowDetail(scope.row, false)">{{
              $t('common.edit')
            }}</el-link>
            <el-link type="danger" @click="toDelete(scope.row)">{{
              $t('common.delete')
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="$t('pages.personal.newsDetail')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showDetail"
    >
      <div class="popup-content form" v-if="currentItem">
        <el-form
          ref="form"
          label-position="right"
          label-width="100px"
          :model="currentItem"
          :rules="rules"
        >
          <el-form-item :label="$t('common.title')" prop="title">
            <el-input v-model="currentItem.title" :readonly="disabled" />
          </el-form-item>
          <el-form-item :label="$t('common.cover')" prop="cover">
            <div class="photo-area">
              <div class="preveiw-area" v-if="currentItem.cover">
                <img :src="currentItem.cover" />
                <i
                  class="el-icon-error delete"
                  @click="currentItem.cover = ''"
                  v-if="!disabled"
                ></i>
              </div>
              <div class="upload-area" v-else>
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeImageUpload"
                  :on-success="
                    (res) => {
                      handleImageUploadSuccess(res, 'cover');
                    }
                  "
                >
                  <el-button class="my-btn" size="small">
                    <span>{{ $t('common.upload') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">
                 {{ $t('common.format') }}JPG、PNG、JPEG<br /> <span>( 244*165  &lt;= 500KB)</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('common.category')" prop="type">
            <el-checkbox-group v-model="currentItem.type" :disabled="disabled">
              <el-checkbox
                v-for="type in categoryOptions"
                :key="type.courseTypeId"
                :label="type.courseTypeId"
                >{{ type.courseTypeName }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('common.applicableArea')" prop="area">
            <el-select
              v-model="currentItem.area"
              filterable
              :placeholder="$t('common.placeHolder_select')"
              multiple
              style="width: 100%"
              :disabled="disabled"
            >
              <el-option
                v-for="item in areaList"
                :key="item.regionId"
                :label="item.regionName"
                :value="item.regionId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('common.applicableIndustry')"
            prop="applyBusiness"
          >
            <el-select
              v-model="currentItem.applyBusiness"
              filterable
              :placeholder="$t('common.placeHolder_select')"
              multiple
              style="width: 100%"
              :disabled="disabled"
            >
              <el-option
                v-for="item in industryList"
                :key="item.businessId"
                :label="item.businessName"
                :value="item.businessId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.sort')" prop="sort">
            <el-input type="number" v-model="currentItem.sort" :readonly="disabled" />
          </el-form-item>
          <el-form-item :label="$t('common.content')" prop="content">
            <editor v-model="currentItem.content" :disabled="disabled" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" v-loading="isSubmitting" @click="toClose">{{
          disabled ? $t('common.close') : $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL } from '@/config';
import Editor from '@/components/Editor';
import {
  selectAreaList,
  selectIndustryList,
  selectCategoryList,
  selectMyNewsList,
  insertNewsData,
  updateNewsData,
  updateNewsStatus,
  deleteNewsData,
  syncSearch,
} from '@/api/common';
export default {
  components: { Editor },
  data() {
    return {
      baseURL,
      categoryOptions: [],
      areaList: [],
      industryList: [],
      disabled: false,
      showDetail: false,
      currentItem: {
        sort: 0,
        id: '',
        title: '',
        cover: '',
        content: '',
        type: [],
        area: [],
        applyBusiness: [],
        isTop: false,
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
        cover: [
          {
            required: true,
            message: this.$t('common.placeHolder_upload'),
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: this.$t('common.placeHolder_select'),
            trigger: 'blur',
          },
        ],
        sort: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
        area: [
          {
            required: true,
            message: this.$t('common.placeHolder_select'),
            trigger: 'blur',
          },
        ],
        applyBusiness: [
          {
            required: true,
            message: this.$t('common.placeHolder_select'),
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: this.$t('common.placeHolder_enter'),
            trigger: 'blur',
          },
        ],
      },
      isSubmitting: false,
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    async toSync() {
      try {
        await syncSearch({ docType: '4' });
      } catch (error) {
        console.log(error);
      }
    },
    async toChangeStatus(val, item) {
      if (val === '1') {
        this.$confirm(this.$t('pages.other.quedingyaoquxiaozhanshima'), this.$t('pages.other.tishi'), { type: 'warning' })
          .then(async () => {
            try {
              await updateNewsStatus({ id: item.newId });
              item.isRecommend = '1';
            } catch (error) {
              item.isRecommend = '0';
            }
          })
          .catch(() => {
            item.isRecommend = '0';
          });
      } else {
        try {
          await updateNewsStatus({ id: item.newId });
          item.isRecommend = '0';
        } catch (error) {
          item.isRecommend = '1';
        }
      }
    },
    toDelete(item) {
      this.$confirm(this.$t('pages.other.quedingyaoshanchucitiaoxinwengonggaoma'), this.$t('pages.other.tishi'), {
        type: 'warning',
      })
        .then(async () => {
          try {
            await deleteNewsData({ id: item.newId });
            this.$message.success(this.$t('pages.other.shanchuchenggong'));
            this.page = 1;
            this.getData();
          } catch (error) {
            console.log('error');
          }
        })
        .catch(() => {});
    },
    async toClose() {
      if (this.disabled) {
        this.showDetail = false;
      } else {
        // 修改笔记
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.isSubmitting = true;
            try {
              const params = {
                sort: this.currentItem.sort,
                newTitle: this.currentItem.title,
                newCoverUrl: this.currentItem.cover,
                newContent: this.currentItem.content,
                courseTypeIds: this.currentItem.type.join(','),
                applyRegionIds: this.currentItem.area.join(','),
                applyBusinessIds: this.currentItem.applyBusiness.join(','),
                locale: this.$store.state.locale,
              };
              if (this.currentItem.id) {
                params.id = this.currentItem.id;
                await updateNewsData(params);
                this.$message.success(this.$t('pages.other.xiugaichenggong'));
              } else {
                await insertNewsData(params);
                this.$message.success(this.$t('pages.other.xinwentianjiachenggong'));
              }
              this.showDetail = false;
              this.page = 1;
              this.getData();
              this.isSubmitting = false;
            } catch (error) {
              console.log(error);
              this.isSubmitting = false;
            }
          }
        });
      }
    },
    toShowDetail(item, flag) {
      this.currentItem.sort = item.sort;
      this.currentItem.id = item.newId;
      this.currentItem.title = item.newTitle;
      this.currentItem.cover = item.newCoverUrl;
      this.currentItem.content = item.newContent;
      this.currentItem.type = item.courseTypeIds
        ? item.courseTypeIds.split(',').map((item) => parseInt(item))
        : [];
      this.currentItem.area = item.applyRegionIds
        ? item.applyRegionIds.split(',').map((item) => parseInt(item))
        : [];
      this.currentItem.applyBusiness = item.applyBusinessIds
        ? item.applyBusinessIds.split(',').map((item) => parseInt(item))
        : [];
      this.currentItem.isTop = item.isRecommend;
      this.disabled = flag;
      this.showDetail = true;
    },
    handleImageUploadSuccess(res, field) {
      if (res.code === 200) {
        this.currentItem[field] = res.data.url;
      } else {
        this.$message.error(this.$t('pages.other.shangchuanshibai'));
      }
    },
    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(this.$t('pages.other.shangchuantouxianggeshi'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('pages.other.shangchuantouxiangdaxiao'));
      }
      return isJPG && isLt2M;
    },
    toAdd() {
      this.currentItem = {
        sort: 0,
        id: '',
        title: '',
        cover: '',
        content: '',
        type: [],
        area: [],
        applyBusiness: [],
        isTop: false,
      };
      this.disabled = false;
      this.showDetail = true;
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async toGetCategoryList() {
      try {
        const res = await selectCategoryList({
          locale: this.$store.state.locale,
        });
        this.categoryOptions = res;
      } catch (error) {
        console.log(error);
      }
    },
    async toGetAreaList() {
      if (this.areaList.length === 0) {
        try {
          let res = await selectAreaList({ locale: this.$store.state.locale });
          this.areaList = res;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async toGetIndustryList() {
      if (this.industryList.length === 0) {
        try {
          let res = await selectIndustryList({
            locale: this.$store.state.locale,
          });
          this.industryList = res;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        const res = await selectMyNewsList({
          newTitle: this.keywords,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.toGetCategoryList();
    this.toGetIndustryList();
    this.toGetAreaList();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .video-timing {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    user-select: none;
    cursor: pointer;
    .iconfont {
      margin-right: 5px;
    }
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
      line-height: 1.5;
      margin-top: 10px;
    }
  }
}
</style>
