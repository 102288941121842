<template>
  <div>
    <div class="header-area">{{$t('pages.other.Evaluatedcourses')}}</div>
    <div class="info-area">
      <ul class="list" v-loading="isLoading" v-if="list.length > 0">
        <li v-for="item in list" :key="item.messageId">
          <div class="left">
            <el-avatar :size="40" :src="userinfo.avatar" />
          </div>
          <div class="right">
            <div class="score-area">
              <div class="start-area">
                <span class="label">{{$t('pages.other.rating')}}</span>
                <el-rate
                  :value="item.appraiseAcount"
                  :colors="['#FF992B', '#FF992B', '#FF992B']"
                  void-color="#DCDCDC"
                  disabled
                ></el-rate>
              </div>
              <div class="create-time">{{ item.appraiseTime }}</div>
            </div>
            <p class="content">{{ item.messageText }}</p>
            <div class="news-area" @click="toDetail(item)">
              <div class="img-area">
                <img
                  :src="item.productDto.productImg"
                  :alt="item.productDto.productName"
                />
              </div>
              <div class="news-info">
                <div class="title">{{ item.productDto.productName }}</div>
                <div class="desc">{{$t('titles.lecturerName')}}：{{ item.productDto.teacherName }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <the-empty v-else :text="$t('pages.other.noScore')" />
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCommentList } from "@/api/common";
import TheEmpty from "@/components/TheEmpty/TheEmpty.vue";
export default {
  components: { TheEmpty },
  data() {
    return {
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 7,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    toDetail(item) {
      if (item.bodyType === "0") {
        this.$router.push(`/course-zone/detail/${item.productDto.productId}`);
      } else {
        this.$router.push(`/course-plan/detail/${item.productDto.productId}`);
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMyCommentList({
          bodyType: 0,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.list = res.data;
        this.total = res.total;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .list {
    li {
      padding-top: 24px;
      padding-bottom: 12px;
      display: flex;
      .left {
        margin-right: 20px;
        display: flex;
      }
      .right {
        padding-top: 9px;
        flex: 1;
        min-width: 0;
        .score-area {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          .start-area {
            display: flex;
            align-items: center;
            .label {
              font-size: 16px;
              color: #666666;
              margin-right: 5px;
            }
            ::v-deep .el-rate__icon {
              font-size: 20px;
            }
          }
          .create-time {
            font-size: 14px;
            color: $text-color-grey;
          }
        }
        .content {
          font-size: 16px;
          margin-bottom: 15px;
          text-align: justify;
        }
        .news-area {
          background: #f7f7f7;
          padding: 14px 20px;
          display: flex;
          margin-left: 30px;
          cursor: pointer;
          .img-area {
            width: 78px;
            height: 50px;
            margin-right: 23px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .news-info {
            flex: 1;
            min-width: 0;
            .title {
              font-size: 14px;
            }
            .desc {
              color: $text-color-grey;
            }
          }
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>