<template>
  <div class="course-plan">
    <!-- banner -->
    <div class="banner-area">
      <div class="plan-info-wrap">
        <div class="plan-info" v-if="detail">
          <div class="title">{{ detail.coursePlanName }}</div>
          <div class="detail">
            <p>{{$t('titles.lecturerName')}}：{{ detail.teacherNames }}</p>
            <p>{{$t('titles.teachingObject')}}：{{ detail.applyPeople }}</p>
            <p>{{$t('common.time')}}：{{ detail.coursePlanEffectDay }} {{$t('common.day')}}</p>
          </div>
          <div class="price-area">
            <!-- <price-num
              :val="detail.coursePlanPrice"
              color="#FFED00"
              :signSize="20"
              :intSize="30"
              :pointSize="30"
              :decSize="30"
              :showDec="false"
            /> -->
            <div class="coll-btn-area">
              <vue-star
                animate="animate__animated animate__bounceIn"
                color="#FFBB05"
                :active="isColl"
              >
                <div
                  slot="icon"
                  v-if="detail"
                  class="coll"
                  @click="toToggleColl"
                >
                  <i class="iconfont" v-if="isColl">&#xe629;</i>
                  <i class="iconfont" v-else>&#xe62a;</i>
                  {{ isColl ? $t('common.collected') : $t('titles.myCollCoursePlan') }}
                </div>
              </vue-star>
            </div>
            <!-- <span class="coll" @click="toToggleColl">
              <i class="iconfont" v-if="isColl">&#xe629;</i>
              <i class="iconfont" v-else>&#xe62a;</i>
              <span class="txt">{{ isColl ? "已收藏" : "收藏计划" }}</span>
            </span> -->
          </div>
          <!-- <div class="btn-area" v-if="!isBuy">
            <el-button class="my-btn" @click="toNext">{{$t('common.buyNow')}}</el-button>
          </div>
          <div class="btn-area" v-else>
            <el-button class="my-btn" @click="toStudy">{{$t('common.learnNow')}}</el-button>
            <el-button class="my-btn" @click="toNext">{{$t('titles.buyAgain')}}</el-button>
          </div>
          <p class="num-area">
            <span class="num">{{ detail.buyAcount }}</span
            >{{$t('titles.AlreadyBought')}}
          </p> -->
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="section">
      <div class="section-container">
        <div class="left">
          <!-- 选项卡 -->
          <div class="tab-area">
            <el-tabs class="my-tabs" v-model="activeName">
              <el-tab-pane :label="$t('titles.courseZoneDetail')" name="first">
                <div
                  class="detail-content"
                  v-if="detail"
                  v-html="detail.coursePlanDetail"
                ></div>
              </el-tab-pane>
              <el-tab-pane :label="$t('titles.CourseList')" name="second">
                <ul class="list">
                  <li v-for="(item, index) in courseList" :key="item.courseId">
                    <a
                      href="javascript:;"
                      @click="toStudyOrDetail(item, index)"
                    >
                      <div class="index-area">{{ index | fixZero }}</div>
                      <div class="title">
                        {{ item.courseName }} （{{ item.teacherName }}{{$t('titles.lecturerName')}}）
                      </div>
                      <div class="info">
                        <i class="iconfont">&#xe66e;</i>
                        {{$t('common.video')}} | {{ item.duration | handleTime }}
                      </div>
                    </a>
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane :label="$t('titles.courseZoneEvaluate')" name="third">
                <ul class="comment-list" v-if="commentList.length > 0">
                  <li v-for="item in commentList" :key="item.id">
                    <div class="img-area">
                      <img
                        :src="
                          item.appraiseUserHeadImg
                            ? item.appraiseUserHeadImg
                            : defaultAvatar
                        "
                        alt=""
                      />
                    </div>
                    <div class="name-area">
                      <div class="name">{{ item.appraiseUserName }}</div>
                      <div class="create-time">{{ item.appraiseTime }}</div>
                    </div>
                    <div class="comment-content">{{ item.messageText }}</div>
                  </li>
                </ul>
                <the-empty :text="$t('common.noData')" v-else />
                <el-pagination
                  :current-page.sync="page"
                  :page-size="pageSize"
                  :hide-on-single-page="true"
                  background
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                  @current-change="getCommentData"
                >
                </el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 更多热门计划 -->
        <div class="right">
          <div class="title-area">
            <div class="title">{{$t('titles.courseHot')}}</div>
            <router-link to="/" class="more">
              {{$t('common.more')}}
              <i class="iconfont">&#xe60a;</i>
            </router-link>
          </div>
          <ul class="list">
            <li
              v-for="item in recommendList"
              :key="item.coursePlanId"
              @click="toDetail(item)"
            >
              <div class="img-area">
                <img
                  :src="item.coursePlanCoverUrl"
                  :alt="item.coursePlanName"
                />
              </div>
              <div class="name-area">
                <div class="name" :title="item.coursePlanName">
                  {{ item.coursePlanName }}
                </div>
                <!-- <price-num
                  :val="item.coursePlanPrice"
                  color="#FF4C4C"
                  :signSize="12"
                  :intSize="20"
                  :pointSize="20"
                  :decSize="20"
                  :showDec="false"
                /> -->
              </div>
            </li>
          </ul>
          <div class="to-all">
            <router-link to="/course-plan">{{$t('common.toSeeAll')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  selectCoursePlanDetail,
  updateCollStatus,
  selectCommentList,
} from '@/api/common';

import PriceNum from '../../components/PriceNum/PriceNum.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
import VueStar from '@/components/VueStar/VueStar';

export default {
  components: {
    PriceNum,
    TheEmpty,
    VueStar,
  },
  data() {
    return {
      activeName: 'first',
      isColl: false,
      isBuy: false,
      id: '',
      detail: null,
      courseList: [],
      recommendList: [],
      commentList: [],
      page: 1,
      pageSize: 12,
      total: 0,
      defaultAvatar: require('@/assets/images/default-avatar.png'),
    };
  },
  filters: {
    fixZero(val) {
      return val.toString().length > 1 ? val : `0${val}`;
    },
  },
  methods: {
    toStudyOrDetail(item, index) {
      if (this.isBuy) {
        this.$router.push(`/course-study/${this.id}/1/${index}`);
      } else {
        this.$router.push(`/course-zone/detail/${item.courseId}`);
      }
    },
    toStudy() {
      this.$router.push(`/course-study/${this.id}/1/0`);
    },
    toNext() {
      this.$router.push(`/pay/confirm-order/${this.id}/1`);
    },
    toDetail(item) {
      this.id = item.coursePlanId;
      this.page = 1;
      this.getData();
    },
    async toToggleColl() {
      try {
        await updateCollStatus({
          productId: this.id,
          productType: 1,
        });
        this.isColl = !this.isColl;
      } catch (error) {
        console.log(error);
      }
    },
    async getCommentData() {
      try {
        const res = await selectCommentList({
          bodyId: this.id,
          bodyType: 1,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.commentList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await selectCoursePlanDetail({
          coursePlanId: this.id,
        });
        this.isColl = res.isCollected === '1';
        this.isBuy = res.isBuy === '1';
        this.detail = res.coursePlan;
        this.courseList = res.courseDtoList;
        this.recommendList = res.coursePlanDtoList ? res.coursePlanDtoList : [];
        this.commentList = res.appariseMsgs ? res.appariseMsgs.data : [];
        this.total = res.appariseMsgs ? res.appariseMsgs.total : 0;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.course-plan {
  .banner-area {
    height: 500px;
    background-image: url('../../assets/images/banner-course-plan-detail.png');
    background-repeat: no-repeat;
    background-position: center;
    .plan-info-wrap {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      .plan-info {
        height: 100%;
        width: 425px;
        color: #ffffff;
        background: #5577ea;
        position: absolute;
        top: 0;
        right: 24px;
        padding: 80px 64px 0;
        .title {
          font-size: 28px;
          line-height: 36px;
          font-weight: bold;
          margin-bottom: 26px;
        }
        .detail {
          font-size: 14px;
          line-height: 24px;
          p {
            margin-bottom: 10px;
          }
        }
        .price-area {
          margin-bottom: 20px;
          position: relative;
          .coll-btn-area {
            position: absolute;
            top: -22px;
            right: 0;
            .coll {
              font-size: 18px;
              color: #fff15f;
              line-height: 1;
              user-select: none;
              cursor: pointer;
              width: 100px;
              height: 54px;
              display: flex;
              align-items: center;
              i.iconfont {
                font-size: 18px;
                margin-right: 7px;
              }
            }
          }
        }
        .btn-area {
          display: flex;
          .my-btn {
            flex: 1;
            width: 100%;
            height: 45px;
            border-radius: 2px;
            font-size: 20px;
            color: #0f123b;
            background: #fff15f;
            margin-bottom: 15px;
            & ~ .my-btn {
              margin-left: 10px;
            }
          }
        }
        .num-area {
          line-height: 1;
          text-align: center;
          .num {
            color: #fff15f;
          }
        }
      }
    }
  }
  .section {
    background: #fafafa;
    .section-container {
      width: 1200px;
      margin: 30px auto 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        width: 900px;
        background: #ffffff;
        padding: 30px 30px 0;
        margin-right: 20px;
        ::v-deep .el-tabs {
          .el-tabs__active-bar {
            height: 3px;
          }
          .el-tabs__item {
            color: $text-color-grey;
            font-size: 16px;
            &.is-active {
              color: $text-color-black;
              font-weight: bold;
            }
          }
        }
        .detail-content {
          img {
            max-width: 100%;
          }
        }
        .list {
          padding: 20px 0 100px;
          li {
            padding: 25px 0 15px;
            border-bottom: 1px solid #f2f2f2;
            a {
              position: relative;
              padding-left: 50px;
              display: block;
              .index-area {
                position: absolute;
                left: 15px;
                top: 0;
                font-size: 16px;
                font-weight: bold;
                color: #c1c8d0;
              }
              .title {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 5px;
                transition: color 0.1s;
              }
              .info {
                font-size: 12px;
                color: #a7b0b8;
                display: flex;
                align-items: center;
                .iconfont {
                  font-size: 10px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .comment-list {
          padding: 20px 0 30px;
          li {
            position: relative;
            padding: 25px 0 25px 76px;
            .img-area {
              width: 32px;
              height: 32px;
              position: absolute;
              top: 25px;
              left: 39px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 32px;
              .name {
                color: #8d8d8d;
                margin-bottom: 5px;
                width: 380px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .create-time {
                color: $text-color-grey;
              }
            }
          }
        }
        .el-pagination {
          text-align: center;
          margin-bottom: 60px;
        }
      }
      .right {
        background: #ffffff;
        padding: 15px;
        flex: 1;
        min-width: 0;
        .title-area {
          padding-left: 10px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          &::before {
            content: '';
            width: 4px;
            height: 16px;
            background: $base-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          .more {
            font-size: 12px;
            font-weight: normal;
            display: inline-flex;
            align-items: center;
            transition: color 0.3s;
            .iconfont {
              font-size: 12px;
            }
          }
        }
        .list {
          li {
            padding: 15px 0 10px;
            cursor: pointer;
            &:not(:last-child) {
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
            }
            .img-area {
              width: 100%;
              height: 140px;
              margin-bottom: 5px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              transition: color 0.3s;
              .name {
                flex: 1;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .to-all {
          text-align: center;
          padding: 20px 0;
          a {
            display: inline-block;
            width: 128px;
            height: 31px;
            line-height: 29px;
            border: 1px solid #ccc;
            border-radius: 3px;
            text-align: center;
            color: $text-color-grey;
            transition: all 0.3s;
            &:hover {
              color: $base-color;
              border-color: $base-color;
            }
          }
        }
      }
    }
  }
}
</style>
