<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title-area">
      <span class="text">{{
        $t(isLogin ? 'pages.login.title_modify' : 'pages.login.title_reset')
      }}</span>
    </div>
    <!-- 表单 -->
    <div class="form-area" :class="{ en: locale === 'en' }">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="right"
        :label-width="locale === 'cn' ? '80px' : '150px'"
      >
        <el-form-item
          :label="$t('pages.login.email')"
          prop="email"
          v-if="method === 'email'"
        >
          <el-input
            v-model="formData.email"
            :disabled="!!userinfo.email"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('pages.login.mobile')"
          prop="tel"
          v-if="method === 'tel'"
        >
          <el-input
            v-model="formData.tel"
            :disabled="!!userinfo.tel"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.login.CAPTCHA')"
          prop="vcode"
        >
          <el-input v-model="formData.vcode"></el-input>
          <div class="v-btn-area">
            <el-button
              plain
              :type="disabled ? 'info' : 'primary'"
              @click="getVCode"
              >{{ txt }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label="$t('pages.login.password')" prop="password">
          <el-input v-model="formData.password" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.cpwd')" prop="checkPass">
          <el-input v-model="formData.checkPass" type="password"></el-input>
        </el-form-item>
        <el-form-item class="row">
          <el-button type="primary" v-loading="isLoading" @click="submitForm">{{
            $t('common.submit')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { selectVcode, updatePassword } from '@/api/common';
let timer = null;
export default {
  data() {
    return {
      method: 'email',
      formData: {
        email: '',
        tel: '',
        vcode: '',
        password: '',
        checkPass: '',
      },
      rules: {
        email: [
          {
            required: false,
            message: this.$t('pages.login.plh_email'),
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: false,
            message: this.$t('pages.login.plh_phone'),
            trigger: 'blur',
          },
        ],
        vcode: [
          {
            required: true,
            message: this.$t('pages.login.plh_captcha'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('pages.login.plh_pwd')));
              } else {
                if (this.formData.checkPass !== '') {
                  this.$refs['form'].validateField('checkPass');
                }
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        checkPass: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('pages.login.plh_pwd_2')));
              } else if (value !== this.formData.password) {
                callback(new Error(this.$t('pages.login.plh_pwd_c')));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
      txt: this.$t('pages.login.send'),
      disabled: false,
      isLoading: false,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin;
    },
    userinfo() {
      return this.$store.state.userinfo;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    submitForm() {
      if (this.isLoading) return;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const params = {
            captcha: this.formData.vcode,
            newPassword: this.formData.password,
            confirmPassword: this.formData.checkPass,
          };
          if (this.method === 'tel') {
            params.phone = this.formData.tel;
          } else {
            params.email = this.formData.email;
          }
          try {
            this.isLoading = true;
            await updatePassword(params);
            this.isLoading = false;
            this.$message.success(this.$t('pages.login.plh_modify_pwd'));
            this.$store.commit('logout');
          } catch (error) {
            console.log(error);
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    showTime() {
      this.disabled = true;
      let time = 60;
      this.txt = `${this.$t('pages.login.resend')}(${time})`;
      timer = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer);
          this.txt = this.$t('pages.login.send');
          this.disabled = false;
          return;
        }
        this.txt = `${this.$t('pages.login.resend')}(${--time})`;
      }, 1000);
    },
    async getVCode() {
      if (!this.disabled) {
        let flag = false;
        const params = {};
        if (this.method === 'tel') {
          if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel)) {
            flag = true;
            params.mobile = this.formData.tel;
            params.type = 2;
          } else {
            this.$message.error(this.$t('pages.login.plh_phone'));
            return false;
          }
        } else {
          if (
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
              this.formData.email
            )
          ) {
            flag = true;
            params.mail = this.formData.email;
            params.type = 1;
          } else {
            this.$message.error(this.$t('pages.login.plh_email'));
            return false;
          }
        }
        if (flag) {
          try {
            await selectVcode(params);
            this.showTime();
            this.$message({
              message: this.$t('pages.login.plh_send'),
              type: 'success',
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
  mounted() {
    this.method = this.$route.params.id === '1' ? 'tel' : 'email';
    if (this.method === 'email') {
      this.rules.email[0].required = true;
      this.formData.email = this.userinfo.email;
    } else {
      this.rules.tel[0].required = true;
      this.formData.tel = this.userinfo.tel;
    }
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .title-area {
    width: 454px;
    margin: 0 auto 70px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    .text {
      display: inline-block;
      padding: 5px 30px;
      background: #ffffff;
      position: relative;
      line-height: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-area {
    width: 386px;
    margin: 0 auto;
    &.en {
      width: 450px;
    }
    .el-form-item {
      &.vcode-item {
        ::v-deep .el-form-item__content {
          display: flex;
          align-items: center;
          .el-input {
            width: 210px !important;
            margin-right: 12px;
            input {
              width: 100%;
            }
          }
          .v-btn-area {
            text-align: right;
            cursor: pointer;
            img {
              width: 87px;
              height: 40px;
            }
          }
        }
      }
      &.row {
        margin-top: 50px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          .el-button {
            width: 100%;
            color: $text-color-black;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
