export default {
  courseZone: '课程专区',
  coursePlan: '课程计划',
  courseSubscribe: '课程预约',
  service: '在线客服',
  feedback: '意见反馈',
  mp: '小程序',
  toTop: '返回顶部',
  download: '下载课程目录',
  more: '查看更多',
  customization: '我要定制',
  startTime: '开课时间',
  contacts: '联系我们',
  BeijingOffice: '北京办公室',
  HongKongOffice: '香港办公室',
  phName: '请输入您的姓名',
  phContacts: '请输入联系方式',
  phEmail: '请输入邮箱',
  phContent: '请输入反馈内容',
  scanWithWechat: '用微信扫码',
  visitMiniProgram: '访问小程序',
  // 客服
  greeting: '您好，欢迎访问锐合教育，请问有什么可以帮助您的？',
  hot: '热门问题',
  noHelp: '感谢您的提问，请您在首页底端联系我们板块留下您的联系方式与问题，我们将尽快为您解答！',
};
