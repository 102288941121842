<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title-area">
      <span class="text">{{ $t('pages.login.userLogin') }}</span>
    </div>
    <!-- 表单 -->
    <div class="form-area">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="right"
        label-width="80px"
      >
        <el-form-item :label="$t('pages.login.account')" prop="username">
          <el-input v-model="formData.username"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.login.password')" prop="password">
          <el-input v-model="formData.password" type="password"></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.login.CAPTCHA')"
          prop="vcode"
        >
          <el-input
            v-model="formData.vcode"
            @keyup.enter.native="submitForm"
          ></el-input>
          <div class="v-img-area" @click="getImgVcode">
            <img :src="`data:image/png;base64,${imgSrc}`" alt="" />
          </div>
        </el-form-item>
        <el-form-item class="row">
          <el-button type="primary" v-loading="isLoading" @click="submitForm">{{
            $t('common.login')
          }}</el-button>
        </el-form-item>
      </el-form>
      <div class="extra">
        <router-link class="forget" to="/auth/pre-reset">{{
          $t('pages.login.forgetPwd')
        }}</router-link>
        <router-link class="register" to="/auth/register-member">{{
          $t('common.register')
        }}</router-link>
      </div>
      <div class="to-wechat-login text-align" @click="toShowWechatLogin">
        <img src="@/assets/images/icon-wechat.png" alt="" />
        <span class="text">{{ $t('pages.login.wechatLogin') }}</span>
      </div>
    </div>
    <!-- 微信登录 -->
    <div class="wechat-login-area" v-show="showWechatLogin">
      <!-- 标题 -->
      <!-- <div class="title-area">
        <span class="text">微信扫一扫登录</span>
      </div> -->
      <!-- 二维码 -->
      <div class="qrcode-area">
        <wxlogin
          v-if="appid"
          :appid="appid"
          :state="uuid"
          scope="snsapi_login"
          theme="black"
          :redirect_uri="redictUrl"
          self_redirect="true"
        />
      </div>
      <p>
        <span class="to-accout-login" @click="toHideWechatLogin"
          >{{ $t('pages.login.accountLogin') }}></span
        >
      </p>
      <p>
        <span class="to-register"
          >{{ $t('pages.login.loginText')
          }}<router-link to="/auth/register-member">{{
            $t('common.register')
          }}</router-link></span
        >
      </p>
    </div>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import {
  selectImgVcode,
  selectWechatQrcodeForLogin,
  selectScanQrcodeResult,
  login,
} from '@/api/common';
let timer = null;
export default {
  components: { wxlogin },
  data() {
    return {
      showWechatLogin: false,
      formData: {
        username: '',
        password: '',
        vcode: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('pages.login.plh_username'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('pages.login.plh_pwd'),
            trigger: 'blur',
          },
        ],
        vcode: [
          {
            required: true,
            message: this.$t('pages.login.plh_captcha'),
            trigger: 'blur',
          },
        ],
      },
      vcode: '',
      imgSrc: '',
      loadingImgVcode: false,
      flag: true,
      appid: '',
      uuid: '',
      redictUrl: '',
      isLoading: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    submitForm() {
      if (this.isLoading) return;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;
            const res = await login({
              username: this.formData.username.trim(),
              password: this.formData.password.trim(),
              code: this.formData.vcode,
              uuid: this.vcode,
            });
            this.isLoading = false;
            this.$store.commit('setToken', res.token);
            res.userShowDto.roles = res.roles;
            this.$store.commit(
              'setCurrentRoleIndex',
              res.userShowDto.roles.length > 1 ? 1 : 0
            );
            this.$store.commit('setUserinfo', res.userShowDto);
            this.$store.commit('setIsFirst', res.userShowDto.loginAcount === 0);
            if (res.teachInfo) {
              this.$store.commit('setTeacherInfo', res.teachInfo);
            }
            if (res.orgShowDto) {
              this.$store.commit('setOrgInfo', res.orgShowDto);
            }
            this.$message.success(this.$t('pages.other.dengluchenggong'));
            if (res.userShowDto.loginAcount !== 0) {
              // if (
              //   this.$store.state.userinfo.roles[
              //     this.$store.state.currentRoleIndex
              //   ] === '2'
              // ) {
              //   this.$router.push('/personal/org-info');
              // } else {
              //   this.$router.push('/');
              // }
              this.$router.push('/personal/my-home');
            } else {
              this.$router.push('/auth/survey');
            }
          } catch (error) {
            console.log(error);
            this.isLoading = false;
            this.getImgVcode();
          }
        } else {
          return false;
        }
      });
    },
    toHideWechatLogin() {
      clearInterval(timer);
      this.showWechatLogin = false;
    },
    async checkLoginStatus() {
      this.flag = false;
      try {
        const res = await selectScanQrcodeResult({ uuid: this.uuid });
        this.flag = true;
        if (res) {
          this.$store.commit('setToken', res.token);
          res.userShowDto.roles = res.roles;
          this.$store.commit(
            'setCurrentRoleIndex',
            res.userShowDto.roles.length > 1 ? 1 : 0
          );
          this.$store.commit('setUserinfo', res.userShowDto);
          this.$store.commit('setIsFirst', res.userShowDto.loginAcount === 0);
          if (res.teachInfo) {
            this.$store.commit('setTeacherInfo', res.teachInfo);
          }
          if (res.orgShowDto) {
            this.$store.commit('setOrgInfo', res.orgShowDto);
          }
          this.$message.success(this.$t('pages.other.dengluchenggong'));
          if (res.userShowDto.loginAcount !== 0) {
            // if (
            //   this.$store.state.userinfo.roles[
            //     this.$store.state.currentRoleIndex
            //   ] === '2'
            // ) {
            //   this.$router.push('/personal/org-info');
            // } else {
            //   this.$router.push('/');
            // }
            this.$router.push('/personal/my-home');
          } else {
            this.$router.push('/auth/survey');
          }
        } else if (res === '') {
          // this.$message.error('此微信号未绑定，请使用账号登录');
          this.showWechatLogin = false;
          clearInterval(timer);
        }
      } catch (error) {
        console.log(error);
      }
    },
    toCheckLoginStatus() {
      if (this.flag) {
        this.checkLoginStatus();
      }
    },
    async toShowWechatLogin() {
      try {
        const res = await selectWechatQrcodeForLogin();
        this.appid = res.Appid;
        this.redictUrl = res.redictUrl;
        this.uuid = res.uuid;
        timer = setInterval(this.toCheckLoginStatus, 1000);
        this.showWechatLogin = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getImgVcode() {
      if (!this.loadingImgVcode) {
        try {
          this.loadingImgVcode = true;
          const res = await selectImgVcode();
          this.loadingImgVcode = false;
          this.vcode = res.uuid;
          this.imgSrc = res.img;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    this.getImgVcode();
  },
  beforeDestroy() {
    clearInterval(timer);
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .title-area {
    width: 454px;
    margin: 0 auto 70px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    .text {
      display: inline-block;
      padding: 5px 30px;
      background: #ffffff;
      position: relative;
      line-height: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-area {
    width: 386px;
    margin: 0 auto;
    .el-form-item {
      &.vcode-item {
        ::v-deep .el-form-item__content {
          display: flex;
          .el-input {
            width: 210px !important;
            margin-right: 12px;
            input {
              width: 100%;
            }
          }
          .v-img-area {
            flex: 1;
            text-align: right;
            cursor: pointer;
            img {
              width: 87px;
              height: 40px;
            }
          }
        }
      }
      &.row {
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          .el-button {
            width: 100%;
            color: $text-color-black;
            font-size: 18px;
          }
        }
      }
    }
    .extra {
      margin-top: 18px;
      line-height: 1;
      font-size: 16px;
      margin-bottom: 80px;
      .forget {
        color: #999999;
      }
      .register {
        float: right;
        color: $base-color;
      }
    }
    .to-wechat-login {
      font-size: 16px;
      cursor: pointer;
      img {
        margin-right: 13px;
      }
    }
  }
  .wechat-login-area {
    position: absolute;
    background: #ffffff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 60px;
    .qrcode-area {
      text-align: center;
      margin-bottom: 50px;
      img {
        width: 172px;
        height: 172px;
      }
    }
    p {
      text-align: center;
      font-size: 16px;
      line-height: 1;
      color: $text-color-grey;
      &:last-child {
        margin-top: 22px;
      }
      .to-accout-login {
        color: $base-color;
        cursor: pointer;
      }
      .to-register {
        a {
          color: $base-color;
        }
      }
    }
  }
}
</style>
