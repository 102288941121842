import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './assets/i18n/i18n';
import './assets/styles/element-variables.scss';
import animated from 'animate.css';

import ImgPreview from '@/components/ImgPreview';

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(ImgPreview);
Vue.use(animated);

Vue.filter('dateFormat', function(value, fmt) {
  if (value) {
    // let getDate = new Date(value * 1000);
    let getDate = new Date(value);
    let o = {
      'M+': getDate.getMonth() + 1,
      'd+': getDate.getDate(),
      'h+': getDate.getHours(),
      'm+': getDate.getMinutes(),
      's+': getDate.getSeconds(),
      'q+': Math.floor((getDate.getMonth() + 3) / 3),
      S: getDate.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (getDate.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
  } else {
    return '-';
  }
  return fmt;
});

Vue.filter('handleFileSize', function(size) {
  if (size >= 1048576) {
    return `${Math.round((size / 1048576) * 100) / 100}MB`;
  } else if (size >= 1024) {
    return `${Math.round((size / 1024) * 100) / 100}KB`;
  } else {
    return `${size}B`;
  }
});

Vue.filter('handleTime', function(val) {
  if (val) {
    let hour = Math.floor(val / 3600);
    let minu = Math.floor((val - 3600 * hour) / 60);
    let sec = Math.floor(val - hour * 3600 - minu * 60);
    if (minu.toString().length === 1) {
      minu = `0${minu}`;
    }
    if (sec.toString().length === 1) {
      sec = `0${sec}`;
    }
    if (hour) {
      if (hour.toString().length === 1) {
        hour = `0${hour}`;
      }
      return `${hour}:${minu}:${sec}`;
    } else {
      return `${minu}:${sec}`;
    }
  } else {
    return '00:00';
  }
});

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default app;
