<template>
  <div class="lecturer">
    <!-- banner -->
    <the-banner type="3" />
    <!-- 筛选 -->
    <the-filter @change="handleFilte" />
    <!-- 列表 -->
    <div class="list-container">
      <ul class="list" v-if="list.length > 0">
        <li v-for="item in list" :key="item.userId">
          <router-link :to="`/lecturer/detail/${item.userId}`">
            <div class="up-area">
              <div class="img-area">
                <img :src="item.bustShot" :alt="item.userName">
              </div>
              <div class="name" :title="item.userName">{{ item.userName }}</div>
            </div>
            <div class="down-area">
              <p class="first">{{ item.officialLabel }}  -  {{ item.school }} </p>
              <p class="second" :title="item.courseTypeLabals">{{$t('pages.other.areasExpertise')}}：{{ item.courseTypeLabals }}</p>
            </div>
          </router-link>
        </li>
      </ul>
      <the-empty v-else />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { selectLecturerList } from '@/api/common';

import TheBanner from '@/components/TheBanner/TheBanner.vue';
import TheFilter from '@/components/TheFilter/TheFilter.vue';
import TheEmpty from '@/components/TheEmpty/TheEmpty.vue';
export default {
  name: 'lecturer',
  components: {
    TheBanner, TheFilter, TheEmpty
  },
  data () {
    return {
      category: '',
      area: '',
      industry: '',
      page: 1,
      pageSize: 12,
      total: 0,
      list: [],
    }
  },
  methods: {
    handleFilte (res) {
      console.log(res);
      this.category = res.category;
      this.area = res.area;
      this.industry = res.industry;
      this.page = 1;
      this.list = [];
      this.getData();
    },
    async getData () {
      try {
        const res = await selectLecturerList({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          courseTypeId: this.category,
          businessId: this.industry,
          regionId: this.area
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.lecturer {
  .list-container {
    width: 1200px;
    margin: 0 auto;
    min-height: 647px;
    .list {
      padding-top: 40px;
      padding-bottom: 70px;
      margin-left: -30px;
      li {
        width: 268px;
        margin-left: 30px;
        margin-bottom: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
        display: inline-block;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.05);
        }
        a {
          display: block;
          padding: 26px 30px 22px;
          .up-area {
            border-bottom: 1px solid #EEEEEE;
            .img-area {
              width: 142px;
              height: 142px;
              margin: 0 auto;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name {
              font-size: 18px;
              margin: 22px 0 20px;
              text-align: center;
              line-height: 1;
            }
          }
          .down-area {
            font-size: 12px;
            text-align: center;
            line-height: 22px;
            padding-top: 17px;
            .first {
              color: $text-color-black;
              margin-bottom: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .second {
              color: $text-color-grey;
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              min-height: 44px;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #FFBB05;
      }
    }
  }
}
</style>
