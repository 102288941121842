import './index.scss';
import { parseDom } from './utils';

/**
 * 水印组件
 */
export default class WaterMarkerComponent {
  /**
   * @constructor 水印构造函数
   * @param {String text 水印内容}
   * @param {Object style 水印样式}
   */
  constructor(text, style) {
    this.text = text;
    this.style = style || { fontSize: '14px', color: '#fff' };
    this.html = parseDom('<div class="water-marker"></div>');
  }

  createEl(el) {
    let str = '';
    for (let i = 0; i < 600; i++) {
      str += `<span>${this.text}</span>`;
    }
    this.html.innerHTML = str;
    el.appendChild(this.html);
  }

  ready() {
    Object.keys(this.style).forEach(
      (key) => (this.html.style[key] = this.style[key])
    );
  }
}
