<template>
  <div class="org-course">
    <!-- 选项卡 -->
    <div class="tab-area">
      <el-tabs v-model="activeName">
        <el-tab-pane name="0">
          <div class="tab" slot="label">
            {{ $t('pages.personal.orgCoursePlan') }}
          </div>
        </el-tab-pane>
        <el-tab-pane name="1">
          <div class="tab" slot="label">
            {{ $t('pages.personal.platformCoursePlan') }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 内容 -->
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-button
            class="my-btn"
            type="primary"
            size="small"
            icon="el-icon-plus"
            v-if="activeName === '0' && role === '2'"
            @click="toAdd"
          >
            <span>{{ $t('common.add') }}</span>
          </el-button>
          <el-button
            class="my-btn"
            type="success"
            size="small"
            icon="el-icon-refresh"
            v-if="activeName === '0' && role === '2'"
            @click="toSync"
          >
            <span>{{ $t('pages.personal.syncSearchLib') }}</span>
          </el-button>
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
        row-key="coursePlanId"
      >
        <el-table-column :label="$t('common.name2')">
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.coursePlanCoverUrl" alt="" />
              <span class="name">{{ scope.row.coursePlanName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.category')"
          width="150"
          prop="courseTypeLabels"
        >
          <template slot-scope="scope">
            {{ scope.row.courseTypeLabels.map((item) => item.label).join(',') }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.personal.testTimes')"
          width="100"
          prop="examTimes"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('common.operation')"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div class="opers">
              <el-link type="primary" @click="toShowDetail(scope.row, true)">{{
                $t('common.detail')
              }}</el-link>
              <el-link
                type="success"
                @click="toAddCourseList(scope.row)"
                v-if="
                  activeName === '0' && !scope.row.courseIds && role === '2'
                "
                >{{ $t('pages.personal.relatedCourses') }}</el-link
              >
              <el-link
                type="success"
                @click="toShowCourseList(scope.row)"
                v-if="scope.row.courseIds"
                >{{ $t('pages.personal.viewCourse') }}</el-link
              >
              <el-link
                type="success"
                @click="toShowPaperDetail(scope.row)"
                v-if="scope.row.examId"
                >{{ $t('pages.personal.viewTest') }}</el-link
              >
              <el-popover
                placement="top"
                :title="$t('pages.personal.selectMethod')"
                width="205"
                trigger="click"
                v-if="activeName === '0' && !scope.row.examId && role === '2'"
              >
                <div class="content">
                  <el-button
                    size="mini"
                    @click="toShowIntelligentForm(scope.row)"
                    >{{ $t('pages.personal.randomly') }}</el-button
                  >
                  <el-button
                    size="mini"
                    @click="toShowQuestionList(scope.row)"
                    >{{ $t('pages.personal.manually') }}</el-button
                  >
                </div>
                <el-link type="danger" slot="reference">{{
                  $t('pages.personal.createTestPaper')
                }}</el-link>
              </el-popover>
              <el-link
                type="danger"
                @click="toRecommend(scope.row)"
                v-if="scope.row.isRecommend === '1' && role === '2'"
                >{{ $t('pages.personal.recommend') }}</el-link
              >
              <el-link
                type="danger"
                @click="toCancelRecommend(scope.row)"
                v-if="scope.row.isRecommend === '0' && role === '2'"
                >{{ $t('pages.personal.unrecommend') }}</el-link
              >
              <el-dropdown
                v-if="activeName === '0' && role === '2'"
                @command="
                  (command) => {
                    handleCommand(command, scope.row);
                  }
                "
              >
                <span class="el-dropdown-link">
                  {{ $t('common.more')
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="course"
                    v-if="scope.row.courseIds"
                    >{{ $t('pages.personal.relatedCourses') }}</el-dropdown-item
                  >
                  <el-dropdown-item command="random" v-if="scope.row.examId">{{
                    $t('pages.personal.randomly')
                  }}</el-dropdown-item>
                  <el-dropdown-item command="manual" v-if="scope.row.examId">{{
                    $t('pages.personal.manually')
                  }}</el-dropdown-item>
                  <el-dropdown-item command="editCourse">{{
                    $t('common.edit')
                  }}</el-dropdown-item>
                  <el-dropdown-item command="deleteCourse">{{
                    $t('common.delete')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 课程计划详情弹窗 -->
    <el-dialog
      :title="
        `${disabled ? '' : form.id ? $t('common.edit') : $t('common.add')} ${$t(
          'pages.other.kechengjihua'
        )} ${disabled ? $t('pages.other.xiangqing') : ''}`
      "
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="toCloseDetail"
      :visible.sync="showDetail"
    >
      <div class="popup-content form">
        <el-form
          label-position="right"
          label-width="200px"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <el-form-item
            :label="$t('pages.other.kechengjihuamingcheng')"
            prop="name"
          >
            <el-input
              v-model="form.name"
              :readonly="disabled"
              :placeholder="$t('pages.other.qingtianxiekechengjihuamingcheng')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.kechengjihuafenlei')"
            prop="type"
          >
            <el-select
              v-model="form.type"
              :placeholder="$t('pages.other.qingxuanze')"
              multiple
              :disabled="disabled"
              :style="{ width: '100%' }"
              :loading="categoryIsLoading"
            >
              <el-option
                v-for="category in categoryOptions"
                :key="category.courseTypeId"
                :label="category.courseTypeName"
                :value="category.courseTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.kechengjihuabiaoqian')"
          >
            <div class="tag-list" v-if="form.tags.length > 0">
              <div
                class="tag"
                v-for="(tag, index) in form.tags"
                :key="tag.labelId"
              >
                <span>{{ tag.label }}</span>
                <i
                  class="el-icon-close delete"
                  v-if="!disabled"
                  @click="toDeleteTag(index)"
                ></i>
              </div>
            </div>
            <el-autocomplete
              class="inline-input"
              v-model="newTag"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              :placeholder="$t('pages.other.PleaseLabel')"
              value-key="label"
              v-if="!disabled"
            >
              <el-button slot="append" icon="el-icon-plus" @click="toAddTag">{{
                $t('pages.other.add')
              }}</el-button>
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.kechengjihuafengmian')"
            prop="cover"
          >
            <div class="photo-area">
              <div class="preveiw-area" v-if="form.cover">
                <img :src="form.cover" />
                <i
                  class="el-icon-error delete"
                  @click="form.cover = ''"
                  v-if="!disabled"
                ></i>
              </div>
              <div class="upload-area" v-else>
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeImageUpload"
                  :on-success="
                    (res) => {
                      handleImageUploadSuccess(res, 'cover');
                    }
                  "
                >
                  <el-button class="my-btn" size="small">
                    <span>{{ $t('pages.other.shangchuanfengmian') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">
                  {{ $t('pages.other.geshi') }}：JPG、PNG、JPEG<br /> <span>( 450*250  &lt;= 500KB)</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.kechengjihuazhengshu')"
            prop="certificateUrl"
          >
            <div class="photo-area">
              <div class="preveiw-area" v-if="form.certificateUrl">
                <img :src="form.certificateUrl" />
                <i
                  class="el-icon-error delete"
                  @click="form.certificateUrl = ''"
                  v-if="!disabled"
                ></i>
              </div>
              <div class="upload-area" v-else>
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeImageUpload"
                  :on-success="
                    (res) => {
                      handleImageUploadSuccess(res, 'certificateUrl');
                    }
                  "
                >
                  <el-button class="my-btn" size="small">
                    <span>{{ $t('pages.other.shangchuanzhengshu') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">
                  {{ $t('pages.other.geshi') }}：JPG、PNG、JPEG<br /> <span>( 270*188  &lt;= 500KB)</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('pages.other.kaoshicishu')" prop="examTimes">
            <el-input-number
              v-model="form.examTimes"
              controls-position="right"
              size="small"
              :disabled="disabled"
              :min="1"
              :max="99"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseDetail">{{
          $t('pages.other.cancel')
        }}</el-button>
        <el-button type="primary" @click="toClose">{{
          disabled ? $t('pages.other.close') : $t('pages.other.submit')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 关联课程弹窗 -->
    <el-dialog
      :title="$t('pages.other.guanliankecheng')"
      width="1100px"
      :visible.sync="addCourseList"
      :before-close="toCloseCourseList"
    >
      <div class="popup-content table">
        <div class="filter-area">
          <el-select
            v-model="cType"
            size="small"
            multiple
            clearable
            :placeholder="$t('pages.other.shaixiankechengleixing')"
            @change="toGetCourseData"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.courseTypeId"
              :label="item.courseTypeName"
              :value="item.courseTypeId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="cSource"
            size="small"
            clearable
            :placeholder="$t('pages.other.kechenglaiyuan')"
            @change="toGetCourseData"
          >
            <el-option :label="$t('pages.other.ziyoukecheng')" value="0">
            </el-option>
            <el-option :label="$t('pages.other.pingtaikecheng')" value="1">
            </el-option>
          </el-select>
          <el-input
            :placeholder="$t('pages.other.keywords')"
            v-model="cKeywords"
            class="input-with-select"
            size="small"
            clearable
            @keyup.enter.native="toGetCourseData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toGetCourseData"
            ></el-button>
          </el-input>
        </div>
        <el-table
          ref="courseTable"
          :data="courseList"
          style="width: 100%"
          row-key="courseId"
          @selection-change="handleCourseSelectionChange"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="55"
          >
          </el-table-column>
          <el-table-column :label="$t('pages.other.kechengmingcheng')">
            <template slot-scope="scope">
              <div class="name-area">
                <img :src="scope.row.courseCoverUrl" alt="" />
                <span class="name">{{ scope.row.courseName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseTypeLabels"
            :label="$t('pages.other.kechengleixing')"
            width="250px"
          >
            <template slot-scope="scope">
              {{
                scope.row.courseTypeLabels.map((type) => type.label).join(',')
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTypeLabel"
            :label="$t('pages.other.kechengguishu')"
            width="160px"
            align="center"
            header-align="center"
          >
          </el-table-column>
        </el-table>
        <div class="pagination-area">
          <el-pagination
            :current-page.sync="cPage"
            :page-size="cPageSize"
            background
            layout="prev, pager, next"
            :total="cTotal"
            @current-change="getCourseData"
          >
          </el-pagination>
        </div>
        <div class="count-info">
          <div class="count-area">
            <div class="count">
              {{ $t('pages.other.yixuan') }}{{ cSelection.length
              }}{{ $t('pages.other.gekecheng') }}
            </div>
          </div>
          <!-- <div class="price-area">
            <span class="label">应付款：</span>
            <span class="price red">
              ¥{{ (Math.round(totalPrice * discount * 100) / 100) | fixZero }}
            </span>
            <span class="discount"
              >(平台优惠：<span class="red"
                >-{{
                  (Math.round(totalPrice * (1 - discount) * 100) / 100)
                    | fixZero
                }}</span
              >)</span
            >
          </div> -->
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseCourseList">{{
          $t('pages.other.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmit">{{
          $t('pages.other.submit')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 查看课程弹窗 -->
    <el-dialog
      :title="$t('pages.other.baohankecheng')"
      width="1100px"
      :visible.sync="showCourseList"
    >
      <div class="popup-content table">
        <el-table :data="sCourseList" style="width: 100%" row-key="courseId">
          <el-table-column :label="$t('pages.other.kechengmingcheng')">
            <template slot-scope="scope">
              <div class="name-area">
                <img :src="scope.row.courseCoverUrl" alt="" />
                <span class="name">{{ scope.row.courseName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseTypeValues"
            :label="$t('pages.other.kechengleixing')"
            width="250px"
          />
          <el-table-column
            prop="createTypeLabel"
            :label="$t('pages.other.kechengguishu')"
            width="160px"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.createType === '0'
                  ? $t('pages.other.ziyoukecheng')
                  : $t('pages.other.pingtaikecheng')
              }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('pages.other.caozuo')"
            width="150px"
            align="center"
          >
            <template slot-scope="scope">
              <el-link type="success" @click="toShowStudyRecord(scope.row)">{{
                $t('pages.other.xuexijilu')
              }}</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showCourseList = false">{{
          $t('pages.other.close')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 随机组卷弹窗 -->
    <el-dialog
      :title="$t('pages.personal.randomly')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="toCloseIntelligentForm"
      :visible.sync="showIntelligentForm"
    >
      <div class="popup-content form">
        <el-form
          label-position="right"
          :label-width="`${locale === 'cn' ? 100 : 200}px`"
          ref="iForm"
          :model="iForm"
          :rules="iRules"
        >
          <el-form-item
            :label="$t('common.category')"
            prop="type"
            v-if="currentItem"
          >
            <el-select
              v-model="iForm.type"
              :placeholder="$t('common.placeHolder_select')"
              multiple
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="category in currentItem.courseTypeLabels"
                :key="category.labelId"
                :label="category.label"
                :value="category.labelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('common.tag')"
            prop="tags"
            v-if="currentItem"
          >
            <el-select
              v-model="iForm.tags"
              :placeholder="$t('common.placeHolder_select')"
              multiple
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="tag in currentItem.courseLabalValues"
                :key="tag.labelId"
                :label="tag.label"
                :value="tag.labelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.singleChoiceQuestion')"
            prop="type1Count"
          >
            <el-input
              v-model="iForm.type1Count"
              type="number"
              :placeholder="$t('common.placeHolder_enter')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.multipleChoiceQuestions')"
            prop="type2Count"
          >
            <el-input
              v-model="iForm.type2Count"
              type="number"
              :placeholder="$t('common.placeHolder_enter')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.trueOrFalseQuestions')"
            prop="type3Count"
          >
            <el-input
              v-model="iForm.type3Count"
              type="number"
              :placeholder="$t('common.placeHolder_enter')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.passingCount')"
            prop="standard"
          >
            <el-input
              v-model="iForm.standard"
              type="number"
              :placeholder="$t('pages.personal.markNumText')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.testDuration')"
            prop="duration"
          >
            <el-input
              v-model="iForm.duration"
              type="number"
              :placeholder="$t('common.placeHolder_enter')"
            >
              <template slot="append">{{ $t('common.min') }}</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseIntelligentForm">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmitConditions">{{
          $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 人工组卷弹窗 -->
    <el-dialog
      :title="$t('pages.personal.questionList')"
      width="1020px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showQuestionList"
      :before-close="toCloseQuestionList"
    >
      <div class="popup-content form">
        <el-form
          label-width="80px"
          :inline="true"
          ref="qForm"
          :model="qForm"
          v-if="!showIntelligentForm"
        >
          <el-form-item prop="type" v-if="currentItem">
            <el-select
              v-model="qForm.type"
              :placeholder="$t('pages.personal.plh_category')"
              multiple
            >
              <el-option
                v-for="category in currentItem.courseTypeLabels"
                :key="category.labelId"
                :label="category.label"
                :value="category.labelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="tags" v-if="currentItem">
            <el-select
              v-model="qForm.tags"
              :placeholder="$t('pages.personal.plh_tag')"
              multiple
            >
              <el-option
                v-for="tag in currentItem.courseLabalValues"
                :key="tag.labelId"
                :label="tag.label"
                :value="tag.labelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="qType">
            <el-select
              v-model="qForm.qType"
              :placeholder="$t('pages.personal.plh_type')"
              clearable
            >
              <el-option
                :label="$t('pages.personal.scq')"
                value="1"
              ></el-option>
              <el-option
                :label="$t('pages.personal.mcq')"
                value="2"
              ></el-option>
              <el-option
                :label="$t('pages.personal.tfq')"
                value="3"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="keywords">
            <el-input
              :placeholder="$t('common.placeHolder_enter')"
              v-model="qKeywords"
              class="input-with-select"
              clearable
              @clear="toSearchQuestion"
              @keyup.enter.native="toSearchQuestion"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="toSearchQuestion"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          :data="questionList"
          border
          v-loading="qIsLoading"
          size="medium"
          style="width: 100%"
          row-key="questionBankId"
          ref="questionList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="55"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.questionType')"
            width="120"
            prop="contentTypeValue"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.questionStem')"
            prop="content"
          ></el-table-column>
          <el-table-column
            :label="$t('common.category')"
            width="150"
            prop="courseTypeValues"
          ></el-table-column>
          <el-table-column
            :label="$t('common.createTime')"
            width="200"
            prop="createTime"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="pagination-area-q">
          <el-pagination
            :current-page.sync="qPage"
            :page-size="qPageSize"
            background
            layout="prev, pager, next"
            :total="qTotal"
            @current-change="toSearchQuestion"
          >
          </el-pagination>
        </div>
        <div class="count-info">
          <div class="left">
            <p>{{ $t('pages.personal.totalCount') }}：{{ qTotal }}</p>
            <p>
              {{ $t('pages.personal.selectedCount') }}：{{ selection.length }}
            </p>
            <p>
              {{ $t('pages.personal.scq') }}：{{ type1Counter }},
              {{ $t('pages.personal.mcq') }}：{{ type2Counter }},
              {{ $t('pages.personal.tfq') }}：{{ type3Counter }}
            </p>
          </div>
          <div class="right">
            <div>
              {{ $t('pages.personal.passingCount') }}({{
                $t('pages.personal.number')
              }})：<el-input-number
                v-model="standard"
                controls-position="right"
                size="small"
                :min="1"
              ></el-input-number>
            </div>
            <div style="margin-top: 10px">
              {{ $t('pages.personal.testDuration') }}({{
                $t('common.min')
              }})：<el-input-number
                v-model="duration"
                controls-position="right"
                size="small"
                :min="1"
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseQuestionList">{{
          $t('common.cancel')
        }}</el-button>
        <el-button
          type="primary"
          v-loading="questionListSubmited"
          @click="toSubmitQuestionList"
          >{{ $t('common.submit') }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 试卷详情 -->
    <el-dialog
      :title="$t('pages.personal.questionList')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showPaperDetail"
      :before-close="toClosePaperDetail"
    >
      <div class="popup-content form" v-if="paperDetail.length > 0">
        <!-- 表格 -->
        <el-table :data="paperDetail" border size="medium" style="width: 100%">
          <el-table-column
            :label="$t('pages.personal.questionStem')"
            prop="content"
          ></el-table-column>
          <!-- <el-table-column
            label="类型"
            width="150"
            prop="courseTypeValues"
          ></el-table-column> -->
          <el-table-column
            :label="$t('pages.personal.questionType')"
            width="120"
            prop="contentTypeValue"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.theAnswer')"
            width="200"
            align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.contentType === '3'
                  ? scope.row.correctOptionIds === 'A'
                    ? $t('common.correct')
                    : $t('common.incorrect')
                  : scope.row.correctOptionIds
              }}
            </template>
          </el-table-column>
        </el-table>
        <div class="count-info">
          <div class="left">
            <p>
              {{ $t('pages.personal.totalCount') }}：{{ paperDetail.length }}
            </p>
            <p>
              {{ $t('pages.personal.scq') }}：{{ type1CounterForPaper }},
              {{ $t('pages.personal.mcq') }}：{{ type2CounterForPaper }},
              {{ $t('pages.personal.tfq') }}：{{ type3CounterForPaper }}
            </p>
          </div>
          <div class="right">
            <div>
              {{ $t('pages.personal.passingCount') }}({{
                $t('pages.personal.number')
              }})：<el-input-number
                :value="paperStandard"
                controls-position="right"
                size="small"
                :disabled="true"
                :controls="false"
                :min="1"
              ></el-input-number>
            </div>
            <div style="margin-top: 10px">
              {{ $t('pages.personal.testDuration') }}({{
                $t('common.min')
              }})：<el-input-number
                v-model="paperDuration"
                controls-position="right"
                size="small"
                :disabled="true"
                :controls="false"
                :min="1"
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toClosePaperDetail">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toClosePaperDetail">{{
          $t('common.close')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 学习记录弹窗 -->
    <el-dialog
      :title="$t('pages.personal.studyRecord')"
      width="1000px"
      :visible.sync="showStudyRecord"
      :before-close="toCloseStudyRecord"
    >
      <div class="popup-content form">
        <div class="oper-area">
          <div class="btn-area">
            <el-button
              class="my-btn"
              type="primary"
              size="mini"
              @click="toExport"
            >
              <i class="iconfont">&#xe612;</i>
              <span>{{ $t('common.exports') }}</span>
            </el-button>
          </div>
          <div class="search-area">
            <el-input
              size="small"
              :placeholder="$t('common.placeHolder_enter')"
              v-model="rKeywords"
              class="input-with-select"
              clearable
              @clear="getStudyRecordData"
              @keyup.enter.native="getStudyRecordData"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getStudyRecordData"
              ></el-button>
            </el-input>
          </div>
        </div>
        <!-- 表格 -->
        <el-table :data="rList" border size="medium" style="width: 100%">
          <el-table-column :label="$t('common.name')" prop="userName" />
          <el-table-column :label="$t('common.account')" prop="loginName" />
          <el-table-column
            :label="$t('common.status')"
            prop="studyStatus"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.studyStatus | typeText(locale) }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('pages.personal.studyDuration')"
            prop="studyTime"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.studyTime | handleTime }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('pages.personal.progress')"
            prop="studySchedule"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.numberOfTests')"
            prop="hasExamTimes"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.numberOfRemaining')"
            prop="leftTimes"
            width="110"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('pages.personal.recentLearningTime')"
            prop="studyLastTime"
            width="120"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="pagination-area-q">
          <el-pagination
            :current-page.sync="rPage"
            :page-size="rPageSize"
            background
            layout="prev, pager, next"
            :total="rTotal"
            @current-change="getStudyRecordData"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseStudyRecord">取 消</el-button>
        <el-button type="primary" @click="toCloseStudyRecord">关闭</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/config';
import {
  selectCoursePlanOfOrg,
  selectCategoryList,
  insertCoursePlanFromOrg,
  updateCoursePlanOfOrg,
  AddUserTag,
  selectTagList,
  selectQuestionListOfOrg,
  insertPaperFromOrg,
  insertPaperRandomFromOrg,
  selectPaperDetailOfOrg,
  deleteCoursePlanOfOrg,
  selectCoursePlanDetailOfOrg,
  selectCourseForPlan,
  bindCourseForCoursePlan,
  selectStudyRecordOfOrg,
  updateCoursePlanRecommendStatus,
  deleteRecommendItem,
  syncSearch,
} from '@/api/common';
const types = {
  0: {
    cn: '全部',
    en: 'All',
  },
  1: {
    cn: '未开始',
    en: 'Not Start',
  },
  2: {
    cn: '学习中',
    en: 'Learning',
  },
  3: {
    cn: '待考试',
    en: 'To Test',
  },
  4: {
    cn: '待补考',
    en: 'Make-up Exam',
  },
  5: {
    cn: '已完成',
    en: 'Completed',
  },
  6: {
    cn: '未完成',
    en: 'Unfinished',
  },
};
export default {
  data() {
    return {
      activeName: '0',
      // 试卷详情
      showPaperDetail: false,
      paperDetail: [],
      // 试题列表
      showQuestionList: false,
      qKeywords: '',
      questionList: [],
      selection: [],
      qIsLoading: false,
      qPage: 1,
      qPageSize: 10,
      qTotal: 0,
      qForm: {
        type: [],
        tags: [],
        qType: '',
        keywords: '',
      },
      standard: 0,
      duration: 30,
      questionListSubmited: false,
      // 随机组卷表单
      showIntelligentForm: false,
      currentItem: null,
      iForm: {
        type: [],
        tags: [],
        type1Count: '',
        type2Count: '',
        type3Count: '',
        standard: '',
        duration: '',
      },
      iRules: {
        // type: [{ required: true, message: '请选择分类', trigger: 'blur' }],
        // tags: [{ required: true, message: '请选择标签', trigger: 'blur' }],
        type1Count: [
          {
            validator: (rule, value, callback) => {
              if (
                this.iForm.type1Count ||
                this.iForm.type2Count ||
                this.iForm.type3Count
              ) {
                callback();
              } else {
                callback(this.$t('pages.other.tianxieshuliang'));
              }
            },
            message: this.$t('pages.other.tianxieshuliang'),
            trigger: 'blur',
          },
        ],
        type2Count: [
          {
            validator: (rule, value, callback) => {
              if (
                this.iForm.type1Count ||
                this.iForm.type2Count ||
                this.iForm.type3Count
              ) {
                callback();
              } else {
                callback(this.$t('pages.other.tianxieshuliang'));
              }
            },
            message: this.$t('pages.other.tianxieshuliang'),
            trigger: 'blur',
          },
        ],
        type3Count: [
          {
            validator: (rule, value, callback) => {
              if (
                this.iForm.type1Count ||
                this.iForm.type2Count ||
                this.iForm.type3Count
              ) {
                callback();
              } else {
                callback(this.$t('pages.other.tianxieshuliang'));
              }
            },
            message: this.$t('pages.other.tianxieshuliang'),
            trigger: 'blur',
          },
        ],
        standard: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(
                  new Error(this.$t('pages.other.tianxiedabiaoshitigeshu'))
                );
              } else if (
                value >
                parseInt(this.iForm.type1Count) +
                  parseInt(this.iForm.type2Count) +
                  parseInt(this.iForm.type3Count)
              ) {
                callback(
                  new Error(
                    this.$t('pages.other.dabiaoshuliangbunengdayushitizhongshu')
                  )
                );
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        duration: [
          {
            required: true,
            message: this.$t('pages.other.qingzhuyikaoshishichang'),
            trigger: 'blur',
          },
        ],
      },
      // 详情
      baseURL,
      disabled: false,
      categoryIsLoading: true,
      categoryOptions: [],
      tagList: [],
      newTag: '',
      form: {
        id: '',
        name: '',
        type: [],
        tags: [],
        cover: '',
        examTimes: 5,
        certificateUrl: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.other.qingtianxiekechengjihuamingcheng'),
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: this.$t('pages.other.qingxuanzekechengjihuafenlei'),
            trigger: 'blur',
          },
        ],
        tags: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingtianjiabiaoqian'));
              }
            },
            message: this.$t('pages.other.qingtianjiabiaoqian'),
            trigger: 'blur',
          },
        ],
        cover: [
          {
            required: true,
            message: this.$t('pages.other.qingshangchuankechengjihuafengmian'),
            trigger: 'blur',
          },
        ],
        certificateUrl: [
          {
            required: true,
            message: this.$t('pages.other.qingshangchaunkechengzhengshu'),
            trigger: 'blur',
          },
        ],
      },
      showDetail: false,
      // 列表
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      // 学习记录
      rList: [],
      rPage: 1,
      rPageSize: 10,
      rTotal: 0,
      rKeywords: '',
      showStudyRecord: false,
      // 关联课程
      addCourseList: false,
      cType: [],
      cSource: '',
      cKeywords: '',
      courseList: [],
      cSelection: [],
      cPage: 1,
      cPageSize: 5,
      cTotal: 0,
      // 查看课程
      showCourseList: false,
      sCourseList: [],
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
    locale() {
      return this.$store.state.locale;
    },
    type1Counter() {
      return this.selection.filter((item) => item.contentType === '1').length;
    },
    type2Counter() {
      return this.selection.filter((item) => item.contentType === '2').length;
    },
    type3Counter() {
      return this.selection.filter((item) => item.contentType === '3').length;
    },
    type1CounterForPaper() {
      return this.paperDetail.filter((item) => item.contentType === '1').length;
    },
    type2CounterForPaper() {
      return this.paperDetail.filter((item) => item.contentType === '2').length;
    },
    type3CounterForPaper() {
      return this.paperDetail.filter((item) => item.contentType === '3').length;
    },
  },
  watch: {
    selection(val) {
      this.standard = Math.ceil(val.length * 0.6);
    },
    activeName() {
      this.page = 1;
      this.getData();
    },
  },
  filters: {
    typeText(type, locale) {
      return types[type][locale];
    },
  },
  methods: {
    async toSync() {
      try {
        await syncSearch({ docType: '1' });
      } catch (error) {
        console.log(error);
      }
    },
    toCancelRecommend(item) {
      this.$confirm(
        this.$t('pages.other.quedingyaoquxiaocikechengzaishouyedetuijianma'),
        this.$t('pages.other.tishi'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          await deleteRecommendItem({
            deleteId: item.coursePlanId,
            recommendType: '1',
          });
          this.toSearch();
        })
        .catch(() => {});
    },
    async toRecommend(item) {
      try {
        await updateCoursePlanRecommendStatus({
          coursePlanId: item.coursePlanId,
        });
        this.$message.success(this.$t('pages.other.tuijianchenggong'));
        this.toSearch();
      } catch (error) {
        console.log(error);
      }
    },
    toCloseStudyRecord() {
      this.currentItem = null;
      this.rList = [];
      this.rPage = 1;
      this.showStudyRecord = false;
    },
    async toExport() {
      axios
        .get(
          `${baseURL}/ruihe/silimar/excelStudyDetail?courseId=${this.currentItem.courseId}`,
          {
            responseType: 'blob',
            headers: {
              'content-disposition': `attachment;filename=xls`,
              'content-type': 'application/x-download;charset=utf-8',
              Authorization: `pBearer ${this.$store.state.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'studyRecord';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    async getStudyRecordData(cb) {
      try {
        const res = await selectStudyRecordOfOrg({
          courseId: this.currentItem.courseId,
          keywords: this.rKeywords,
          pageNum: this.rPage,
          pageSize: this.rPageSize,
        });
        this.rTotal = res.total;
        this.rList = res.data;
        typeof cb === 'function' && cb();
      } catch (error) {
        console.log(error);
      }
    },
    toShowStudyRecord(item) {
      this.currentItem = item;
      this.getStudyRecordData(() => {
        this.showStudyRecord = true;
      });
    },
    toCloseCourseList() {
      this.cSelection = [];
      this.$refs.courseTable.clearSelection();
      this.currentItem = null;
      this.addCourseList = false;
    },
    async toSubmit() {
      console.log(this.cSelection);
      if (this.cSelection.length > 0) {
        try {
          await bindCourseForCoursePlan({
            coursePlanId: this.currentItem.coursePlanId,
            courseIds: this.cSelection.map((item) => item.courseId).join(','),
          });
          this.$message.success(this.$t('pages.other.kechengtianjiachenggong'));
          this.toCloseCourseList();
          this.getData();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error(this.$t('pages.other.qingxuanzekecheng'));
      }
    },
    handleCourseSelectionChange(val) {
      this.cSelection = val;
    },
    toGetCourseData() {
      this.cPage = 1;
      this.getCourseData();
    },
    async getCourseData(cb) {
      try {
        const res = await selectCourseForPlan({
          locale: this.$store.state.locale,
          createType: this.cSource,
          courseName: this.cKeywords,
          pageNum: this.cPage,
          pageSize: this.cPageSize,
          courseTypeIds: this.cType.length > 0 ? this.cType.join(',') : '',
        });
        this.courseList = res.data;
        this.cTotal = res.total;
        cb && cb();
      } catch (error) {
        console.log(error);
      }
    },
    toAddCourseList(item) {
      this.currentItem = item;
      this.getCourseData(() => {
        this.addCourseList = true;
        this.$nextTick(() => {
          if (this.currentItem.courseIds) {
            const ids = this.currentItem.courseIds
              .split(',')
              .map((item) => parseInt(item));
            this.courseList.forEach((course) => {
              if (ids.includes(course.courseId)) {
                this.$refs.courseTable.toggleRowSelection(course);
              }
            });
          }
        });
      });
    },
    toShowCourseList(item) {
      this.sCourseList = item.courseDtoList;
      this.showCourseList = true;
    },
    toUpload() {
      if (this.uploading) return;
      if (this.$refs.uploader.file) {
        this.$refs.uploader.toAuthUpload();
      } else {
        this.$message.error(this.$t('pages.other.qingxuanzewenjian'));
      }
    },
    handleCommand(command, item) {
      switch (command) {
        case 'course': {
          this.toAddCourseList(item);
          break;
        }
        case 'random': {
          this.toShowIntelligentForm(item);
          break;
        }
        case 'manual': {
          this.toShowQuestionList(item);
          break;
        }
        case 'editCourse': {
          this.toShowDetail(item, false);
          break;
        }
        case 'deleteCourse': {
          this.toDelete(item);
          break;
        }
      }
    },
    toClosePaperDetail() {
      this.paperDetail = [];
      this.showPaperDetail = false;
    },
    toCloseQuestionList() {
      this.currentItem = null;
      this.selection = [];
      this.$refs.questionList.clearSelection();
      this.showQuestionList = false;
    },
    async toSubmitQuestionList() {
      if (this.selection.length > 0) {
        if (this.questionListSubmited) return;
        if (this.standard > this.selection.length) {
          this.$message.error(
            this.$t(
              'pages.other.dabiaoshuliangbunengchaoguoyixuanshitishuliang'
            )
          );
          return;
        }
        try {
          this.questionListSubmited = true;
          this.selection = this.selection.sort((a, b) => {
            return a.contentType - b.contentType;
          });
          await insertPaperFromOrg({
            paperType: 1,
            questionIds: this.selection
              .map((item) => item.questionBankId)
              .join(','),
            bindId: this.currentItem.coursePlanId,
            rightAcount: this.standard,
            duration: this.duration,
            allAcount: this.selection.length,
          });
          this.questionListSubmited = false;
          this.$message.success(
            this.$t('pages.other.shijuanchuangjianchenggong')
          );
          this.toSearch();
          this.toCloseQuestionList();
        } catch (error) {
          console.log(error);
          this.questionListSubmited = false;
        }
      } else {
        this.$message.error(this.$t('pages.other.qinggouxuanshiti'));
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    async toShowPaperDetail(item) {
      try {
        const res = await selectPaperDetailOfOrg({
          authorizeId: item.coursePlanId,
          queryType: 1,
        });
        this.paperDetail = res.questionBankList;
        this.showPaperDetail = true;
        this.paperStandard = res.rightAcount;
        this.paperDuration = res.duration;
      } catch (error) {
        console.log(error);
      }
    },
    prepareShowQuestionList(item) {
      this.currentItem = item;
      this.showQuestionList = true;
      this.toSearchQuestion();
    },
    toShowQuestionList(item) {
      if (item.examId) {
        this.$confirm(
          this.$t('pages.other.bunengzaicichuangjianshijuan'),
          this.$t('pages.other.tixing')
        )
          .then(() => {
            this.prepareShowQuestionList(item);
          })
          .catch(() => {});
      } else {
        this.prepareShowQuestionList(item);
      }
    },
    async toSearchQuestion() {
      try {
        this.qIsLoading = true;
        const res = await selectQuestionListOfOrg({
          // courseTypeIds:
          //   this.qForm.type.length > 0
          //     ? this.qForm.type.join(',')
          //     : this.currentItem.courseTypeLabels
          //         .map((item) => item.labelId)
          //         .join(','),
          // labalIds:
          //   this.qForm.tags.length > 0
          //     ? this.qForm.tags.join(',')
          //     : this.currentItem.courseLabalValues
          //         .map((item) => item.labelId)
          //         .join(','),
          contentType: this.qForm.qType,
          content: this.qKeywords,
          locale: this.$store.state.locale,
          pageNum: this.qPage,
          pageSize: this.qPageSize,
        });
        this.questionList = res.data;
        this.qTotal = res.total;
        this.qIsLoading = false;
      } catch (error) {
        console.log(error);
        this.qIsLoading = false;
      }
    },
    toCloseIntelligentForm() {
      this.$refs['iForm'].resetFields();
      this.currentItem = null;
      this.showIntelligentForm = false;
    },
    async toSubmitConditions() {
      this.$refs['iForm'].validate(async (valid) => {
        if (valid) {
          try {
            await insertPaperRandomFromOrg({
              paperType: 1,
              bindId: this.currentItem.coursePlanId,
              oneOptionAcount: this.iForm.type1Count,
              moreOptionAcount: this.iForm.type2Count,
              booleanOptionAcount: this.iForm.type3Count,
              rightAcount: this.iForm.standard,
              duration: this.iForm.duration,
              courseTypeIds: this.iForm.type.join(','),
              courseLabals: this.iForm.tags.join(','),
            });
            this.$message.success(
              this.$t('pages.other.shijuanchuangjianchenggong')
            );
            this.$refs['iForm'].resetFields();
            this.showIntelligentForm = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    prepareShowIntelligentForm(item) {
      this.currentItem = item;
      this.iForm.type = item.courseTypeLabels.map((item) => item.labelId);
      this.iForm.tags = item.courseLabalValues.map((item) => item.labelId);
      this.showIntelligentForm = true;
    },
    toShowIntelligentForm(item) {
      if (item.examId) {
        this.$confirm(
          this.$t('pages.other.bunengzaicichuangjianshijuan'),
          this.$t('pages.other.tixing')
        )
          .then(() => {
            this.prepareShowIntelligentForm(item);
          })
          .catch(() => {});
      } else {
        this.prepareShowIntelligentForm(item);
      }
    },
    toDelete(item) {
      this.$confirm(
        this.$t('pages.other.quedingyaoshanchuma'),
        this.$t('pages.other.tishi'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteCoursePlanOfOrg({ coursePlandIds: item.coursePlanId });
            this.toSearch();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    toCloseDetail() {
      this.$refs['form'].resetFields();
      this.form = {
        id: '',
        name: '',
        type: [],
        tags: [],
        cover: '',
        fileList: [],
        courseware: '',
        antiCheating: '0',
        examTimes: 5,
        certificateUrl: '',
      };
      this.disabled = false;
      this.showDetail = false;
    },
    async toClose() {
      if (this.disabled) {
        this.toCloseDetail();
        return;
      }
      this.$refs['form'].validate(async (valid) => {
        console.log(this.form);
        if (valid) {
          try {
            let params = {
              coursePlanName: this.form.name,
              courseTypeIds: this.form.type.join(','),
              coursePlanLabals: this.form.tags
                ? this.form.tags.map((item) => item.labelId).join(',')
                : '',
              coursePlanCoverUrl: this.form.cover,
              examTimes: this.form.examTimes,
              certificateUrl: this.form.certificateUrl,
              locale: this.$store.state.locale,
            };
            if (this.form.id) {
              params.coursePlanId = this.form.id;
              await updateCoursePlanOfOrg(params);
            } else {
              await insertCoursePlanFromOrg(params);
            }
            this.$message.success(
              `${this.$t('pages.other.kechengjihua')}${
                this.form.id
                  ? this.$t('pages.other.xiugai')
                  : this.$t('pages.other.add')
              }${this.$t('pages.other.chenggong')}！`
            );
            this.toCloseDetail();
            this.toSearch();
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    async toShowDetail(item, flag) {
      try {
        const res = await selectCoursePlanDetailOfOrg({
          coursePlanId: item.coursePlanId,
        });
        this.form.id = res.coursePlanId;
        this.form.name = res.coursePlanName;
        this.form.type = res.courseTypeIds
          .split(',')
          .map((item) => parseInt(item));
        this.form.tags = res.coursePlanLabalValues;
        this.form.cover = res.coursePlanCoverUrl;
        this.form.examTimes = res.examTimes;
        this.form.certificateUrl = res.certificateUrl;
        this.disabled = !!flag;
        this.showDetail = true;
      } catch (error) {
        console.log(error);
      }
    },
    handleImageUploadSuccess(res, field) {
      if (res.code === 200) {
        this.form[field] = res.data.url;
      } else {
        this.$message.error(this.$t('pages.other.shangchuanshibai'));
      }
    },
    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 <= 500;
      if (!isJPG) {
        this.$message.error(this.$t('pages.other.shangchuantupiangeshi'));
      }
      if (!isLt2M) {
        this.$message.error(
          this.$t('pages.other.shangchuantupiandaxiaobunengchaoguo')
        );
      }
      return isJPG && isLt2M;
    },
    toPreviewImage(item) {
      this.$imgPreview({
        imgs: [item.slideImg],
      });
    },
    async toAddTag() {
      if (this.newTag.trim()) {
        const checkArr = this.form.tags.filter(
          (item) => item.label === this.newTag.trim()
        );
        if (checkArr.length === 0) {
          try {
            const res = await AddUserTag({ labalContent: this.newTag.trim() });
            this.form.tags.push({
              labalType: res.labalType,
              label: res.labalContent,
              labelId: res.labalId,
            });
            this.newTag = '';
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message.error(this.$t('pages.other.biaoqianchongfu'));
        }
      } else {
        this.$message.error(this.$t('pages.other.qingshurubiaoqianneirong'));
      }
    },
    async querySearch(queryString, cb) {
      if (queryString) {
        const res = await selectTagList({ label: queryString });
        if (res.length > 0) {
          cb(res);
        } else {
          cb([]);
        }
      }
    },
    toDeleteTag(index) {
      if (this.disabled) return;
      this.form.tags.splice(index, 1);
    },
    toAdd() {
      this.showDetail = true;
    },
    async toGetCategoryList() {
      try {
        const res = await selectCategoryList({
          locale: this.$store.state.locale,
        });
        this.categoryOptions = res;
        this.categoryIsLoading = false;
      } catch (error) {
        console.log(error);
        this.categoryIsLoading = false;
      }
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectCoursePlanOfOrg({
          locale: this.$store.state.locale,
          coursePlanName: this.keywords,
          createType: this.activeName,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        // const arr = [];
        // res.data.forEach(item => {
        //   const obj = {
        //     rowKey: item.coursePlanId,
        //     id: item.coursePlanId,
        //     name: item.coursePlanName,
        //     cover: item.coursePlanCoverUrl,
        //     type: item.courseTypeLabels.map(type => type.label).join(','),
        //     examTimes: item.examTimes
        //   };
        //   if (item.courseDtoList) {
        //     obj.children = [];
        //     item.courseDtoList.forEach(course => {
        //       obj.children.push({
        //         rowKey: `${obj.rowKey}_${course.courseId}`,
        //         id: course.courseId,
        //         name: course.courseName,
        //         cover: course.courseCoverUrl,
        //         type: course.courseTypeValues,
        //         examTimes: course.examTimes
        //       });
        //     });
        //   }
        //   arr.push(obj);
        // });
        this.list = res.data;
        this.total = res.total;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.toGetCategoryList();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.org-course {
  padding: 20px 0 30px;
}
.tab-area {
  padding: 0 30px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 14px;
    height: 2px;
    width: 100%;
    background: #e4e7ed;
  }
  .el-tabs__item.is-active {
    .tab {
      font-weight: bold;
    }
  }
  .tab {
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    user-select: none;
  }
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .btn-area {
      display: flex;
      align-items: center;
      .my-btn {
        margin-right: 10px;
      }
    }
  }
  .delete {
    display: block;
  }
  .sub-content {
    color: $text-color-grey;
  }
  .status {
    &.finished {
      color: $base-color;
    }
    &.in-progress {
      color: #ff8106;
    }
    &.start {
      color: #3dcb6b;
    }
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  .opers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    user-select: none;
    cursor: pointer;
  }
  .el-link {
    margin-right: 10px;
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
      line-height: 1.5;
      margin-top: 10px;
    }
  }
}
.tag-list {
  .tag {
    padding: 8px 20px 8px 16px;
    background: #e6ffee;
    color: #3fca6c;
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    position: relative;
    margin-bottom: 17px;
    & ~ .tag {
      margin-left: 25px;
    }
    &::after {
      content: '';
      border: 15px solid;
      border-color: transparent transparent transparent #e6ffee;
      position: absolute;
      right: -30px;
      top: 0px;
    }
    .delete {
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #3fca6c;
        border-radius: 50%;
        color: #ffffff;
      }
    }
  }
}
::v-deep .el-table {
  border: 1px solid #ebeef5;
  border-bottom: none;
  th {
    background: #f7f7f7;
    // &.el-table-column--selection {
    //   .cell {
    //     padding-left: 14px;
    //   }
    // }
  }
}
.pagination-area-q {
  border: 1px solid #ebeef5;
  border-top: none;
  padding: 18px 0;
  .el-pagination {
    text-align: center;
  }
}
.count-info {
  display: flex;
  padding-top: 30px;
  align-items: center;
  .left {
    flex: 1;
  }
}
.oper-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .btn-area {
    display: flex;
    align-items: center;
    .my-btn {
      margin-right: 10px;
    }
  }
}
.popup-content {
  &.table {
    padding: 0 70px;
    .filter-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 33px;
      .input-with-select {
        width: 250px;
      }
    }
    .name-area {
      display: flex;
      padding: 10px 0;
      align-items: center;
      img {
        width: 82px;
        height: 49px;
        margin-right: 13px;
      }
      .name {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ::v-deep .el-table {
      border: 1px solid #ebeef5;
      border-bottom: none;
      th {
        background: #f7f7f7;
        // text-align: left;
        &.el-table-column--selection {
          .cell {
            padding-left: 14px;
          }
        }
      }
    }
    .pagination-area {
      border: 1px solid #ebeef5;
      border-top: none;
      padding: 18px 0;
      .el-pagination {
        text-align: center;
      }
    }
    .count-info {
      line-height: 1;
      padding-top: 36px;
      .count-area {
        padding-left: 20px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .count {
          font-size: 16px;
          color: $text-color-grey;
        }
        .price {
          font-size: 18px;
          margin-left: 5px;
        }
      }
      // .price-area {
      //   text-align: right;
      //   font-size: 16px;
      //   color: $text-color-grey;
      //   .label {
      //     color: $text-color-black;
      //   }
      //   .price {
      //     font-size: 20px;
      //     font-weight: bold;
      //   }
      //   .red {
      //     color: #ff4c4c;
      //   }
      // }
    }
  }
}
</style>
