<template>
  <div class="news">
    <!-- banner -->
    <the-banner type="4" />
    <!-- 筛选 -->
    <the-filter @change="handleFilte" />
    <!-- 列表 -->
    <div class="list-container">
      <ul class="list" v-if="list.length > 0">
        <li v-for="item in list" :key="item.id">
          <router-link :to="`/news/detail/${item.id}`">
            <div class="img-area">
              <img :src="item.newCoverUrl" alt="" />
            </div>
            <div class="info-area">
              <div class="up-area">
                <div class="title">{{ item.newTitle }}</div>
                <div class="subtitle">
                  <span class="tag">{{ item.ancestorName }}</span>
                </div>
              </div>
              <div class="create-time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </li>
      </ul>
      <the-empty v-else />
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { selectNewsList } from "@/api/common";

import TheBanner from "@/components/TheBanner/TheBanner.vue";
import TheFilter from "@/components/TheFilter/TheFilter.vue";
import TheEmpty from "@/components/TheEmpty/TheEmpty.vue";
export default {
  name: "news",
  components: {
    TheBanner,
    TheFilter,
    TheEmpty,
  },
  data() {
    return {
      category: "",
      area: "",
      industry: "",
      page: 1,
      pageSize: 12,
      total: 0,
      list: [],
    };
  },
  methods: {
    handleFilte(res) {
      console.log(res);
      this.category = res.category;
      this.area = res.area;
      this.industry = res.industry;
      this.page = 1;
      this.list = [];
      this.getData();
    },
    async getData() {
      try {
        const res = await selectNewsList({
          locale: this.$store.state.locale,
          pageNum: this.page,
          pageSize: this.pageSize,
          courseTypeIds: this.category,
          applyBusinessIds: this.industry,
          applyRegionIds: this.area,
        });
        this.list = res.data;
        this.total = res.total;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.news {
  .list-container {
    width: 1200px;
    margin: 30px auto;
    min-height: 647px;
    background: #ffffff;
    .list {
      padding: 15px 40px 70px;
      li {
        a {
          display: flex;
          padding: 15px 0;
          .img-area {
            width: 244px;
            height: 115px;
            margin-right: 40px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .info-area {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .up-area {
              .title {
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
                margin-bottom: 10px;
                transition: all 0.3s;
              }
              .subtitle {
                .tag {
                  display: inline-block;
                  border: 1px solid #dbdbdb;
                  font-size: 12px;
                  color: $text-color-grey;
                  padding: 0px 9px;
                  border-radius: 5px;
                }
              }
            }
            .create-time {
              font-size: 14px;
              color: $text-color-grey;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 85px;
    &.is-background .el-pager li {
      background-color: #ffffff;
      &:not(.disabled).active {
        background-color: #ffbb05;
      }
    }
  }
}
</style>
