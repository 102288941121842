export default {
  teachingAge: '教龄',
  year: '年',
  areasExpertise: '擅长领域',
  applicableArea: '适用区域',
  noRecords: '暂无记录',
  applicableIndustries: '适用行业',
  haveExpertise: '具备专长',
  followed: '已关注',
  followLecturer: '关注讲师',
  coursesTaught: '在授课程',
  noCourses: '暂无在授课程',
  instructors: '讲师介绍',
  favoriteLessonPlans: '收藏课程计划',
  noCollectedPlan: '还未收藏任何课程计划',
  collectedLesson: '收藏课程',
  noCollectionLesson: '还未收藏任何课程',
  unfavorite: '确定要取消收藏吗？',
  keywords: '请输入关键字',
  Newsheadline: '新闻标题',
  publishTime: '发布时间',
  publishResource: '发布来源',
  cancelCollection: '取消收藏',
  jiaoLin: '教龄',
  numberCourses: '课程数',
  noFollowLecture: '还未关注任何讲师',
  EvaluatedLessonPlans: '评价的课程计划',
  rating: '评分',
  noScore: '暂无评价信息',
  Evaluatedcourses: '评价的课程',
  LatestNews: '最新资讯',
  selectFile: '选择文件',
  uploadFileTiShi:
    '支持的视频文件格式：mkv/avi/mp4，建议尺寸:1920x1080px，文件大小不超过2G',
  noSelectFile: '未选择任何文件',
  checkUploadInfomation: '请核对上传文件信息，文件名',
  fileSize: '文件大小',
  fileSuccess: '添加文件成功, 等待上传，请点击上传按钮...',
  startUpload: '文件开始上传...',
  uploadCerdenty: '获取上传凭证失败',
  UploadedSuccessfully: '上传成功',
  Fileuploadfailed: '文件上传失败',
  Fileuploadpaused: '文件已暂停上传',
  Filetimedout: '文件超时...',
  fileuploadcomplete: '文件上传完毕',
  wxSaoBinding: '微信扫一扫绑定',
  PersonalPhotos: '个人照片',
  uploadphotos: '上传照片',
  uploadPhotoTile: '建议上传一寸正装照，尺寸为110px * 154px，比例5：7',
  xueLi: '学历',
  school: '学校',
  Applicablearea: '适用地区',
  Applicableindustries: '适用行业',
  myexpertise: '我的专长',
  PleaseLabel: '请输入标签',
  add: '添加',
  myexperience: '我的经验',
  Pleaseuploadhalfbodyphoto: '请上传半身照',
  Pleaseenterteachingage: '请输入教龄',
  Pleaseselectdegree: '请选择学历',
  PleaseEnterCollege: '请输入院校',
  PleaseTickAreasExpertise: '请勾选擅长领域',
  qingxuanzeshiyongdiqu: '请选择适用地区',
  qingxuanzeshiyonghangye: '请选择适用行业',
  qingtianjiabiaoqian: '请添加标签',
  qingshurujingyan: '请输入经验',
  gengxinchenggong: '更新成功！',
  shangchuanshibai: '上传失败',
  shangchuantouxianggeshi: '上传头像图片只能是 JPG 或 png 格式!',
  shangchuantouxiangdaxiao: '上传头像图片大小不能超过 2MB!',
  biaoqianchongfu: '标签重复！',
  qingshurubiaoqianneirong: '请输入标签内容',
  wenjuandiaochabaocun: '问卷调查保存成功！',
  wenjuandiaochabaocuntishi: '请选择调研问题',
  cuotiguanli: '错题管理',
  qingshuruguanjianzi: '请输入关键字',
  tigan: '题干',
  leixing: '类型',
  tixing: '题型',
  cuotirenshu: '错题人数',
  cuowulv: '错误率',
  caozuo: '操作',
  chakanjiexi: '查看解析',
  cuotijiexi: '错题解析',
  zhengquedaan: '正确答案',
  daanjiexi: '答案解析',
  zanwujiexi: '暂无解析',
  peixunleixing: '培训类型',
  xinzenghebianji: '新增和编辑',
  leixingmingcheng: '类型名称',
  qingshuruleixingmingcheng: '请输入类型名称',
  shangjileixing: '上级类型',
  wushangji: '无上级',
  baocunxiugai: '保存修改',
  xinzeng: '新增',
  chongzhi: '重置',
  leixingguanli: '类型管理',
  tianjiashijian: '添加时间',
  caozuochenggong: '操作成功',
  beiyinyongbunengshanchu: '此记录已被引用，不能删除',
  quedingyaoshanchucileixing: '确定要删除此类型吗？',
  tishi: '提示',
  shitiliebiao: '试题列表',
  xinzengshiti: '新增试题',
  piliangshanchu: '批量删除',
  xiazaidaorumuban: '下载导入模板',
  yinyongcishu: '引用次数',
  shititigan: '试题题干',
  qingtianxie: '请填写',
  qingxuanze: '请选择',
  shitifenlei: '试题分类',
  shititixing: '试题题型',
  shitibiaoqian: '试题标签',
  shitixuanxiang: '试题选项',
  shitidaan: '试题答案',
  shitijiexi: '试题解析',
  cancel: '取 消',
  close: '关闭',
  submit: '提交',
  danxuanti: '单选题',
  duoxuanti: '多选题',
  panduanti: '判断题',
  qingshurutigan: '请输入题干',
  xuanxiangbunengweikong: '选项不能为空',
  qinglurudaan: '请录入答案',
  quedingyaoshanchushitima: '确定要删除试题吗？',
  youshitibeishijuanyinyong: '有试题被试卷引用。',
  weixuanzerenheshiti: '未选择任何试题',
  quedingyaoshanchuma: '确定要删除吗？',
  caozuotixing: '操作提醒',
  shiti: '试题',
  xiugai: '修改',
  chenggong: '成功',
  peixunjihua: '培训计划',
  xinzengpeixunjihua: '新增培训计划',
  peixunjihuamingcheng: '培训计划名称',
  tianjiariqi: '添加日期',
  peixun: '培训',
  qingtianxiepeixunjihuamingcheng: '请填写培训计划名称',
  quedingyaoshanchucitiaojiluma: '确定要删除此条记录吗？',
  mubiaoshouzhong: '目标受众',
  xinzengmubiaoshouzhong: '新增目标受众',
  bianjimubiaoshouzhong: '编辑目标受众',
  tianjiamubiaoshouzhong: '添加目标受众',
  qingtianxiemubiaoshouzhong: '请填写目标受众',
  woyaodingzhi: '我要定制',
  kechengdabao: '课程打包',
  dingzhikecheng: '定制课程',
  tijiaopeixun: '您可以在这里提交您的培训需求',
  peixunketicanjia: '您的企业想培训什么课题？有多少人参加？培训人员层次？',
  peixunxuqiulianxi:
    '提交培训需求后我们会和您取得联系，为您提供最适合您的培训课程方案！',
  company: '公司',
  chenhu: '称呼',
  lianxifangshi: '联系方式',
  jutipeixunxuqiu: '具体培训需求',
  tijiaoxuqiu: '提交需求',
  dabaokecheng: '打包课程',
  shaixuankechengleixing: '筛选课程类型',
  kechengmingcheng: '课程名称',
  kechengleixing: '课程类型',
  jiangshi: '讲师',
  jiage: '价格',
  yixuan: '已选',
  gekecheng: '个课程',
  heji: '合计',
  yingfukuan: '预估应付款',
  pingtaiyouhui: '平台优惠：',
  jigouyouhui: '机构优惠：',
  lijigoumai: '立即购买',
  qingshurugongsimingcheng: '请输入公司名称',
  qingshurunindechenhu: '请输入您的称呼',
  qingshuruzhengquedelianxifangshi: '请输入正确的联系方式',
  qingshurujutixuqiu: '请输入具体需求',
  qingxuanzekecheng: '请至少选择三门课程！',
  dingzhixuqiutijiaochenggong: '定制需求提交成功',
  fanhui: '返回',
  qukaoshi: '去考试',
  mulu: '目录',
  wenda: '问答',
  biji: '笔记',
  qingshurunidewenti: '请输入你的问题',
  tiwen: '提问',
  zaicijilubiji: '在此记录笔记...',
  jilushijian: '记录时间',
  baocun: '保存',
  quedingyaoshanchuzheyitiaobijima: '确定要删除这一条笔记吗？',
  queding: '确定',
  huanyinshiyongruihejiaoyupintai: '欢迎使用锐合教育平台',
  shanchuchenggong: '删除成功',
  shitingyijiesuqugoumai: '试听已结束，去购买吧',
  qugoumai: '去购买',
  tuihejiaoyu: '锐合教育',
  tijiaodingdan: '提交订单',
  dingdanzhifu: '订单支付',
  kaikechenggong: '开课成功',
  querendingdan: '确认订单',
  danjia: '单价',
  shuliang: '数量',
  yigong: '共',
  shangpinzongshangpinjine: '件商品，总商品金额',
  youhuijine: '优惠金额',
  yingfujine: '应付总额',
  shenqingtuikuan: '申请退款',
  pingtaichuli: '平台处理',
  tuikuanchenggong: '退款成功',
  goumaichenggong: '购买成功',
  kaitongxiangguankecheng:
    '我们已经为您开通了相关课程，您可以前往个人中心查看订单。',
  chakandingdan: '查看订单',
  dingdanyitijiaoqingzai: '订单已提交，请在',
  neiwanchengzhifu: '内完成支付！',
  yuqidingdanjiangbeiquxiao: '逾期订单将被取消',
  dingdanyiguoqiqingchongxinxiadan: '订单已过期，请重新下单！',
  dingdanbiaohao: '订单编号',
  zhifujine: '支付金额',
  fukuanfangshi: '付款方式',
  zhifupingtai: '支付平台',
  kechengduhuanma: '课程兑换码',
  fukuanyudaowenti: '付款遇到问题',
  fukuanwanchengtishi1:
    '付款完成后请等待页面跳转，系统将自动为您开通课程学习权限。',
  fukuanwanchengtishi2: '建议您使用现代浏览器进行支付。',
  lijizhifu: '立即支付',
  shuruduihuanma: '输入兑换码',
  yanzhengduihuanma: '验证兑换码',
  zhifubao: '支付宝',
  saomazhifu: '扫码支付',
  saoyisaofukuan: '扫一扫付款（元）',
  dakaishouji: '打开手机',
  saoyisaofukuan2: '扫一扫付款',
  zhifuchenggong: '支付成功!',
  quedingyaoguanbima: '确定要关闭吗？',
  kechengyuyue: '课程预约',
  kechengyuyuexiangqing: '课程预约详情',
  kaikeshijian: '开课时间',
  shoukeduixiang: '授课对象',
  yiyuyue: '已预约',
  lijiyuyue: '立即预约',
  yiyou: '已有',
  renyuyue: '人预约',
  kechengxiangqing: '课程详情',
  jiangshixinxi: '讲师信息',
  gengduoremenkecheng: '更多热门课程',
  chakansuoyoukecheng: '查看所有课程',
  kechengyuyuechenggong: '课程预约成功！',
  quxiaoyuyuechenggong: '取消预约成功！',
  zaixiankaoshi: '在线考试',
  shangyiti: '上一题',
  jiaojuan: '交卷',
  xiayiti: '下一题',
  qianbutimu: '全部题目',
  tijiaochenggong: '提交成功',
  shibai: '失败',
  gerenzhongxin: '个人中心',
  chakanzhengshu: '查看证书',
  kaoshirenzheng: '考试认证',
  ti: '题',
  bencidatigong: '本次答题共',
  dangqiandadui: '当前答对',
  dacuo: '答错',
  kaoshijiesu: '考试结束',
  datiweiwanchengqueding: '答题未完成，确定交卷吗？',
  dengluchenggong: '登录成功！',
  bangdingweixinxiacishiyongweixindenglu:
    '绑定微信，下次使用微信登录更加安全快捷',
  tiaoguo: '跳过',
  bangdingchenggong: '绑定成功！',
  shoujihao: '手机号',
  yanzhengma: '验证码',
  xinshoujihao: '新手机号',
  huoqushoujihao: '获取验证码',
  cishoujihaoyizhuce: '此手机号已注册',
  qingshuruzhengquedeshoujihao: '请输入正确的手机号',
  qingshuruyanzhengma: '请输入验证码',
  shoujihaoxiugaichenggong: '手机号修改成功！',
  shoujihaobangdingchenggong: '手机号绑定成功！',
  chongxinfasong: '重新发送',
  fasongyanzhengma: '发送验证码',
  yanzhengmafasongchenggong: '验证码发送成功',
  kechengbiji: '课程笔记',
  heguiguanliheanjianfangkongkecheng: '合规管理和案件防控课程',
  biaoti: '标题',
  shipinshijian: '视频时间',
  xiangqing: '详情',
  wodebiji: '我的笔记',
  quedingyaoshanchucitiaobijima: '确定要删除此条笔记吗？',
  xiugaichenggong: '修改成功',
  wodewenda: '我的问答',
  quanbu: '全部',
  yijieda: '已解答',
  weijieda: '未解答',
  dengdaihuida: '等待回答',
  nianjiaoling: '年教龄',
  ask: '问',
  answer: '答',
  kechengjihua: '课程计划',
  kechengjihuamingcheng: '课程计划名称',
  qingtianxiekechengjihuamingcheng: '请填写课程计划名称',
  kechengjihuafenlei: '课程计划分类',
  kechengjihuabiaoqian: '课程计划标签',
  kechengjihuafengmian: '课程计划封面',
  shangchuanfengmian: '上传封面',
  geshi: '格式',
  kechengjihuazhengshu: '课程计划证书',
  shangchuanzhengshu: '上传证书',
  kaoshicishu: '考试次数',
  guanliankecheng: '关联课程',
  shaixiankechengleixing: '筛选课程类型',
  kechenglaiyuan: '课程来源',
  ziyoukecheng: '自有课程',
  pingtaikecheng: '平台课程',
  kechengguishu: '课程归属',
  baohankecheng: '包含课程',
  xuexijilu: '学习记录',
  tianxieshuliang: '填写数量',
  tianxiedabiaoshitigeshu: '填写达标试题个数',
  dabiaoshuliangbunengdayushitizhongshu: '达标数量不能大于试题总数',
  qingzhuyikaoshishichang: '请注意考试时长',
  qingxuanzekechengjihuafenlei: '请选择课程计划分类',
  qingshangchuankechengjihuafengmian: '请上传课程计划封面',
  qingshangchaunkechengzhengshu: '请上传课程证书',
  quedingyaoquxiaocikechengzaishouyedetuijianma:
    '确定要取消此课程在首页的推荐吗',
  tuijianchenggong: '推荐成功',
  kechengtianjiachenggong: '课程添加成功',
  qingxuanzewenjian: '请选择文件',
  dabiaoshuliangbunengchaoguoyixuanshitishuliang:
    '达标数量不能超过已选试题数量！',
  shijuanchuangjianchenggong: '试卷创建成功！',
  qinggouxuanshiti: '请勾选试题',
  bunengzaicichuangjianshijuan: '此课程已创建试卷，再次创建将覆盖之前的试卷！',
  shangchuantupiangeshi: '上传图片只能是 JPG 或 png 格式!',
  shangchuantupiandaxiaobunengchaoguo: '上传图片大小不能超过 2MB!',
  qingshurujigoumingcheng: '请输入机构名称',
  qingshurujigoudizhi: '请输入机构地址',
  qingshurujigouguimo: '请输入机构规模',
  qingshangchuanyingyezhizhao: '请上传营业执照',
  qingzhishaotianjiayigejigoufuzheren: '请至少添加一个机构负责人',
  cirenyishilianxiren: '此人已是联系人',
  qingshurupingjianeirong: '请输入评价内容',
  qingshuruyonghuming: '请输入用户名',
  mimayanzhengtishi: '最少6位，由字母数字下划线组成，且不能以数字开头',
  qingshuruxingming: '请输入姓名',
  qingxuanzexingbie: '请选择性别',
  qingshurubumenmingcheng: '请输入部门名称',
  qingshuruzhiwu: '请输入职务',
  quedingyaojiebangweixinma: '确定要解绑微信吗？',
  jiebangchenggong: '解绑成功',
  xinzengpeixun: '新增培训',
  daochu: '导出',
  peixunzhuti: '培训主题',
  peixunxingshi: '培训形式',
  peixunshichang: '培训时长',
  peixunjindu: '培训进度',
  bianjipeixun: '编辑培训',
  tianjiapeixun: '添加培训',
  qingtianxiepeixunzhuti: '请填写培训主题',
  ziyuanleixing: '资源类型',
  peixunziyuan: '培训资源',
  xuanzekecheng: '选择课程',
  qingtianxiepeixunshichang: '请填写培训时长',
  yusuan: '预算',
  qiandaoqingkuang: '签到情况',
  wanchengqingkuang: '完成情况',
  kehoufankui: '课后反馈',
  jilubeicha: '记录备查',
  yijilu: '已记录',
  weijilu: '未记录',
  neibu: '内部',
  waibu: '外部',
  shipin: '视频',
  mianshou: '面授',
  wushijuan: '无试卷',
  qingxuanzepeixunleixing: '请选择培训类型',
  qingxuanzemubiaoshouzhong: '请选择目标受众',
  qingxuanzeziyuanleixing: '请选择资源类型',
  qingxuanzepeixunxingshi: '请选择培训形式',
  qingtianxieshitimingcheng: '请填写试题名称',
  qingtianxieyushoujine: '请填写预售金额',
  quedingyaoquxiaozhanshima: '确定要取消展示吗？',
  quedingyaoshanchucitiaoxinwengonggaoma: '确定要删除此条新闻公告吗？',
  xinwentianjiachenggong: '新闻添加成功',
  xingmingbunengchaoguo30gezi: '姓名不能超过30个字',
  qingtianxiezhengquedeshoujihao: '请填写正确的手机号',
  qingtianxiezhengquedeyouxiangdizhi: '请填写正确的邮箱地址',
  ganxienindefankui: '感谢您的反馈！',
  qingtianxiefankuiyijian: '请填写反馈意见',
  haveClasses: '已开课',
};
