export default {
  myCenter: 'My Center',
  messages: 'Messages',
  switch: 'Switch to ',
  modifyPwd: 'Modify Password',
  logout: 'Logout',
  // sider menu
  index: 'Home',
  personal: 'Personal',
  profiles: 'Profiles',
  orders: 'Order List',
  exp: 'Experience Specialty',
  survey: 'Needs Survey',
  learning: 'Learning',
  courses: 'My Courses',
  coursePlans: 'My Packages',
  myTry: 'My Try',
  appointments: 'My Appointments',
  myWrongs: 'My Wrongs',
  myDemands: 'My Demands',
  myCertificates: 'My Certificates',
  myFavorite: 'My Favorite',
  favCourses: 'Fav Courses',
  favCoursePlans: 'Fav Packages',
  favTeacher: 'Fav Teacher',
  favNews: 'Fav News',
  myRating: 'My Rating',
  myRatingCourses: 'Courses',
  myRatingCoursePlans: 'CPackages',
  orgManage: 'Org Manage',
  orgInfo: 'Org Info',
  orgMember: 'Org Members',
  orgOrders: 'Org Orders',
  orgNews: 'Org News',
  orgBanners: 'Org Banners',
  courseManage: 'Courses',
  orgVideos: 'Videos',
  orgCourses: 'Courses',
  orgCoursePlans: 'Packages',
  qna: 'Q&A',
  statistics: 'Statistics',
  demandManage: 'Demands',
  testManage: 'Test Manage',
  wrongManage: 'Wrongs Manage',
  trainManage: 'Train Manage',
  trainPlans: 'Train Plans',
  trainTarget: 'Train Audience',
  trainType: 'Train Type',
  // 个人中心首页
  stuCourse: 'Course',
  finishedCourse: 'Finished',
  unfinishedCourse: 'Learning',
  toTest: 'To Test',
  makeUpTest: 'Make-up Test',
  stuCoursePlan: 'Packages',
  courseQna: 'Q&A',
  undo: 'Unfinished',
  done: 'Finished',
  stuStatistics: 'Stu Statistics',
  countOfStu: 'Count Of Stu',
  // 调研问题
  surveyTitle1:
    'Dear users, welcome to use ruike risk compliance learning and training platform.',
  surveyTitle2:
    'Before you sail, please answer the following questions so that we can know more about you and send you news about your own courses, lecturers and news.',
  prev: 'Prev',
  fillInTheLater: 'Fill in the later',
  next: 'Next',
  finished: 'Finished',
  askSkip: 'Are you sure to skip the questionnaire?',
  // 机构信息
  guide: 'Guide',
  guideText:
    'Please import the students in the page of "Organization personnel", after which the students can register with the organization',
  orgName: 'Org Name',
  orgAddr: 'Org Addr',
  orgSize: 'Org Size',
  orgLicense: 'License',
  orgManager: 'Manager',
  // 机构成员
  orgStructure: 'Org Structure',
  role: 'Role',
  position: 'Position',
  stuDetail: 'Student Detail',
  sendingSMSNotification: 'Sending SMS Notification',
  departments: 'Departments',
  canotEdit: 'Child bodies cannot be edited',
  deptDetail: 'Dept Detail',
  sendSmsText: 'Send a text message to remind the students to report? ',
  plsEnterDeptName: 'Please enter department name',
  removeConfirmText1: 'Are you sure you want to remove ',
  removeConfirmText2: ' from the facility?',
  // 我的订单
  refundRejection: 'Refund rejection',
  canceled: 'Canceled',
  allOrders: 'ALL',
  unpaid: 'Unpaid',
  paid: 'Paid',
  refund: 'Refund',
  courseName: 'Course name',
  unit: 'CNY',
  price: 'Price',
  promotionPrice: 'Promotion price',
  payPrice: 'Actual payment',
  amount: 'Amount',
  orderTime: 'Order time',
  orderNumber: 'Order number',
  orderPrice: 'Order Price',
  comment: 'Comment',
  invoice: 'Invoice',
  invoicing: 'Invoicing',
  checkProgress: 'Check process',
  checkInvoice: 'Check invoice',
  pay: 'Pay',
  checkProcess: 'Check refund',
  cancelOrder: 'Cancel order',
  cancelRefund: 'Cancel refund',
  auditResult: 'Audit Result',
  toAudit: 'To Audit',
  pass: 'Pass',
  reject: 'Reject',
  rejectReason: 'Reject Reason',
  invoiceInfo: 'Invoice Info',
  invoiceType: 'Invoice Type',
  vat: 'VAT special invoice',
  invoiceAmount: 'Invoice Amount',
  titleType: 'Title Type',
  titleType1: 'Individuals or public institutions',
  titleType2: 'Enterprise',
  title: 'Title',
  TFN: 'TFN',
  companyName: 'Company Name',
  contactName: 'Contact Name',
  depositBank: 'Deposit Bank',
  bankAccount: 'Bank Account',
  enterpriceAddr: 'Enterprice Addr',
  enterpriceTel: 'Enterprice Tel',
  rate: 'Rate',
  content: 'Content',
  cancelRefundText: 'Are you sure you want to cancel your refund?',
  cancelOrderText: 'Are you sure you want to cancel this order?',
  refundAmount: 'Refund Amount',
  refundReason: 'Refund Reason',
  plsEnterReason: 'Please enter refund reason',
  refundText1: 'Please wait patiently while the platform is processing',
  refundText2:
    'The refund is successful and the payment amount has been refunded in the original way',
  // 消息中心
  sendMsg: 'Post Message',
  onlyOrgMsg: 'Only Org Messages',
  // 机构新闻
  syncSearchLib: 'Sync Search Lib',
  whetherHomePageDisplay: 'Whether home page display',
  newsDetail: 'News Detail',
  // 轮播图列表
  noteText: 'Note: A maximum of 5 rounds can be enabled',
  banner: 'Banner',
  url: 'Url',
  isActived: 'Is Activated',
  placeHolder1:
    'Enter the forward address(http(s)://***.***.***). If no forward address is required, leave it blank',
  placeHolder2: 'The higher the number, the higher the order',
  promptText: 'Are you sure you want to cancel the presentation?',
  // 需求管理
  peopleCounting: 'People Counting',
  purchased: 'Purchased',
  // 课程统计
  credits: 'Credits',
  duration: 'Duration',
  completeness: 'Completeness',
  passRate: 'One-time pass rate',
  // 课程问答
  solved: 'Solved',
  unsolved: 'Unsolved',
  question: 'Question',
  questioner: 'Questioner',
  answer: 'Answer',
  ignore: 'Ignore',
  stick: 'Stick',
  unstick: 'Unstick',
  ignoreText:
    'Are you sure you want to ignore the question? If ignored, it will not be displayed in the list',
  // 视频库
  changeVideo: 'Change Video',
  plsEnterName: 'Please enter video name',
  description: 'Description',
  uploadRule:
    'Only mp4 files can be uploaded, and the size does not exceed 2048MB',
  editName: 'Edit Name',
  plsChooseFile: 'Please select file',
  // 机构课程
  orgCourse: 'Org Course',
  platformCourse: 'Platform Course',
  antiCheating: 'Anti-cheating',
  testTimes: 'Test Times',
  record: 'Records',
  viewTest: 'View Test',
  recommend: 'Recommend',
  unrecommend: 'Unrecommend',
  createTestPaper: 'Create Test Paper',
  selectMethod: 'Select Method',
  randomly: 'Randomly',
  manually: 'Manually',
  supplementary: 'Supplementary',
  certificate: 'Certificate',
  courseware: 'Courseware',
  chooseFile: 'Choose File',
  chooseFileText:
    'Supported file formats: PDF, Word, and PPT. The file size cannot exceed 100 MB',
  courseVideo: 'Video',
  chooseVideo: 'Choose Video',
  choose: 'Choose',
  testDuration: 'Duration',
  markNumText:
    'If the number of correct answers is greater than or equal to this number, it is regarded as passing the examination',
  singleChoiceQuestion: 'Single Choice Question',
  multipleChoiceQuestions: 'Multiple Choice Questions',
  trueOrFalseQuestions: 'True Or False Questions',
  passingCount: 'Passing Count',
  questionList: 'Question List',
  plh_category: 'Please select category',
  plh_tag: 'Please select tag',
  plh_type: 'Please select typee',
  questionType: 'Question Type',
  questionStem: 'Question Stem',
  totalCount: 'Total Count',
  selectedCount: 'Selected Count',
  scq: 'Single Choice Question',
  mcq: 'Multiple Choice Questions',
  tfq: 'True Or False Questions',
  number: 'Num',
  theAnswer: 'Answer',
  studyRecord: 'Study Record',
  studyDuration: 'Study Duration',
  progress: 'Progress',
  numberOfTests: 'Number of tests',
  numberOfRemaining: 'Number of remaining',
  recentLearningTime: 'Recent Learning Time',
  showInHomePage: 'Show In HomePage',
  randomlyRuleText:
    'The number of qualified questions cannot be greater than the total number of questions',
  duplicateTag: 'Duplicate Tag',
  createTestRuleText:
    'This course has already created an exam paper, creating it again will overwrite the previous exam paper!',
  // 机构课程计划
  orgCoursePlan: 'Org Packages',
  platformCoursePlan: 'Platform Packages',
  relatedCourses: 'Related courses',
  viewCourse: 'View Courses',
  // 预约课程
  yy:
    'This reservation is available now and you can find it in the course section!',
  yycancel: 'Are you sure you want to cancel?',
  // 我的证书
  certificatesEmpty: 'No certificate record, go to study!',
};
