<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.other.peixun') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-button
            class="my-btn"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="toAdd"
          >
            <span>{{ $t('pages.other.xinzengpeixun') }}</span>
          </el-button>
          <el-button
            class="my-btn"
            type="success"
            size="mini"
            @click="toExport"
          >
            <i class="iconfont">&#xe612;</i>
            <span>{{ $t('pages.other.daochu') }}</span>
          </el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('pages.other.peixunleixing')" prop="typeName"></el-table-column>
        <el-table-column :label="$t('pages.other.peixunzhuti')" prop="trainTheme"></el-table-column>
        <el-table-column :label="$t('pages.other.mubiaoshouzhong')" prop="audName"></el-table-column>
        <el-table-column :label="$t('pages.other.peixunxingshi')" align="center">
          <template slot-scope="scope">
            {{ scope.row.trainMethod | methodName }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('pages.other.peixunshichang')" prop="time"></el-table-column>
        <el-table-column :label="$t('pages.other.caozuo')" width="180" align="center">
          <template slot-scope="scope">
            <el-link type="success" @click="toShowProgress(scope.row)"
              >{{$t('pages.other.peixunjindu')}}</el-link
            >
            <el-link type="warning" @click="toShowDetail(scope.row)"
              >{{$t('common.edit')}}</el-link
            >
            <el-link type="danger" @click="toDelete(scope.row)">{{ $t('common.delete') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="currentItem.id ? $t('pages.other.bianjipeixun') : $t('pages.other.tianjiapeixun')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="toCloseAdd"
      :visible.sync="showDetail"
    >
      <div class="popup-content form" v-if="currentItem">
        <el-form
          label-position="right"
          label-width="180px"
          ref="form"
          :model="currentItem"
          :rules="rules"
        >
          <el-form-item :label=" $t('pages.other.peixunleixing')" prop="typeId">
            <el-select
              v-model="currentItem.typeId"
              :placeholder="$t('pages.other.qingxuanze')"
              style="width: 100%"
            >
              <el-option
                  v-for="item in myTypeList"
                  :key="item.typeId"
                  :label="item.typeName"
                  :value="item.typeId"
                >
              </el-option>
              <!-- <el-option-group
                v-for="group in typeList"
                :key="group.typeId"
                :label="group.typeName"
              >
                <el-option
                  v-for="item in group.children"
                  :key="item.typeId"
                  :label="item.typeName"
                  :value="item.typeId"
                >
                </el-option>
              </el-option-group> -->
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.peixunzhuti')" prop="topic">
            <el-input
              v-model="currentItem.topic"
              :placeholder="$t('pages.other.qingtianxiepeixunzhuti') "
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.mubiaoshouzhong')" prop="targetId">
            <el-select
              v-model="currentItem.targetId"
              :placeholder="$t('pages.other.qingxuanze')"
              style="width: 100%"
            >
              <el-option
                v-for="item in targetList"
                :key="item.audId"
                :label="item.audName"
                :value="item.audId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.ziyuanleixing')" prop="resourceId">
            <el-select
              v-model="currentItem.resourceId"
              :placeholder="$t('pages.other.qingxuanze')"
              style="width: 100%"
            >
              <el-option
                v-for="item in resourceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('pages.other.peixunxingshi')" prop="methodId">
            <el-select
              v-model="currentItem.methodId"
              :placeholder="$t('pages.other.qingxuanze')"
              style="width: 100%"
              @change="handleMethodChange"
            >
              <el-option
                v-for="item in methodList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('pages.other.peixunziyuan')"
            prop="courseId"
            v-if="currentItem.methodId === '0'"
          >
            <div class="video-name" v-if="currentItem.courseName">
              《{{ currentItem.courseName }}》
            </div>
            <el-button
              class="my-btn"
              type=""
              size="medium"
              @click="toShowVideoList"
            >
              <span>{{$t('pages.other.xuanzekecheng')}}</span>
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('pages.other.peixunziyuan')" prop="resourceName" v-else>
            <el-input v-model="currentItem.resourceName" :placeholder="$t('pages.other.qingtianxie')" />
          </el-form-item>
          <el-form-item :label="$t('pages.other.peixunshichang')" prop="duration">
            <el-input
              v-model="currentItem.duration"
              :placeholder="$t('pages.other.qingtianxiepeixunshichang')"
              :readonly="disabled"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.shiti')" prop="testName">
            <el-input
              v-model="currentItem.testName"
              :placeholder="$t('pages.other.qingtianxie')"
              :readonly="disabled && disableTest"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.yusuan')" prop="price">
            <el-input
              v-model="currentItem.price"
              :placeholder="$t('pages.other.qingtianxie')"
              :readonly="disabled"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseAdd">{{$t('pages.other.cancel')}}</el-button>
        <el-button type="primary" @click="toClose">{{$t('pages.other.submit')}}</el-button>
      </div>
    </el-dialog>
    <!-- 关联课程弹窗 -->
    <el-dialog
      :title="$t('pages.other.guanliankecheng')"
      width="1100px"
      :visible.sync="showCourseList"
      :before-close="toCloseCourseList"
    >
      <div class="popup-content table">
        <div class="filter-area">
          <!-- <el-select
            v-model="cType"
            size="small"
            clearable
            placeholder="筛选课程类型"
            @change="toGetCourseData"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.courseTypeId"
              :label="item.courseTypeName"
              :value="item.courseTypeId"
            >
            </el-option>
          </el-select> -->
          <el-select
            v-model="cSource"
            size="small"
            clearable
            :placeholder="$t('pages.other.kechenglaiyuan')"
            @change="toGetCourseData"
          >
            <el-option :label="$t('pages.other.ziyoukecheng')" value="0"> </el-option>
            <el-option :label="$t('pages.other.pingtaikecheng')" value="1"> </el-option>
          </el-select>
          <el-input
            :placeholder="$t('pages.other.keywords')"
            v-model="cKeywords"
            class="input-with-select"
            size="small"
            clearable
            @keyup.enter.native="toGetCourseData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toGetCourseData"
            ></el-button>
          </el-input>
        </div>
        <el-table
          ref="courseTable"
          :data="courseList"
          highlight-current-row
          style="width: 100%"
          @current-change="handleCurrentChange"
        >
          <el-table-column type="index" width="50" label="#"></el-table-column>
          <el-table-column :label="$t('pages.other.kechengmingcheng')">
            <template slot-scope="scope">
              <div class="name-area">
                <img :src="scope.row.courseCoverUrl" alt="" />
                <span class="name">{{ scope.row.courseName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseTypeLabels"
            :label="$t('pages.other.kechengleixing')"
            width="250px"
          >
            <template slot-scope="scope">
              {{
                scope.row.courseTypeLabels
                  ? scope.row.courseTypeLabels
                      .map((type) => type.label)
                      .join(',')
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTypeLabel"
            :label="$t('pages.other.kechengguishu')"
            width="160px"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.createType === '0' ? $t('pages.other.ziyoukecheng') : $t('pages.other.pingtaikecheng') }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-area">
          <el-pagination
            :current-page.sync="cPage"
            :page-size="cPageSize"
            background
            layout="prev, pager, next"
            :total="cTotal"
            @current-change="getCourseData"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toCloseCourseList">{{$t('pages.other.cancel')}}</el-button>
        <el-button type="primary" @click="toCheckCourseData">{{$t('pages.other.queding')}}</el-button>
      </div>
    </el-dialog>
    <!-- 培训进度弹窗 -->
    <el-dialog
      :title="$t('pages.other.peixunjindu')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showProgress"
    >
      <div class="popup-content form" v-if="progress">
        <el-form
          label-position="right"
          label-width="100px"
          ref="form"
          :model="progress"
          :rules="rules"
        >
          <el-form-item :label="$t('pages.other.qiandaoqingkuang')" prop="signInInfo">
            <el-input
              v-model="progress.signInInfo"
              :placeholder="$t('pages.other.qingtianxie')"
              :readonly="disableProgress"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.wanchengqingkuang')" prop="complateInfo">
            <el-input
              v-model="progress.complateInfo"
              :placeholder="$t('pages.other.qingtianxie')"
              :readonly="disableProgress"
            />
          </el-form-item>
          <el-form-item :label="$t('pages.other.kehoufankui')" prop="trainFeedback">
            <el-input v-model="progress.trainFeedback" :placeholder="$t('pages.other.qingtianxie')" />
          </el-form-item>
          <el-form-item :label="$t('pages.other.jilubeicha')" prop="record">
            <el-radio v-model="progress.record" label="0">{{$t('pages.other.yijilu')}}</el-radio>
            <el-radio v-model="progress.record" label="1">{{$t('pages.other.weijilu')}}</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showProgress = false">{{$t('pages.other.cancel')}}</el-button>
        <el-button type="primary" @click="toSaveProgress">{{$t('pages.other.baocun')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/config';
import {
  selectOrgTrainTargetList,
  selectOrgTrainTypeList,
  selectOrgTrainList,
  insertOrgTrain,
  updateOrgTrain,
  deleteOrgTrain,
  selectAllCourseList,
  selectCourseCalcData,
  selectTrainProgressData,
} from '@/api/common';
const methodList = {
  0: '视频',
      1: '面授',
};
export default {
  data() {
    return {
      showDetail: false,
      disabled: true,
      disableTest: true,
      currentItem: {
        id: '',
        typeId: '',
        topic: '',
        targetId: '',
        resourceId: '0',
        methodId: '0',
        courseId: '',
        courseName: '',
        resourceName: '',
        duration: '',
        testId: '',
        testName: '',
        price: '',
      },
      rules: {
        typeId: [{ required: true, message: this.$t('pages.other.qingxuanzepeixunleixing') }],
        topic: [{ required: true, message: this.$t('pages.other.qingtianxiepeixunzhuti') }],
        targetId: [{ required: true, message:this.$t('pages.other.qingxuanzemubiaoshouzhong') }],
        resourceId: [{ required: true, message: this.$t('pages.other.qingxuanzeziyuanleixing') }],
        methodId: [{ required: true, message: this.$t('pages.other.qingxuanzepeixunxingshi') }],
        courseId: [{ required: true, message: this.$t('pages.other.qingxuanzekecheng') }],
        resourceName: [{ required: true, message: this.$t('pages.other.qingtianxie') }],
        duration: [{ required: true, message: this.$t('pages.other.qingtianxiepeixunshichang') }],
        testName: [{ required: true, message: this.$t('pages.other.qingtianxieshitimingcheng') }],
        price: [{ required: true, message: this.$t('pages.other.qingtianxieyushoujine') }],
      },
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      typeList: [],
      targetList: [],
      resourceList: [
        { id: '0', name: this.$t('pages.other.neibu') },
        { id: '1', name: this.$t('pages.other.waibu') },
      ],
      methodList: [
        { id: '0', name: this.$t('pages.other.shipin') },
        { id: '1', name: this.$t('pages.other.mianshou') },
      ],
      // 课程列表
      cKeywords: '',
      cSource: '',
      showCourseList: false,
      cPage: 1,
      cPageSize: 6,
      courseList: [],
      cSelection: null,
      cTotal: 0,
      disableProgress: true,
      showProgress: false,
      progress: {
        id: '',
        signInInfo: '',
        complateInfo: '',
        trainFeedback: '',
        record: '',
      },
    };
  },
 
  filters: {
    methodName(id) {
      return methodList[id];
    },
  },
  computed: {
    myTypeList () {
      const arr = []
      this.typeList.forEach(item => {
        arr.push(item)
        if (item.children && item.children.length > 0) {
          item.children.forEach(val => {
            arr.push(val)
          })
        }
      })
      return arr
    }
  },
  methods: {
    async toExport() {
      axios
        .get(
          `${baseURL}/ruihe/webTrain/exportTrainData?trainPlanId=${this.$route.params.id}`,
          {
            responseType: 'blob',
            headers: {
              'content-disposition': `attachment;filename=xls`,
              'content-type': 'application/x-download;charset=utf-8',
              Authorization: `pBearer ${this.$store.state.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = 'trainPlan';
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          window.URL.revokeObjectURL(blob);
        });
    },
    async toSaveProgress() {
      try {
        await updateOrgTrain({
          trainId: this.progress.id,
          trainPlanId: this.$route.params.id,
          ...this.progress,
        });
        this.$message.success(this.$t('pages.other.caozuochenggong'));
        this.showProgress = false;
      } catch (error) {
        console.log(error);
      }
    },
    async toShowProgress(item) {
      this.progress.id = item.trainId;
      try {
        const res = await selectTrainProgressData({
          trainId: this.progress.id,
        });
        if (item.trainMethod === '0') {
          this.disableProgress = true;
        } else {
          this.disableProgress = false;
        }
        this.progress.signInInfo = res.signInInfo;
        this.progress.complateInfo = res.complateInfo;
        this.progress.trainFeedback = res.trainFeedback;
        this.progress.record = res.record;
        this.showProgress = true;
      } catch (error) {
        console.log(error);
      }
    },
    toCloseAdd() {
      this.disableTest = true;
      this.showDetail = false;
    },
    handleCurrentChange(val) {
      this.cSelection = val;
    },
    async toCheckCourseData() {
      if (this.cSelection) {
        try {
          const res = await selectCourseCalcData({
            courseId: this.cSelection.courseId,
          });
          this.currentItem.courseId = this.cSelection.courseId;
          this.currentItem.courseName = this.cSelection.courseName;
          this.currentItem.price = res.budget;
          this.currentItem.testId = res.ques || '';
          this.currentItem.testName = res.quesName || this.$t('pages.other.wushijuan');
          this.currentItem.duration = res.time;
          if (this.currentItem.testId) {
            this.disableTest = true;
          } else {
            this.disableTest = false;
          }
          this.toCloseCourseList();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error(this.$t('pages.other.qingxuanzekecheng'));
      }
    },
    toCloseCourseList() {
      this.cKeywords = '';
      this.cSource = '';
      this.cSelection = [];
      this.$refs.courseTable.clearSelection();
      this.showCourseList = false;
    },
    toGetCourseData() {
      this.cPage = 1;
      this.getCourseData();
    },
    async getCourseData(cb) {
      try {
        const res = await selectAllCourseList({
          locale: this.$store.state.locale,
          createType: this.cSource,
          courseName: this.cKeywords,
          pageNum: this.cPage,
          pageSize: this.cPageSize,
        });
        this.courseList = res.data;
        this.cTotal = res.total;
        cb && cb();
      } catch (error) {
        console.log(error);
      }
    },
    toShowVideoList() {
      this.getCourseData(() => {
        this.showCourseList = true;
        this.$nextTick(() => {
          if (this.currentItem.courseId) {
            const id = parseInt(this.currentItem.courseId);
            this.courseList.forEach((course) => {
              if (id === course.courseId) {
                this.$refs.courseTable.toggleRowSelection(course);
              }
            });
          }
        });
      });
    },
    handleMethodChange() {
      if (this.currentItem.methodId === '0') {
        // 视频
        this.disabled = true;
        this.currentItem.courseId = '';
        this.currentItem.courseName = '';
        this.currentItem.duration = '';
        this.currentItem.testId = '';
        this.currentItem.testName = '';
        this.currentItem.price = '';
      } else {
        // 面授
        this.disabled = false;
      }
    },
    toAdd() {
      this.currentItem = {
        id: '',
        typeId: '',
        topic: '',
        targetId: '',
        resourceId: '0',
        methodId: '0',
        courseId: '',
        courseName: '',
        resourceName: '',
        duration: '',
        testId: '',
        testName: '',
        price: '',
      };
      this.showDetail = true;
    },
    toDelete(item) {
      if (item.used) {
        this.$message.error(this.$t('pages.other.beiyinyongbunengshanchu'));
      } else {
        this.$confirm(this.$t('pages.other.quedingyaoshanchucitiaojiluma'), this.$t('pages.other.tishi'), {
          type: 'warning',
        })
          .then(async () => {
            try {
              // 删除
              await deleteOrgTrain({ trainIds: item.trainId });
              this.page = 1;
              this.getData();
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {});
      }
    },
    async toClose() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            if (this.currentItem.id) {
              // 修改
              await updateOrgTrain({
                trainId: this.currentItem.id,
                trainPlanId: this.$route.params.id,
                trainTheme: this.currentItem.topic,
                typeId: this.currentItem.typeId,
                audId: this.currentItem.targetId,
                resourceType: this.currentItem.resourceId,
                trainMethod: this.currentItem.methodId,
                resource:
                  this.currentItem.methodId === '0'
                    ? this.currentItem.courseId
                    : this.currentItem.resourceName,
                time: this.currentItem.duration,
                ques:
                  this.currentItem.methodId === '0'
                    ? this.currentItem.testId
                      ? this.currentItem.testId
                      : this.currentItem.testName
                    : this.currentItem.testName,
                budget: this.currentItem.price,
              });
            } else {
              // 新增
              await insertOrgTrain({
                trainPlanId: this.$route.params.id,
                trainTheme: this.currentItem.topic,
                typeId: this.currentItem.typeId,
                audId: this.currentItem.targetId,
                resourceType: this.currentItem.resourceId,
                trainMethod: this.currentItem.methodId,
                resource:
                  this.currentItem.methodId === '0'
                    ? this.currentItem.courseId
                    : this.currentItem.resourceName,
                time: this.currentItem.duration,
                ques:
                  this.currentItem.methodId === '0'
                    ? this.currentItem.testId
                      ? this.currentItem.testId
                      : this.currentItem.testName
                    : this.currentItem.testName,
                budget: this.currentItem.price,
              });
            }
            this.disabled = true;
            this.page = 1;
            this.getData();
            this.showDetail = false;
            this.$message.success(this.$t('pages.other.caozuochenggong'));
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    toShowDetail(item) {
      this.currentItem.id = item.trainId;
      this.currentItem.typeId = item.typeId;
      this.currentItem.typeName = item.typeName;
      this.currentItem.topic = item.trainTheme;
      this.currentItem.targetId = item.audId;
      this.currentItem.targetName = item.audName;
      this.currentItem.resourceId = item.resourceType;
      this.currentItem.methodId = item.trainMethod;
      this.currentItem.courseId = item.trainMethod === '0' ? item.resource : '';
      this.currentItem.courseName = item.resourceName;
      this.currentItem.resourceName = item.resource;
      this.currentItem.duration = item.time;
      this.currentItem.testId = item.trainMethod === '0' ? item.ques : '';
      this.currentItem.testName = item.ques || this.$t('pages.other.wushijuan');
      this.currentItem.price = item.budget;
      this.disabled = item.trainMethod === '0';
      this.showDetail = true;
    },
    async getTargetList() {
      try {
        const res = await selectOrgTrainTargetList();
        this.targetList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getTypeList() {
      try {
        const res = await selectOrgTrainTypeList();
        this.typeList = res;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectOrgTrainList({
          pageNum: this.page,
          pageSize: this.pageSize,
          trainPlanId: this.$route.params.id,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.getTypeList();
    this.getTargetList();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    .btn-area {
      display: flex;
      align-items: flex-end;
      .my-btn {
        margin-right: 10px;
        i {
          line-height: 1;
          font-size: 12px;
          margin-right: 5px;
        }
      }
      a {
        margin-left: 10px;
        color: $base-color;
        text-decoration: underline;
      }
    }
  }
  .banner {
    width: 110px;
    height: 70px;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
      line-height: 1.5;
      padding: 10px 15px 0;
    }
  }
}
.popup-content {
  &.table {
    padding: 0 70px;
    .filter-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 33px;
      .input-with-select {
        width: 250px;
      }
    }
    .name-area {
      display: flex;
      align-items: center;
      img {
        width: 82px;
        height: 49px;
        margin-right: 13px;
      }
      .name {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ::v-deep .el-table {
      border: 1px solid #ebeef5;
      border-bottom: none;
      th {
        background: #f7f7f7;
        // text-align: left;
        &.el-table-column--selection {
          .cell {
            padding-left: 14px;
          }
        }
      }
    }
    .pagination-area {
      border: 1px solid #ebeef5;
      border-top: none;
      padding: 18px 0;
      .el-pagination {
        text-align: center;
      }
    }
    // .count-info {
    //   line-height: 1;
    //   padding-top: 36px;
    //   .count-area {
    //     padding-left: 20px;
    //     margin-bottom: 15px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     .count {
    //       font-size: 16px;
    //       color: $text-color-grey;
    //     }
    //     .price {
    //       font-size: 18px;
    //       margin-left: 5px;
    //     }
    //   }
    // }
  }
}
</style>
