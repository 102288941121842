<template>
  <div class="order-confirm">
    <div class="steps-area">
      <my-steps :active="active" :list="[$t('pages.other.tijiaodingdan'), $t('pages.other.dingdanzhifu'), $t('pages.other.kaikechenggong')]" />
    </div>
    <div class="bottom-content">
      <div class="title">{{$t('pages.other.querendingdan')}}</div>
      <el-table
        :data="list"
        style="width: 100%"
      >
        <el-table-column
          :label="$t('pages.other.kechengmingcheng')"
          width="410"
        >
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.productImg" alt="">
              <span class="name">{{ scope.row.productName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.other.danjia')">
          <template slot-scope="scope">
            <span>￥{{ scope.row.singlePrice | fixZero }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productNumber"
          :label="$t('pages.other.shuliang')"
          width="120"
        >
        </el-table-column>
      </el-table>
      <div class="info-area">
        <p>{{$t('pages.other.yigong')}}{{ totalCount }}{{ $t('pages.other.shangpinzongshangpinjine') }}：<span class="price">￥{{ originPrice | fixZero }}</span> </p>
        <!-- <p>折扣率：<span class="price">85%</span></p> -->
        <p>{{$t('pages.other.youhuijine')}}：<span class="price">-￥{{ discountPrice | fixZero }}</span></p>
        <p>{{ $t('pages.other.yingfujine') }}：<span class="price red">￥{{ payPrice | fixZero }}</span></p>
      </div>
      <div class="btns">
        <el-button type="primary" class="my-btn" @click="toNext">{{ $t('pages.other.tijiaodingdan') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { selectPreOrderData, insertOrder } from '@/api/common';
import MySteps from '@/components/MySteps/MySteps.vue'
export default {
  components: { MySteps },
  data () {
    return {
      active: 1,
      id: '',
      type: '',
      list: [],
      totalCount: 0,
      originPrice: 0,
      payPrice: 0,
      discountPrice: 0
    }
  },
  computed: {
    userinfo () {
      return this.$store.state.userinfo;
    }
  },
  filters: {
    fixZero (val) {
      let str = val.toString();
      const tempArr = str.split('.');
      if (tempArr[1]) {
        if (tempArr[1].length > 1) {
          return val;
        } else {
          return `${tempArr[0]}.${tempArr[1]}`;
        }
      } else {
        return `${tempArr[0]}.00`;
      }
    }
  },
  methods: {
    async toNext () {
      try {
        const res = await insertOrder({
          orderType: this.type,
          productIds: this.id,
          payRole: this.userinfo.roles[this.$store.state.currentRoleIndex]
        });
        this.$router.replace(`/pay/order-pay/${res.orderId}`);
      } catch (error) {
        console.log(error);
      }
    },
    async getData () {
      try {
        const res = await selectPreOrderData({
          orderType: this.type,
          productIds: this.id,
          payRole: this.userinfo.roles[this.$store.state.currentRoleIndex]
        });
        console.log(res);
        this.list = res.productList;
        this.totalCount = res.allAcount;
        this.originPrice = res.orderPrice;
        this.payPrice = res.payPrice;
        this.discountPrice = res.discountPrice;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.order-confirm {
  width: 960px;
  margin: 0 auto;
  .steps-area {
    padding: 70px 30px 90px;
  }
  .bottom-content {
    margin-bottom: 215px;
    min-height: 550px;
    background: #ffffff;
    padding: 40px;
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 20px;
    }
    .name-area {
      display: flex;
      padding: 10px 0;
      align-items: center;
      img {
        width: 82px;
        height: 49px;
        margin-right: 13px;
      }
      .name {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ::v-deep .el-table {
      border: 1px solid #EBEEF5;
      border-bottom: none;
      th {
        background: #F7F7F7;
        text-align: left;
        &.el-table-column--selection {
          .cell {
            padding-left: 14px;
          }
        }
      }
    }
    .info-area {
      text-align: right;
      padding: 40px 0 30px;
      color: $text-color-grey;
      line-height: 24px;
      .price {
        color: $text-color-black;
        &.red {
          color: #FF4C4C;
        }
      }
    }
    .btns {
      text-align: right;
      .my-btn {
        color: $text-color-black;
        width: 174px;
        border-radius: 4px;
      }
    }
  }
}
</style>
