<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.other.peixunjihua') }}</span>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <el-button
            class="my-btn"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="toAdd"
          >
            <span>{{$t('pages.other.xinzengpeixunjihua')}}</span>
          </el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('pages.other.peixunjihuamingcheng')" prop="planName"></el-table-column>
        <el-table-column
          :label="$t('pages.other.tianjiariqi')"
          prop="createTime"
          align="center"
        ></el-table-column>
        <el-table-column :label="$t('pages.other.caozuo')" width="150" align="center">
          <template slot-scope="scope">
            <el-link type="warning" @click="toShowDetail(scope.row)"
              >{{$t('common.edit')}}</el-link
            >
            <el-link type="danger" @click="toDelete(scope.row)">{{ $t('common.delete') }}</el-link>
            <el-link type="success" @click="toNext(scope.row)">{{$t('pages.other.peixun')}}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="currentItem.id ? `${this.$t('common.edit')} ${$t('pages.other.peixunjihuamingcheng')}` :`${this.$t('common.add')} ${$t('pages.other.peixunjihua')}` "
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showDetail"
    >
      <div class="popup-content form" v-if="currentItem">
        <el-form
          label-position="right"
          label-width="150px"
          ref="form"
          :model="currentItem"
          :rules="rules"
        >
          <el-form-item :label="$t('pages.other.peixunjihua')" prop="name">
            <el-input
              v-model="currentItem.name"
              :placeholder="$t('pages.other.qingtianxiepeixunjihuamingcheng')"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{$t('pages.other.cancel')}}</el-button>
        <el-button type="primary" @click="toClose">{{ $t('pages.other.submit') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectOrgTrainPlanList,
  insertOrgTrainPlan,
  updateOrgTrainPlan,
  deleteOrgTrainPlan,
} from '@/api/common';
export default {
  data() {
    return {
      showDetail: false,
      currentItem: {
        id: '',
        name: '',
      },
      rules: {
        name: [{ required: true, message:  this.$t('pages.other.qingtianxiepeixunjihuamingcheng') }],
      },
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    toNext(item) {
      this.$router.push(`org-train-list/${item.trainPlanId}`);
    },
    toAdd() {
      this.currentItem = {
        id: '',
        name: '',
      };
      this.showDetail = true;
    },
    toDelete(item) {
      if (item.used) {
        this.$message.error(this.$t('pages.other.beiyinyongbunengshanchu'));
      } else {
        this.$confirm(this. $t('pages.other.quedingyaoshanchucitiaojiluma'),this.$t('pages.other.tishi'), {
          type: 'warning',
        })
          .then(async () => {
            try {
              // 删除
              await deleteOrgTrainPlan({
                trainPlanIds: item.trainPlanId,
              });
              this.page = 1;
              this.getData();
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {});
      }
    },
    async toClose() {
      if (this.disabled) {
        this.showDetail = false;
      } else {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              if (this.currentItem.id) {
                // 修改
                await updateOrgTrainPlan({
                  trainPlanId: this.currentItem.id,
                  orgId: this.$store.state.orgInfo.orgId,
                  planName: this.currentItem.name,
                });
              } else {
                // 新增
                await insertOrgTrainPlan({
                  planName: this.currentItem.name,
                });
              }
              this.page = 1;
              this.getData();
              this.showDetail = false;
              this.$message.success(this.$t('pages.other.caozuochenggong'));
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    },
    toShowDetail(item) {
      this.currentItem.id = item.trainPlanId;
      this.currentItem.name = item.planName;
      this.showDetail = true;
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectOrgTrainPlanList({
          pageNum: this.page,
          pageSize: this.pageSize,
          orgId: this.$store.state.orgInfo.orgId,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    .intro {
      color: $text-color-grey;
    }
  }
  .banner {
    width: 110px;
    height: 70px;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
      line-height: 1.5;
      padding: 10px 15px 0;
    }
  }
}
</style>
