import axios from 'axios';
import qs from 'qs';
import store from '@/store';
// import router from '@/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { baseURL } from '@/config';
import { Message } from 'element-ui';
import { MessageBox } from 'element-ui';

class HttpRequest {
  constructor(baseUrl = baseURL) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getDefaultConfig() {
    const config = {
      baseURL: this.baseUrl,
      withCredentials: true,
      method: 'post',
      transMethod: 'form',
      showLoading: true,
      timeout: 10000,
    };
    return config;
  }
  interceptors(instance, option) {
    instance.interceptors.request.use(
      (config) => {
        if (store.state.token) {
          config.headers.Authorization = `pBearer ${store.state.token}`;
        }
        // doSomething before request
        if (config.showLoading) {
          // 判断请求队列是否为空，如果为空，则显示loading
          if (!Object.keys(this.queue).length) {
            NProgress.start();
          }
          // 将此次请求放入队列 this.queue
          this.queue[option.url] = true;
        }

        if (config.method === 'get' && config.data) {
          config.params = config.data;
        }

        if (config.transMethod === 'json') {
          return config;
        } else if (config.headers['Content-Type'] === 'multipart/form-data') {
          return config;
        } else {
          config.data = qs.stringify(config.data);
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (res) => {
        // doSomething before response
        delete this.queue[option.url];
        // 将此次请求移出请求队列 this.queue
        // 判断请求队列是否为空，如果为空，则隐藏loading
        if (Object.keys(this.queue).length === 0) {
          NProgress.done();
        }
        const data = res.data;
        if (process.env.NODE_ENV === 'development') {
          console.log(`>>> ${option.url}`);
          console.log(data);
          console.log('<<<');
        }
        // 在返回响应结果之前可以进行数据判断，修改响应结果
        switch (data.code) {
          case 200: {
            return data.data;
          }
          case 202: {
            MessageBox.alert(data.msg);
            return data.data;
          }
          case 400: {
            if (data.msg) {
              Message({
                type: 'error',
                message: data.msg,
              });
            } else {
              Message({
                type: 'error',
                message: '网络错误',
              });
            }
            return Promise.reject();
          }
          case 401: {
            return Promise.reject();
          }
          case 500: {
            Message({
              type: 'error',
              message: '登录已失效，请重新登录',
            });
            store.commit('logout');
            return Promise.reject();
          }
        }
      },
      (error) => {
        delete this.queue[option.url];
        if (Object.keys(this.queue).length === 0) {
          NProgress.done();
        }
        Message({
          type: 'error',
          message: '网络错误，请稍后重试！',
        });
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getDefaultConfig(), options);
    this.interceptors(instance, options);
    return instance(options);
  }
}

export default HttpRequest;
