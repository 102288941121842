import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { setTitle } from '../lib/utils';

import BasicLayout from '@/layout/basic.vue';
import LoginLayout from '@/layout/login.vue';
import PersonalLayout from '@/layout/personal.vue';

// 屏蔽跳转到本身时的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  if (
    location &&
    typeof location == 'string' &&
    location.indexOf('http') != -1
  ) {
    return window.location.open(location, '_self');
  }
  return originalPush.call(this, location).catch((err) => err);
};

const originalResolve = VueRouter.prototype.resolve;
VueRouter.prototype.pushToTab = function pushToTab(location) {
  if (!location) return;
  if (
    location &&
    typeof location == 'string' &&
    location.indexOf('http') != -1
  ) {
    return window.open(location, '_blank');
  }
  const { href } = originalResolve.call(this, location);
  window.open(href, '_blank');
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: BasicLayout,
    redirect: '/index',
    name: 'Index',
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          title: 'index',
        },
        component: () => import('@/views/index'),
      },
      {
        path: '/auth',
        name: 'auth',
        component: LoginLayout,
        redirect: '/auth/login',
        children: [
          {
            path: 'login',
            name: 'login',
            meta: {
              title: 'login',
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/login'),
          },
          {
            path: 'pre-reset*',
            name: 'pre_reset',
            meta: {
              title: 'reset',
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/pre_reset'),
          },
          {
            path: 'reset/:id?',
            name: 'reset',
            meta: {
              title: 'reset',
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/reset'),
          },
          {
            path: 'register-member',
            name: 'registerMember',
            meta: {
              title: 'register',
            },
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '@/views/login/register_member'
              ),
          },
          {
            path: 'register-org-member',
            name: 'registerOrgMember',
            meta: {
              title: 'register',
            },
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '@/views/login/register_org_member'
              ),
          },
          {
            path: 'register-org',
            name: 'registerOrg',
            meta: {
              title: 'register',
            },
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '@/views/login/register_org'
              ),
          },
          {
            path: 'binding/:flag?',
            name: 'binding',
            meta: {
              title: 'binding',
              auth: true,
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/binding'),
          },
          {
            path: 'register-success',
            name: 'registerSuccess',
            meta: {
              title: 'register',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '@/views/login/register_success'
              ),
          },
          {
            path: 'survey',
            name: 'survey',
            meta: {
              title: 'survey',
              auth: true,
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/survey'),
          },
          {
            path: 'reset-tel',
            name: 'resetTel',
            meta: {
              title: 'resetTel',
              auth: true,
            },
            component: () =>
              import(/* webpackChunkName: "auth" */ '@/views/login/reset_tel'),
          },
          {
            path: 'reset-email',
            name: 'resetEmail',
            meta: {
              title: 'resetEmail',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '@/views/login/reset_email'
              ),
          },
        ],
      },
      {
        path: '/course-zone',
        name: 'courseZone',
        meta: {
          title: 'courseZone',
          keepAlive: true,
          deepth: 1,
        },
        component: () =>
          import(/* webpackChunkName: "course" */ '@/views/course/course_zone'),
      },
      {
        path: '/course-zone/detail/:id',
        name: 'courseZoneDetail',
        meta: {
          title: 'courseZoneDetail',
          deepth: 3,
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/course/course_detail'
          ),
      },
      {
        path: '/course-plan',
        name: 'coursePlan',
        meta: {
          title: 'coursePlan',
          keepAlive: true,
          deepth: 1,
        },
        component: () =>
          import(/* webpackChunkName: "course" */ '@/views/course/course_plan'),
      },
      {
        path: '/course-plan/detail/:id',
        name: 'coursePlanDetail',
        meta: {
          title: 'coursePlanDetail',
          deepth: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/course/course_plan_detail'
          ),
      },
      {
        path: '/course-customization',
        name: 'courseCustomization',
        meta: {
          title: 'courseCustomization',
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/course/course_customization'
          ),
      },
      {
        path: '/course-subscribe',
        name: 'courseSubscribe',
        meta: {
          title: 'courseSubscribe',
          keepAlive: true,
          deepth: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/course/course_subscribe'
          ),
      },
      {
        path: '/course-subscribe-detail/:id',
        name: 'courseSubscribeDetail',
        meta: {
          title: 'courseSubscribeDetail',
          deepth: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/course/course_subscribe_detail'
          ),
      },
      {
        path: '/lecturer',
        name: 'lecturer',
        meta: {
          title: 'lecturer',
          keepAlive: true,
          deepth: 1,
        },
        component: () =>
          import(/* webpackChunkName: "course" */ '@/views/lecturer/lecturer'),
      },
      {
        path: '/lecturer/detail/:id',
        name: 'lecturerDetail',
        meta: {
          title: 'lecturerDetail',
          keepAlive: true,
          deepth: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "course" */ '@/views/lecturer/lecturer_detail'
          ),
      },
      {
        path: '/search',
        name: 'search',
        meta: {
          title: 'search',
          keepAlive: true,
          deepth: 1,
        },
        component: () =>
          import(/* webpackChunkName: "course" */ '@/views/course/search'),
      },
      {
        path: '/pay/confirm-order/:id/:type',
        name: 'confirmOrder',
        meta: {
          title: 'confirmOrder',
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ '@/views/pay/order_confirm'),
      },
      {
        path: '/pay/order-pay/:id',
        name: 'orderPay',
        meta: {
          title: 'orderPay',
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ '@/views/pay/order_pay'),
      },
      {
        path: '/pay/order-result',
        name: 'orderResult',
        meta: {
          title: 'orderResult',
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ '@/views/pay/order_result'),
      },
      {
        path: '/pay/refund/:id',
        name: 'refund',
        meta: {
          title: 'refund',
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ '@/views/pay/refund'),
      },
      {
        path: '/news',
        name: 'news',
        meta: {
          title: 'news',
        },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/news'),
      },
      {
        path: '/news/detail/:id',
        name: 'newsDetail',
        meta: {
          title: 'newsDetail',
        },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/news_detail'),
      },
      {
        path: '/personal',
        name: 'personal',
        component: PersonalLayout,
        redirect: '/personal/my-info',
        children: [
          {
            path: 'my-home',
            name: 'myHome',
            meta: {
              title: 'myHome',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_home.vue'
              ),
          },
          {
            path: 'my-info',
            name: 'myInfo',
            meta: {
              title: 'myInfo',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_info'
              ),
          },
          {
            path: 'my-orders',
            name: 'myOrders',
            meta: {
              title: 'myOrders',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_orders'
              ),
          },
          {
            path: 'my-demand',
            name: 'myDemand',
            meta: {
              title: 'myDemand',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_demand'
              ),
          },
          {
            path: 'my-demand-manage',
            name: 'myDemandManage',
            meta: {
              title: 'myDemandManage',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_demand_manage'
              ),
          },
          {
            path: 'my-coll-news',
            name: 'myCollNews',
            meta: {
              title: 'myCollNews',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_coll_news'
              ),
          },
          {
            path: 'my-coll-teacher',
            name: 'myCollTeacher',
            meta: {
              title: 'myCollTeacher',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_coll_teacher'
              ),
          },
          {
            path: 'my-coll-course',
            name: 'myCollCourse',
            meta: {
              title: 'myCollCourse',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_coll_course'
              ),
          },
          {
            path: 'my-coll-course-plan',
            name: 'myCollCoursePlan',
            meta: {
              title: 'myCollCoursePlan',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_coll_course_plan'
              ),
          },
          {
            path: 'my-comment-news',
            name: 'myCommentNews',
            meta: {
              title: 'myCommentNews',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_comment_news'
              ),
          },
          {
            path: 'my-comment-course',
            name: 'myCommentCourse',
            meta: {
              title: 'myCommentCourse',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_comment_course'
              ),
          },
          {
            path: 'my-comment-course-plan',
            name: 'myCommentCoursePlan',
            meta: {
              title: 'myCommentCoursePlan',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_comment_course_plan'
              ),
          },
          {
            path: 'my-subscribe',
            name: 'mySubscribe',
            meta: {
              title: 'mySubscribe',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_subscribe'
              ),
          },
          {
            path: 'teacher-info',
            name: 'teacherInfo',
            meta: {
              title: 'teacherInfo',
              auth: ['4'],
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/teacher_info'
              ),
          },
          {
            path: 'org-info',
            name: 'orgInfo',
            meta: {
              title: 'orgInfo',
              auth: ['2'],
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_info'
              ),
          },
          {
            path: 'org-manage',
            name: 'orgManage',
            meta: {
              title: 'orgManage',
              auth: ['2'],
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_manage'
              ),
          },
          {
            path: 'my-message',
            name: 'myMessage',
            meta: {
              title: 'myMessage',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_message'
              ),
          },
          {
            path: 'my-survey',
            name: 'mySurvey',
            meta: {
              title: 'mySurvey',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/survey'
              ),
          },
          {
            path: 'my-course/:type?',
            name: 'myCourse',
            meta: {
              title: 'myCourse',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_course.vue'
              ),
          },
          {
            path: 'my-course-plan',
            name: 'myCoursePlan',
            meta: {
              title: 'myCoursePlan',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_course_plan.vue'
              ),
          },
          {
            path: 'my-try',
            name: 'myTry',
            meta: {
              title: 'myTry',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_try.vue'
              ),
          },
          {
            path: 'my-wrong-set',
            name: 'myWrongSet',
            meta: {
              title: 'myWrongSet',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_wrong_set.vue'
              ),
          },
          {
            path: 'my-qa-list/:id',
            name: 'myQAList',
            meta: {
              title: 'myQAList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_qa_list.vue'
              ),
          },
          {
            path: 'my-note-list/:id/:name?',
            name: 'myNoteList',
            meta: {
              title: 'myNoteList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_note_list.vue'
              ),
          },
          {
            path: 'my-news-list',
            name: 'myNewsList',
            meta: {
              title: 'myNewsList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_news_list.vue'
              ),
          },
          {
            path: 'my-banner-list',
            name: 'myBannerList',
            meta: {
              title: 'myBannerList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_banner_list.vue'
              ),
          },
          {
            path: 'org-target-list',
            name: 'orgTargetList',
            meta: {
              title: 'orgTargetList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_target_list.vue'
              ),
          },
          {
            path: 'org-type-list',
            name: 'orgTypeList',
            meta: {
              title: 'orgTypeList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_type_list.vue'
              ),
          },
          {
            path: 'org-plan-list',
            name: 'orgPlanList',
            meta: {
              title: 'orgPlanList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_plan_list.vue'
              ),
          },
          {
            path: 'org-train-list/:id',
            name: 'orgTrainList',
            meta: {
              title: 'orgTrainList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_train_list.vue'
              ),
          },
          {
            path: 'org-video-list',
            name: 'orgVideoList',
            meta: {
              title: 'orgVideoList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_video_list.vue'
              ),
          },
          {
            path: 'org-course-list',
            name: 'orgCourseList',
            meta: {
              title: 'orgCourseList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_course.vue'
              ),
          },
          {
            path: 'org-course-plan-list',
            name: 'orgCoursePlanList',
            meta: {
              title: 'orgCoursePlanList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_course_plan.vue'
              ),
          },
          {
            path: 'org-question-list',
            name: 'orgQuestionList',
            meta: {
              title: 'orgQuestionList',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_question_list.vue'
              ),
          },
          {
            path: 'my-qa/:type?',
            name: 'myQa',
            meta: {
              title: 'myQa',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_qa.vue'
              ),
          },
          {
            path: 'org-statistics',
            name: 'orgStatistics',
            meta: {
              title: 'orgStatistics',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_statistics.vue'
              ),
          },
          {
            path: 'org-wrong-set',
            name: 'orgWrongSet',
            meta: {
              title: 'orgWrongSet',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/org_wrong_set.vue'
              ),
          },
          {
            path: 'my-certificate',
            name: 'myCertificate',
            meta: {
              title: 'myCertificate',
              auth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/personal/my_certificate.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/course-study/:id/:type/:index',
    name: 'courseStudy',
    meta: {
      title: 'courseStudy',
    },
    component: () =>
      import(/* webpackChunkName: "course" */ '@/views/course/course_study'),
  },
  {
    path: '/exam/:id/:type',
    name: 'exam',
    meta: {
      title: 'exam',
    },
    component: () =>
      import(/* webpackChunkName: "course" */ '@/views/course/exam'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          savedPosition.behavior = 'smooth';
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0, behavior: 'smooth' });
        }
      }, 100);
    });
  },
});

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (!store.state.token) {
      return next(`/auth/login?redirect=${encodeURIComponent(to.fullPath)}`);
    } else {
      if (
        Array.isArray(to.meta.auth) &&
        !to.meta.auth.includes(
          store.state.userinfo.roles[store.state.currentRoleIndex]
        )
      ) {
        NProgress.start();
        next('/');
      } else {
        NProgress.start();
        next();
      }
    }
  } else {
    NProgress.start();
    next();
  }
});

router.afterEach((to) => {
  NProgress.done();
  to.meta && setTitle(`titles.${to.meta.title}`);
});

export default router;
