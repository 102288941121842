<template>
  <div class="header-top">
    <div class="container">
      <div class="left-area">
        <div class="logo-area">
          <router-link to="/index">
            <img src="@/assets/images/logo.png" alt="锐合教育" />
          </router-link>
        </div>
        <ul class="navs">
          <li>
            <router-link to="/index" class="active">{{
              $t('header.index')
            }}</router-link>
          </li>
          <li>
            <router-link to="/course-zone">{{
              $t('header.course_zone')
            }}</router-link>
          </li>
          <li>
            <router-link to="/course-plan">{{
              $t('header.course_plan')
            }}</router-link>
          </li>
          <li>
            <router-link to="/lecturer">{{
              $t('header.lecturer')
            }}</router-link>
          </li>
          <li>
            <router-link to="/news">{{ $t('header.news') }}</router-link>
          </li>
        </ul>
      </div>
      <div class="right-area">
        <el-autocomplete
          :placeholder="$t('header.placeHolder_input')"
          size="small"
          clearable
          v-model="keywords"
          :fetch-suggestions="querySearch"
          @select="handleSelect"
          @keyup.enter.native="toSearchPage"
          @clear="toSearchPage"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-autocomplete>
        <div class="to-login-area" v-if="!isLogin">
          <router-link class="to-login" to="/auth/login">{{
            $t('common.login')
          }}</router-link>
          <span class="separater">/</span>
          <router-link to="/auth/register-member">{{
            $t('common.register')
          }}</router-link>
        </div>
        <el-dropdown v-else>
          <div class="userinfo-area">
            <div class="avatar-area">
              <span class="msg-notice" v-if="hasNewMsg"></span>
              <el-avatar
                size="small"
                :src="userinfo.avatar"
                v-if="userinfo.avatar"
              />
              <el-avatar size="small" :src="defaultAvatar" v-else />
              <i
                class="iconfont identity"
                v-if="
                  $store.state.userinfo.roles[$store.state.currentRoleIndex] !==
                    '0'
                "
                >&#xe60d;</i
              >
            </div>
            <span class="username">{{ userinfo.name }}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="toPersonal">
                <i class="iconfont">&#xe916;</i>
                <span class="label">{{ $t('pages.personal.myCenter') }}</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="menu-item" @click="toMessage">
                <span class="msg-notice" v-if="hasNewMsg"></span>
                <i class="iconfont">&#xe60e;</i>
                <span class="label">{{ $t('pages.personal.messages') }}</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="userinfo.roles.length > 1 && userinfo.roles[1] !== '1'"
            >
              <div @click="toSwitchIdentity">
                <i class="iconfont">&#xe647;</i>
                <span class="label"
                  >{{ $t('pages.personal.switch')
                  }}{{
                    userinfo.roles[currentRoleIndex === 1 ? 0 : 1]
                      | roleText($store.state.locale)
                  }}</span
                >
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="toReset">
                <i class="iconfont">&#xe600;</i>
                <span class="label">{{ $t('pages.personal.modifyPwd') }}</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="toLogout">
                <i class="iconfont">&#xe60c;</i>
                <span class="label">{{ $t('pages.personal.logout') }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="switch-locale" @click="toSwitchLocale">
          {{ $store.state.locale === 'cn' ? 'EN' : '中' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout, selectHotWords } from '@/api/common';
const roles = {
  0: {
    cn: '学员',
    en: 'student',
  },
  1: {
    cn: '机构学员',
    en: 'institution student',
  },
  2: {
    cn: '班主任',
    en: 'manager',
  },
  3: {
    cn: '小组长',
    en: 'group leader',
  },
  4: {
    cn: '讲师',
    en: 'teacher',
  },
};
export default {
  data() {
    return {
      keywords: '',
      searchWords: [],
      defaultAvatar: require('@/assets/images/default-avatar.png'),
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
    currentRoleIndex() {
      return this.$store.state.currentRoleIndex;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
    hasNewMsg() {
      return this.$store.state.hasNewMsg;
    },
  },
  filters: {
    roleText(id, locale) {
      return roles[id][locale];
    },
  },
  methods: {
    handleSelect(item) {
      this.keywords = item.value;
      this.toSearchPage();
    },
    querySearch(queryString, cb) {
      let searchWords = this.searchWords;
      let results = queryString
        ? searchWords.filter(this.createFilter(queryString))
        : searchWords;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (searchWord) => {
        return (
          searchWord.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    async getSearchWords() {
      try {
        const res = await selectHotWords();
        const arr = [];
        res.forEach((word) => {
          arr.push({
            value: word,
          });
        });
        this.searchWords = arr;
      } catch (error) {
        console.log(error);
      }
    },
    toSearchPage() {
      this.$store.commit('setKeywords', this.keywords);
      if (this.$route.path !== '/search') {
        this.$router.push('/search');
      }
    },
    async toLogout() {
      try {
        await logout();
        this.$store.commit('logout');
      } catch (error) {
        console.log(error);
      }
    },
    toReset() {
      this.$router.push('/auth/pre-reset');
    },
    toSwitchIdentity() {
      this.$store.commit(
        'setCurrentRoleIndex',
        this.currentRoleIndex === 0 ? 1 : 0
      );
      // if (this.role === '2') {
      //   this.$router.push('/personal/org-info');
      // } else if (this.role === '3') {
      //   this.$router.push('/personal/org-course-list');
      // } else {
      //   this.$router.push('/personal');
      // }
      this.$router.push('/personal/my-home');
    },
    toMessage() {
      this.$router.push('/personal/my-message');
    },
    toPersonal() {
      // if (this.role === '2') {
      //   this.$router.push('/personal/org-info');
      // } else if (this.role === '3') {
      //   this.$router.push('/personal/org-course-list');
      // } else {
      //   this.$router.push('/personal');
      // }
      this.$router.push('/personal/my-home');
    },
    toSwitchLocale() {
      if (this.$store.state.locale === 'cn') {
        this.$i18n.locale = 'en';
        this.$store.commit('setLocale', 'en');
      } else {
        this.$i18n.locale = 'cn';
        this.$store.commit('setLocale', 'cn');
      }
      window.location.reload();
    },
  },
  mounted() {
    this.getSearchWords();
  },
};
</script>

<style lang="scss" scoped>
.header-top {
  background: #ffffff;
  height: 100%;
  user-select: none;
  .container {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .left-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-area {
        width: 100px;
        height: 48px;
        margin-right: 70px;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .navs {
        flex: 1;
        min-width: 0;
        font-size: 16px;
        li {
          display: inline-block;
          &:not(:first-child) {
            margin-left: 60px;
          }
          a {
            position: relative;
            &.router-link-active::after {
              content: '';
              width: 16px;
              height: 3px;
              background: $base-color;
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    .right-area {
      display: flex;
      align-items: center;
      .el-input {
        width: 210px;
        height: 34px;
        ::v-deep input {
          height: 100%;
        }
        ::v-deep .el-input__icon {
          line-height: 34px;
        }
      }
      .to-login-area {
        margin-left: 27px;
        padding: 0 15px;
        height: 32px;
        background: $base-color;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        a {
          &:hover {
            color: #ffffff;
          }
        }
        .separater {
          margin: 0 5px;
        }
      }
      .userinfo-area {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 27px;
        width: 120px;
        user-select: none;
        cursor: pointer;
        .username {
          margin-left: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .avatar-area {
          position: relative;
          .msg-notice {
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #f56c6c;
          }
          i.identity {
            position: absolute;
            color: $base-color;
            right: -2px;
            bottom: 0;
            line-height: 1;
          }
        }
      }
      .switch-locale {
        width: 20px;
        margin-left: 20px;
        font-size: 14px;
        color: #909090;
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-dropdown-menu {
  .el-dropdown-menu__item {
    padding: 0;
    a {
      display: block;
    }
  }
}
.menu-item {
  position: relative;
  .msg-notice {
    position: absolute;
    top: 7px;
    right: -6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #f56c6c;
  }
}
</style>
