<template>
  <div>
    <div class="header-area">{{$t('titles.myTry')}}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')+$t('common.keywords')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('titles.courseZoneName')">
          <template slot-scope="scope">
            <div class="name-area" @click="toCourseDetail(scope.row)">
              <img :src="scope.row.productCoverUrl" alt="" />
              <span class="name">{{ scope.row.productName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('titles.StatusStudy')" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.isBuy === "1" ? $t('common.own') : $t('common.trying') }}
          </template>
        </el-table-column>
        <el-table-column width="120" :label="$t('common.tryCount')" align="center">
          <template slot-scope="scope">
            {{ 5 - scope.row.listeningAcount }}
          </template>
        </el-table-column>
        <el-table-column width="180" :label="$t('common.lastTime')" align="center">
          <template slot-scope="scope">
            {{ scope.row.lastStudyTime }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" width="120" align="center">
          <template slot-scope="scope">
            <div class="opers">
              <a
                class="red-text"
                href="javascript:;"
                v-if="scope.row.isBuy !== '1'"
                @click="toBuy(scope.row)"
                >{{$t('common.buyNow')}}</a
              >
              <a
                href="javascript:;"
                @click="toStudy(scope.row)"
                v-if="scope.row.listeningAcount > 0"
                >{{$t('common.continue')}}{{ scope.row.isBuy !== "1" ? $t('common.try') : $t('common.study') }}</a
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectCourseTryList } from "@/api/common";
export default {
  data() {
    return {
      type: 0,
      keywords: "",
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    toStudy(item) {
      this.$router.push(`/course-study/${item.productId}/0/0`);
    },
    toBuy(item) {
      this.$router.push(`/pay/confirm-order/${item.productId}/0`);
      console.log(item);
    },
    toCourseDetail(item) {
      this.$router.push(`/course-zone/detail/${item.productId}`);
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectCourseTryList({
          keyWords: this.keywords,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    cursor: pointer;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .opers {
    a {
      display: block;
      color: $base-color;
      &.red-text {
        color: #ff7373;
      }
    }
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>