<template>
  <div>
    <transition name="fade">
      <div class="service-container" v-show="show">
        <div class="title-area">
          <span>{{ $t('pages.index.service') }}</span>
          <i class="el-icon-close" @click="toClose"></i>
        </div>
        <ul class="msg-list">
          <li
            class="msg-content"
            :class="{ my: item.flow === 'out' }"
            v-for="(item, index) in msgList"
            :key="index"
          >
            <!-- 头像 -->
            <div class="avatar-area">
              <img
                :src="
                  item.flow === 'in' ? logo : userinfo.avatar || defaultAvatar
                "
              />
            </div>
            <div class="msg-area">
              <!-- 文字消息 -->
              <div v-if="item.type === 'text'" class="bubble">
                <span>{{ item.content }}</span>
              </div>
              <!-- 自定义消息 -->
              <div class="bubble" v-else>
                <div class="q-title" v-if="item.title">
                  {{ $t('pages.index.hot') }}
                </div>
                <ul class="q-list">
                  <li
                    v-for="quiz in item.content"
                    :key="quiz.id"
                    @click="toAsk(quiz)"
                  >
                    {{ quiz.serviceQuestion }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <div class="input-area">
          <div class="input-wrap">
            <textarea
              :placeholder="$t('common.placeHolder_enter')"
              :value="msgContent"
              @input="toHandleInput"
            ></textarea>
          </div>
          <div class="btn-area">
            <el-button
              size="mini"
              type="primary"
              :disabled="!msgContent"
              @click="getData"
              >{{ $t('common.send') }}</el-button
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { selectServiceList } from '@/api/common';
export default {
  name: 'TheService',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msgList: [
        {
          type: 'text',
          content: this.$t('pages.index.greeting'),
          flow: 'in',
        },
      ],
      msgContent: '',
      logo: require('@/assets/images/logo_for_service.png'),
      defaultAvatar: require('@/assets/images/default-avatar.png'),
    };
  },
  watch: {
    msgList() {
      this.$nextTick(() => {
        document.querySelector('.msg-list').scrollTop = 9999999;
      });
    },
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    toClose() {
      this.$emit('close');
    },
    toAsk(quiz) {
      this.msgList.push({
        type: 'text',
        content: quiz.serviceQuestion,
        flow: 'out',
      });
      setTimeout(() => {
        this.msgList.push({
          type: 'text',
          content: quiz.serviceAnswer,
          flow: 'in',
        });
      }, 500);
    },
    toHandleInput(e) {
      this.msgContent = e.target.value.trim();
    },
    async getData() {
      try {
        if (this.msgContent.trim()) {
          this.msgList.push({
            type: 'text',
            content: this.msgContent.trim(),
            flow: 'out',
          });
        }
        const res = await selectServiceList({
          keyWords: this.msgContent,
          locale: this.$store.state.locale,
        });
        this.msgContent = '';
        if (res.data.length > 0) {
          this.msgList.push({
            title: this.$t('pages.index.hot'),
            type: 'custom',
            content: res.data,
            flow: 'in',
          });
        } else {
          this.msgList.push({
            type: 'text',
            content: this.$t('pages.index.noHelp'),
            flow: 'in',
          });
        }
      } catch (error) {
        console.log('error');
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.service-container {
  position: fixed;
  z-index: 9999;
  bottom: 55px;
  right: 160px;
  background: #ffffff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 375px;
  .title-area {
    background: $base-color;
    color: #ffffff;
    font-size: 16px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    i {
      cursor: pointer;
      user-select: none;
    }
  }
  .msg-list {
    padding: 15px;
    background: #f1f3f5;
    height: 463px;
    overflow-y: auto;
    .msg-content {
      display: flex;
      padding: 15px 0;
      &.my {
        flex-direction: row-reverse;
        .msg-area {
          margin-left: 0;
          margin-right: 14px;
          .bubble {
            background: $base-color;
            color: #ffffff;
            border-top-left-radius: 14px;
            border-top-right-radius: 0;
            &::before {
              left: auto;
              right: -12.5px;
            }
            &::after {
              left: auto;
              right: -12.5px;
              border-top-left-radius: 14px;
              border-top-right-radius: 0;
            }
          }
        }
      }
      .avatar-area {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .msg-area {
        margin-left: 14px;
        .bubble {
          max-width: 260px;
          border-radius: 14px;
          border-top-left-radius: 0;
          padding: 12px;
          box-sizing: border-box;
          background: #ffffff;
          color: #333333;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -12.5px;
            width: 14px;
            height: 100%;
            background: inherit;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -12.5px;
            width: 14px;
            height: 100%;
            background: #f1f3f5;
            border-top-right-radius: 14px;
          }
          .q-title {
            font-size: 16px;
            line-height: 1;
            padding-bottom: 12px;
            border-bottom: 1px solid #ddd;
          }
          .q-list {
            color: $base-color;
            list-style: disc inside;
            padding-top: 10px;
            li {
              padding: 5px 0;
              cursor: pointer;
              list-style: disc inside;
              &:hover {
                text-decoration: underline;
                text-underline-offset: 4px;
              }
            }
          }
        }
      }
    }
  }
  .input-area {
    box-shadow: 0 -1px 10px 0 rgba($color: #000000, $alpha: 0.1);
    .input-wrap {
      padding: 10px 10px 0;
      textarea {
        border: none;
        outline: none;
        resize: none;
        width: 100%;
        height: 106px;
      }
    }
    .btn-area {
      text-align: right;
      padding: 0px 15px 10px;
    }
  }
}
</style>
