export default {
  courseZone: 'Courses',
  coursePlan: 'Packages',
  courseSubscribe: 'Course Subscribes',
  service: 'Service',
  feedback: 'Feedback',
  mp: 'Mini Program',
  toTop: 'ToTop',
  download: 'Download Index',
  more: 'More',
  customization: 'Customization',
  startTime: 'Start Time',
  contacts: 'CONTACT US',
  BeijingOffice: 'Beijing Office',
  HongKongOffice: 'Hong Kong Office',
  phName: 'Please enter your name',
  phContacts: 'Please enter your contact information',
  phEmail: 'Please enter your email',
  phContent: 'Please enter feedback',
  scanWithWechat: 'Scan with Wechat',
  visitMiniProgram: 'Visit mini program',
  // 客服
  greeting: 'Hello, welcome to Visit RegHub Education, how can I help you?',
  hot: 'Hot',
  noHelp: 'No help information available at this time',
};
