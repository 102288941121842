<template>
  <!-- 筛选 -->
  <div class="filter-area">
    <div class="filter-container">
      <div class="options-area">
        <div class="label" :class="{ en: locale === 'en' }">
          {{ $t('common.courseCategory') }}
        </div>
        <ul class="values">
          <li
            :class="{ active: category === '' }"
            @click="setOption('category', '')"
          >
            {{ $t('common.all') }}
          </li>
          <li
            :class="{ active: category === item.courseTypeId }"
            v-for="item in categoryList"
            :key="item.courseTypeId"
            @click="setOption('category', item.courseTypeId)"
          >
            {{ item.courseTypeName }}
          </li>
        </ul>
      </div>
      <div class="options-area">
        <div class="label" :class="{ en: locale === 'en' }">
          {{ $t('common.applicableArea') }}
        </div>
        <ul class="values">
          <li :class="{ active: area === '' }" @click="setOption('area', '')">
            {{ $t('common.all') }}
          </li>
          <li
            :class="{ active: area === item.regionId }"
            v-for="item in areaList"
            :key="item.regionId"
            @click="setOption('area', item.regionId)"
          >
            {{ item.regionName }}
          </li>
        </ul>
      </div>
      <div class="options-area">
        <div class="label" :class="{ en: locale === 'en' }">
          {{ $t('common.applicableIndustry') }}
        </div>
        <ul class="values">
          <li
            :class="{ active: industry === '' }"
            @click="setOption('industry', '')"
          >
            {{ $t('common.all') }}
          </li>
          <li
            :class="{ active: industry === item.businessId }"
            v-for="item in industryList"
            :key="item.businessId"
            @click="setOption('industry', item.businessId)"
          >
            {{ item.businessName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  selectAreaList,
  selectIndustryList,
  selectCategoryList,
} from '@/api/common';

export default {
  data() {
    return {
      category: '',
      categoryList: [],
      area: '',
      areaList: [],
      industry: '',
      industryList: [],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    setOption(type, value) {
      this[type] = value;
      this.$emit('change', {
        category: this.category,
        area: this.area,
        industry: this.industry,
      });
    },
    async toGetCategoryList() {
      try {
        const res = await selectCategoryList({
          locale: this.$store.state.locale,
        });
        this.categoryList = res;
      } catch (error) {
        console.log(error);
      }
    },
    async toGetAreaList() {
      try {
        let res = await selectAreaList({ locale: this.$store.state.locale });
        this.areaList = res;
      } catch (error) {
        console.log(error);
      }
    },
    async toGetIndustryList() {
      try {
        let res = await selectIndustryList({
          locale: this.$store.state.locale,
        });
        this.industryList = res;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.toGetCategoryList();
    this.toGetAreaList();
    this.toGetIndustryList();
  },
};
</script>

<style lang="scss" scoped>
.filter-area {
  background: #ffffff;
  .filter-container {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0 37px;
    .options-area {
      display: flex;
      font-size: 14px;
      .label {
        color: $text-color-grey;
        margin-right: 27px;
        &.en {
          min-width: 120px;
        }
      }
      ul {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          text-align: center;
          padding: 0 14px;
          height: 24px;
          line-height: 24px;
          border-radius: 3px;
          margin-right: 25px;
          margin-bottom: 18px;
          user-select: none;
          cursor: pointer;
          transition: background 0.3s;
          &.active {
            background: $base-color;
          }
        }
      }
    }
  }
}
</style>
