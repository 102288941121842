export default {
  teachingAge: 'Teaching Age',
  year: 'year',
  areasExpertise: 'Areas of expertise',
  applicableArea: 'Applicable area',
  noRecords: 'No records',
  applicableIndustries: 'Applicable industries',
  haveExpertise: 'have expertise',
  followed: 'Followed',
  followLecturer: 'Follow the lecturer',
  coursesTaught: 'Courses being taught',
  noCourses: 'No courses currently being taught',
  instructors: 'Instructors',
  favoriteLessonPlans: 'Favorite Lesson Plans',
  noCollectedPlan: 'Haven‘t saved any lesson plans yet',
  collectedLesson: 'Favorite courses',
  noCollectionLesson: 'Haven‘t bookmarked any courses yet',
  unfavorite: 'Are you sure you want to unfavorite?',
  keywords: 'Please enter key words',
  Newsheadline: 'News headline',
  publishTime: 'release time',
  publishResource: 'Publish source',
  cancelCollection: 'Cancel Collection',
  jiaoLin: 'Teaching age',
  numberCourses: 'number of courses',
  noFollowLecture: 'Haven‘t followed any lecturers yet',
  EvaluatedLessonPlans: 'Evaluated Lesson Plans',
  rating: 'score',
  noScore: 'No evaluation information yet',
  Evaluatedcourses: 'Evaluated courses',
  LatestNews: 'Latest News',
  selectFile: 'Select the file',
  uploadFileTiShi:
    'Supported video file formats: mkv/avi/mp4, recommended size: 1920x1080px, file size not exceeding 2G',
  noSelectFile: 'No files selected',
  checkUploadInfomation:
    'Please check the uploaded file information, file name',
  fileSize: 'File Size',
  fileSuccess:
    'Add file successfully, waiting for upload, please click the upload button...',
  startUpload: 'File upload starts...',
  uploadCerdenty: 'Failed to get upload credentials',
  UploadedSuccessfully: 'Uploaded Successfully',
  Fileuploadfailed: 'File upload failed',
  Fileuploadpaused: 'File upload paused',
  Filetimedout: 'File timed out...',
  fileuploadcomplete: 'file upload complete',
  wxSaoBinding: 'WeChat scan and bind',
  PersonalPhotos: 'Personal Photos',
  uploadphotos: 'upload photos',
  uploadPhotoTile:
    'It is recommended to upload a one-inch formal dress photo, the size is 110px * 154px, the ratio is 5:7',
  xueLi: 'Education',
  school: 'school',
  Applicablearea: 'Applicable area',
  Applicableindustries: 'Applicable industries',
  myexpertise: 'my expertise',
  PleaseLabel: 'Please enter a label',
  add: 'Add to',
  myexperience: 'my experience',
  Pleaseuploadhalfbodyphoto: 'Please upload half body photo',
  Pleaseenterteachingage: 'Please enter teaching age',
  Pleaseselectdegree: 'Please select a degree',
  PleaseEnterCollege: 'Please enter college',
  PleaseTickAreasExpertise: 'Please tick areas of expertise',
  qingxuanzeshiyongdiqu: 'Please select the applicable region',
  qingxuanzeshiyonghangye: 'Please select the applicable industry',
  qingtianjiabiaoqian: 'please add a tag',
  qingshurujingyan: 'Please enter your experience',
  gengxinchenggong: 'update completed!',
  shangchuanshibai: 'upload failed',
  shangchuantouxianggeshi:
    'The uploaded avatar image can only be in JPG or png format!',
  shangchuantouxiangdaxiao:
    'The size of the uploaded avatar image cannot exceed 2MB!',
  biaoqianchongfu: 'Tag repeat!',
  qingshurubiaoqianneirong: 'Please enter the label content',
  wenjuandiaochabaocun: 'Questionnaire saved successfully!',
  wenjuandiaochabaocuntishi: 'Please select the research question',
  cuotiguanli: 'Error management',
  qingshuruguanjianzi: 'Please enter key words',
  tigan: 'stem',
  leixing: 'type',
  tixing: 'Question type',
  cuotirenshu: 'Number of wrong questions',
  cuowulv: 'Error rate',
  caozuo: 'operate',
  chakanjiexi: 'View Analysis',
  cuotijiexi: 'Error analysis',
  zhengquedaan: 'correct answer',
  daanjiexi: 'Answer analysis',
  zanwujiexi: 'No analysis yet',
  peixunleixing: 'type of training',
  xinzenghebianji: 'Add and edit',
  leixingmingcheng: 'type name',
  qingshuruleixingmingcheng: 'Please enter a type name',
  shangjileixing: 'Superior type',
  wushangji: 'no superior',
  baocunxiugai: 'Save changes',
  xinzeng: 'new add',
  chongzhi: 'reset',
  leixingguanli: 'Type management',
  tianjiashijian: 'add time',
  caozuochenggong: 'Successful operation',
  beiyinyongbunengshanchu:
    'This record is already referenced and cannot be deleted',
  quedingyaoshanchucileixing: 'Are you sure you want to delete this type?',
  tishi: 'hint',
  shitiliebiao: 'List of exam questions',
  xinzengshiti: 'Add test questions',
  piliangshanchu: 'batch deletion',
  xiazaidaorumuban: 'Download import template',
  yinyongcishu: 'Citations',
  shititigan: 'test questions',
  qingtianxie: 'Please fill out',
  qingxuanze: 'Please select',
  shitifenlei: 'question classify',
  shititixing: 'question type',
  shitibiaoqian: 'Question Labels',
  shitixuanxiang: 'Question Options',
  shitidaan: 'Question Answers',
  shitijiexi: 'Questions Analysis',
  cancel: 'cancel',
  close: 'close',
  submit: 'submit',
  danxuanti: 'single choice',
  duoxuanti: 'multiple choice',
  panduanti: 'True or False',
  qingshurutigan: 'Please enter title stem',
  xuanxiangbunengweikong: 'Options cannot be empty',
  qinglurudaan: 'Please enter answer',
  quedingyaoshanchushitima: 'Are you sure you want to delete the question?',
  youshitibeishijuanyinyong:
    'There are test questions that are referenced by the test paper.',
  weixuanzerenheshiti: 'No questions selected',
  quedingyaoshanchuma: 'You sure you want to delete it?',
  caozuotixing: 'Operation reminder',
  shiti: 'Question',
  xiugai: 'modify',
  chenggong: 'success',
  peixunjihua: 'training program',
  xinzengpeixunjihua: 'Add a training plan',
  peixunjihuamingcheng: 'training program name',
  tianjiariqi: 'date added',
  peixun: 'training',
  qingtianxiepeixunjihuamingcheng: 'Please fill in the training program name',
  quedingyaoshanchucitiaojiluma: 'Are you sure you want to delete this record?',
  mubiaoshouzhong: 'Target Audience',
  xinzengmubiaoshouzhong: 'Add new target audience',
  bianjimubiaoshouzhong: 'Edit target audience',
  tianjiamubiaoshouzhong: 'Add target audience',
  qingtianxiemubiaoshouzhong: 'Please fill in the target audience',
  woyaodingzhi: 'I want to customize',
  kechengdabao: 'Course Packaging',
  dingzhikecheng: 'Customized courses',
  tijiaopeixun: 'You can submit your training needs here',
  peixunketicanjia:
    'What subject does your company want to train on? How many people attended? Trainer level?',
  peixunxuqiulianxi:
    'After submitting your training needs, we will get in touch with you to provide you with the most suitable training program for you!',
  company: 'company',
  chenhu: 'name',
  lianxifangshi: 'Contact information',
  jutipeixunxuqiu: 'Specific training needs',
  tijiaoxuqiu: 'Submit a request',
  dabaokecheng: 'Package the course',
  shaixuankechengleixing: 'Filter by course type',
  kechengmingcheng: 'Course Title',
  kechengleixing: 'Course type',
  jiangshi: 'Lecturers',
  jiage: 'price',
  yixuan: 'selected',
  gekecheng: 'courses',
  heji: 'total',
  yingfukuan: 'Estimated payables',
  pingtaiyouhui: 'Platform Offers:',
  jigouyouhui: 'Org Offers:',
  lijigoumai: 'Buy now',
  qingshurugongsimingcheng: 'Please enter company name',
  qingshurunindechenhu: 'Please enter your salutation',
  qingshuruzhengquedelianxifangshi:
    'Please enter the correct contact information',
  qingshurujutixuqiu: 'Please enter specific requirements',
  qingxuanzekecheng: 'Please choose at least three courses!',
  dingzhixuqiutijiaochenggong: 'Custom requirements submitted successfully',
  fanhui: 'back',
  qukaoshi: 'go to the exam',
  mulu: 'content',
  wenda: 'Q&A',
  biji: 'notes',
  qingshurunidewenti: 'Please enter your question',
  tiwen: 'ask questions',
  zaicijilubiji: 'Take notes here...',
  jilushijian: 'record time',
  baocun: 'save',
  quedingyaoshanchuzheyitiaobijima:
    'Are you sure you want to delete this note?',
  queding: '确定',
  huanyinshiyongruihejiaoyupintai: 'Welcome to Ruihe Education Platform',
  shanchuchenggong: 'successfully deleted',
  shitingyijiesuqugoumai: 'The trial is over, go buy it',
  qugoumai: 'to buy',
  tuihejiaoyu: 'Ruihe Education',
  tijiaodingdan: 'Submit orders',
  dingdanzhifu: 'Order payment',
  kaikechenggong: 'Successful start of the course',
  querendingdan: 'Confirm Order',
  danjia: 'unit price',
  shuliang: 'quantity',
  yigong: 'total',
  shangpinzongshangpinjine: 'items, total item amount',
  youhuijine: 'Discounted price',
  yingfujine: 'Total payable',
  shenqingtuikuan: 'Request a refund',
  pingtaichuli: 'Platform processing',
  tuikuanchenggong: 'Refund successfully',
  goumaichenggong: 'successful purchase',
  kaitongxiangguankecheng:
    'We have opened relevant courses for you, you can go to the personal center to check the order.',
  chakandingdan: 'check order',
  dingdanyitijiaoqingzai: 'The order has been submitted, please',
  neiwanchengzhifu: 'complete the payment within! ',
  yuqidingdanjiangbeiquxiao: 'Overdue orders will be cancelled',
  dingdanyiguoqiqingchongxinxiadan:
    'The order has expired, please place a new order!',
  dingdanbiaohao: 'order number',
  zhifujine: 'Payment amount',
  fukuanfangshi: 'payment method',
  zhifupingtai: 'Payment platform',
  kechengduhuanma: 'Course redemption code',
  fukuanyudaowenti: 'Problem with payment',
  fukuanwanchengtishi1:
    'After the payment is completed, please wait for the page to jump, and the system will automatically open the course learning permission for you.',
  fukuanwanchengtishi2:
    'It is recommended that you use a modern browser to pay.',
  lijizhifu: 'pay immediately',
  shuruduihuanma: 'Enter redemption code',
  yanzhengduihuanma: 'Verify redemption code',
  zhifubao: 'Alipay',
  saomazhifu: 'Scan code to pay',
  saoyisaofukuan: 'Scan to pay (yuan)',
  dakaishouji: 'Turn on the phone',
  saoyisaofukuan2: 'Scan to pay',
  zhifuchenggong: 'payment successful!',
  quedingyaoguanbima: 'Are you sure you want to close it?',
  kechengyuyue: 'Course Appointment',
  kechengyuyuexiangqing: 'Course Appointment Details',
  kaikeshijian: 'start time',
  shoukeduixiang: 'Teaching object',
  yiyuyue: 'reserved',
  lijiyuyue: 'book now',
  yiyou: 'have',
  renyuyue: 'people booked',
  kechengxiangqing: 'Course Details',
  jiangshixinxi: 'Lecturers Information',
  gengduoremenkecheng: 'More popular courses',
  chakansuoyoukecheng: 'View all courses',
  kechengyuyuechenggong: 'Course reservation is successful!',
  quxiaoyuyuechenggong: 'Appointment cancelled successfully',
  zaixiankaoshi: 'online examination',
  shangyiti: 'previous question',
  jiaojuan: 'finish up job',
  xiayiti: 'next question',
  qianbutimu: 'All topics',
  tijiaochenggong: 'Submitted successfully',
  shibai: 'fail',
  gerenzhongxin: 'personal center',
  chakanzhengshu: 'View certificates',
  kaoshirenzheng: 'Exam Certification',
  ti: 'topic',
  bencidatigong: 'A total of this question',
  dangqiandadui: 'Current correct answer',
  dacuo: 'wrong answer',
  kaoshijiesu: 'Exam is over',
  datiweiwanchengqueding:
    'The answer has not been completed, are you sure to submit it?',
  dengluchenggong: 'login successful!',
  bangdingweixinxiacishiyongweixindenglu:
    'Binding WeChat, the next time you use WeChat to log in is safer and faster',
  tiaoguo: 'jump over',
  bangdingchenggong: 'Binding succeeded!',
  shoujihao: 'Phone number',
  yanzhengma: 'verification code',
  xinshoujihao: 'new phone number',
  huoqushoujihao: 'get verify code',
  cishoujihaoyizhuce: 'This phone number is already registered',
  qingshuruzhengquedeshoujihao: 'please enter a valid phone number',
  qingshuruyanzhengma: 'please enter verification code',
  shoujihaoxiugaichenggong: 'The phone number has been modified successfully!',
  shoujihaobangdingchenggong: 'Mobile number binding is successful!',
  chongxinfasong: 'Resend',
  fasongyanzhengma: 'Send the verification code',
  yanzhengmafasongchenggong: 'Verification code sent successfully',
  kechengbiji: 'Course Notes',
  heguiguanliheanjianfangkongkecheng:
    'Compliance Management and Case Prevention Course',
  biaoti: 'title',
  shipinshijian: 'video time',
  xiangqing: 'detail',
  wodebiji: 'my notes',
  quedingyaoshanchucitiaobijima: 'Are you sure you want to delete this note?',
  xiugaichenggong: 'Successfully modified',
  wodewenda: 'my Q&A',
  quanbu: 'all',
  yijieda: 'answered',
  weijieda: 'unanswered',
  dengdaihuida: 'waiting for answer',
  nianjiaoling: 'teaching age',
  ask: 'ask',
  answer: 'answer',
  kechengjihua: 'Lesson plan',
  kechengjihuamingcheng: 'Lesson plan name',
  qingtianxiekechengjihuamingcheng: 'Please fill in the lesson plan name',
  kechengjihuafenlei: 'Lesson Plan Classification',
  kechengjihuabiaoqian: 'Lesson Plan Tab',
  kechengjihuafengmian: 'Lesson Plan Cover',
  shangchuanfengmian: 'upload cover',
  geshi: 'Format',
  kechengjihuazhengshu: 'Lesson Plan Certificate',
  shangchuanzhengshu: 'Upload certificate',
  kaoshicishu: 'number of exams',
  guanliankecheng: 'Associated Courses',
  shaixiankechengleixing: 'Filter by course type',
  kechenglaiyuan: 'Course Source',
  ziyoukecheng: 'own course',
  pingtaikecheng: 'Platform course',
  kechengguishu: 'Course attribution',
  baohankecheng: 'Include courses',
  xuexijilu: 'learning record',
  tianxieshuliang: 'Fill in the quantity',
  tianxiedabiaoshitigeshu: 'Fill in the number of exam questions',
  dabiaoshuliangbunengdayushitizhongshu:
    'The number of qualified items cannot be greater than the total number of test questions',
  qingzhuyikaoshishichang: 'Please pay attention to the test time',
  qingxuanzekechengjihuafenlei: 'Please select a lesson plan category',
  qingshangchuankechengjihuafengmian: 'Please upload the lesson plan cover',
  qingshangchaunkechengzhengshu: 'Please upload course certificate',
  quedingyaoquxiaocikechengzaishouyedetuijianma:
    'Are you sure you want to cancel the recommendation of this course on the homepage',
  tuijianchenggong: 'Recommended success',
  kechengtianjiachenggong: 'Course added successfully',
  qingxuanzewenjian: 'Please select file',
  dabiaoshuliangbunengchaoguoyixuanshitishuliang:
    'The number of qualified items cannot exceed the number of selected exam questions!',
  shijuanchuangjianchenggong: 'The test paper was created successfully!',
  qinggouxuanshiti: 'Please tick the question',
  bunengzaicichuangjianshijuan:
    'An exam paper has been created for this course, creating it again will overwrite the previous exam paper!',
  shangchuantupiangeshi: 'Uploaded images can only be in JPG or png format!',
  shangchuantupiandaxiaobunengchaoguo: 'Upload image size cannot exceed 2MB!',
  qingshurujigoumingcheng: 'Please enter organization name',
  qingshurujigoudizhi: 'Please enter the institution address',
  qingshurujigouguimo: 'Please enter the size of the organization',
  qingshangchuanyingyezhizhao: 'Please upload business license',
  qingzhishaotianjiayigejigoufuzheren: 'Please add at least one agency head',
  cirenyishilianxiren: 'This person is already a contact',
  qingshurupingjianeirong: 'Please enter review content',
  qingshuruyonghuming: 'please enter user name',
  mimayanzhengtishi:
    'At least 6 characters, consisting of alphanumeric underscores, and cannot start with a number',
  qingshuruxingming: 'Please type in your name',
  qingxuanzexingbie: 'Please select gender',
  qingshurubumenmingcheng: 'Please enter department name',
  qingshuruzhiwu: 'Please enter job title',
  quedingyaojiebangweixinma: 'Are you sure you want to unbind WeChat?',
  jiebangchenggong: 'Unbind successfully',
  xinzengpeixun: 'Add training',
  daochu: 'export',
  peixunzhuti: 'Training topics',
  peixunxingshi: 'training form',
  peixunshichang: 'training time',
  peixunjindu: 'training progress',
  bianjipeixun: 'editorial training',
  tianjiapeixun: 'Add training',
  qingtianxiepeixunzhuti: 'Please fill in the training topic',
  ziyuanleixing: 'Resource Type',
  peixunziyuan: 'training resources',
  xuanzekecheng: 'Choose a course',
  qingtianxiepeixunshichang: 'Please fill in the training duration',
  yusuan: 'budget',
  qiandaoqingkuang: 'Sign in',
  wanchengqingkuang: 'Completion',
  kehoufankui: 'After class feedback',
  jilubeicha: 'record for reference',
  yijilu: 'recorded',
  weijilu: 'not recorded',
  neibu: 'internal',
  waibu: 'external',
  shipin: 'video',
  mianshou: 'face to face',
  wushijuan: 'No test paper',
  qingxuanzepeixunleixing: 'Please select a training type',
  qingxuanzemubiaoshouzhong: 'Please select target audience',
  qingxuanzeziyuanleixing: 'Please select a resource type',
  qingxuanzepeixunxingshi: 'Please select a training format',
  qingtianxieshitimingcheng: 'Please fill in the question name',
  qingtianxieyushoujine: 'Please fill in the pre-sale amount',
  quedingyaoquxiaozhanshima: 'Are you sure you want to cancel the impression?',
  quedingyaoshanchucitiaoxinwengonggaoma:
    'Are you sure you want to delete this news bulletin?',
  xinwentianjiachenggong: 'News added successfully',
  xingmingbunengchaoguo30gezi: 'Name cannot exceed 30 characters',
  qingtianxiezhengquedeshoujihao: 'Please fill in the correct mobile number',
  qingtianxiezhengquedeyouxiangdizhi:
    'Please fill in the correct email address',
  ganxienindefankui: 'Thanks for your feedback!',
  qingtianxiefankuiyijian: 'Please fill in feedback',
  haveClasses: 'Have classes',
};
// {{ $t('pages.other.renyuyue') }}
// this.$t('pages.other.qingtianxiefankuiyijian')
// {{ $t('common.delete') }}
