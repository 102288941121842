<template>
  <div class="course-plan">
    <div class="section">
      <div class="breadcrumb-area">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t('titles.index')
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course-subscribe' }">{{
            $t('pages.other.kechengyuyue')
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            $t('pages.other.kechengyuyuexiangqing')
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 课程信息 -->
      <div class="course-info" v-if="detail">
        <div class="img-area">
          <img
            :src="detail.courseApointCoverUrl"
            :alt="detail.courseApointName"
          />
        </div>
        <div class="info">
          <div class="title">{{ detail.courseApointName }}</div>
          <div class="line">
            <div class="label">{{ $t('pages.other.kaikeshijian') }}：</div>
            <div class="value">{{ detail.courseStartTime }}</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('pages.other.shoukeduixiang') }}：</div>
            <div class="value">{{ detail.applyPeople }}</div>
          </div>
          <!-- <price-num
            :val="detail.courseApointPrice"
            color="#FF4C4C"
            :signSize="20"
            :intSize="30"
            :pointSize="30"
            :decSize="30"
            :showDec="false"
          /> -->
          <div class="btns">
            <el-button
              type="primary"
              class="my-btn"
              @click="toCourseDetail"
              v-if="detail.courseId"
              >{{ $t('pages.other.haveClasses') }}</el-button
            >
            <el-button
              type="primary"
              class="my-btn"
              @click="toToggleColl"
              v-else
              >{{
                isColl ? $t('pages.other.yiyuyue') : $t('pages.other.lijiyuyue')
              }}</el-button
            >
            <span class="count"
              >{{ $t('pages.other.yiyou')
              }}<span class="focus">{{ detail.apointAcount }}</span
              >{{ $t('pages.other.renyuyue') }}</span
            >
          </div>
          <!-- <div class="coll-tag" @click="toToggleColl">
            <i class="iconfont" v-if="isColl">&#xe629;</i>
            <i class="iconfont" v-else>&#xe62a;</i>
            {{ isColl ? '已收藏' : '收藏课程' }}
          </div> -->
        </div>
      </div>
      <!-- 内容 -->
      <div class="bottom-container">
        <div class="left">
          <!-- 选项卡 -->
          <div class="tab-area">
            <el-tabs class="my-tabs" v-model="activeName">
              <el-tab-pane
                :label="$t('pages.other.kechengxiangqing')"
                name="first"
              >
                <div
                  class="detail-content"
                  v-if="detail"
                  v-html="detail.courseApointDetail"
                ></div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- right -->
        <div class="right">
          <!-- 讲师信息 -->
          <router-link
            class="teacher-info"
            :to="`/lecturer/detail/${teacherInfo.userId}`"
            v-if="teacherInfo"
          >
            <div class="title-area">
              <div class="title">{{ $t('pages.other.jiangshixinxi') }}</div>
            </div>
            <div class="img-area">
              <img :src="teacherInfo.bustShot" :alt="teacherInfo.userName" />
            </div>
            <p class="name">{{ teacherInfo.userName }}</p>
            <div class="info-text">
              <p class="first">
                {{ teacherInfo.officialLabel }} - {{ teacherInfo.school }}
              </p>
              <p class="second">
                {{ $t('pages.other.areasExpertise') }}：{{
                  teacherInfo.courseTypeLabals
                }}
              </p>
            </div>
          </router-link>
          <!-- 更多热门计划 -->
          <div class="more-plan">
            <div class="title-area">
              <div class="title">
                {{ $t('pages.other.gengduoremenkecheng') }}
              </div>
              <router-link to="/" class="more">
                {{ $t('common.more') }}
                <i class="iconfont">&#xe60a;</i>
              </router-link>
            </div>
            <ul class="list">
              <li
                v-for="item in recommendList"
                :key="item.courseApointId"
                @click="toDetail(item)"
              >
                <div class="img-area">
                  <img
                    :src="item.courseApointCoverUrl"
                    :alt="item.courseApointName"
                  />
                </div>
                <div class="name-area">
                  <div class="name">{{ item.courseApointName }}</div>
                  <!-- <price-num
                    :val="item.courseApointPrice"
                    color="#FF4C4C"
                    :signSize="12"
                    :intSize="20"
                    :pointSize="20"
                    :decSize="20"
                    :showDec="false"
                  /> -->
                </div>
              </li>
            </ul>
            <div class="to-all">
              <router-link to="/course-subscribe">{{
                $t('pages.other.chakansuoyoukecheng')
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  selectCourseSubscribeDetail,
  updateSubscribeStatus,
} from '@/api/common';

import PriceNum from '../../components/PriceNum/PriceNum.vue';
export default {
  components: {
    PriceNum,
  },
  data() {
    return {
      activeName: 'first',
      id: '',
      isColl: false,
      detail: null,
      teacherInfo: null,
      recommendList: [],
    };
  },
  filters: {
    fixZero(val) {
      return val.toString().length > 1 ? val : `0${val}`;
    },
  },
  methods: {
    toCourseDetail() {
      this.$router.push(`/course-zone/detail/${this.detail.courseId}`);
    },
    toDetail(item) {
      this.id = item.courseApointId;
      this.page = 1;
      this.getData();
    },
    async toToggleColl() {
      try {
        await updateSubscribeStatus({
          apointId: this.id,
        });
        this.isColl = !this.isColl;
        if (this.isColl) {
          this.detail.apointAcount++;
          this.$message.success(this.$t('pages.other.kechengyuyuechenggong'));
        } else {
          this.detail.apointAcount--;
          this.$message.success(this.$t('pages.other.quxiaoyuyuechenggong'));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await selectCourseSubscribeDetail({
          courseApointId: this.id,
        });
        console.log(res);
        this.isColl = res.isApoint === '1';
        this.detail = res.courseApoint;
        this.teacherInfo = res.teacherDto;
        this.recommendList = res.CourseApointList ? res.CourseApointList : [];
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.course-plan {
  .section {
    .breadcrumb-area {
      padding: 15px 0;
    }
    width: 1200px;
    margin: 0 auto;
    .course-info {
      padding: 30px 30px 40px;
      background: #ffffff;
      display: flex;
      .img-area {
        width: 450px;
        height: 250px;
        margin-right: 70px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex: 1;
        min-width: 0;
        position: relative;
        .title {
          font-size: 24px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 24px;
          width: 470px;
        }
        .line {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          line-height: 20px;
          color: $text-color-grey;
          margin-bottom: 10px;
          .value {
            flex: 1;
            min-width: 0;
          }
        }
        .btns {
          margin-top: 10px;
          .my-btn {
            border-radius: 0;
            width: 160px;
          }
          .count {
            margin-left: 30px;
            .focus {
              color: $base-color;
            }
          }
        }
        // .coll-tag {
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   width: 114px;
        //   height: 34px;
        //   border-radius: 17px;
        //   color: #ffffff;
        //   font-size: 14px;
        //   background: #FF5C5C;
        //   user-select: none;
        //   cursor: pointer;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   .iconfont {
        //     font-size: 14px;
        //     margin-right: 3px;
        //   }
        // }
      }
    }
    .bottom-container {
      margin: 30px auto 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        width: 900px;
        background: #ffffff;
        padding: 30px 30px 0;
        margin-right: 20px;
        ::v-deep .el-tabs {
          .el-tabs__active-bar {
            height: 3px;
          }
          .el-tabs__item {
            color: $text-color-grey;
            font-size: 16px;
            &.is-active {
              color: $text-color-black;
              font-weight: bold;
            }
          }
        }
        .detail-content {
          padding-bottom: 30px;
          img {
            max-width: 100%;
          }
        }
        .comment-list {
          padding: 20px 0 30px;
          li {
            position: relative;
            padding: 25px 0 25px 76px;
            .img-area {
              width: 32px;
              height: 32px;
              position: absolute;
              top: 25px;
              left: 39px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 32px;
              .name {
                color: #8d8d8d;
                margin-bottom: 5px;
                width: 380px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .create-time {
                color: $text-color-grey;
              }
            }
          }
        }
        .el-pagination {
          text-align: center;
          margin-bottom: 60px;
        }
      }
      .right {
        flex: 1;
        min-width: 0;
        .teacher-info {
          display: block;
          padding: 15px;
          background: #ffffff;
          user-select: none;
          .img-area {
            margin: 20px auto;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            transition: color 0.3s;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .info-text {
            padding: 0 15px;
            text-align: center;
            font-size: 14px;
            .first {
              color: #333333;
              margin-bottom: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .second {
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              min-height: 50px;
            }
          }
        }
        .more-plan {
          margin-top: 20px;
          padding: 15px;
          background: #ffffff;
        }
        .title-area {
          padding-left: 10px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          color: #333333;
          &::before {
            content: '';
            width: 4px;
            height: 16px;
            background: $base-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          .more {
            font-size: 12px;
            font-weight: normal;
            display: inline-flex;
            align-items: center;
            transition: color 0.3s;
            .iconfont {
              font-size: 12px;
            }
          }
        }
        .list {
          li {
            padding: 15px 0 10px;
            cursor: pointer;
            &:not(:last-child) {
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
            }
            .img-area {
              width: 100%;
              height: 140px;
              margin-bottom: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              transition: color 0.3s;
              .name {
                flex: 1;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .to-all {
          text-align: center;
          padding: 20px 0;
          a {
            display: inline-block;
            width: 128px;
            height: 31px;
            line-height: 29px;
            border: 1px solid #ccc;
            border-radius: 3px;
            text-align: center;
            color: $text-color-grey;
            transition: all 0.3s;
            &:hover {
              color: $base-color;
              border-color: $base-color;
            }
          }
        }
      }
    }
  }
}
</style>
