<template>
  <div>
    <div class="header-area">{{ $t('titles.myCourse') }}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="btn-area">
          <!-- <el-button class="my-btn" type="primary" size="mini">
            <i class="iconfont">&#xe612;</i>
            <span>导出</span>
          </el-button> -->
          <el-dropdown @command="changeType">
            <el-button class="my-btn" type="" size="small">
              {{ type | typeText }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="'0'">{{
                $t('common.all')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'1'">{{
                $t('common.haveNotStarted')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'2'">{{
                $t('common.learning')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'3'">{{
                $t('common.waitingExamination')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'4'">{{
                $t('common.makeUpExamination')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'5'">{{
                $t('common.completed')
              }}</el-dropdown-item>
              <el-dropdown-item :command="'6'">{{
                $t('common.unfinished')
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="
              $t('common.placeHolder_enter') + $t('common.keywords')
            "
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('titles.courseZoneName')" width="310">
          <template slot-scope="scope">
            <div class="name-area">
              <img :src="scope.row.authorizeCoverUrl" alt="" />
              <span class="name">{{ scope.row.authorizeName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('titles.StatusStudy')" width="150">
          <template slot-scope="scope">
            <div
              class="progress-area"
              v-if="scope.row.studyInfoDtos.length > 0"
            >
              <p>
                {{ $t('common.completed') }}
                <span
                  class="status"
                  :class="[
                    scope.row.studyInfoDtos[0].studySchedule === '0%'
                      ? 'start'
                      : scope.row.studyInfoDtos[0].studySchedule === '100%'
                      ? 'finished'
                      : 'in-progress',
                  ]"
                  >{{ scope.row.studyInfoDtos[0].studySchedule }}</span
                >
              </p>
              <p class="sub-content">
                {{
                  scope.row.studyInfoDtos[0].studyStatus === '2'
                    ? `${$t('common.study')} ${handleTime(
                        scope.row.studyInfoDtos[0].studyTime
                      )}`
                    : handleStudyStatus(scope.row.studyInfoDtos[0].studyStatus)
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          :label="$t('common.lastTime')"
          align="center"
        >
          <template slot-scope="scope">
            {{
              (scope.row.studyInfoDtos[0] &&
                scope.row.studyInfoDtos[0].studyLastTime) ||
                '--'
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              v-if="!scope.row.children"
              @click="toStudy(scope.row)"
              >{{ $t('common.study') }}</el-link
            >
            <el-link
              type="danger"
              @click="toExam(scope.row)"
              v-if="
                scope.row.studyInfoDtos[0] &&
                  scope.row.studyInfoDtos[0].studyStatus === '3'
              "
              >{{ $t('titles.exam') }}</el-link
            >
            <el-link
              type="danger"
              @click="toExam(scope.row)"
              v-if="
                scope.row.studyInfoDtos[0] &&
                  scope.row.studyInfoDtos[0].studyStatus === '4'
              "
              >{{ $t('common.makeUpExamination') }}</el-link
            >
            <el-link type="success" @click="toMyNote(scope.row)">{{
              $t('titles.LookOverNoteList')
            }}</el-link>
            <el-link type="warning" @click="toMyQuestion(scope.row)">{{
              $t('common.questions')
            }}</el-link>
            <el-link type="info" @click="toDownload(scope.row)">{{
              $t('common.download')
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { selectMyCourseOrCoursePlan } from '@/api/common';
import { baseURL } from '@/config';
let types = {};
export default {
  data() {
    return {
      type: '0',
      keywords: '',
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  filters: {
    typeText(type) {
      return types[type];
    },
  },
  created() {
    types = {
      0: this.$t('common.all'),
      1: this.$t('common.haveNotStarted'),
      2: this.$t('common.learning'),
      3: this.$t('common.waitingExamination'),
      4: this.$t('common.makeUpExamination'),
      5: this.$t('common.completed'),
      6: this.$t('common.unfinished'),
    };
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  methods: {
    handleTime(val) {
      if (val) {
        let hour = Math.floor(val / 3600);
        let minu = Math.floor((val - 3600 * hour) / 60);
        let sec = Math.floor(val - hour * 3600 - minu * 60);
        if (minu.toString().length === 1) {
          minu = `0${minu}`;
        }
        if (sec.toString().length === 1) {
          sec = `0${sec}`;
        }
        if (hour) {
          if (hour.toString().length === 1) {
            hour = `0${hour}`;
          }
          return `${hour}:${minu}:${sec}`;
        } else {
          return `${minu}:${sec}`;
        }
      } else {
        return '00:00';
      }
    },
    handleStudyStatus(status) {
      return types[status];
    },
    toDownload(item) {
      const url = `${baseURL}/ruihe/file/downloadZip?zipName=${item.authorizeName}&filePath=${item.courseWareUrl}&fileName=${item.courseWareName}`
      window.open(url, '_blank')
      // console.log(item)
      // donwnloadZip({
      //   zipName: item.authorizeName,
      //   filePath: item.courseWareUrl,
      //   fileName: item.courseWareName
      // }).then(data => {
      //   console.log(data)
      // }, err => {
      //   console.log(err)
      // })
      // donwnloadZip
      // let a = document.createElement('a');
      // a.href = item.courseWareUrl;
      // a.download = 'temp';
      // a.dispatchEvent(
      //   new MouseEvent('click', {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window,
      //   })
      // );
    },
    toMyNote(item) {
      this.$router.push(
        `/personal/my-note-list/${item.authorizeId}/${item.authorizeName}`
      );
    },
    toMyQuestion(item) {
      this.$router.push(`/personal/my-qa-list/${item.authorizeId}`);
    },
    toExam(item) {
      this.$router.push(`/exam/${item.authorizeId}/0`);
    },
    toStudy(item) {
      this.$router.push(`/course-study/${item.authorizeId}/0/0`);
    },
    changeType(type) {
      this.type = type;
      this.page = 1;
      this.getData();
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMyCourseOrCoursePlan({
          queryType: 0,
          studyStatus: this.type,
          pageNum: this.page,
          pageSize: this.pageSize,
          authorizeName: this.keywords,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .btn-area {
      display: flex;
      align-items: center;
      .my-btn {
        margin-right: 10px;
      }
    }
  }
  .delete {
    display: block;
  }
  .sub-content {
    color: $text-color-grey;
  }
  .status {
    &.finished {
      color: $base-color;
    }
    &.in-progress {
      color: #ff8106;
    }
    &.start {
      color: #3dcb6b;
    }
  }
  .name-area {
    display: flex;
    padding: 10px 0;
    align-items: center;
    img {
      width: 92px;
      height: 54px;
      margin-right: 13px;
    }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .el-link {
    margin-right: 10px;
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
