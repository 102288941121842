<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title-area">
      <span class="text">{{
        $t(isLogin ? 'pages.login.title_modify' : 'pages.login.title_reset')
      }}</span>
    </div>
    <!-- 表单 -->
    <div class="form-area">
      <el-radio-group v-model="method">
        <div class="radio-area">
          <el-radio label="1" :disabled="isLogin && !userinfo.tel">
            <div class="label-text">
              <p class="main-label">{{ $t('pages.login.byPhone') }}</p>
              <p class="sub-label" v-if="isLogin">
                {{
                  userinfo.tel
                    ? `${$t('pages.login.byPhoneText')}${userinfo.tel}`
                    : $t('pages.login.byPhonePlh')
                }}
              </p>
            </div>
          </el-radio>
        </div>
        <div class="radio-area">
          <el-radio label="2" :disabled="isLogin && !userinfo.email">
            <div class="label-text">
              <p class="main-label">{{ $t('pages.login.byEmail') }}</p>
              <p class="sub-label" v-if="isLogin">
                {{
                  userinfo.email
                    ? `${$t('pages.login.byEmailText')}${userinfo.email}`
                    : $t('pages.login.byEmailPlh')
                }}
              </p>
            </div>
          </el-radio>
        </div>
      </el-radio-group>
      <div class="btn-area">
        <el-button type="primary" @click="toNext">{{
          $t('common.next')
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      method: '1',
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  methods: {
    toNext() {
      this.$router.push(`/auth/reset/${this.method}`);
    },
  },
  mounted() {
    if (this.isLogin) {
      if (this.userinfo.tel) {
        this.method = '1';
      } else {
        this.method = '2';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .title-area {
    width: 454px;
    margin: 0 auto 70px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    .text {
      display: inline-block;
      padding: 5px 30px;
      background: #ffffff;
      position: relative;
      line-height: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-area {
    width: 368px;
    margin: 0 auto;
    .radio-area {
      .el-radio {
        display: flex;
        .label-text {
          font-size: 16px;
          color: $text-color-black;
          .main-label {
            margin-bottom: 14px;
          }
          .sub-label {
            font-size: 14px;
            color: $text-color-grey;
          }
        }
      }
      & ~ .radio-area {
        margin-top: 30px;
      }
    }
    .btn-area {
      margin-top: 60px;
      .el-button {
        width: 100%;
        color: $text-color-black;
        font-size: 18px;
      }
    }
  }
}
</style>
