<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title-area">
      <span class="text">{{
        $t(userinfo.tel ? 'titles.resetTel' : 'titles.title_addTel')
      }}</span>
    </div>
    <!-- 表单 -->
    <div class="form-area">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="right"
        label-width="160px"
      >
        <el-form-item :label="$t('pages.other.shoujihao')" prop="tel" v-if="userinfo.tel">
          <el-input v-model="formData.tel" disabled></el-input>
        </el-form-item>
        <el-form-item
          class="vcode-item"
          :label="$t('pages.other.yanzhengma')"
          prop="vcode"
          v-if="userinfo.tel"
        >
          <el-input v-model="formData.vcode"></el-input>
          <div class="v-btn-area">
            <el-button
              plain
              :type="disabled ? 'info' : 'primary'"
              @click="getVCode"
              >{{ txt }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label="$t('pages.other.xinshoujihao')" prop="newTel">
          <el-input v-model="formData.newTel"></el-input>
        </el-form-item>
        <el-form-item class="vcode-item" :label="$t('pages.other.yanzhengma')" prop="newVcode">
          <el-input v-model="formData.newVcode"></el-input>
          <div class="v-btn-area">
            <el-button
              plain
              :type="newDisabled ? 'info' : 'primary'"
              @click="getNewVCode"
              >{{ NewTxt }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item class="row">
          <el-button type="primary" v-loading="isLoading" @click="submitForm"
            >{{ $t('pages.other.submit') }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  selectVcode,
  checkUsername,
  updateUserinfoTelOrEmail,
} from '@/api/common';
let timer = null;
let newTimer = null;
export default {
  data() {
    return {
      phoneStatus: false,
      emailStatus: false,
      formData: {
        tel: '',
        vcode: '',
        newTel: '',
        newVcode: '',
      },
      rules: {
        newTel: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value)) {
                checkUsername({
                  phone: this.formData.newTel,
                })
                  .then(() => {
                    this.phoneStatus = true;
                    callback();
                  })
                  .catch(() => {
                    this.phoneStatus = false;
                    callback(new Error(this.$t('pages.other.cishoujihaoyizhuce')));
                  });
              } else {
                callback(new Error(this.$t('pages.other.qingshuruzhengquedeshoujihao')));
              }
            },
          },
        ],
        newVcode: [
          { required: true, message: this.$t('pages.other.qingshuruyanzhengma'), trigger: 'blur' },
        ],
      },
      txt: this.$t('pages.other.huoqushoujihao'),
      NewTxt: this.$t('pages.other.huoqushoujihao'),
      disabled: false,
      newDisabled: false,
      isLoading: false,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    submitForm() {
      if (this.isLoading) return;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;
            await updateUserinfoTelOrEmail({
              oldCaptcha: this.formData.vcode,
              newCaptcha: this.formData.newVcode,
              phone: this.formData.newTel,
            });
            this.isLoading = false;
            this.$message.success(
              this.userinfo.tel ? this.$t('pages.other.shoujihaoxiugaichenggong') : this.$t('pages.other.shoujihaobangdingchenggong')
            );
            this.$store.commit('setUserinfoTel', this.formData.newTel);
            this.$router.push('/personal/my-info');
          } catch (error) {
            console.log(error);
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    showTime() {
      this.disabled = true;
      let time = 60;
      this.txt = this.$t('pages.other.chongxinfasong')+'(' + time + ')';
      timer = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer);
          this.txt = this.$t('pages.other.fasongyanzhengma');
          this.disabled = false;
          return;
        }
        this.txt = this.$t('pages.other.chongxinfasong')+'(' + --time + ')';
      }, 1000);
    },
    async getVCode() {
      if (!this.disabled) {
        if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.tel)) {
          try {
            await selectVcode({
              mobile: this.formData.tel,
              type: 2,
            });
            this.showTime();
            this.$message({
              message: this.$t('pages.other.yanzhengmafasongchenggong'),
              type: 'success',
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$refs['form'].validateField('tel');
          return false;
        }
      }
    },
    showNewTime() {
      this.newDisabled = true;
      let time = 60;
      this.NewTxt = this.$t('pages.other.chongxinfasong')+'(' + time + ')';
      newTimer = setInterval(() => {
        if (time <= 0) {
          clearInterval(newTimer);
          this.NewTxt = this.$t('pages.other.fasongyanzhengma');
          this.newDisabled = false;
          return;
        }
        this.NewTxt = this.$t('pages.other.chongxinfasong')+'(' + --time + ')';
      }, 1000);
    },
    async getNewVCode() {
      if (!this.newDisabled) {
        if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formData.newTel)) {
          if (this.phoneStatus) {
            try {
              await selectVcode({
                mobile: this.formData.newTel,
                type: 2,
              });
              this.showNewTime();
              this.$message({
                message: this.$t('pages.other.yanzhengmafasongchenggong'),
                type: 'success',
              });
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          this.$refs['form'].validateField('newTel');
          return false;
        }
      }
    },
  },
  created() {
    this.formData.tel = this.userinfo.tel ? this.userinfo.tel : '';
    if (this.formData.tel) {
      this.rules.vcode = [
        { required: true, message: this.$t('pages.other.qingshuruyanzhengma'), trigger: 'blur' },
      ];
    }
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .title-area {
    width: 454px;
    margin: 0 auto 70px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    .text {
      display: inline-block;
      padding: 5px 30px;
      background: #ffffff;
      position: relative;
      line-height: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-area {
    width: 386px;
    margin: 0 auto;
    .el-form-item {
      &.vcode-item {
        ::v-deep .el-form-item__content {
          display: flex;
          align-items: center;
          .el-input {
            width: 210px !important;
            margin-right: 12px;
            input {
              width: 100%;
            }
          }
          .v-btn-area {
            text-align: right;
            cursor: pointer;
            img {
              width: 87px;
              height: 40px;
            }
          }
        }
      }
      &.row {
        margin-top: 50px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          .el-button {
            width: 100%;
            color: $text-color-black;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
