<template>
  <div>
    <div class="header-area">{{ $t('pages.personal.orgInfo') }}</div>
    <div class="info-area">
      <!-- 表单 -->
      <div class="form-area">
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          :hide-required-asterisk="false"
          label-position="right"
          :label-width="`${locale === 'cn' ? 80 : 120}px`"
        >
          <el-form-item :label="$t('pages.personal.orgName')" prop="orgName">
            <el-input v-model="formData.orgName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.personal.orgAddr')" prop="orgAddr">
            <el-input v-model="formData.orgAddr"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pages.personal.orgSize')" prop="orgSize">
            <el-input v-model="formData.orgSize"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.orgLicense')"
            prop="license"
            class="photo-item"
          >
            <div class="photo-area">
              <div
                class="preveiw-area"
                v-if="formData.license"
                @click="toPreviewImage"
              >
                <img :src="formData.license" />
                <i
                  class="el-icon-error delete"
                  @click="formData.license = ''"
                ></i>
              </div>
              <div class="upload-area" v-else>
                <el-upload
                  :action="`${baseURL}/file/pcUpload`"
                  :headers="{ Authorization: `pBearer ${$store.state.token}` }"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                >
                  <el-button class="my-btn" size="small">
                    <span>{{ $t('common.upload') }}</span>
                  </el-button>
                </el-upload>
                <div class="desc">{{ $t('common.format') }}JPG、PNG、JPEG（&lt;1M）</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('pages.personal.orgManager')"
            prop="leaderList"
          >
            <div class="list" v-if="formData.leaderList">
              <div
                class="leader-card"
                v-for="(leader, index) in formData.leaderList"
                :key="leader.userId"
              >
                <el-avatar
                  :size="60"
                  :src="leader.headImg ? leader.headImg : defaultAvatar"
                />
                <div class="leader-info">
                  <p class="name">{{ leader.userName }}</p>
                  <p class="tel">
                    {{ $t('common.tel') }}/{{ $t('common.email') }}：{{
                      leader.userPhone ? leader.userPhone : leader.userMail
                    }}
                  </p>
                </div>
                <div class="opers">
                  <!-- <span class="edit">编辑</span> -->
                  <span
                    class="delete"
                    @click="toDelete(index)"
                    v-if="index !== 0"
                    >{{ $t('common.delete') }}</span
                  >
                </div>
              </div>
            </div>
            <div class="leader-card add" v-if="formData.leaderList.length < 3">
              <el-button
                icon="el-icon-plus"
                circle
                @click="toShowMemberList"
              ></el-button>
            </div>
          </el-form-item>
          <el-form-item class="row">
            <el-button type="primary" @click="submitForm">{{
              $t('common.save')
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 添加联系人弹窗 -->
    <el-dialog
      :title="$t('common.choose')"
      width="800px"
      :visible.sync="showPopup"
    >
      <el-table :data="memberList" height="500" size="mini" style="width: 100%">
        <el-table-column
          prop="userName"
          :label="$t('common.name')"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="userPhone" :label="$t('common.tel')" width="180">
        </el-table-column>
        <el-table-column prop="userMail" :label="$t('common.email')">
        </el-table-column>
        <el-table-column
          :label="$t('common.operation')"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="handleChoose(scope.row)">{{
              $t('common.choose')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL } from '@/config';
import { selectOrgMemberWithoutLeader, updateOrgInfo } from '@/api/common';
export default {
  // components: { editor },
  data() {
    return {
      baseURL,
      defaultAvatar: require('@/assets/images/default-avatar.png'),
      showPopup: false,
      memberList: [],
      formData: {
        orgId: '',
        orgName: '',
        orgAddr: '',
        orgSize: '',
        license: '',
        leaderList: [],
      },
      rules: {
        orgName: [
          { required: true, message: this.$t('pages.other.qingshurujigoumingcheng'), trigger: 'blur' },
        ],
        orgAddr: [
          { required: true, message: this.$t('pages.other.qingshurujigoudizhi'), trigger: 'blur' },
        ],
        orgSize: [
          { required: true, message: this.$t('pages.other.qingshurujigouguimo'), trigger: 'blur' },
        ],
        license: [
          { required: true, message: this.$t('pages.other.qingshangchuanyingyezhizhao'), trigger: 'blur' },
        ],
        leaderList: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback();
              } else {
                callback(this.$t('pages.other.qingzhishaotianjiayigejigoufuzheren'));
              }
            },
            message: this.$t('pages.other.qingzhishaotianjiayigejigoufuzheren'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    orgInfo() {
      return this.$store.state.orgInfo;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await updateOrgInfo({
              orgId: this.formData.orgId,
              companyName: this.formData.orgName,
              companyAddress: this.formData.orgAddr,
              companySize: this.formData.orgSize,
              companyLicense: this.formData.license,
              orgLeadersStr: this.formData.leaderList
                .map((leader) => leader.userId)
                .join(','),
            });
            console.log(res);
            this.$message.success(this.$t('pages.other.gengxinchenggong'));
            this.$store.commit('setOrgInfo', res);
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    handleChoose(item) {
      const checkArr = this.formData.leaderList.filter(
        (leader) => leader.userId === item.userId
      );
      if (checkArr.length > 0) {
        this.$message.error(this.$t('pages.other.cirenyishilianxiren'));
      } else {
        this.formData.leaderList.push(item);
        this.showPopup = false;
      }
    },
    async toShowMemberList() {
      if (this.memberList.length === 0) {
        try {
          const res = await selectOrgMemberWithoutLeader({
            orgId: this.orgInfo.orgId,
          });
          this.memberList = res;
          this.showPopup = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.showPopup = true;
      }
    },
    toDelete(index) {
      this.formData.leaderList.splice(index, 1);
    },
    toPreviewImage() {
      this.$imgPreview({
        imgs: [this.formData.license],
      });
    },
    handleAvatarSuccess(res) {
      console.log(res);
      if (res.code === 200) {
        this.formData.license = res.data.url;
      } else {
        this.$message.error(this.$t('pages.other.shangchuanshibai'));
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG) {
        this.$message.error(this.$t('pages.other.shangchuantouxianggeshi'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('pages.other.shangchuantouxiangdaxiao'));
      }
      return isJPG && isLt2M;
    },
    toShowNotice() {
      if (this.$store.state.needNoticeToOrg) {
        this.$confirm(
          this.$t('pages.personal.guideText'),
          this.$t('pages.personal.guide')
        )
          .then(() => {
            this.$store.commit('setNeedNoticeToOrg');
            this.$router.push('/personal/org-manage');
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    if (this.orgInfo) {
      this.formData.orgId = this.orgInfo.orgId;
      this.formData.orgName = this.orgInfo.companyName;
      this.formData.orgAddr = this.orgInfo.companyAddress;
      this.formData.orgSize = this.orgInfo.companySize;
      this.formData.license = this.orgInfo.companyLicense || '';
      this.formData.leaderList = this.orgInfo.orgLeaders || [];
    }
    this.toShowNotice();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 35px 40px 80px;
  .form-area {
    width: 730px;
    .photo-area {
      width: 210px;
      height: 135px;
      border: 1px #e6e6e6 dashed;
      background: #fbfbfb;
      .preveiw-area {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        i.delete {
          position: absolute;
          top: -10px;
          right: -10px;
          color: #ff4c4c;
          font-size: 18px;
          background: #ffffff;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      .upload-area {
        height: 100%;
        text-align: center;
        padding-top: 34px;
        .desc {
          color: $text-color-grey;
          font-size: 12px;
        }
      }
    }
    .leader-card {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &.add {
        .el-button {
          width: 60px;
          height: 60px;
        }
      }
      .leader-info {
        margin-left: 17px;
        .name {
          font-size: 14px;
          line-height: 34px;
        }
        .tel {
          font-size: 12px;
          color: #666666;
          line-height: 1;
        }
      }
      .opers {
        margin-left: 60px;
        font-size: 12px;
        cursor: pointer;
        .edit {
          color: #1990ff;
          margin-right: 10px;
        }
        .delete {
          color: #ff4c4c;
        }
      }
    }
    .row {
      margin-top: 50px;
      .el-button {
        width: 120px;
      }
    }
  }
}
::v-deep .el-table th {
  background: #f1f4f7;
}
</style>
