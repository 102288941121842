<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.other.kechengbiji') }}</span>
      <el-button type="" size="mini" @click="$router.go(-1)">{{
        $t('pages.other.fanhui')
      }}</el-button>
    </div>
    <div class="info-area">
      <div class="oper-area">
        <div class="name">{{ $route.params.name }}</div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('pages.other.biaoti')">
          <template slot-scope="scope">
            <div class="content">
              {{ scope.row.noteContent }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.other.shipinshijian')"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div
              class="video-timing"
              v-if="scope.row.nodeTime !== '' && scope.row.nodeTime !== null"
            >
              <i class="iconfont">&#xe648;</i>
              <span>{{ scope.row.nodeTime | handleTime }}</span>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          :label="$t('pages.other.jilushijian')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pages.other.caozuo')"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="toShowDetail(scope.row, true)">{{
              $t('pages.other.xiangqing')
            }}</el-link>
            <el-link type="warning" @click="toShowDetail(scope.row, false)">{{
              $t('common.edit')
            }}</el-link>
            <el-link type="danger" @click="toDelete(scope.row)">{{
              $t('common.delete')
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      :title="$t('pages.other.wodebiji')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showNote"
    >
      <div class="popup-content form" v-if="currentItem">
        <el-form label-position="top" label-width="100px" size="mini">
          <el-form-item>
            <el-input
              type="textarea"
              resize="none"
              :readonly="disabled"
              :autosize="{ minRows: 10, maxRows: 20 }"
              v-model="currentItem.noteContent"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showNote = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toClose">{{
          disabled ? $t('common.close') : $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectMyNoteList,
  updateMyNoteData,
  deleteMyNoteData,
} from '@/api/common';
export default {
  data() {
    return {
      disabled: false,
      showNote: false,
      currentItem: null,
      type: 0,
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    toDelete(item) {
      this.$confirm(
        this.$t('pages.other.quedingyaoshanchucitiaobijima'),
        this.$t('pages.other.tishi'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          await deleteMyNoteData({ id: item.id });
          this.$message.success(this.$t('pages.other.shanchuchenggong'));
          this.page = 1;
          this.getData();
        })
        .catch(() => {});
    },
    async toClose() {
      if (this.disabled) {
        this.showNote = false;
      } else {
        // 修改笔记
        try {
          await updateMyNoteData({
            id: this.currentItem.id,
            courseId: this.$route.params.id,
            noteContent: this.currentItem.noteContent,
            nodeTime: this.currentItem.nodeTime,
          });
          this.$message.success(this.$t('pages.other.xiugaichenggong'));
          this.page = 1;
          this.getData();
        } catch (error) {
          console.log(error);
        }
        this.showNote = false;
      }
    },
    toShowDetail(item, flag) {
      this.currentItem = JSON.parse(JSON.stringify(item));
      this.disabled = flag;
      this.showNote = true;
    },
    async getData() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        const res = await selectMyNoteList({
          courseId: this.$route.params.id,
          pageNum: this.nPage,
          pageSize: this.nPageSize,
        });
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // .dropdown-area {
    //   width: 200px;
    // }
    .name {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: bold;
      // text-align: right;
    }
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .video-timing {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    user-select: none;
    cursor: pointer;
    .iconfont {
      margin-right: 5px;
    }
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
