<template>
  <div>
    <div class="header-area">
      <span>{{ $t('pages.other.peixunleixing') }}</span>
    </div>
    <div class="info-area">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :span="8">
          <div class="title-container">
            <i class="el-icon-edit" />
            {{ $t('pages.other.xinzenghebianji') }}
          </div>
          <el-form
            ref="form"
            label-width="80px"
            :model="formData"
            :rules="rules"
          >
            <el-form-item :label="$t('pages.other.leixingmingcheng')" prop="name">
              <el-input v-model="formData.name" :placeholder="$t('pages.other.qingshuruleixingmingcheng') " />
            </el-form-item>
            <el-form-item :label="$t('pages.other.shangjileixing')" prop="father">
              <el-select
                v-model="formData.father"
                :loading="isLoading"
                style="width: 100%;"
              >
                <el-option :label="$t('pages.other.wushangji')" value="" />
                <el-option
                  v-for="item in flattenList"
                  :key="item.typeId"
                  :label="item.typeName"
                  :value="item.typeId"
                >
                  <span v-for="index in item.level" :key="index" />
                  {{ item.typeName }}
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="排序" prop="sort">
              <el-input v-model="formData.sort" placeholder="数字越小越靠前" />
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSubmit">{{
                formData.id ? $t('pages.other.baocunxiugai') : $t('pages.other.xinzeng') 
              }}</el-button>
              <el-button @click="onReset">{{ $t('pages.other.chongzhi')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="16">
          <div class="title-container">
            <i class="el-icon-s-tools" />
            {{$t('pages.other.leixingguanli')}}
          </div>
          <el-table :data="list" row-key="typeId" style="width: 100%;">
            <!-- <el-table-column label="#" type="index" align="center" width="80">
              <template slot-scope="scope">
                <span
                  v-for="index in scope.row.level"
                  :key="index"
                  class="el-table__placeholder"
                />
                <span>{{ scope.row.index }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="typeName" :label=" $t('pages.other.peixunleixing')" />
            <el-table-column
              prop="createTime"
              :label="$t('pages.other.tianjiashijian')"
              width="160"
              align="center"
            />
            <el-table-column :label="$t('pages.other.caozuo')" width="145" align="center">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="toEdit(scope.row)"
                  >{{$t('common.edit')}}</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  @click="toDelete(scope.row)"
                  >{{ $t('common.delete') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  selectOrgTrainTypeList,
  insertOrgTrainType,
  updateOrgTrainType,
  deleteOrgTrainType,
} from '@/api/common';
import { flatten } from '@/lib/utils';
export default {
  data() {
    return {
      formData: {
        id: '',
        name: '',
        father: '',
      },
      rules: {
        name: [{ required: true, message: this.$t('pages.other.qingshuruleixingmingcheng'), trigger: 'blur' }],
      },
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    flattenList() {
      return this.list.length > 0
        ? this.flatten(this.list, 'children').filter((item) => item.level === 1)
        : [];
    },
  },
  methods: {
    flatten,
    onReset() {
      this.$refs.form.resetFields();
      this.formData.id = '';
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 验证通过
          if (this.formData.id) {
            // 修改
            await updateOrgTrainType({
              typeId: this.formData.id,
              orgId: this.$store.state.orgInfo.orgId,
              typeName: this.formData.name,
              parentId: this.formData.father,
            });
          } else {
            // 新增
            await insertOrgTrainType({
              typeName: this.formData.name,
              parentId: this.formData.father,
            });
          }
          this.getData();
          this.$message.success(this.$t('pages.other.caozuochenggong'));
        }
      });
    },
    toDelete(item) {
      if (item.useAcount > 0) {
        this.$message.error(this.$t('pages.other.beiyinyongbunengshanchu'));
      } else {
        this.$confirm(this.$t('pages.other.quedingyaoshanchucileixing'), this.$t('pages.other.tishi'), {
          type: 'warning',
        })
          .then(async () => {
            // 删除
            await deleteOrgTrainType({
              typeIds: item.typeId,
            });
            this.getData();
            this.$message.success(this.$t('pages.other.caozuochenggong'));
          })
          .catch(() => {});
      }
    },
    toEdit(item) {
      this.formData.id = item.typeId;
      this.formData.name = item.typeName;
      this.formData.father = item.parentId ? item.parentId : '';
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectOrgTrainTypeList();
        this.isLoading = false;
        this.list = res;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .title-container {
    padding: 0 0 14px;
    // margin-top: -20px;
    // margin-bottom: 20px;
    // border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
  .oper-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    .intro {
      color: $text-color-grey;
    }
  }
  .banner {
    width: 110px;
    height: 70px;
  }
  .el-link ~ .el-link {
    margin-left: 10px;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.photo-area {
  width: 210px;
  height: 135px;
  border: 1px #e6e6e6 dashed;
  background: #fbfbfb;
  .preveiw-area {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i.delete {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ff4c4c;
      font-size: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .upload-area {
    height: 100%;
    text-align: center;
    padding-top: 34px;
    .desc {
      color: $text-color-grey;
      font-size: 12px;
      line-height: 1.5;
      padding: 10px 15px 0;
    }
  }
}
</style>
