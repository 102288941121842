<template>
  <!-- 楼层导航 -->
  <transition name="el-fade-in-linear">
    <ul class="floor-nav" :class="{ toggleSide: !show }" v-show="visible">
      <div class="handled-area" @click="toggleShow">
        <i class="iconfont" :class="{ left: !show }">&#xe602;</i>
      </div>
      <li
        v-for="(item, index) in floorNavList.filter(
          (item) => item.target !== 'mp' || (item.target === 'mp' && qrCodeUrl)
        )"
        :key="index"
        :class="[
          item.target ? `${item.target.substring(1)}-nav` : '',
          {
            active:
              item.rangeStart &&
              item.rangeStart <= scrollTop &&
              item.rangeEnd > scrollTop,
          },
          { hide: !show },
          { top: !item.target },
        ]"
        @click="scrollTo(item.target)"
        @mouseover="showQrcode(item.target)"
        @mouseleave="hideQrcode"
      >
        <div class="inner-area">
          <img :src="item.img" :alt="item.title" />
          <p>{{ item.title }}</p>
        </div>
      </li>
      <div
        class="qrcode-area"
        :class="{ en: locale === 'en' }"
        v-if="isShowQrcode"
      >
        <div class="img-area">
          <img :src="qrCodeUrl" />
        </div>
        <div class="intro">
          <i class="iconfont">&#xe6a5;</i>
          <div class="right">
            <p>{{ $t('pages.index.scanWithWechat') }}</p>
            <p>{{ $t('pages.index.visitMiniProgram') }}</p>
          </div>
        </div>
      </div>
    </ul>
  </transition>
</template>

<script>
import { selectIndexData } from '@/api/common';
function cubic(value) {
  return Math.pow(value, 3);
}
function easeInOutCubic(value) {
  return value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
}
let timer = null;
export default {
  props: {
    qrcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      qrCodeUrl: null,
      floorNavList: [
        {
          title: this.$t('pages.index.courseZone'),
          target: '.course-area',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-course-area.png'),
        },
        {
          title: this.$t('pages.index.coursePlan'),
          target: '.course-plan',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-course-plan.png'),
        },
        {
          title: this.$t('pages.index.courseSubscribe'),
          target: '.course-subscribe',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-course-subscribe.png'),
        },
        {
          title: this.$t('pages.index.service'),
          target: 'service',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-service.png'),
        },
        {
          title: this.$t('pages.index.feedback'),
          target: '.contact-us',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-feedback.png'),
        },
        {
          title: this.$t('pages.index.mp'),
          target: 'mp',
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-mp.png'),
        },
        {
          title: this.$t('pages.index.toTop'),
          target: undefined,
          rangeStart: 0,
          rangeEnd: 0,
          img: require('@/assets/images/icon-back-top.png'),
        },
      ],
      container: null,
      el: null,
      visible: false,
      scrollTop: 0,
      show: true,
      isShowQrcode: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  created () {
    this.getQrCode();
  },
  methods: {
    getQrCode () {
      selectIndexData({ locale: this.$store.state.locale }).then(res => {
        this.qrCodeUrl = res.pcConfig.qrCode;
      }).catch((error) => {
        console.log(error);
      })
    },
    showQrcode(target) {
      if (target === 'mp') {
        this.isShowQrcode = true;
      }
    },
    hideQrcode() {
      this.isShowQrcode = false;
    },
    toggleShow() {
      this.show = !this.show;
    },
    init() {
      console.log('Floor Nav Init ...');
      this.container = document;
      this.el = document.documentElement;
      timer = setTimeout(() => {
        this.floorNavList.forEach((item) => {
          if (
            item.target &&
            item.target !== 'service' &&
            item.target !== 'mp'
          ) {
            let el = document.querySelector(item.target);
            item.rangeStart = el.offsetTop;
            item.rangeEnd = el.offsetTop + el.offsetHeight;
          }
        });
      }, 2000);
    },
    scrollTo(target) {
      if (target !== 'service') {
        let el = this.el;
        let targetEle = target
          ? document.querySelector(target)
          : document.documentElement;
        let beginTime = Date.now();
        let beginValue = el.scrollTop;
        let targetTop = targetEle.getBoundingClientRect().top;
        let endValue = beginValue + targetTop;
        let rAF =
          window.requestAnimationFrame ||
          function(func) {
            return setTimeout(func, 16);
          };
        let frameFunc = function frameFunc() {
          let progress = (Date.now() - beginTime) / 500;
          if (progress < 1) {
            el.scrollTop = beginValue + targetTop * easeInOutCubic(progress);
            rAF(frameFunc);
          } else {
            el.scrollTop = endValue;
          }
        };
        rAF(frameFunc);
      } else {
        this.$emit('service');
      }
    },
    onscroll() {
      let scrollTop = this.el.scrollTop;
      this.visible =
        scrollTop >= document.querySelector('.course-area').offsetTop;
      this.scrollTop = scrollTop;
    },
  },
  mounted() {
    this.init();
    this.container.addEventListener('scroll', this.onscroll);
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.onscroll);
    clearTimeout(timer);
  },
};
</script>

<style lang="scss" scoped>
.floor-nav {
  position: fixed;
  top: 50%;
  right: 46px;
  transform: translateY(-50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(6, 0, 1, 0.05);
  z-index: 99999;
  transition: all 0.3s;
  &.toggleSide {
    transform: translate(81px, -50%) rotate(90deg);
  }
  .handled-area {
    text-align: center;
    cursor: pointer;
    user-select: none;
    color: #ddd;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    &:hover {
      color: $base-color;
      &::before {
        border-color: $base-color;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      border-bottom: 1px solid #ddd;
      transform: translate(-50%, -50%);
    }
    .iconfont {
      font-size: 14px;
      position: relative;
      background: #ffffff;
      width: 20px;
      &.left {
        transform: rotate(-90deg);
      }
    }
  }
  li {
    width: 90px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;
    &.hide {
      height: 0;
      transform: rotateX(90deg);
    }
    &:hover {
      background: #f4f9ff;
    }
    &.active {
      background: #f4f9ff;
    }
    // &.top {
    //   height: auto;
    //   .inner-area {
    //     p {
    //       display: none;
    //     }
    //   }
    // }
    .inner-area {
      text-align: center;
      img {
        width: 24px;
        height: 24px;
      }
      p {
        font-size: 14px;
        color: $text-color-grey;
        line-height: 1;
        margin-top: 11px;
      }
    }
  }
  .qrcode-area {
    position: absolute;
    bottom: 0;
    left: -160%;
    background: #ffffff;
    box-shadow: 1px 1px 10px 0 rgba($color: #000000, $alpha: 0.1);
    border-radius: 10px;
    padding: 15px;
    overflow: hidden;
    &.en {
      left: -220%;
      .intro {
        .right {
          font-size: 12px;
        }
      }
    }
    .img-area {
      width: 100px;
      height: 100px;
      margin: 0 auto 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .intro {
      font-size: 14px;
      line-height: 1.4;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 20px;
        color: $base-color;
      }
      .right {
        flex: 1;
        margin-left: 10px;
        color: $text-color-grey;
      }
    }
  }
}
</style>
