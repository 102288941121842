<template>
  <div>
    <div class="header-area">{{ $t('titles.myInfo') }}</div>
    <div class="info-area">
      <!-- 表单 -->
      <div class="form-area">
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          :hide-required-asterisk="true"
          label-position="right"
          label-width="150px"
        >
          <el-form-item :label="$t('common.account')" prop="username">
            <el-input v-model="formData.username" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.name')" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('common.sex')"
            prop="gender"
            class="gender-item"
          >
            <el-radio-group v-model="formData.gender">
              <el-radio label="0">{{ $t('common.man') }}</el-radio>
              <el-radio label="1">{{ $t('common.woman') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('common.companyName')" prop="company">
            <!-- <el-input
              v-model="formData.company"
              :disabled="userinfo.roles.length > 1"
            ></el-input> -->
            <el-input
              v-model="formData.company"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.dept')" prop="dept">
            <!-- <el-input
              v-model="formData.dept"
              :disabled="userinfo.roles.length > 1"
            ></el-input> -->
            <el-input
              disabled
              v-model="formData.dept"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.post')" prop="job">
            <el-input v-model="formData.job"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('common.weight')"
            prop="weights"
            v-if="userinfo.roles[$store.state.currentRoleIndex] === '4'"
          >
            <div class="text-area">
              <span class="text" v-show="formData.weights">{{
                formData.weights
              }}</span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('common.tel')" prop="tel">
            <div class="text-area">
              <span class="text" v-show="formData.tel">{{ formData.tel }}</span>
              <router-link to="/auth/reset-tel">{{
                formData.tel ? $t('titles.resetTel') : $t('titles.bindTel')
              }}</router-link>
            </div>
          </el-form-item>
          <el-form-item :label="$t('common.email')" prop="email">
            <div class="text-area">
              <span class="text" v-show="formData.email">{{
                formData.email
              }}</span>
              <router-link to="/auth/reset-email">{{
                formData.email
                  ? $t('titles.resetEmail')
                  : $t('common.bindEmail')
              }}</router-link>
            </div>
          </el-form-item>
          <el-form-item :label="$t('titles.wx')" prop="wechat">
            <div class="text-area">
              <div class="wechat-area" v-if="formData.wechat">
                <el-avatar size="small" :src="formData.wechatAvatar" />
                <span class="text">{{ formData.wechat }}</span>
              </div>
              <router-link to="/auth/binding">{{
                formData.wechat ? $t('titles.resetWx') : $t('titles.binding')
              }}</router-link>
              <span
                v-if="formData.wechat"
                class="unbind"
                @click="toUnbindWechat"
                >{{ $t('titles.removeWx') }}</span
              >
            </div>
          </el-form-item>
          <el-form-item class="row">
            <el-button type="primary" @click="submitForm">{{
              $t('common.save')
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { updateUserinfoBase, unbindWechat } from '@/api/common';
export default {
  data() {
    return {
      formData: {
        username: '',
        name: '',
        gender: '',
        company: '',
        dept: '',
        job: '',
        tel: '',
        email: '',
        wechatAvatar: '',
        wechat: '',
        weights: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('pages.other.qingshuruyonghuming'),
            trigger: 'blur',
          },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]{5,15}$/,
            message: this.$t('pages.other.mimayanzhengtishi'),
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('pages.other.qingshuruxingming'),
            trigger: 'blur',
          },
        ],
        gender: [
          {
            required: true,
            message: this.$t('pages.other.qingxuanzexingbie'),
            trigger: 'blur',
          },
        ],
        company: [
          {
            required: true,
            message: this.$t('pages.other.qingshurugongsimingcheng'),
            trigger: 'blur',
          },
        ],
        dept: [
          {
            required: true,
            message: this.$t('pages.other.qingshurubumenmingcheng'),
            trigger: 'blur',
          },
        ],
        job: [
          {
            required: true,
            message: this.$t('pages.other.qingshuruzhiwu'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    toUnbindWechat() {
      this.$confirm(
        this.$t('pages.other.quedingyaojiebangweixinma'),
        this.$t('pages.other.tishi'),
        {
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await unbindWechat();
            this.$store.commit('unbindWechat');
            this.$message.success(this.$t('pages.other.jiebangchenggong'));
            window.location.reload()
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {});
    },
    async submitForm() {
      try {
        await updateUserinfoBase({
          realName: this.formData.name,
          sex: this.formData.gender,
          company: this.formData.company,
          dept: this.formData.dept,
          position: this.formData.job,
        });
        this.$message.success(this.$t('pages.other.xiugaichenggong'));
        this.$store.commit('setUserinfoBase', this.formData);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.formData.username = this.userinfo.username;
    this.formData.name = this.userinfo.name;
    this.formData.gender = this.userinfo.gender;
    this.formData.company = this.userinfo.company;
    this.formData.dept = this.userinfo.dept;
    this.formData.job = this.userinfo.job;
    this.formData.tel = this.userinfo.tel;
    this.formData.email = this.userinfo.email;
    this.formData.wechatAvatar = this.userinfo.avatar;
    this.formData.wechat = this.userinfo.nickName;
    this.formData.weights = this.userinfo.weights || '0';
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 35px 40px 80px;
  .form-area {
    width: 730px;
    .row {
      .el-button {
        width: 120px;
      }
    }
    .text-area {
      .text {
        margin-right: 30px;
        vertical-align: middle;
      }
      .wechat-area {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        .text {
          margin-left: 10px;
        }
      }
      .unbind {
        color: #ff4c4c;
        user-select: none;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      a {
        color: $base-color;
        user-select: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
