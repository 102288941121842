<template>
  <div>
    <div class="header-area">{{$t('titles.myWrongSet')}}</div>
    <div class="info-area">
      <div class="oper-area">
        <div class="search-area">
          <el-input
            size="small"
            :placeholder="$t('common.placeHolder_enter')+$t('common.keywords')"
            v-model="keywords"
            class="input-with-select"
            clearable
            @clear="toSearch"
            @keyup.enter.native="toSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="toSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="list"
        border
        v-loading="isLoading"
        size="medium"
        style="width: 100%"
      >
        <el-table-column :label="$t('common.topic')" prop="content">
          <template slot-scope="scope">
            <div class="name-area">{{ scope.row.content }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.genre')" width="180" prop="courseTypeValues">
          <template slot-scope="scope">
            <div class="name-area">{{ scope.row.courseTypeValues }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.questionTypes')"
          width="120"
          prop="contentTypeValue"
          align="center"
        />
        <el-table-column width="120" :label="$t('common.errorRate')" align="center">
          <template slot-scope="scope">
            {{
              Math.round(
                (scope.row.errorAcount / scope.row.allDidAcount) * 100
              )
            }}%
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" width="120" align="center">
          <template slot-scope="scope">
            <div class="opers">
              <el-link type="primary" @click="toShowDetail(scope.row)"
                >{{$t('common.toSee') + $t('titles.analysis')}}</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 错题详情弹窗 -->
    <el-dialog :title="$t('common.toSee') + $t('titles.analysis')" width="800px" :visible.sync="showDetail">
      <div class="pop-content" v-if="currentItem">
        <div class="t-area">
          <div class="question">
            【{{ currentItem.contentTypeValue }}】{{ currentItem.content }}
          </div>
          <el-radio-group
            size="medium"
            :value="currentItem.correctOptionIds"
            v-if="currentItem.contentType !== '2'"
          >
            <div
              class="option"
              v-for="option in currentItem.allOptions"
              :key="option.optionId"
            >
              <el-radio :label="option.optionId">
                <span class="the-label" v-if="currentItem.contentType !== '3'"
                  >{{ option.optionId }}、 </span
                >{{ option.optionText }}</el-radio
              >
            </div>
          </el-radio-group>
          <el-checkbox-group
            size="medium"
            :value="currentItem.correctOptionIds.split(',')"
            v-else
          >
            <div
              class="option"
              v-for="option in currentItem.allOptions"
              :key="option.optionId"
            >
              <el-checkbox :label="option.optionId">
                {{ option.optionId }}、 {{ option.optionText }}</el-checkbox
              >
            </div>
          </el-checkbox-group>
          <div class="answer-area">
            <span class="label">{{$t('common.answers')}}</span>
            <span class="value">{{ currentItem.correctOptionIds }}</span>
          </div>
          <div class="intro">
            <div class="title">{{$t('common.answers') + $t('titles.analysis')}}</div>
            <div class="intro-content">
              {{ currentItem.optionExplain || $t('common.noData') }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectMyWrongSet } from "@/api/common";
export default {
  data() {
    return {
      keywords: "",
      isLoading: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      showDetail: false,
      currentItem: null,
    };
  },
  methods: {
    toShowDetail(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
      if (this.currentItem.allOptions) {
        this.currentItem.allOptions = JSON.parse(this.currentItem.allOptions);
      }
      this.showDetail = true;
    },
    toSearch() {
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMyWrongSet({
          keywords: this.keywords,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        console.log(res);
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .name-area {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    th {
      background: #f7f7f7;
      &.el-table-column--selection {
        .cell {
          padding-left: 14px;
        }
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
.t-area {
  font-size: 16px;
  line-height: 26px;
  .question {
    padding: 0 27px 22px;
    font-size: 16px;
    color: #333333;
  }
  ::v-deep .el-radio-group {
    display: block;
    .el-radio {
      display: flex;
      padding: 5px 30px 5px 46px;
      .el-radio__input {
        padding-top: 6px;
      }
      .el-radio__label {
        white-space: pre-wrap;
        line-height: 26px;
        padding-left: 28px;
        font-size: 16px;
      }
    }
  }
  ::v-deep .el-checkbox-group {
    .el-checkbox {
      display: flex;
      padding: 5px 30px 5px 46px;
      .el-checkbox__input {
        padding-top: 6px;
      }
      .el-checkbox__label {
        white-space: pre-wrap;
        line-height: 26px;
        padding-left: 28px;
        font-size: 16px;
      }
    }
  }
  .option {
    margin-bottom: 10px;
  }
  .answer-area {
    padding: 22px 27px;
    font-size: 16px;
    color: #333333;
    .label {
      margin-right: 30px;
    }
  }
  .intro {
    padding: 30px 22px 30px;

    border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
    .title {
      color: $base-color;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .intro-content {
      font-size: 14px;
      color: $text-color-grey;
    }
  }
}
</style>