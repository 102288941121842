<template>
  <div class="VueStar">
    <div class="VueStar__ground">
      <div
        class="VueStar__icon"
        @click="toggle"
        :class="AnimateClass"
        :style="{ color: ColorValue }"
      >
        <slot name="icon"></slot>
      </div>
      <div
        class="VueStar__decoration"
        :class="{ 'VueStar__decoration--active': active }"
      ></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const hexColors = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;
const rgbColors = /^[rR][gG][Bb][Aa]?[\(]([\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),){2}[\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),?[\s]*(0\.\d{1,2}|1|0)?[\)]{1}$/g;

export default {
  name: "VueStar",
  props: {
    animate: String,
    color: String,
    active: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    AnimateClass() {
      return this.active ? this.animate : "";
    },
    ColorValue() {
      return this.active ? this.color : "";
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    toggle() {
      this.toggleAnimate = this.active;
      this.toggleColor = this.active;
    },
    isColors(value) {
      return hexColors.test(value) || rgbColors.test(value);
    },
  },
  mounted() {
    if (this.color) {
      if (this.isColors(this.color)) {
        return;
      } else {
        console.error("this color must be hexcolor or rgbcolor  ---VueStar");
      }
    } else {
      return;
    }
  },
};
</script>

<style lang="scss">
@import "./style/main.scss";
</style>