import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import index from './pages/index/cn';
import login from './pages/login/cn';
import personal from './pages/personal/cn';
import other from './pages/other/cn';
const cn = {
  common: {
    placeHolder_Please_question_stem: '请输入题干',
    placeHolder_select: '请选择',
    placeHolder_enter: '请输入',
    placeHolder_upload: '请上传',
    login: '登录',
    register: '注册',
    keywords: '关键字',
    study: '学习',
    collected: '已收藏',
    collect: '收藏',
    learnNow: '立刻学习',
    buyNow: '立刻购买',
    continue: '继续',
    buy: '购买',
    own: '已购买',
    try: '试听',
    trying: '试听中',
    tryCount: '试听次数',
    noData: '暂无数据',
    submit: '提交',
    resubmit: '重新提交',
    confirm: '确 定',
    cancel: '取 消',
    close: '关闭',
    courseCategory: '分类',
    applicableArea: '地区',
    applicableIndustry: '行业',
    all: '全部',
    haveNotStarted: '未开始',
    learning: '学习中',
    waitingExamination: '待考试',
    makeUpExamination: '待补考',
    completed: '已完成',
    unfinished: '未完成',
    toSeeAll: '查看所有',
    toSee: '查看',
    questions: '我的提问',
    next: '下一步',
    upload: '上传文件',
    uploadV: '上传视频',
    video: '视频',
    sec: '秒',
    min: '分钟',
    day: '天',
    time: '有效期',
    format: '格式：',
    formatWrong: '格式错误',
    size: '大小',
    overSize: '文件过大',
    enter: '输入',
    save: '保存',
    delete: '删除',
    exports: '导出',
    imports: '导入',
    downloadTheImportTemplate: '下载导入模板',
    download: '下载',
    downloadOriginal: '下载原件',
    weight: '权重',
    tel: '手机',
    account: '用户名',
    companyName: '公司名称',
    name: '姓名',
    name2: '名称',
    email: '电子邮箱',
    bindEmail: '绑定邮箱',
    operation: '操作',
    choose: '选择',
    status: '状态',
    detail: '详情',
    remove: '移出',
    edit: '编辑',
    add: '新增',
    prompt: '提示',
    dept: '部门',
    post: '职务',
    modify: '修改',
    groupLeader: '小组长',
    success: '操作成功',
    modifyConfirm: '确定要修改吗？',
    deleteConfirm: '确定要删除吗？',
    cancelConfirm: '确定要取消吗？',
    title: '标题',
    topic: '题干',
    questionTypes: '题型',
    cover: '封面',
    category: '分类',
    genre: '类型',
    tag: '标签',
    createTime: '创建时间',
    submitTime: '提交时间',
    lastTime: '最近学习时间',
    broadcastTime: '开播时间',
    content: '内容',
    sort: '排序',
    send: '发送',
    more: '更多',
    correct: '对',
    incorrect: '错',
    errorRate: '错误率',
    sex: '性别',
    man: '男',
    woman: '女',
    answers: '答案',
    price: '价格',
    select: '选择',
  },
  titles: {
    index: '首页',
    login: '登录',
    reset: '重置密码',
    register: '注册',
    binding: '微信绑定',
    resetWx: '更换微信',
    removeWx: '解绑微信',
    wx: '微信',
    bindTel: '绑定手机号',
    survey: '需求调研',
    course: '课程',
    courseHot: '热门课程',
    courseZone: '课程专区',
    courseZoneName: '课程名称',
    courseZoneDetail: '课程详情',
    courseZoneEvaluate: '课程评价',
    coursePlan: '课程计划',
    coursePlanDetail: '课程计划',
    courseCustomization: '课程定制',
    curriculumHighlights: '课程亮点',
    teachingObject: '授课对象',
    lecturerInfo: '讲师信息',
    lecturerName: '讲师',
    lecturers: '讲师',
    adept: '擅长领域',
    prepareData: '预习资料',
    buyAgain: '课程补购',
    AlreadyBought: '人已经购买了',
    myHome: '首页',
    myCourse: '我的课程',
    CourseList: '课程列表',
    myCoursePlan: '我的课程计划',
    myNewsList: '新闻公告',
    myBannerList: '轮播图管理',
    orgVideoList: '视频库',
    orgCourseList: '机构课程',
    orgCoursePlanList: '机构课程计划',
    myQa: '课程问答',
    orgStatistics: '学习统计',
    StatusStudy: '学习状态',
    orgQuestionList: '试题列表',
    orgWrongSet: '错题管理',
    myTry: '我的试听',
    myQuestionList: '我的问答',
    myNoteList: '我的笔记',
    LookOverNoteList: '查看笔记',
    myCertificate: '我的证书',
    myWrongSet: '我的错题集',
    courseSubscribe: '课程预约',
    courseSubscribeDetail: '课程预约',
    courseStudy: '课程学习',
    exam: '在线考试',
    lecturer: '讲师风貌',
    lecturerDetail: '讲师详情',
    confirmOrder: '确认订单',
    orderPay: '订单支付',
    orderResult: '支付结果',
    refund: '申请退款',
    news: '新闻',
    newsDetail: '新闻详情',
    myInfo: '个人信息',
    myOrders: '我的订单',
    myMessage: '消息中心',
    myDemand: '课程需求',
    myDemandManage: '需求管理',
    mySubscribe: '预约课程',
    myCollNews: '收藏新闻',
    myCollTeacher: '收藏讲师',
    myCollCourse: '收藏课程',
    myCollCoursePlan: '收藏课程计划',
    myCommentNews: '评价的新闻',
    myCommentCourse: '评价的课程',
    myCommentCoursePlan: '评价的课程计划',
    mySurvey: '需求调研',
    resetTel: '修改手机号码',
    resetEmail: '修改邮箱',
    bindEmail: '绑定邮箱',
    orgManage: '机构人员管理',
    teacherInfo: '经验与专长',
    orgInfo: '机构信息',
    orgTypeList: '培训类型',
    orgTargetList: '目标受众',
    orgPlanList: '培训计划',
    orgTrainList: '培训',
    analysis: '解析',
  },
  pages: {
    index,
    login,
    personal,
    other,
  },
  header: {
    index: '首页',
    course_zone: '课程专区',
    course_plan: '课程计划',
    lecturer: '讲师风貌',
    news: '新闻',
    placeHolder_input: '输入课程或培训',
  },
  footer: {
    about_us: '关于我们',
    about_us_content:
      '锐合新创科技（北京）有限公司（简称“锐合”）是一家融合了行业先进的合规与风险咨询服务与创新监管科技应用的新创企业。',
    contacts: '联系我们',
    tel: '010-59576028(周一至周五 9:30-19:30)',
    location: '北京市东城区银河SOHO B座11层21106',
    location2: '香港九龙观塘巧明街116-118号万年工业大厦8层A17',
    follow_us: '关注我们',
    follow_us_wechat: '关注锐合微信公众号',
    follow_us_linkedin: '加入我们在领英上的群组',
  },
  ...zhLocale,
};

export default cn;
