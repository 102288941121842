<template>
  <div class="order-confirm">
    <div class="steps-area">
      <my-steps :active="active" :list="[$t('pages.other.tijiaodingdan'), $t('pages.other.dingdanzhifu'), $t('pages.other.kaikechenggong')]" />
    </div>
    <div class="bottom-content">
      <div class="img-area">
        <img src="@/assets/images/icon-success.png" alt="">
      </div>
      <p class="title">{{$t('pages.other.goumaichenggong')}}</p>
      <p class="subtitle">{{$t('pages.other.kaitongxiangguankecheng')}}</p>
      <el-button type="primary" class="my-btn" @click="toNext">{{ $t('pages.other.chakandingdan') }}</el-button>
    </div>
  </div>
</template>

<script>
import MySteps from '@/components/MySteps/MySteps.vue'
export default {
  components: { MySteps },
  data () {
    return {
      active: 3,
    }
  },
  methods: {
    toNext () {
      this.$router.push('/personal/my-orders');
    }
  }
}
</script>

<style lang="scss" scoped>
.order-confirm {
  width: 960px;
  margin: 0 auto;
  .steps-area {
    padding: 70px 30px 90px;
  }
  .bottom-content {
    margin-bottom: 215px;
    min-height: 520px;
    background: #ffffff;
    padding: 108px 0 0;
    text-align: center;
    .img-area {
      width: 66px;
      height: 66px;
      margin: 0 auto 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 24px;
    }
    .subtitle {
      font-size: 16px;
      color: $text-color-grey;
      margin-top: 5px;
    }
    .my-btn {
      margin-top: 30px;
      width: 174px;
      height: 42px;
      color: $text-color-black;
    }
  }
}
</style>
