<template>
  <div class="main-content">
    <!-- 说明 -->
    <div class="intro">
      <p>{{ $t('pages.personal.surveyTitle1') }}</p>
      <p>
        {{ $t('pages.personal.surveyTitle2') }}
      </p>
    </div>
    <div class="t-area" v-if="list.length > 0">
      <dl>
        <dt>
          <span class="index">{{ index + 1 }}、</span>
          <span class="stem">{{
            locale === 'cn'
              ? list[index].questionTest
              : list[index].questionTestEn
          }}</span>
        </dt>
        <el-checkbox-group v-model="list[index].checked">
          <dd
            v-for="option in list[index].options"
            :key="option.questionOptionId"
          >
            <el-checkbox :label="option.questionOptionId">
              {{ locale === 'cn' ? option.optionText : option.optionTextEn }}
            </el-checkbox>
          </dd>
        </el-checkbox-group>
      </dl>
      <div class="oper-area">
        <span class="btn prev" @click="toPrev" v-show="index > 0"
          ><i class="iconfont">&#xe627;</i>
          {{ $t('pages.personal.prev') }}</span
        >
        <span class="btn skip" @click="toSkip">{{
          $t('pages.personal.fillInTheLater')
        }}</span>
        <span
          class="btn next"
          @click="toNext"
          v-show="index !== list.length - 1"
          >{{ $t('pages.personal.next') }}
          <i class="iconfont">&#xe60a;</i></span
        >
        <span
          class="btn next"
          @click="toSubmit"
          v-show="index === list.length - 1"
          >{{ $t('pages.personal.finished') }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { selectSurveyList, updateSurvey } from '@/api/common';
export default {
  data() {
    return {
      index: 0,
      list: [],
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  methods: {
    toNextPage() {
      if (this.role === '2') {
        this.$router.push('/personal/org-info');
      } else {
        this.$router.push('/');
      }
    },
    async toSubmit() {
      try {
        const answer = [];
        this.list.forEach((item) => {
          answer.push({
            questionId: item.questionId,
            questionOptionId: item.checked.join(','),
          });
        });
        await updateSurvey(answer);
        this.$message.success(this.$t('common.success'));
        this.toNextPage();
      } catch (error) {
        console.log(error);
      }
    },
    toPrev() {
      if (this.index > 0) {
        this.index--;
      }
    },
    toNext() {
      if (this.index < this.list.length - 1) {
        this.index++;
      }
    },
    toSkip() {
      this.$confirm(
        this.$t('pages.personal.askSkip'),
        this.$t('common.prompt'),
        {
          type: 'warning',
        }
      )
        .then(() => {
          this.toNextPage();
        })
        .catch(() => {});
    },
    async getData() {
      try {
        const res = await selectSurveyList({
          locale: this.$store.state.locale,
        });
        res.forEach((item) => {
          item.checked = [];
        });
        this.list = res;
        if (this.list.length === 0) {
          this.toNextPage();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  .intro {
    color: $text-color-black;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 50px;
    p {
      padding: 0 30px;
    }
  }
  .t-area {
    width: 530px;
    margin: 0 auto;
    dl {
      min-height: 250px;
      dt {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      dd {
        color: $text-color-grey;
        font-size: 14px;
        padding: 5px 0 5px 26px;
      }
    }
    .oper-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      .btn {
        width: 100px;
        text-align: center;
        font-weight: bold;
        .iconfont {
          font-size: 14px;
        }
        &.skip {
          font-weight: normal;
          color: $text-color-grey;
          flex: 1;
        }
      }
    }
  }
}
</style>
