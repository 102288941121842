import { render, staticRenderFns } from "./my_home.vue?vue&type=template&id=779ac7cd&scoped=true&"
import script from "./my_home.vue?vue&type=script&lang=js&"
export * from "./my_home.vue?vue&type=script&lang=js&"
import style0 from "./my_home.vue?vue&type=style&index=0&id=779ac7cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779ac7cd",
  null
  
)

export default component.exports