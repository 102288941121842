<template>
  <div>
    <div class="header-area">
      <div class="left">{{ $t('pages.personal.messages') }}</div>
      <div class="right" v-if="role === '2'">
        <el-button type="primary" size="small" @click="showDetail = true">{{
          $t('pages.personal.sendMsg')
        }}</el-button>
      </div>
    </div>
    <div class="info-area">
      <div
        class="oper-area"
        v-if="role === '1' || role === '2' || role === '3'"
      >
        <el-checkbox v-model="checked">{{
          $t('pages.personal.onlyOrgMsg')
        }}</el-checkbox>
      </div>
      <!-- 列表 -->
      <ul class="list">
        <li v-for="item in list" :key="item.msgId">
          <div class="up-area">
            <span class="author">{{ item.ancestorName }}</span>
            <span class="create-time">{{ item.createTime }}</span>
          </div>
          <div class="down-area">{{ item.msgContent }}</div>
        </li>
      </ul>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="page"
          :page-size="pageSize"
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 发布消息弹窗 -->
    <el-dialog
      :title="$t('pages.personal.sendMsg')"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showDetail"
    >
      <div class="popup-content form">
        <el-input
          v-model="content"
          type="textarea"
          :rows="10"
          :placeholder="$t('common.placeHolder_enter')"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{
          $t('common.cancel')
        }}</el-button>
        <el-button type="primary" @click="toSubmit">{{
          $t('common.submit')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectMessageList, insertMessageData } from '@/api/common';

export default {
  data() {
    return {
      checked: false,
      showDetail: false,
      content: '',
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    role() {
      // 0散户，1机构学员，2班主任，3小组长，4讲师
      return this.userinfo.roles[this.$store.state.currentRoleIndex];
    },
  },
  watch: {
    checked() {
      this.page = 1;
      this.getData();
    },
  },
  methods: {
    async toSubmit() {
      if (this.content) {
        try {
          await insertMessageData({
            msgContent: this.content,
          });
          this.$message.success(this.$t('common.success'));
          this.content = '';
          this.showDetail = false;
          this.page = 1;
          this.getData();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const res = await selectMessageList({
          seeOrg: this.checked ? 0 : 1,
          pageNum: this.page,
          pageSize: this.pageSize,
        });
        this.isLoading = false;
        this.total = res.total;
        this.list = res.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header-area {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-area {
  padding: 20px 30px 60px;
  .oper-area {
    text-align: right;
  }
  .list {
    li {
      padding: 30px 0;
      border-bottom: 1px solid #efefef;
      .up-area {
        font-size: 16px;
        color: $text-color-grey;
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        .create-time {
          font-size: 14px;
          margin-left: 22px;
        }
      }
      .down-area {
        font-size: 14px;
        color: #363840;
        line-height: 24px;
      }
    }
  }
  .pagination-area {
    // border: 1px solid #EBEEF5;
    border-top: none;
    padding: 50px 0 18px;
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
