import ImgPreviewComponent from "./ImgPreview";

const ImgPreview = {};

ImgPreview.install = (Vue) => {
  const ImgConstructor = Vue.extend(ImgPreviewComponent);

  const instance = new ImgConstructor();

  instance.$mount(document.createElement("div"));

  document.body.appendChild(instance.$el);

  Vue.prototype.$imgPreview = (options) => {
    instance.imgs = (options && options.imgs) || [];
    instance.index = (options && options.index) || 0;
    instance.isShowImageDialog = true;
  };
};

export default ImgPreview;
